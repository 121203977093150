import React, { Component } from 'react';
import { getData } from '../services';
export default class trackorder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      trackOrderDetails: [],
      orderStatusDetails: [],
    }
  }
  componentDidMount() {
    let buyerId = localStorage.getItem('userId');
    const postData = {
      functionName: "userdashboarddetails/trackOrder",
      buyerId: buyerId,
    }
    getData(postData).then((data) => {
      if (data.data.success == "1") {
        this.setState({
          trackOrderDetails: data.data.result.trackOrder
        });
      }
    })
  }
  render() {
    return (
      <div className="accountdetails">
        <div className="dashTitle">Track Order</div>
        <div className="dashSection">
          <div className="trackOrder">
            {this.state.trackOrderDetails.map((trackOrderDetailsobj, i) => (
              <div className="trackOrderList">
                <div className="storeTrack">{trackOrderDetailsobj.storeName}</div>
                <div className="storeTrackDate">
                  <span>Order Number: {trackOrderDetailsobj.orderNo}</span>
                  <span>Order Date: {trackOrderDetailsobj.orderDate}</span>
                </div>
                <div className="trackPath">
                  {trackOrderDetailsobj.orderStatus.map((orderStaus, index) => (
                    (() => {
                      if (orderStaus.isActive == '1') {
                        return (
                          <span className="trackActive">{orderStaus.name}</span>
                        )
                      }
                      else {
                        return (
                          <span >{orderStaus.name}</span>
                        )
                      }
                    })()
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}
