import React, { Component } from 'react';
import close from "../images/icons/close.png"
import '../css/slick.css';
import '../css/slick-theme.css';
import Slider from 'react-slick';
import { getData } from '../services';
import CartBtn from "../headerComponents/addcartBtn"

export default class savemore extends Component {
  constructor(props) {
    super(props)
    this.state = {
      itemData: [],
      swapItemData: [],
      isLoading: '1'
    };
  }
  componentDidUpdate() {
  }
  componentDidMount() {
    const postData = {
      functionName: "getitemresult/saveMore",
      sisId: this.props.sisId,
      itemPrice: this.props.price,
      unit: this.props.unit,
      itemId: this.props.itemId,
      cityId: this.props.cityId,
      storeId: this.props.storeId,
      selectedProduct: JSON.parse(localStorage.getItem('selectedCartProduct'))
    }

    if (this.props.flag == '1') {
      getData(postData).then((data) => {
        if (data.data.success === "1") {
          this.setState({
            itemData: data.data.result.itemData,
            swapItemData: data.data.result.swapItemData,
            isLoading: '1'
          });
        }
      })
    }
  }

  render() {
    let themeId = localStorage.getItem('themeId')
    let cartLabel = 'ADD TO CART'
    if (themeId === '36') {
      cartLabel = '+'
    }
    else if (themeId === '35') {

    }
    else if (themeId === '34' || themeId === '29'|| themeId === '4') {
      cartLabel = '<span><img src="https://storedigfiles.s3.amazonaws.com/production/themes/cartBtnLokal.png" /> ADD TO CART</span>'
    }
    const itemSetting = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            swipeToSlide: true,
            speed: 200
          }
        }
      ]
    };
    return (
      <div>
        <div className="popOverlay"></div>
        <div className="loginPop saveMoreDiv">
          <div className="loginHead">
            <span className="saveClose"><img src={close} alt="" /></span>
            <div className="loginText">Save more</div>
          </div>
          <div className="saveMoreContent">
            <div className="saveMoreItem">
              <div className="productList">
                {this.state.itemData.map((itemData, index) =>
                  <div className="productItem">
                    <input type="hidden" id={"sisId" + ('Main' + index)} value={itemData.sisId} />
                    <input type="hidden" id={"itemId" + ('Main' + index)} value={itemData.itemId} />
                    <input type="hidden" id={"price" + ('Main' + index)} value={itemData.netAmount} />
                    <input type="hidden" id={"unit" + ('Main' + index)} value="" />
                    <input type="hidden" id={"packId" + ('Main' + index)} value={itemData.packId} />
                    <input type="hidden" id={"itemStockQuantity" + ('Main' + index)} value={itemData.quantity} />
                    <input type="hidden" id={"itemName" + ('Main' + index)} value={itemData.itemName} />
                    <input type="hidden" id={"mrp" + ('Main' + index)} value={itemData.mrp} />
                    <input type="hidden" id={"image" + ('Main' + index)} value={itemData.imageName} />
                    <input type="hidden" id={"hidPackIndex" + ('Main' + index)} value={'Main' + index} />
                    {itemData.isOffer == true ?
                      <span className="offerText"> {itemData.offerText}</span>
                      : ""}
                    <div className="productImage">
                      <a href={itemData.slug}>
                        <img alt={itemData.imageName} title={itemData.imageName} src={itemData.imageName} />
                      </a>
                    </div>
                    <div className="productDetails">
                      <div className="brandName">{itemData.brandName}</div>
                      <div className="productName">
                        <a href={itemData.slug} title={itemData.itemName} className="elipsis">{itemData.itemName}</a>
                      </div>
                      <div className="productQty">{itemData.packName}</div>
                      <div className="productPrice">
                        {itemData.displayPrice == true ? <span className="newPrice">{parseFloat(itemData.netAmount).toFixed(window.sessionStorage.getItem('decimalCount'))}</span> : <span className="newPrice">{parseFloat(itemData.mrp).toFixed(window.sessionStorage.getItem('decimalCount'))}</span>}
                        {itemData.mrp > itemData.netAmount ? <span className="oldPrice">{parseFloat(itemData.mrp).toFixed(window.sessionStorage.getItem('decimalCount'))}</span> : ""}
                      </div>
                      <div className="addCartButton">
                        <CartBtn
                          index={"Main" + index}
                          item_names={itemData.itemName}
                          item_ids={itemData.itemId}
                          item_prices={itemData.netAmount}
                          store_item_stock_ids={itemData.sisId}
                          pack_ids={itemData.packId}
                          stock_quantity={itemData.quantity}
                          itemAddedColour="itemAddedColourMain"
                          qtyError="qtyErrorMain"
                          itemCount="itemCountMain"
                          cartLabel={cartLabel}
                          fromPage="Profile"
                          cartItemFlag={itemData.cartItems.length > 0 ? true : false}
                          cartItemQuantity={itemData.cartItems.length > 0 ? itemData.cartItems[0].quantity : 1}
                          itemImage={itemData.imageName}
                        ></CartBtn>
                      </div>
                    </div>
                    {itemData.cartItems.length > 0 ?
                      <div>
                        <div className="itemAdded itemDisplay" id={"itemAddedColourMainMain" + index}><span className="itemAddCount" id={"itemCountMainMain" + index}>{itemData.cartItems[0].quantity}</span></div>
                        <div className="itemAlertText" id={'qtyErrorMainMain' + index}></div>
                      </div>
                      :
                      <div>
                        <div className="itemAdded" id={"itemAddedColourMainMain" + index}><span className="itemAddCount" id={"itemCountMainMain" + index}></span></div>
                        <div className="itemAlertText" id={'qtyErrorMainMain' + index}></div>
                      </div>
                    }
                  </div>
                )}
              </div>
            </div>
            <div className="relatedSlider saveMoreSlider">
              <Slider {...itemSetting}>
                {this.state.swapItemData.map((swapItem, index) =>
                  <div>
                    <div className="productItem">
                      <input type="hidden" id={"sisId" + ('Swap' + index)} value={swapItem.sisId} />
                      <input type="hidden" id={"itemId" + ('Swap' + index)} value={swapItem.itemId} />
                      <input type="hidden" id={"price" + ('Swap' + index)} value={swapItem.netAmount} />
                      <input type="hidden" id={"unit" + ('Swap' + index)} value="" />
                      <input type="hidden" id={"packId" + ('Swap' + index)} value={swapItem.packId} />
                      <input type="hidden" id={"itemStockQuantity" + ('Swap' + index)} value={swapItem.quantity} />
                      <input type="hidden" id={"itemName" + ('Swap' + index)} value={swapItem.itemName} />
                      <input type="hidden" id={"mrp" + ('Swap' + index)} value={swapItem.mrp} />
                      <input type="hidden" id={"image" + ('Swap' + index)} value={swapItem.imageName} />
                      <input type="hidden" id={"hidPackIndex" + ('Swap' + index)} value={'Swap' + index} />
                      {swapItem.isOffer == true ?
                        <span className="offerText"> {swapItem.offerText}</span>
                        : ""}
                      <div className="productImage">
                        <a href={swapItem.slug}>
                          <img alt={swapItem.imageName} title={swapItem.imageName} src={swapItem.imageName} />
                        </a>
                      </div>
                      <div className="productDetails">
                        <div className="brandName">{swapItem.brandName}</div>
                        <div className="productName">
                          <a href={swapItem.slug} title={swapItem.itemName} className="elipsis">{swapItem.itemName}</a>
                        </div>
                        <div className="productQty">{swapItem.packName}</div>
                        <div className="productPrice">
                          {swapItem.displayPrice == true ? <span className="newPrice">{parseFloat(swapItem.netAmount).toFixed(window.sessionStorage.getItem('decimalCount'))}</span> : <span className="newPrice">{parseFloat(swapItem.mrp).toFixed(window.sessionStorage.getItem('decimalCount'))}</span>}
                          {swapItem.mrp > swapItem.netAmount ? <span className="oldPrice">{parseFloat(swapItem.mrp).toFixed(window.sessionStorage.getItem('decimalCount'))}</span> : ""}
                        </div>
                        <div className="addCartButton">
                          <CartBtn
                            index={"Swap" + index}
                            item_names={swapItem.itemName}
                            item_ids={swapItem.itemId}
                            item_prices={swapItem.netAmount}
                            store_item_stock_ids={swapItem.sisId}
                            pack_ids={swapItem.packId}
                            stock_quantity={swapItem.quantity}
                            itemAddedColour="itemAddedColourSwap"
                            qtyError="qtyErrorSwap"
                            itemCount="itemCountSwap"
                            cartLabel={cartLabel}
                            fromPage="Profile"
                            cartItemFlag={swapItem.cartItems.length > 0 ? true : false}
                            cartItemQuantity={swapItem.cartItems.length > 0 ? swapItem.cartItems[0].quantity : 1}
                            itemImage={swapItem.imageName}
                          ></CartBtn>
                        </div>
                      </div>
                      {swapItem.cartItems.length > 0 ?
                        <div>
                          <div className="itemAdded itemDisplay" id={"itemAddedColourSwapSwap" + index}><span className="itemAddCount" id={"itemCountSwapSwap" + index}>{swapItem.cartItems[0].quantity}</span></div>
                          <div className="itemAlertText" id={'qtyErrorSwapSwap' + index}></div>
                        </div>
                        :
                        <div>
                          <div className="itemAdded" id={"itemAddedColourSwapSwap" + index}><span className="itemAddCount" id={"itemCountSwapSwap" + index}></span></div>
                          <div className="itemAlertText" id={'qtyErrorSwapSwap' + index}></div>
                        </div>
                      }
                    </div>
                  </div>
                )}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
