import React, { Component } from "react";
import BannerImg1 from "../school/assets/images/bannerImage.jpg";

export default class BannerSlider extends Component {

    render() {
        return (
            <div className="bannerImgesProd"  >
                <img src={BannerImg1} alt="" className="img-fluid"/>
            </div>
        )
    }
}