import React, { Component } from "react"
import BorderImg from "./assets/images/separator.svg";
import leftBg from "./assets/images/bg-5.png";
import rightBg from "./assets/images/bg-6.png";
import dish1 from "./assets/images/puttuBiriyani.jpg";
import dish2 from "./assets/images/orotti_(1).jpg";
import dish3 from "./assets/images/kesari_peda.jpg";
import dish4 from "./assets/images/doda_burfi.jpg";
import dish5 from "./assets/images/moti_pak.jpg";
import dish6 from "./assets/images/kova_apple.jpg";
import dish7 from "./assets/images/coconut_orange.jpg";
import dish8 from "./assets/images/cake.jpg";
import dish9 from "./assets/images/kaju_burfi.jpg";
import dish10 from "./assets/images/kaju_mango.jpg";
import dish11 from "./assets/images/kaju_apple.jpg";
import dish12 from "./assets/images/chaat.jpg";
import dish13 from "./assets/images/pista_halwa.jpg";
import dish14 from "./assets/images/muscoth_halwa.jpg";
import dish15 from "./assets/images/boondi-ke-ladoo.jpg";
import dish16 from "./assets/images/tirupati-laddu.jpg";
import dish17 from "./assets/images/besan.jpg";
import dish18 from "./assets/images/Unnakkaya.jpg";
import dish19 from "./assets/images/Kunji-Kalathappam.jpg";
import dish20 from "./assets/images/malpuri.png";
import dish21 from "./assets/images/ela_ada.jpg";
import dish22 from "./assets/images/elanchi.jpg";
import dish23 from "./assets/images/banana_stuffed.jpg";
import dish24 from "./assets/images/syrianbeef.jpg";
import dish25 from "./assets/images/chicken.jpg";
import dish26 from "./assets/images/black_forest.jpg";
import dish27 from "./assets/images/butter_scotch.png";
import dish28 from "./assets/images/red-velvet.png";
import dish29 from "./assets/images/Vegetable-Sandwich.jpg";
import dish30 from "./assets/images/veg-burger.png";
import dish31 from "./assets/images/veg_pizza.jpeg";
import dish32 from "./assets/images/Chicken_Pizza.jpg";

import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faMapMarker } from '@fortawesome/free-solid-svg-icons'
export default class MenuStatic extends Component {
    render() {
        return (
            <>
                <div className="categorOuter menuOuter">
                    <div class="left-bot-bg">
                        <img src={leftBg} alt="" title="" />

                    </div>
                    <div class="right-top-bg">
                        <img src={rightBg} alt="" title="" />

                    </div>
                    <div className="auto-container">
                        <div className="mraTitle">
                            <span>SPECIAL SELECTION</span>
                            <img src={BorderImg} alt="" />

                            <div className="contentSectn">
                                <h1>Delicious Menu</h1>
                            </div>

                        </div>
                        {/*  */}
                        <Tabs>
                            <TabList>
                                <Tab>Biriyani</Tab>
                                <Tab>Breads</Tab>
                                <Tab>Burfies & Pedas</Tab>
                                <Tab>Cakes</Tab>
                                <Tab>Cashew Sweets</Tab>
                                <Tab>Chaat</Tab>
                                <Tab>Halwas</Tab>
                                <Tab>Ladus</Tab>
                                <Tab>Malabar Varieties</Tab>
                                <Tab>Non Veg</Tab>
                                <Tab>Pastries</Tab>
                                <Tab>Snacks</Tab>
                            </TabList>
                            <TabPanel>
                                <div class="row clearfix">
                                    <div class="menu-col col-lg-12 col-md-12 col-sm-12 menuStaticMain">
                                    <p className="menuTagline">  Indians favorite food throughout the year... Biriyani... </p>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish1} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">Puttu Biriyani</Link></h1> 
                                               <p> This one will be surprising for those who have not tried it yet. Why wait? Come enjoy it at our newly opened restaurant at Venpalavattom. </p>
                                                <Link to="/menuStaticDetails" > <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA LA CUISINE RESTAURANT</Link>

                                                </div>
                                                 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div class="row clearfix">
                                    <div class="menu-col col-lg-12 col-md-12 col-sm-12 menuStaticMain">
                                    <p className="menuTagline"> We serve a variety of Kerala bread, among which our Malabar bread is the most popular.</p>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish2} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">MALABAR OROTTI</Link></h1> 
                                               <p>Orotti is famous through our Kerala but one should definitely try our Malabar Orotti. You can taste it at our outlet in Venpalavattom - TVM.</p>
                                                <Link to="/menuStaticDetails"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA LA CUISINE RESTAURANT</Link>
                                                </div>                 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div class="row clearfix">
                                    <div class="menu-col col-lg-12 col-md-12 col-sm-12 menuStaticMain">
                                    <p className="menuTagline"> Burfies and Pedas are all time favourite sweets. </p>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish3} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">KESARI PEDA</Link></h1> 
                                               <p>Saffron-flavored peda bejeweled with pistachios – a visual and gastronomical delight!</p>
                                                <Link to="/menuStaticDetails"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS</Link>
                                                <Link to="/menuStaticDetails"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS</Link>
                                                <Link to="/menuStaticDetails"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS</Link>
                                                </div>                 
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish4} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">DODA BURFI</Link></h1> 
                                               <p>Rich in taste and different from other barfi recipes, Dodha Barfi is a traditional dessert and has a grainy texture that melts in your mouth.</p>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS</Link>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS</Link>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS</Link>
                                                </div>                 
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish5} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">MOTI PAK SANDWICH</Link></h1> 
                                               <p>Moti Pak Sandwitch is a delicious sweet burfi.</p>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS</Link>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS</Link>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS</Link>
                                                </div>                 
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish6} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">KOVA APPLE SWEET</Link></h1> 
                                               <p>Kova Apple Sweet is a traditional dessert made in festive seasons.</p>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS</Link>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS</Link>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS</Link>
                                                </div>                 
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish7} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">COCONUT ORANGE</Link></h1> 
                                               <p>Delicious Orange and Coconut Burfi.</p>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS</Link>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS</Link>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS</Link>
                                                </div>                 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div class="row clearfix">
                                    <div class="menu-col col-lg-12 col-md-12 col-sm-12 menuStaticMain">
                                    <p className="menuTagline">Help celebrate a special day or commemorate a significant occasion with a sweet treat from MRA.</p>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish8} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">FRESH CREAM CAKE</Link></h1> 
                                               <p>We serve delicious fresh cream cakes for auspicious occasions and parties.</p>
                                                <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & COOLZONE</Link>
                                                <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & COOLZONE</Link>
                                                <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & COOLZONE (Kesavadasapuram)</Link>
                                                <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & COOLZONE (technopark-phase2-ustglobal)</Link>
                                                <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & CAFÉ</Link>
                                                <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY (Nanthancode)</Link>
                                                </div>                 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div class="row clearfix">
                                    <div class="menu-col col-lg-12 col-md-12 col-sm-12 menuStaticMain">
                                    <p className="menuTagline"> Cashew sweets have a smooth & melt-in-the-mouth texture with mild flavours. </p>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish9} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">KAJU BURFI</Link></h1> 
                                               <p>Kaju Burfi is an Indian dessert similar to a barfi. Kaju means cashew; Barfi is often, but not always, made by thickening milk with sugar and other ingredients. </p>
                                                <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS </Link>
                                                <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS (Chakai)</Link>
                                                <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS (Vanchiyoor)</Link>
                                                </div>                 
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish10} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">KAJU MANGO</Link></h1> 
                                               <p>Kaju Mango is sweet and tangling delicious.</p>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS</Link>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS (Chakai)</Link>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS (Vanchiyoor)</Link>
                                                </div>                 
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish11} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">KAJU APPLE</Link></h1> 
                                               <p>Kaju Apples are made on auspicious festivals.</p>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS</Link>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS (Chakai)</Link>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS (Vanchiyoor)</Link>
                                                </div>                 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div class="row clearfix">
                                    <div class="menu-col col-lg-12 col-md-12 col-sm-12 menuStaticMain">
                                    <p className="menuTagline"> We serve several varieties of chaat at MRA Chaat Bazaar. </p>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish12} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">PANI PURI</Link></h1> 
                                               <p>Pani Puri is a tasty and popular CHAAT all over India.</p>
                                                <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA  CHAAT BAZAAR</Link>
                                                </div>                 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div class="row clearfix">
                                    <div class="menu-col col-lg-12 col-md-12 col-sm-12 menuStaticMain">
                                    <p className="menuTagline"> Halwa is a very sweet and delicious dessert common in all get-together. </p>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish13} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">MALABAR HALWA</Link></h1> 
                                               <p>Malabar Halwa is one of the most famous halwa.</p>
                                                <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS </Link>
                                                <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS (Chakai)</Link>
                                                <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS (Vanchiyoor)</Link>
                                                </div>                 
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish14} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">MUSCOTH HALWA</Link></h1> 
                                               <p>It is also known as Muscat halwa and finds its origins in the Tirunelveli district of Tamil Nadu.</p>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS</Link>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS (Chakai)</Link>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS (Vanchiyoor)</Link>
                                                </div>                 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div class="row clearfix">
                                    <div class="menu-col col-lg-12 col-md-12 col-sm-12 menuStaticMain">
                                    <p className="menuTagline"> Laddus are made of flour, ghee/butter/oil and sugar, with other ingredients that vary by recipe, like chopped nuts or dried raisins. </p>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish15} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">MOTICHOOR LADOO/LADDU</Link></h1> 
                                               <p>Motichur Laddu / Motichoor Ladoo / Boondi Na Ladva is very popular round shaped Indian Sweet made with chickpea pearls called boondi and saffron and cardamom flavored sugar syrup. </p>
                                                <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS </Link>
                                                <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS (Chakai)</Link>
                                                <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS (Vanchiyoor)</Link>
                                                </div>                 
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish16} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">TIRUPATI LADDU</Link></h1> 
                                               <p>Tirupathi Laddu is very famous and needs no introduction to most South Indians and many North Indians. It is a sweet, offered as Prasadam/ Prasad to the Lord Venkateswara Swami of Tirumala Temple.</p>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS</Link>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS (Chakai)</Link>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS (Vanchiyoor)</Link>
                                                </div>                 
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish17} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">BESAN LADU</Link></h1> 
                                               <p>Besan Ladu is a healthy and nutritious sweet.</p>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS</Link>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS (Chakai)</Link>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS (Vanchiyoor)</Link>
                                                </div>                 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div class="row clearfix">
                                    <div class="menu-col col-lg-12 col-md-12 col-sm-12 menuStaticMain">
                                    <p className="menuTagline"> Delecious malabar varieties are avalable at MRA. </p>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish18} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">UNNAKKAYA</Link></h1> 
                                               <p>‘Unnakkaya’ is a very popular dish across Kerala. It is also one of the first items that come to one’s mind when thought about Malabar special snacks. It is prepared using smashed banana stuffed with coconut and sugar and later fried in oil. </p>
                                                </div>                 
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish19} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">KUNJI KALATHAPPAM</Link></h1> 
                                               <p>Kunji Kalathappam is a special recipe mostly popular in the northern regions of Kerala.</p>
                                                </div>                 
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish20} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">MALPURI</Link></h1> 
                                               <p>Malpuri is a delicious Indian recipe served as a Dessert.</p>
                                                </div>                 
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish21} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">ELA ADA</Link></h1> 
                                               <p>Ella Ada is an Indian sweet and traditional Kerala delicacy, consisting of rice parcels encased in a dough made of rice flour, with fresh coconut and jaggery fillings, steamed in banana leaf and served as an evening snack.</p>
                                                </div>                 
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish22} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">ELANCHI</Link></h1> 
                                               <p>Elanchi / Coconut Stuffed Crepes is a tasty evening snack.</p>
                                                </div>                 
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish23} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">PAZHAM NIRACHATHU</Link></h1> 
                                               <p>Pazham nirachathu / Banana stuffed with a sweet mixture of coconut, egg, and sugar is another Malabar dish, usually served during iftar.</p>
                                                </div>                 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div class="row clearfix">
                                    <div class="menu-col col-lg-12 col-md-12 col-sm-12 menuStaticMain">
                                    <p className="menuTagline">Halal meat is used at all our outlets to serve you the best taste of Malabar </p>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish24} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">SYRIAN BEEF FRY</Link></h1> 
                                               <p>Come enjoy this signature dish from MRA at our newly opened outlet at Venpalavattom TVM</p>
                                                <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA LA CUISINE RESTAURANT</Link>
                                                </div>                 
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish25} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">RAYROTH CHICKEN FRY</Link></h1> 
                                               <p>Chicken meat lovers should try our masterpiece and signature dish Rayroth Chicken Fry from our newly opened restaurant at Venpalavattom - TVM.</p>
                                                <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA LA CUISINE RESTAURANT</Link>
                                                </div>                 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div class="row clearfix">
                                    <div class="menu-col col-lg-12 col-md-12 col-sm-12 menuStaticMain">
                                    <p className="menuTagline"> We serve different varieties of pastries at MRA. </p>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish26} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">BLACK /WHITE FOREST</Link></h1> 
                                               <p>We provide spongy black forest cake sandwiched with soft whipped cream and berries, is perfect for every celebration and loved by all.</p>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS </Link>
                                                <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS (Chakai)</Link>
                                                <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS (Vanchiyoor)</Link>
                                                </div>                 
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish27} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">BUTTER SCOTCH</Link></h1> 
                                               <p>Crunchy and yummy butter scotch pastries are available at MRA.</p>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS </Link>
                                                <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS (Chakai)</Link>
                                                <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS (Vanchiyoor)</Link>
                                                </div>                 
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish28} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">RED VELVET</Link></h1> 
                                               <p>Red Velvet Pastry is beautiful and delicious. It is best to offer a heavenly experience to the taste buds of your loved ones and make their major occasions unforgettable.</p>
                                               <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS </Link>
                                                <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS (Chakai)</Link>
                                                <Link to="/menuStaticDetails" target="new"> <FontAwesomeIcon icon={faMapMarker} />&nbsp; MRA BAKERY & SWEETS (Vanchiyoor)</Link>
                                                </div>                 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div class="row clearfix">
                                    <div class="menu-col col-lg-12 col-md-12 col-sm-12 menuStaticMain">
                                    <p className="menuTagline"> Different varieties of snacks are available at MRA. </p>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish29} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">VEG/CHICKEN SANDWITCH</Link></h1> 
                                               <p>We serve tasty veg/chicken  sandwitch</p>
                                                </div>                 
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish30} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">VEG/CHICKEN BURGER</Link></h1> 
                                               <p>We provides delecious veg/chicken burger.</p>
                                                </div>                 
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish31} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">VEG PIZZA</Link></h1> 
                                               <p>We serve yummy veg pizza.</p>
                                                </div>                 
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="menuImgsm">
                                                    <img src={dish32} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 menuStatRWrap">
                                                <div class="mraMenuRigt"> 
                                                <h1><Link to="/">CHICKEN PIZZA</Link></h1> 
                                               <p>We serve delectable chicken pizza.</p>
                                                </div>                 
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>
                        {/*  */}
                        <div className="timeInfo booktbles">
                            {/* <p>During winter daily from <span >7:00 pm</span> to <span>9:00 pm</span></p> */}
                            <Link to="/mraMenu" className="btn-style-one  btn-9" style={{ color: "#fff" }}>View All</Link>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}