
import { getData } from './services';
const ACCESS_KEY = process.env.REACT_APP_ACCESSDENIED
export default {
    jwtSecret: "somesecretkeyforjsontoken"
    //jwtSecret: "mYySh0pPp0weR"    
}

// export function elasticLink() {
//     const elsticLink = "ms-prod";
//     return elsticLink;
// }

// export function elasticHostURL() {
//     const elasticHostURL = "https://search-rhealth-prod-d53qbfwui7ffwcaelbhygfdlq4.ap-south-1.es.amazonaws.com";
//     return elasticHostURL;
// }

// export function environmentType() {
//     const environmentType = "production";
//     return environmentType;
// }

export function elasticLink() {
    const elsticLink = "ms-prod";
    return elsticLink;
}
export function elasticHostURL() {
    const elasticHostURL = "https://search-rhealth-prod-d53qbfwui7ffwcaelbhygfdlq4.ap-south-1.es.amazonaws.com";
    return elasticHostURL;
}
export function environmentType() {
    const environmentType = "production";
    return environmentType;
}
export function printParams(paramsfrom,paramsValue) {
  if(ACCESS_KEY === "ON"){
    console.log(paramsfrom,paramsValue)
  }
}

export function fetchSiteDetails() {
    var serverName = window.location.host   
    var siteUrl = serverName
    var sellerId = ''
    var categoryId = ''
    if (window.sessionStorage.getItem('sellerId') !== '0' && window.sessionStorage.getItem('sellerId') !== 'null' && window.sessionStorage.getItem('sellerId') !== null && window.sessionStorage.getItem('sellerId') !== '') {
        siteUrl = ''
        sellerId = window.sessionStorage.getItem('sellerId')
    }
    const postData = {
        functionName: "getsitedetail",
        siteUrl: siteUrl,
        storeId: "",
        sellerId: sellerId,
        categoryId: categoryId
    }
    getData(postData).then((data) => {
       // console.log('getsitedetails....data..signup...', data)
        if (data.data.success === "1") {
            if (data.data.results.siteData[0].storeId !== "") {
                window.sessionStorage.setItem('subDomainFlag', data.data.results.siteData[0].subDomainFlag)
                window.sessionStorage.setItem('storeId', data.data.results.siteData[0].storeId)
                window.sessionStorage.setItem('mainGroupCategory', data.data.results.siteData[0].mainGroupCategoryId)
                window.sessionStorage.setItem('mainCategoryId', data.data.results.siteData[0].mainCategoryId);
            }
            localStorage.setItem('siteId', data.data.results.siteData[0].siteId);
            window.sessionStorage.setItem('googleAnalyticsId', data.data.results.siteData[0].googleAnalyticsId);
            window.sessionStorage.setItem('sellerId', data.data.results.siteData[0].sellerId);
            window.sessionStorage.setItem('siteCategoryId', data.data.results.siteData[0].siteCategoryGroupId);
            localStorage.setItem('themeId', data.data.results.siteData[0].themeId);
            window.sessionStorage.setItem('IsEmbeded', true);
            window.sessionStorage.setItem('siteDetails', JSON.stringify(data.data.results));
            if (data.data.results.siteData[0].siteCategoryGroupId !== 0) {
                window.sessionStorage.setItem('hasLocation', '1');
                window.sessionStorage.setItem('displayCity', data.data.results.siteData[0].siteCategoryData[0].display_city);
            }
            else {
                window.sessionStorage.setItem('hasLocation', data.data.results.siteData[0].sellerData[0].hasLocation);
            }
            if (data.data.results.siteData[0].sellerId !== 0) {
                window.sessionStorage.setItem('storeId', data.data.results.siteData[0].sellerData[0].storeData[0].storeId);
                window.sessionStorage.setItem('mainCategoryId', data.data.results.siteData[0].sellerData[0].storeData[0].mainCategoryId);
                window.sessionStorage.setItem('mainGroupCategory', data.data.results.siteData[0].sellerData[0].storeData[0].mainGroupCategoryId)
            }
           // $("#defaultBannerLoad").hide();
           return true;
        }
    });
    
}

