import React, { Component } from 'react'
import { getCartItemsMultiple } from '../services'
import $ from "jquery"
export default class Promocode extends Component {
    constructor(props) {
        super(props)
        this.state = {
            promocodeDetails: [],
            isPromoClick: '1'
        }
    }
    componentDidMount() {

    }
    showPromocodes() {
        $("#promoLink" + this.props.storeId).hide()
        $(".checkoutAvailPromo" + this.props.storeId).show()
        var postData = {
            "functionName": "checkPromoCode",
            "storeId": this.props.storeId,
            "myCategoryId": "",
            "userId": localStorage.getItem('userId'),
            "totalAmount": this.props.amount,
            "isApp": "0",
            "timeZone": new Date().getTimezoneOffset().toString()
        }
        getCartItemsMultiple(postData).then((data) => {
            if (data.data.success === "1") {
                if (data.data.result[0].promocodeDetails.length > 0) {
                    $("#promoNoDataLink" + this.props.storeId).hide();
                    this.setState({
                        promocodeDetails: data.data.result[0].promocodeDetails
                    });
                }
                else {
                    $("#promoNoDataLink" + this.props.storeId).show();
                    this.setState({
                        promocodeDetails: []
                    });
                }
            }
        })
    }
    setPromoCode(promoCode, storeId, promocodeDetailsList) {
        $("#txtPromocode" + storeId).val(promoCode)
        $("#txtPromocode" + storeId).focus();
        $("#hidPromocodeDetails" + storeId).val(JSON.stringify(promocodeDetailsList))
    }
    applypromocode(storeId, amount) {
        if ($("#txtPromocode" + storeId).val() === "") {
            alert("Promo Code Required");
            $("#txtPromocode" + storeId).focus()
        }
        else {
            if ($("#hidPromocodeDetails" + storeId).val() === "") {
                var promocodeArray = []
                promocodeArray = this.state.promocodeDetails
                var promoFlag = '0'
                for (var i = 0; i < promocodeArray.length; i++) {
                    if (promocodeArray[i].promoCode == $("#txtPromocode" + storeId).val()) {
                        $("#hidPromocodeDetails" + storeId).val(JSON.stringify(promocodeArray[i].promocodeDetailsList))
                        promoFlag = '1'
                        break;
                    }
                }
                if (promoFlag === '1') {
                    $("#applyPromo" + storeId).hide()
                    $("#remPromo" + storeId).show()
                    $(".checkoutAvailPromo" + storeId).addClass("promoCodeDisable")
                    this.props.savedAmount()
                }
                else {
                    alert("Invalid Promo Code");
                }
            }
            else {
                $("#applyPromo" + storeId).hide()
                $("#remPromo" + storeId).show()
                $(".checkoutAvailPromo" + storeId).addClass("promoCodeDisable")
                this.props.savedAmount()
            }
        }
    }
    removepromocode(storeId) {
        $(".checkoutAvailPromo" + storeId).removeClass("promoCodeDisable")
        $("#hidPromocodeDetails" + storeId).val('');
        $("#txtPromocode" + storeId).val('');
        $("#applyPromo" + storeId).show()
        $("#remPromo" + storeId).hide()
        this.props.savedAmount()
    }
    render() {
        return (
            <div className="checkoutSlotHalf" >
                <div className="checkoutSlotItem checkoutSlotItemPromoDiv">
                    <label>Use Promo Code</label>
                    <div className="checkoutSlotItemPromo">
                        <input type="text" autoComplete="off" onClick={() => this.showPromocodes()} className="inputField" placeholder="Promo Code" id={"txtPromocode" + this.props.storeId} />
                        <input type="hidden" id={"hidPromocodeDetails" + this.props.storeId} />
                        <div className="checkoutAvailPromoBtn">
                            <span className="commonButton commonPromoButton" id={"applyPromo" + this.props.storeId} onClick={(e) => this.applypromocode(this.props.storeId, this.props.amount)}>Apply</span>
                            <span style={{ display: 'none' }} className="commonButton commonPromoDel" id={"remPromo" + this.props.storeId} onClick={(e) => this.removepromocode(this.props.storeId)}>Remove</span>
                        </div>
                    </div>
                    {this.state.promocodeDetails.length > 0 ?
                        <div className={"checkoutAvailPromo border-0 checkoutAvailPromo" + this.props.storeId}>
                            {this.state.promocodeDetails.map((promocodeDetailsObject, promocodeDetailsIndex) => (
                                <div id={"availPromo" + this.props.storeId} onClick={(e) => this.setPromoCode(promocodeDetailsObject.promoCode, this.props.storeId, promocodeDetailsObject.promocodeDetailsList)}>
                                    <a className="promoOfferListLink promoOfferLinkLokal" >
                                        <div className="promoOfferDetails">
                                            <p>{promocodeDetailsObject.offerDescription}</p>
                                            <span className="promoCodeText">{promocodeDetailsObject.promoCode}</span>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                        :
                        <div style={{ display: 'none' }} id={"promoNoDataLink" + this.props.storeId} className="noPromoCode clickNoPromoCodeAvail">No Promo Codes Available</div>
                    }
                    <div id={"promoLink" + this.props.storeId} className="noPromoCode clickPromoCodeAvail" onClick={() => this.showPromocodes()}>Click to avail promo code</div>
                </div>
            </div>
        )
    }
}