import React, { Component } from 'react';
import $ from "jquery";
import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css"
import arowdown from '../images/lokal/arow-down.png'
import { elasticLink, elasticHostURL } from "../config";
const AWS = require('aws-sdk');
const elasticsearch = require("elasticsearch");
AWS.config.region = 'us-west-1';
export default class filterSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryResult: [],
            brandResult: [],
            priceResult: [],
            minPrice: '',
            maxPrice: '',
            value: ''
        };
        this.showAlert = this.showAlert.bind(this);
    }
    filterClick() {
        $("#fliterCover").toggleClass("fliterMenuOpen")
    }
    async category(txtUserSearch, category1, category2, category3, brandID, minPrc, maxPrc) {
        debugger
        const client = new elasticsearch.Client({
            host: elasticHostURL(),
            amazonES: {
                region: 'us-west-1',
                credentials: new AWS.Credentials(this.props.s3AccessKey, this.props.s3SecretKey)
            }
        });
        let storeId = window.sessionStorage.getItem('storeId');
        brandID = (brandID === '' ? '*' : brandID.replace(/ /g, "-"));
        var filters = [];
        var fields = ['store_id', 'category_level1_id', 'category_level2_id', 'category_level3_id', 'sold_out', 'brand_id'];
        var paramsVal = [storeId, category1, category2, category3, '0', brandID]
        for (var k = 0; k < fields.length; k++) {
            if (paramsVal[k] !== '*' && paramsVal[k] !== '') {
                if (fields[k] === 'brand_id') {
                    var brandSplit = paramsVal[k].toString().split('-')
                    var termq = { terms: {} };
                    termq.terms[fields[k]] = brandSplit;
                    filters.push(termq);
                }
                else {
                    var termq = { term: {} };
                    termq.term[fields[k]] = paramsVal[k];
                    filters.push(termq);
                }
            }
        }
        if (minPrc !== '*' && minPrc !== '') {
            filters.push({ range: { net_amount: { gte: minPrc, lte: maxPrc } } });
        }
        if (txtUserSearch !== '*') {
            var matchq = { match_phrase_prefix: {} };
            matchq.match_phrase_prefix['search_item_name'] = txtUserSearch;
            filters.push(matchq);
        }
        const body = await client.search({
            index: elasticLink() + '-autocomplete-loose',
            from: 0, size: 500,
            body: {
                query:
                {
                    bool: {
                        filter:
                        {
                            bool:
                            {
                                must: filters
                            }
                        }
                    }
                },
                sort: [
                    {
                        category_level1_id: "asc"
                    },
                    {
                        "category_level2_name.keyword": "asc"
                    },
                    {
                        "category_level3_name.keyword": "asc"
                    }
                ],
                "aggs": {
                    "category_level1": {
                        terms: {
                            field: "category_level1_id",
                            order: {
                                "_term": "asc"
                            }
                        },
                        "aggs": {
                            "category_level1_name": {
                                terms: {
                                    field: "category_level1_name.keyword"
                                },
                                "aggs": {
                                    "category_level2": {
                                        terms: {
                                            field: "category_level2_id"
                                        },
                                        "aggs": {
                                            "category_level2_name": {
                                                terms: {
                                                    field: "category_level2_name.keyword"
                                                },
                                                "aggs": {
                                                    "category_level3": {
                                                        terms: {
                                                            field: "category_level3_id"
                                                        },
                                                        "aggs": {
                                                            "category_level3_name": {
                                                                terms: {
                                                                    field: "category_level3_name.keyword"
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        );
        var categoryLevel1Array = [];
        var categoryLevel2Array = [];
        var categoryLevel3Array = [];
        var categoryArray1 = [];
        var categoryArray2 = [];
        var categoryArray3 = [];
        for (const category_level1Id of body.aggregations.category_level1.buckets) {
            categoryLevel2Array = [];
            for (const category_level2Id of category_level1Id.category_level1_name.buckets[0].category_level2.buckets) {
                categoryLevel3Array = [];
                if (category_level2Id.category_level2_name.buckets[0].category_level3.buckets[0]) {
                    for (const category_level3Id of category_level2Id.category_level2_name.buckets[0].category_level3.buckets) {
                        if (category_level3Id.category_level3_name.buckets[0]) {
                            categoryArray3 = {
                                categoryName: category_level3Id.category_level3_name.buckets[0].key,
                                categoryId: category_level3Id.key,
                                categoryCount: 0
                            }
                            const resultObject3 = Object.values(categoryArray3);
                            categoryLevel3Array.push(resultObject3)
                        }
                    }
                }
                categoryArray2 = {
                    categoryName: category_level2Id.category_level2_name.buckets[0].key,
                    categoryId: category_level2Id.key,
                    categoryCount: 0,
                    categoryLevel3: categoryLevel3Array
                }
                const resultObject2 = Object.values(categoryArray2);
                categoryLevel2Array.push(resultObject2)
            }
            categoryArray1 = {
                categoryName: category_level1Id.category_level1_name.buckets[0].key,
                categoryId: category_level1Id.key,
                categoryCount: 0,
                categoryLevel2: categoryLevel2Array
            }
            const resultObject1 = Object.values(categoryArray1);
            categoryLevel1Array.push(resultObject1)
        }
        console.log("leftmenucat",categoryLevel1Array)
        this.setState({
            categoryResult: categoryLevel1Array
        });
    }
    async brand(txtUserSearch, category1, category2, category3, minPrc, maxPrc) {
        debugger
        const client = new elasticsearch.Client({
            host: elasticHostURL(),
            amazonES: {
                region: 'us-west-1',
                credentials: new AWS.Credentials(this.props.s3AccessKey, this.props.s3SecretKey)
            }
        });
        let storeId = window.sessionStorage.getItem('storeId');
        var filters = [];
        var fields = ['store_id', 'category_level1_id', 'category_level2_id', 'category_level3_id', 'sold_out'];
        var paramsVal = [storeId, category1, category2, category3, '0']
        for (var k = 0; k < fields.length; k++) {
            if (paramsVal[k] !== '*' && paramsVal[k] !== '') {
                var termq = { term: {} };
                termq.term[fields[k]] = paramsVal[k];
                filters.push(termq);
            }
        }
        if (minPrc !== '*' && minPrc !== '') {
            filters.push({ range: { net_amount: { from: minPrc, to: maxPrc } } });
        }
        if (txtUserSearch !== '*') {
            var matchq = { match_phrase_prefix: {} };
            matchq.match_phrase_prefix['search_item_name'] = txtUserSearch;
            filters.push(matchq);
        }
        const body = await client.search({
            index: elasticLink() + '-autocomplete-loose',
            body: {
                query:
                {
                    bool: {
                        filter:
                        {
                            bool:
                            {
                                must: filters
                            }
                        }
                    }
                },
                sort: [
                    {
                        "brand_name.keyword": "asc"
                    }
                ],
                "aggs": {
                    "brand_id": {
                        terms: {
                            field: "brand_id",
                            size: 500,
                            min_doc_count: 1,
                            order: [
                                {
                                    "_count": "desc"
                                },
                                {
                                    "_key": "asc"
                                }
                            ]
                        },
                        "aggs": {
                            "brand_name": {
                                terms: {
                                    field: "brand_name.keyword",
                                    size: 10,
                                    "min_doc_count": 1,
                                    order: [
                                        {
                                            "_count": "desc"
                                        },
                                        {
                                            "_key": "asc"
                                        }
                                    ]
                                }
                            }
                        }
                    }
                }
            }
        }
        )
        var brandArray = [];
        var brandArrayResult = [];
        var brand = [];
        for (const brand_id of body.aggregations.brand_id.buckets) {
            if (brand_id.key != '0' && brand_id.key != 0 && brand_id.brand_name.buckets[0].key != '' && brand_id.brand_name.buckets[0].key != null && brand_id.brand_name.buckets[0].key != 'null') {
                if (brandArray.indexOf(brand_id.brand_name.buckets[0].key) == -1) {
                    brandArray.push(brand_id.brand_name.buckets[0].key)
                    brand = {
                        brand_id: brand_id.key,
                        brand_name: brand_id.brand_name.buckets[0].key
                    }
                    const resultObject1 = Object.values(brand);
                    brandArrayResult.push(resultObject1)
                }
            }
        }
        this.setState({
            brandResult: brandArrayResult
        });
    }
    async price(txtUserSearch, category1, category2, category3, brandID) {
        debugger
        const client = new elasticsearch.Client({
            host: elasticHostURL(),
            amazonES: {
                region: 'us-west-1',
                credentials: new AWS.Credentials(this.props.s3AccessKey, this.props.s3SecretKey)
            }
        });
        let storeId = window.sessionStorage.getItem('storeId');
        brandID = (brandID === '' ? '*' : brandID.replace(/ /g, "-"));
        var filters = [];
        var fields = ['store_id', 'category_level1_id', 'category_level2_id', 'category_level3_id', 'sold_out', 'brand_id'];
        var paramsVal = [storeId, category1, category2, category3, '0', brandID]
        for (var k = 0; k < fields.length; k++) {
            if (paramsVal[k] !== '*' && paramsVal[k] !== '') {
                if (fields[k] === 'brand_id') {
                    var brandSplit = paramsVal[k].toString().split('-')
                    var termq = { terms: {} };
                    termq.terms[fields[k]] = brandSplit;
                    filters.push(termq);
                }
                else {
                    var termq = { term: {} };
                    termq.term[fields[k]] = paramsVal[k];
                    filters.push(termq);
                }
            }
        }
        if (txtUserSearch !== '*') {
            var matchq = { match_phrase_prefix: {} };
            matchq.match_phrase_prefix['search_item_name'] = txtUserSearch;
            filters.push(matchq);
        }
        const body = await client.search({
            index: elasticLink() + '-autocomplete-loose',
            size: 0,
            body: {
                query:
                {
                    bool: {
                        filter:
                        {
                            bool:
                            {
                                must: filters
                            }
                        }
                    }
                },
                aggregations: {
                    "grades_stats": { "stats": { "field": "net_amount" } }
                }
            }
        });
        var minPriceSelected;
        var maxPriceSelected;
        if (body.aggregations.grades_stats.sum > 0) {
            if (window.sessionStorage.getItem('minPriceSelected') !== null && window.sessionStorage.getItem('minPriceSelected') !== '') {
                minPriceSelected = window.sessionStorage.getItem('minPriceSelected')
            }
            else {
                minPriceSelected = parseInt(body.aggregations.grades_stats.min - 1)
            }
            if (window.sessionStorage.getItem('maxPriceSelected') !== null && window.sessionStorage.getItem('maxPriceSelected') !== '') {
                maxPriceSelected = window.sessionStorage.getItem('maxPriceSelected')
            }
            else {
                maxPriceSelected = parseInt(body.aggregations.grades_stats.max + 1)
            }
            this.setState({
                value: {
                    min: parseInt(minPriceSelected),
                    max: parseInt(maxPriceSelected)
                },
                minPrice: parseInt(body.aggregations.grades_stats.min - 1),
                maxPrice: parseInt(body.aggregations.grades_stats.max + 1)
            });
        }
    }
    showAlert() {
        debugger
        var txtUserSearch = ((window.sessionStorage.getItem('searchKeyword') !== '' && window.sessionStorage.getItem('searchKeyword') !== null && window.sessionStorage.getItem('searchKeyword') !== 'null') ? window.sessionStorage.getItem('searchKeyword') : '*');
        var category1 = ((window.sessionStorage.getItem('category1Id') === '' || window.sessionStorage.getItem('category1Id') === null) ? '*' : window.sessionStorage.getItem('category1Id'));
        var category2 = ((window.sessionStorage.getItem('category2Id') === '' || window.sessionStorage.getItem('category2Id') === null) ? '*' : window.sessionStorage.getItem('category2Id'));
        var category3 = ((window.sessionStorage.getItem('category3Id') === '' || window.sessionStorage.getItem('category3Id') === null) ? '*' : window.sessionStorage.getItem('category3Id'));
        var brandID = ((window.sessionStorage.getItem('brandIds') === '' || window.sessionStorage.getItem('brandIds') === null) ? '*' : window.sessionStorage.getItem('brandIds'));
        var minPrc = ((window.sessionStorage.getItem('minPrice') === '' || window.sessionStorage.getItem('minPrice') === null) ? '*' : window.sessionStorage.getItem('minPrice'));
        var maxPrc = ((window.sessionStorage.getItem('maxPrice') === '' || window.sessionStorage.getItem('maxPrice') === null) ? '*' : window.sessionStorage.getItem('maxPrice'));
        this.category(txtUserSearch, category1, category2, category3, brandID, minPrc, maxPrc)
        this.brand(txtUserSearch, category1, category2, category3, minPrc, maxPrc)
        this.price(txtUserSearch, category1, category2, category3, brandID)
    }
    componentDidMount() {
        this.showAlert();
        if (window.sessionStorage.getItem('brandIds') !== null && window.sessionStorage.getItem('brandIds') !== '*' && window.sessionStorage.getItem('brandIds') !== '') {
            var brandArr = window.sessionStorage.getItem('brandIds').split(' ')
            for (var b = 0; b < brandArr.length; b++) {
                if (typeof $("#chechk" + brandArr[b]).val() !== 'undefined') {
                    document.getElementById('chechk' + brandArr[b]).checked = true;
                }
            }
        }
    }
    categoryFilter(category1Id, category2Id, category3Id) {
        debugger
        let themeId = localStorage.getItem('themeId')
        if (themeId === 34 || themeId === '29') {
            $(".filterListName").removeClass('filterListActive')
            $("#categoryList" + category1Id.toString() + category2Id.toString() + category3Id.toString()).addClass('filterListActive')
        }
        this.props.onFilterParent('Left Category', category1Id, category2Id, category3Id, '', '', '', '0', '40', '1');
    }
    brandFilter(id) {
        debugger
        {console.log(document.getElementById('chechk' + id).checked,"$(#hidBrand).val()",$("#hidBrand").val())}
        window.sessionStorage.setItem('minPriceSelected', '')
        window.sessionStorage.setItem('maxPriceSelected', '')
        if (document.getElementById('chechk' + id).checked === true) {
            if ($("#hidBrand").val() === '*' || $("#hidBrand").val() === null) {
                $("#hidBrand").val(id.toString().trim())
            }
            else {
                var val = $("#hidBrand").val() + ' ' + id
                $("#hidBrand").val(val.toString().trim())
            }
        }
        else {
            var brandArray = $("#hidBrand").val().split(' ')
            var brandData = '';
            for (var j = 0; j < brandArray.length; j++) {
                if (id.toString() !== brandArray[j].toString()) {
                    if (brandData === '') {
                        brandData = brandArray[j]
                    }
                    else {
                        brandData = brandData + ' ' + brandArray[j]
                    }
                }
            }
            $("#hidBrand").val(brandData.toString().trim())
            window.sessionStorage.setItem('brandIds', brandData)
        }
        this.props.onFilterParent('', '', '', '', $("#hidBrand").val(), '', '', '0', '40', '1');
    }
    priceFilter() {
        window.sessionStorage.setItem('minPriceSelected', this.state.value.min)
        window.sessionStorage.setItem('maxPriceSelected', this.state.value.max)
        this.props.onFilterParent('', '', '', '', '', this.state.value.min, this.state.value.max, '0', '40', '1');
    }
    filterOpen() {
        $("#fliterCover").toggleClass("fliterMenuOpen")
    }
    fliterAccordion(index) {
        $("#accordionList" + index).toggleClass("fliterAccordionOpen");
        $("#accordionName" + index).toggleClass("accordionFliterActive");
    }
    render() {
        let themeId = localStorage.getItem('themeId')
        return (
            <div className="itemFilterSection">
                <span className="mobileFilter" onClick={this.filterOpen}>Filter <img src={arowdown} alt="" /></span>
                <div id="fliterCover" className="fliterCoverDiv">
                    {(() => {
                        if (themeId === '34' || themeId === '29'|| themeId === '4') {
                            return (
                                <div className="itemFilterTitle">Category</div>
                            )
                        }
                    })()}
                    {this.state.categoryResult.map((category1, index) => (
                        <div className="fliterList">
                            {(() => {
                                if (themeId === '34' || themeId === '29'|| themeId === '4') {
                                    return (
                                        <h5 className="filterCategoryName" onClick={(e) => this.fliterAccordion(index)} id={"accordionName" + index}>{category1[0]}<img src={arowdown} alt="" /></h5>
                                    )
                                }
                                else {
                                    return (
                                        <h5 className="filterCategoryName" onClick={(e) => this.categoryFilter(category1[1], '', '')}>{category1[0]}</h5>
                                    )
                                }
                            })()}
                            <ul id={"accordionList" + index}>
                                {category1[3].map((category2) => (
                                    <li>
                                        <a class="filterListName " id={"categoryList" + category1[1] + category2[1]} href="javascript:void(0)" onClick={(e) => this.categoryFilter(category1[1], category2[1], '')}>{category2[0]}</a>
                                        <ul>
                                            {category2[3].map((category3) => (
                                                <li><a class="filterListName" id={"categoryList" + category1[1] + category2[1] + category3[1]} href="javascript:void(0)" onClick={(e) => this.categoryFilter(category1[1], category2[1], category3[1])}>{category3[0]}</a></li>
                                            ))}
                                        </ul>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                    {this.state.brandResult.length > 0 ?
                        <div className="fliterList">
                            {(() => {
                                if (themeId === '34' || themeId === '29'|| themeId === '4') {
                                    return (
                                        <h5 className="filterCategoryName" onClick={(e) => this.fliterAccordion('Brand')} id={"accordionNameBrand"}>Brands<img src={arowdown} alt="" /></h5>
                                    )
                                }
                                else {
                                    return (
                                        <h5 className="filterCategoryName" >Brands</h5>
                                    )
                                }
                            })()}
                            <ul className="filterListHeight" id={"accordionListBrand"}>
                                {this.state.brandResult.map((brand) => (
                                    <li className="brandListing">
                                        <span class="checkBoxSquare">
                                            <input onClick={(e) => this.brandFilter(brand[0])} class="brandClass" type="checkbox" id={"chechk" + brand[0]} name={"chechk" + brand[0]} />
                                            <label for={"chechk" + brand[0]}></label>
                                        </span>{brand[1]}
                                    </li>
                                ))}
                            </ul>
                            <input type="hidden" id="hidBrand" />
                        </div>
                        : ''}
                        {console.log(typeof(this.state.maxPrice),"kkkkkkkkiikkkk",typeof(this.state.minPrice))}
                    {
                        (this.state.maxPrice !== this.state.minPrice) ?
                            <div className="fliterList">
                                <h5>Price</h5>
                                <div className="priceRanger">
                                    <InputRange
                                        maxValue={this.state.maxPrice}
                                        minValue={this.state.minPrice}
                                        value={this.state.value}
                                        onChange={value => this.setState({ value })}
                                        onChangeComplete={(e) => this.priceFilter()} />
                                </div>
                            </div>
                            :
                            ''
                    }
                </div>
            </div>
        )
    }
}
