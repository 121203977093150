import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import myaccount from "../images/icons/myaccount.png";
import feedback from "../images/icons/feedback.png";
import trackorder from "../images/icons/trackorder.png";
import myorders from "../images/icons/myorders.png";
import wishlist from "../images/icons/wishlist.png";
export default class dashboardMenu extends Component {
  logout() {
    localStorage.setItem('userId', "");
    localStorage.setItem('displayName', "");
    localStorage.setItem('role', "");
    window.sessionStorage.setItem('mobileNumber', "");
    window.sessionStorage.setItem('emailId', "");
    window.sessionStorage.setItem('addressId', "");
    window.location.href = "/";
  }
  continueClick() {
    let themeId = localStorage.getItem('themeId')
    let mainCategoryId = window.sessionStorage.getItem('mainGroupCategory')
    let siteCategoryGroupId = window.sessionStorage.getItem('siteCategoryId')
    let subDomainFlag = window.sessionStorage.getItem('subDomainFlag');
    if (siteCategoryGroupId != '0' && siteCategoryGroupId != 'null' && siteCategoryGroupId != null) {
      if (subDomainFlag == '1') {
        window.location.href = '/'
      }
      else if (localStorage.getItem('hasGlobalSearch') == "true" ){
        window.location.href = '/'
    }
      else {
        window.location.href = '/category'
      }
    }
    else {
      if (mainCategoryId == '5') {
        window.location.href = "/restaurantListing/0/0/";
      }
      else if (mainCategoryId == '8') {
        // if (themeId == '36') {
        //   window.location.href = "/cmCategoryHome";
        // }
        // else {
          if (themeId == '38' || themeId == '2') {
            window.location.href = "/";
          }
          else{
            window.location.href = "/cmItemListing/0/0/0/";
          // }
        }
      }
      else {
        window.location.href = '/'
      }
    }
  }
  render() {
    return (
      <div>
        <ul>
          <li className="msloggineduser"> <a>Welcome! <br /> <span>{localStorage.getItem('displayName')}</span></a><hr />  </li>
          <li>
            <Link ><img src={myaccount} alt="My Account" title="My Account" />My Account</Link>
            <ul>
              <li id="accountdetailsLink" className="accountdetailsLink"><NavLink className="dashboardMenuLink" activeClassName="dashboardActive" to="/dashboard/accountdetails">Account Details</NavLink></li>
              <li id="addressDetailsLink" className="dashboardMenuLink"><NavLink className="dashboardMenuLink" activeClassName="dashboardActive" to="/dashboard/addressDetails">Address Details</NavLink></li>
              <li id="personalLink" className="dashboardMenuLink"><NavLink className="dashboardMenuLink" activeClassName="dashboardActive" to="/dashboard/personal">Personal Information</NavLink></li>
              <li id="changepasswordLink" className="dashboardMenuLink"><NavLink className="dashboardMenuLink" activeClassName="dashboardActive" to="/dashboard/changepassword">Change Password</NavLink></li>
            </ul>
          </li>
          <li id="feedbackLink" ><NavLink activeClassName="dashboardActive" className="dashboardMenuLink" to="/dashboard/feedback"><img src={feedback} alt="Feedback" title="Feedback" />Feedback</NavLink></li>
          <li id="trackorderLink" ><NavLink activeClassName="dashboardActive" className="dashboardMenuLink" to="/dashboard/trackorder"><img src={trackorder} alt="Track Order" title="Track Order" />Track Order</NavLink></li>
          <li id="myorderLink" ><NavLink activeClassName="dashboardActive" className="dashboardMenuLink" to="/dashboard/myorders"><img src={myorders} alt="My Orders" title="My Orders" />My Orders</NavLink></li>
          {window.sessionStorage.getItem('mainCategoryId') == '5' ?
            <li id="wishlistLink"><NavLink activeClassName="dashboardActive" className="dashboardMenuLink" to="/dashboard/wishlist"><img src={wishlist} alt="Wish List" title="Wish List" />Wish List</NavLink></li>
            : ''}
          <li className=" logoutLink text-center"><button onClick={() => this.continueClick()} className="msContinueShopping mslogout">Continue Shopping</button></li>
          <li className="logoutLink1 text-center"><Link to="/" onClick={this.logout} ><button className="mslogout">Logout</button></Link></li>
        </ul>
      </div>
    )
  }
}
