import { getData } from '../services'
var ResMsgDTO = require('./ResMsgDTO.js');
var ReqMsgDTO = require('./ReqMsgDTO.js');
var AWLMEAPI = require('./AWLMEAPI.js');
var VtransactSecurity = require('./VtransactSecurity.js');
var HTTPPost = require('./HTTPPost.js');
var reqMsgDTO = new ReqMsgDTO();
var transactMeAPI = new AWLMEAPI();

export function loadWorldLine(mid, encKey,amount,itemBeforePaymentGroupCode,cityId,deliveryAddressId,hasLocation) {
    var d = new Date();
    var n = d.getTime();
    var orderID = 'WL' + n + '' + (Math.floor(Math.random() * (0 - 1000 + 1) + 0));   
    document.getElementById("hdnOrderID").value = orderID;
    var url = window.location.href.replace(/[^/]*$/, '')+`transactionResponse/`+orderID+`/`+document.getElementById("addField5").value;
    //var url = `https://dxjs9y4rhvuxe.cloudfront.net/transactionResponse/`+orderID+`/`+document.getElementById("addField5").value;
    
    document.getElementById("resUrl").value = url;
    document.getElementById("mid").value = mid;
    document.getElementById("encKey").value = encKey;
    document.getElementById("trnAmt").value = amount;
    document.getElementById("addField1").value = itemBeforePaymentGroupCode;
    document.getElementById("addField2").value = cityId;
    document.getElementById("addField3").value = deliveryAddressId;
    document.getElementById("addField4").value = hasLocation;
    

    var mid = mid;
    var encKey = encKey;
    var hdnOrderID = orderID;
    var trnAmt = amount;
    var currency = document.getElementById("currency").value;
    var meTransReqType = document.getElementById("meTransReqType").value;
    var trnRemarks = document.getElementById("trnRemarks").value;
    var recPeriod = document.getElementById("recPeriod").value;
    var recDay = document.getElementById("recDay").value;
    var noOfRec = document.getElementById("noOfRec").value;
    var resUrl = document.getElementById("resUrl").value;
    var addField1 = itemBeforePaymentGroupCode;
    var addField2 = cityId;
    var addField3 = deliveryAddressId;
    var addField4 = hasLocation;
    var addField5 = document.getElementById("addField5").value;
    var addField6 = document.getElementById("addField6").value;
    var addField7 = document.getElementById("addField7").value;
    var addField8 = document.getElementById("addField8").value;
    console.log("inside meTrnPay")
    reqMsgDTO.setMid(mid);
    reqMsgDTO.setEnckey(encKey);
    reqMsgDTO.setOrderId(hdnOrderID);
    reqMsgDTO.setTrnAmt(trnAmt);
    reqMsgDTO.setTrnCurrency(currency);
    if (trnRemarks != null) {
        reqMsgDTO.setTrnRemarks(trnRemarks);
    }
    if (trnRemarks != null) {
        reqMsgDTO.setMeTransReqType(meTransReqType);
    }
    if (trnRemarks != null) {
        reqMsgDTO.setRecurrPeriod(recPeriod);
    }
    if (trnRemarks != null) {
        reqMsgDTO.setRecurrDay(recDay);
    }
    if (noOfRec != null) {
        reqMsgDTO.setNoOfRecurring(noOfRec);
    }
    reqMsgDTO.setResponseUrl(resUrl);
    //Optional Addition fields for Merchant use 
    reqMsgDTO.setAddField1(addField1);
    reqMsgDTO.setAddField2(addField2);
    reqMsgDTO.setAddField3(addField3);
    reqMsgDTO.setAddField4(addField4);
    reqMsgDTO.setAddField5(addField5);
    reqMsgDTO.setAddField6(addField6);
    reqMsgDTO.setAddField7(addField7);
    reqMsgDTO.setAddField8(addField8);
    reqMsgDTO.setAddField9("");
    reqMsgDTO.setAddField10("");
    reqMsgDTO.setStatusDesc("")
    console.log('test');
    var merchantRequest = null;
    try {
        // Initialise object to generate transaction request message AWLMEAPI 
        // var transactMeAPI = new AWLMEAPI();
        reqMsgDTO = transactMeAPI.generateTrnReqMsg(reqMsgDTO);
        console.log('reqMsgDTO........', reqMsgDTO)
        // Check status desciption for message generation 
        console.log('reqMsgDTO.getStatusDesc()........', reqMsgDTO.getStatusDesc())
        if (reqMsgDTO.getStatusDesc() == "Success") {
            merchantRequest = reqMsgDTO.getReqMsg();
            console.log("merchantRequest.......", merchantRequest);
            var txn_url = window.sessionStorage.getItem('WorldlineUrl')+"doMEPayRequest"
            // var txn_url = "https://cgt.in.worldline.com/ipg/doMEPayRequest"; // for staging
            //var txn_url = "https://ipg.in.worldline.com/doMEPayRequest";
            console.log("txn_url..2...", txn_url)
            document.getElementById("checkoutPaymentDiv").style.display = "none";
            var form_fields="";
            form_fields += "<input type='hidden' name='merchantRequest'  value='" + merchantRequest + "' >";
            form_fields += "<input type='hidden' name='MID' id = 'MID' value = '" + mid + "'/>";
            document.getElementById("worldLineSubmit").innerHTML += '<html><head><title>Merchant Checkout Page</title></head><body><center><h1>Please do not refresh this page. Processing...</h1></center><form method="post" action="' + txn_url + '" name="f1">' + form_fields + '</form><script type="text/javascript">document.f1.submit();</script></body></html>'
            document.f1.submit();
            // "<h3>This is the text which has been inserted by JS</h3>";
            //var res = $("worldLine")
            //  res.writeHead(200, { 'Content-Type': 'text/html' });
            //   res.html('<html><head><title>Merchant Checkout Page</title></head><body><center><h1>Please do not refresh this page 123...</h1></center><form method="post" action="' + txn_url + '" name="f1">' + form_fields + '</form><script type="text/javascript">document.f1.submit();</script></body></html>');
            //  res.end();
        }
        // merchantRequest = reqMsgDTO.getReqMsg();
        // res.send(merchantRequest + ' Submitted Successfully!');
    } catch (ex) {
        reqMsgDTO.setStatusDesc("Error Occurred during processing" + ex);
        console.log(ex)
        // throw new Exception(ex.getMessage());
    }
}


export async function checkTransactionStatus(orderid, mid, enc_key) {
    console.log("inside checkTransactionStatus......")
    //var mid = ""
    var pgmetrn = ""
    // var enc_key = ""
    // var orderid = orderId
    var enc_data = null;
    // var host = ini_array.HOST
    
    var qpURL = window.sessionStorage.getItem('WorldlineUrl')+"getTransactionStatus";
    //var qpURL = "https://cgt.in.worldline.com/ipg/getTransactionStatus";
    //var qpURL = "https://ipg.in.worldline.com/getTransactionStatus";
    // console.log(qpURL)
    var resMsgDTO = new ResMsgDTO();
    var vtransactSecurity = new VtransactSecurity();

    if (!(mid) || !(orderid) || !(enc_key)) {
        resMsgDTO.setStatusCode("F");
        resMsgDTO.setStatusDesc("Invalid request was received.");
        return resMsgDTO;
    }
    var strVar = "";
    var merchantReqStr = mid +
        "|" + orderid +
        "|" + pgmetrn + "|" + strVar +
        "|" + strVar + "|" + strVar +
        "|" + strVar +
        "|" + strVar +
        "|" + strVar +
        "|" + strVar +
        "|" + strVar +
        "|" + strVar + "|";
    console.log("stattus input string...", merchantReqStr)
    enc_data = vtransactSecurity.encryptValue(merchantReqStr, enc_key);
    console.log("stattus input enc_data...", enc_data)


    const postData = {
        "functionName": "checkout/worldLineStatus",
        "mid": mid,
        "merchantReqStrT": enc_data,
        "worldlineUrl":qpURL
    }
    console.log("before...api...")
    await getData(postData).then((data) => {            
        console.log('WorldlineUrl....',data)
        enc_data = data.data.result.body
    });
    console.log("after api enc_data...", enc_data)


   // enc_data = await httpPost.excutePost(qpURL, enc_data, mid)
   // console.log("stattus input enc_data...", enc_data)

    // resMsgDTO = await this.parseTrnResMsg(enc_data, enc_key);



    var resMsgDTO = new ResMsgDTO();
    try {
        var vTransactSecurity = new VtransactSecurity();
        var dec_data = vTransactSecurity.decryptValue(enc_data, enc_key);
        console.log('responce data...', dec_data)
        if (!(dec_data)) {
            resMsgDTO.setStatusCode("F");
            resMsgDTO.setStatusDesc("Invalid response message");
            return resMsgDTO;
        }
        console.log("test 4")
        var strings = dec_data.split("|");
        console.log("test 3")
        if (!(strings) && (strings.length) != 22) {

            resMsgDTO.setStatusCode("F");
            resMsgDTO.setStatusDesc("Number of fields not matching.");
            return resMsgDTO;
        }
        console.log("test 2")
        console.log("test 2 strings[10]...........", strings[10])
        resMsgDTO.setPgMeTrnRefNo(strings[0]);
        resMsgDTO.setOrderId(strings[1]); // ORDERID
        resMsgDTO.setTrnAmt(strings[2]);
        resMsgDTO.setAuthNStatus(strings[3]); // AUTHNSTATUS
        resMsgDTO.setAuthZStatus(strings[4]);
        resMsgDTO.setCaptureStatus(strings[5]);
        resMsgDTO.setRrn(strings[6]);
        resMsgDTO.setAuthZCode(strings[7]);
        resMsgDTO.setResponseCode(strings[8]);
        resMsgDTO.setTrnReqDate(strings[9]);
        resMsgDTO.setStatusCode(strings[10]);
        resMsgDTO.setStatusDesc(strings[11]);
        resMsgDTO.setAddField1(strings[12]);
        resMsgDTO.setAddField2(strings[13]);
        resMsgDTO.setAddField3(strings[14]);
        resMsgDTO.setAddField4(strings[15]);
        resMsgDTO.setAddField5(strings[16]);
        resMsgDTO.setAddField6(strings[17]);
        resMsgDTO.setAddField7(strings[18]);
        resMsgDTO.setAddField8(strings[19]);
        resMsgDTO.setAddField9(strings[20]);
        resMsgDTO.setAddField10(strings[21]);
        console.log("test");

    } catch (ex) {
        resMsgDTO.setStatusCode("Error Occurred " + ex);

    }
    resMsgDTO.getOrderId();








    console.log("stattus input resMsgDTO...", resMsgDTO)
    return resMsgDTO
}


// export function parseTrnResMsg(enc_data, enc_key) {
//     var resMsgDTO = new ResMsgDTO();
//     try {
//         var vTransactSecurity = new VtransactSecurity();
//         var dec_data = vTransactSecurity.decryptValue(enc_data, enc_key);
//         console.log('responce data...', dec_data)
//         if (!(dec_data)) {
//             resMsgDTO.setStatusCode("F");
//             resMsgDTO.setStatusDesc("Invalid response message");
//             return resMsgDTO;
//         }
//         console.log("test 4")
//         var strings = dec_data.split("|");
//         console.log("test 3")
//         if (!(strings) && (strings.length) != 22) {

//             resMsgDTO.setStatusCode("F");
//             resMsgDTO.setStatusDesc("Number of fields not matching.");
//             return resMsgDTO;
//         }
//         console.log("test 2")
//         console.log("test 2 strings[10]...........", strings[10])
//         resMsgDTO.setPgMeTrnRefNo(strings[0]);
//         resMsgDTO.setOrderId(strings[1]); // ORDERID
//         resMsgDTO.setTrnAmt(strings[2]);
//         resMsgDTO.setAuthNStatus(strings[3]); // AUTHNSTATUS
//         resMsgDTO.setAuthZStatus(strings[4]);
//         resMsgDTO.setCaptureStatus(strings[5]);
//         resMsgDTO.setRrn(strings[6]);
//         resMsgDTO.setAuthZCode(strings[7]);
//         resMsgDTO.setResponseCode(strings[8]);
//         resMsgDTO.setTrnReqDate(strings[9]);
//         resMsgDTO.setStatusCode(strings[10]);
//         resMsgDTO.setStatusDesc(strings[11]);
//         resMsgDTO.setAddField1(strings[12]);
//         resMsgDTO.setAddField2(strings[13]);
//         resMsgDTO.setAddField3(strings[14]);
//         resMsgDTO.setAddField4(strings[15]);
//         resMsgDTO.setAddField5(strings[16]);
//         resMsgDTO.setAddField6(strings[17]);
//         resMsgDTO.setAddField7(strings[18]);
//         resMsgDTO.setAddField8(strings[19]);
//         resMsgDTO.setAddField9(strings[20]);
//         resMsgDTO.setAddField10(strings[21]);
//         console.log("test");

//     } catch (ex) {
//         resMsgDTO.setStatusCode("Error Occurred " + ex);

//     }
//     resMsgDTO.getOrderId();


//     return resMsgDTO;
// }