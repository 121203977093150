import React, { Component, Suspense } from 'react';
import quote from "../images/quote.png"
import quoteleft from "../images/quote-left.png"
import vision from "../images/vision.png"
import mission from "../images/mission.png"
import values from "../images/values.png"
import gestures from "../images/gestures.png"
import transportation from "../images/transportation.png"
import excellence from "../images/seo-and-web.png"
import fairness from "../images/miscellaneous.png"
import innovation from "../images/business.png"
import impact from "../images/business-and-finance.png"
let Header = React.lazy(() => import("../header"));
let Footer = React.lazy(() => import("../footer"));
export default class OurDna extends Component {
    render() {
        return (
            <div class="myyshoppWrapperInner myyshopp-main-wrapper">
                <Suspense fallback={<div></div>}>
                    <Header></Header>
                    <div class="container myyshopp-container pb-0">
                        <section class="">
                            <div class="col-md-12">
                                <h1 class="main-head text-center mt-0">Purpose </h1>
                            </div>
                            <div class="gray-text-dim-div">
                                <img src={quote} class="quote-img1" alt=""></img>
                                <div class="gray-text-dim"> Magnify Delight,<br></br> Multiply Opportunity  </div>
                                <img src={quoteleft} class="quote-img2" alt=""></img>
                            </div>
                            <div class="col-md-12 text-center">
                                <h4 class="head-thin mb-4">To maximize opportunities for local Retailers and Business Owners everywhere by creating unlimited choices, bringing new hope, possibilities, access to profitability and joy.</h4>
                            </div>
                        </section>
                        <section class="mshopp-ourdna-main">
                            <div class="row ourdna-listMain">
                                <div class="col-lg-1"></div>
                                <div class="col-lg-5 ourdna-list ourdna-list-after">
                                    <img src={vision} class="img-fluid" width="80" alt=""></img>
                                    <h4 class="ourdna-head">Vision</h4>
                                    <p style={{ fontWeight: "600" }}>To democratize online retailing and shopping.</p>
                                    <p>Empower and enable every local retailer worldwide to do business with their customers, current and potential, online.</p>
                                </div>
                                <div class="col-lg-5 ourdna-list">
                                    <img src={mission} class="img-fluid" width="80" alt=""></img>
                                    <h4 class="ourdna-head">Mission</h4>
                                    <p style={{ fontWeight: "600" }}>To assist local retailers globally with every Tool, Technology and Skill they need to create a profitable online presence.</p>
                                    <p>To provide Business Owners with online platforms that cater to their specific business nuances and ensure they are able to leverage the world wide web and offer the best selection of products, services and offerings to their customers across all product and service categories.</p>
                                </div>
                            </div>
                        </section>
                        <section class="mshopp-values-main">
                            <div class="row">
                                <div class="col-lg-12 ourdna-listMain text-center">
                                    <img src={values} class="img-fluid" width="80" alt=""></img>
                                    <h4 class="ourdna-head">Values</h4>
                                </div>
                                <div class="col-md-6 values-listsection">
                                    <h4 class="head-thin txtclr-lightblue"><img src={gestures} width="50" class="mshopp-dna-values-img mr-3" alt=""></img> Integrity</h4>
                                    <p class="txtclr-lightblue">We say what we think and do what we say.</p>
                                </div>
                                <div class="col-md-6 values-listsection">
                                    <h4 class="head-thin txtclr-lightblue"><img src={transportation} width="50" class="mshopp-dna-values-img mr-3" alt=""></img> Velocity</h4>
                                    <p class="txtclr-lightblue">Think and execute fast, with the right focus.</p>
                                </div>
                                <div class="col-md-6 values-listsection">
                                    <h4 class="head-thin txtclr-lightblue"><img src={excellence} width="50" class="mshopp-dna-values-img mr-3" alt=""></img> Excellence</h4>
                                    <p class="txtclr-lightblue">In everything we do, at every step of the way.</p>
                                </div>
                                <div class="col-md-6 values-listsection">
                                    <h4 class="head-thin txtclr-lightblue"><img src={fairness} width="50" class="mshopp-dna-values-img mr-3" alt=""></img> Fairness</h4>
                                    <p class="txtclr-lightblue">In the way we treat every person we touch, every time.</p>
                                </div>
                                <div class="col-md-6 values-listsection">
                                    <h4 class="head-thin txtclr-lightblue"><img src={innovation} width="50" class="mshopp-dna-values-img mr-3" alt=""></img> Innovation</h4>
                                    <p class="txtclr-lightblue">Make everything better and easier than yesterday.</p>
                                </div>
                                <div class="col-md-6 values-listsection">
                                    <h4 class="head-thin txtclr-lightblue"><img src={impact} width="50" class="mshopp-dna-values-img mr-3" alt=""></img> Impact</h4>
                                    <p class="txtclr-lightblue">Make a real difference in the lives of people everywhere.</p>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer></Footer>
                </Suspense>
            </div>
        )
    }
}