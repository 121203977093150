import React, { Component,Suspense } from 'react';
import Joincompany from "../home/joincompany"
import $ from "jquery";
import advantagesbanner from "../images/advantages-banner.png"
import hrs from "../images/hr.png"
import channel from "../images/social-media-marketing.png"
import advantagesleft from "../images/advantages-left.png"
import server from "../images/server.png"
import mobile from "../images/mobile.png"
import overhead from "../images/overhead.png"
import money from "../images/money.png"
import size from "../images/size.png"
import advantagesright from "../images/advantages-right.png"
import Product from "../images/Product.png"
import surface from "../images/surface.png"
import traction from "../images/traction.png"
let Header = React.lazy(() => import("../header"));
let Footer = React.lazy(() => import("../footer"));
export default class Advantages extends Component {
    componentDidMount() {
        var heightsection = $("#advantages-section3-right").height();
        $("#advantages-section3-left").height(heightsection + 767);
        var heightsection1 = $("#advantages-section4-right").height();
        $("#advantages-section4-left").height(heightsection1 + 767);

    }
    render() {
        return (
            <div class="myyshoppWrapperInner myyshopp-main-wrapper mshopp-section-top section-logobg">
                 <Suspense fallback={<div></div>}>
                <Header></Header>
                <div class="container myyshopp-container p-lg-0 pt-lg-5">
                    <section class="pt-sm-0">
                        <div class="row myystory-div">
                            <div class="col-lg-4">
                                <div className="myystoryContent">
                                    <h1 class="main-head">advantages for <span> your <br></br> business </span></h1>
                                </div>
                            </div>
                            <div class="col-lg-8 storybanner-img">
                                <img src={advantagesbanner} class="img-fluid" alt=""></img>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div class="row">
                            <div class="col-lg-6 advantages-listsection">
                                <h4 class="head-thin txtclr-lightblue"><img src={hrs} width="50" class="mr-3" alt=""></img> Business 24x7</h4>
                                <p class="txtclr-lightblue">Don’t lose out on that customer that always works late, or that old couple in your neighbourhood who is unable to visit your store. Enable all your customer to shop from you, anytime and from anywhere.</p>
                            </div>
                            <div class="col-lg-6 advantages-listsection">
                                <h4 class="head-thin txtclr-lightblue"><img src={channel} width="50" class="mr-3" alt=""></img> Channel</h4>
                                <p class="txtclr-lightblue">Don’t be limited to just your physical store. Your online store ensures you have an additional channel for promoting, marketing and sales. Think of this as another store, just with much greater reach to your customers, wherever they may be!</p>
                            </div>
                        </div>
                    </section>
                </div>
                <section class="col-md-12 advantages-section3-outer">
                    <div class="row">
                        <div class="col-lg-6 px-0 advantages-section3-img" id="advantages-section3-left">
                            <img src={advantagesleft} class="img-fluid" alt=""></img>
                        </div>
                        <div class="col-lg-6 advantages-section3" id="advantages-section3-right" style={{ backgroundColor: "#fceee8" }}>
                            <div class="col-md-12 my-5">
                                <h4 class="head-thin txtclr-lightblue"><img src={server} width="40" class="mr-3" alt=""></img> Database</h4>
                                <p class="txtclr-lightblue">Get new customers, extend your area of operations. Build promotions and increase your sales. Manage inventory and build your product database. All this and more, in one place & easily managed.</p>
                            </div>
                            <div class="col-md-12 my-5">
                                <h4 class="head-thin txtclr-lightblue"><img src={mobile} width="40" class="mr-3" alt=""></img> Convenience</h4>
                                <p class="txtclr-lightblue">Now you can not only offer your customers the convenience of shopping from anywhere and at anytime, but you too can run, manage and maintain your store, from anywhere and at any time.</p>
                            </div>
                            <div class="col-md-12 my-5">
                                <h4 class="head-thin txtclr-lightblue"><img src={overhead} width="40" class="mr-3" alt=""></img> No Overheads</h4>
                                <p class="txtclr-lightblue">Our simple and Pay as You Go Plan ensures you don’t have to worry about hidden costs. Also, if you were planning on opening another store, sign up with us and forget those huge rentals and maintenance bills.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="container">
                    <section>
                        <div class="row">
                            <div class="col-lg-6 advantages-listsection">
                                <h4 class="head-thin txtclr-lightblue"><img src={money} width="50" class="mr-3" alt=""></img> Very Low Investment</h4>
                                <p class="txtclr-lightblue">A simple subscription fee that ensures you pay for only what you need. You can now start, run and manage your business without any huge investments in money, time and effort.</p>
                            </div>
                            <div class="col-lg-6 advantages-listsection">
                                <h4 class="head-thin txtclr-lightblue"><img src={size} width="50" class="mr-3" alt=""></img> Business Expansion</h4>
                                <p class="txtclr-lightblue">Don’t restrict yourself to only customers in your locality. Once you’re online, your business is as large and scalable as you want it to be. Grow and increase your business operations without any hassles.</p>
                            </div>
                        </div>
                    </section>
                </div>
                <section class="col-md-12 advantages-section3-outer">
                    <div class="row">
                        <div class="col-lg-6 advantages-section3" id="advantages-section4-right" style={{ backgroundColor: "#e8f7f2" }}>
                            <div class="col-md-12 my-5">
                                <h4 class="head-thin txtclr-lightblue"><img src={Product} width="40" class="mr-3" alt=""></img> Value Added Services</h4>
                                <p class="txtclr-lightblue">Thinking about using social media? Don’t worry we have taken care of it. Worried about managing your online store? Our Team will do it for you. Want to analyse your customer data? We’ve already done it for you. And there’s much more.</p>
                            </div>
                            <div class="col-md-12 my-5">
                                <h4 class="head-thin txtclr-lightblue"><img src={surface} width="40" class="mr-3" alt=""></img> Customisation</h4>
                                <p class="txtclr-lightblue">From choosing the look and feel of your online store, to customising your product offerings and delivery rules, we’ve thought of it all. Simply decide how you want your store to function and with the click of a button it’ll be done.</p>
                            </div>
                            <div class="col-md-12 my-5">
                                <h4 class="head-thin txtclr-lightblue"><img src={traction} width="40" class="mr-3" alt=""></img> Traction </h4>
                                <p class="txtclr-lightblue">With your web address you’re now a part of a global community of Shoppers and Businesses. Increase footfall and engagement by running campaigns online and increasing Sales and Distribution.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 px-0 advantages-section3-img" id="advantages-section4-left">
                            <img src={advantagesright} class="img-fluid" alt=""></img>
                        </div>
                    </div>
                </section>
                <div class="container">
                    <Joincompany></Joincompany>
                </div>
                <Footer></Footer>
                </Suspense>
            </div>
        )
    }
}