import React, { Component, Suspense } from 'react';
import Arrow from "../images/lokal/ph_arrow-up.png";
import { FaCheck } from "react-icons/fa6";
import Minus from "../images/lokal/minus.png";
import Plus from "../images/lokal/plus.png";
import Dot from "../images/lokal/dot.png";
import Invoice from "../images/lokal/invoice.png";
import Googlepay from "../images/lokal/google-play.png";
import Phonepay from "../images/lokal/phonepe.png";
import Paytm from "../images/lokal/paytm.png";
import { FiEdit } from "react-icons/fi";
import { TiHome } from "react-icons/ti";
import Card from "../images/lokal/ion_card-outline.png"
import Close from "../images/lokal/close.png"
import Creditcard from "../images/lokal/credit-card.png"

const Header = React.lazy(() => import("../luna/header"));
const Footer = React.lazy(() => import("../luna/footer"));

export default class Storelisting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 1,
      activeOption: 'UPI',
      showPopup: false
    };
  }

  handleClick = (option) => {
    this.setState({ activeOption: option });
  };

  openModal = () => {
    console.log("test")
    this.setState({ showPopup: true });
  };


  handleStepClick = (step) => {
    if (step === 1) {
      this.setState((prevState) => ({
        activeStep: prevState.activeStep === 1 ? null : 1,
      }));
    } else {
      this.setState((prevState) => ({
        activeStep: prevState.activeStep === step ? null : step,
      }));
    }
  };
  closePopup = () => {
    this.setState({ showPopup: false });
  };
  renderContent = () => {
    const { activeOption } = this.state;
    // const { showPopup } = this.state;
    switch (activeOption) {
      case 'Credit and Debit Card':
        return (
          <div>
            <div className='payment-head'>Pay using Credit/Debit Card</div>
            <div className='pay-now-div cursor-pointer'>
              <div className='my-5'>
                <div className='add-new-card'>
                  <div onClick={this.openModal}>
                    <span>
                      <img src={Card} alt="Card" className='img-fluid mr-1' width="30" height="30" />
                    </span>
                    Add New Card
                  </div>                  
                  <img src={Creditcard} alt="Creditcard" className='img-fluid mr-1' width="200" height="200"/>
                  </div>               
              </div>              
            </div>
          
          </div>
        );

      case 'UPI':
        return (
          <div>
            <div className='payment-header'>
              <div>Pay using your favourite UPI apps</div>
              <div className='pay-using'>
                <img src={Googlepay} alt="Googlepay" className='img-fluid' width="30" height="30" />
                <img src={Phonepay} alt="Phonepay" className='img-fluid' width="30" height="30" />
                <img src={Paytm} alt="Paytm" className='img-fluid' width="30" height="30" />
              </div>
            </div>
            <div className='payment-head'>Pay using UPI ID</div>
            <div className='pay-now-div'>
              <div className='mb-4'>
                <label htmlFor="" className='form-label'>*Enter UPI ID</label>
                <input type="text" className='form-control' />
              </div>
              <div className='verify-btn'>
                Verify & Pay
              </div>
            </div>
          </div>
        );

      case 'Option 3':
        return (
          <div>
            <div className='payment-head'>Pay using Option 3</div>
            {/* Add content for Option 3 */}
          </div>
        );

      case 'Option 4':
        return (
          <div>
            <div className='payment-head'>Pay using Option 4</div>
            {/* Add content for Option 4 */}
          </div>
        );

      default:
        return null;
    }
  };

  render() {
    const { activeStep, showPopup } = this.state;

    return (
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Header />
          <div className='main-cart'>
            <div className='top-header'>
              <div className='container-fluid outer-padding'>
                <div className="row m-0">
                  <div className='bread-crumb col-12'>
                    <span className='link'>Home</span>
                    <span className='px-1'>
                      <img src={Arrow} alt="Arrow" className='img-responsive' width="15" height="15" />
                    </span>
                    <span className='link'>Mumbai (Saki Vihar)</span>
                    <span className='px-1'>
                      <img src={Arrow} alt="Arrow" className='img-responsive' width="15" height="15" />
                    </span>
                    <span className='link'>Groceries</span>
                    <span className='px-1'>
                      <img src={Arrow} alt="Arrow" className='img-responsive' width="15" height="15" />
                    </span>
                    <span className='link-active'>Checkout</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='container-fluid outer-padding'>
              <div className="row m-0">
                <div className="col-12 col-lg-6 col-md-6 py-5">
                  <div className="stepper">
                    {/* Step 1 */}
                    <div className={`step ${activeStep === 1 ? 'active' : ''}`}>
                      <div className="circle">
                        <FaCheck color='#fff' />
                      </div>
                      <div className="dotted-line"></div>
                      <div className="dotted-line"></div>
                      <div className="step-content">
                        <div className="step-header" onClick={() => this.handleStepClick(1)}>
                          <span className='step-header-name'>Delivery Address</span>
                          <i className={`arrow-icon ${activeStep === 1 ? 'rotate' : ''}`}></i>
                        </div>
                        {activeStep === 1 && (
                          <div className="address-content">
                            <p className='strong-text'><span className='mr-1'>
                              <TiHome fontSize={20} /></span>ConvergenSEE India</p>
                            <p>
                              Lighthall, Tower Lighthall, Floor 5, Dominos, Saki Vihar,<br />
                              Muranjan Wadi, Marol, Andheri East, Mumbai
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Step 2 */}
                    <div className={`step ${activeStep === 2 ? 'active' : ''}`}>
                      <div className="circle">
                        <FaCheck color='#fff' />
                      </div>
                      <div className="step-content">
                        <div className="step-header" onClick={() => this.handleStepClick(2)}>
                          <span className='step-header-name'>Schedule Your Order</span>
                          <i className={`arrow-icon ${activeStep === 2 ? 'rotate' : ''}`}></i>
                        </div>
                        {activeStep === 2 && (
                          <div>
                            <div className="address-content">
                              <div className='order-schedule'>
                                <div className='order-days active'>Today</div>
                                <div className='order-days'>Tomorrow</div>
                                <div className='order-days'>Later</div>
                              </div>
                            </div>
                            <div className='mt-3'>
                              <div className="green-label">
                                Deliver on May 19, 2024
                              </div>
                              <div>
                                <select name="" id="" className='form-control ps-0 pl-0 select-control'>
                                  <option value="">Choose Your Delivery Time</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Step 3 */}
                    <div className={`step ${activeStep === 3 ? 'active' : ''}`}>
                      <div className="circle">
                        <FaCheck color='#fff' />
                      </div>
                      <div className="step-content">
                        <div className="step-header" onClick={() => this.handleStepClick(3)}>
                          <span className='step-header-name'>Select Payment Method</span>
                          <i className={`arrow-icon ${activeStep === 3 ? 'rotate' : ''}`}></i>
                        </div>
                        {activeStep === 3 && (
                          <div className="address-content">
                            <p>Select the payment method from the existing one or create one</p>
                            <div className='row'>
                              <div className="col-12 col-lg-3">
                                <ul className='payment-options'>
                                  <li
                                    className={this.state.activeOption === 'Credit and Debit Card' ? 'active' : ''}
                                    onClick={() => this.handleClick('Credit and Debit Card')}
                                  >
                                    Credit and Debit Card
                                  </li>
                                  <li
                                    className={this.state.activeOption === 'UPI' ? 'active' : ''}
                                    onClick={() => this.handleClick('UPI')}
                                  >
                                    UPI
                                  </li>
                                  <li
                                    className={this.state.activeOption === 'Option 3' ? 'active' : ''}
                                    onClick={() => this.handleClick('Option 3')}
                                  >
                                    Option 3
                                  </li>
                                  <li
                                    className={this.state.activeOption === 'Option 4' ? 'active' : ''}
                                    onClick={() => this.handleClick('Option 4')}
                                  >
                                    Option 4
                                  </li>
                                </ul>
                              </div>
                              <div className="col-12 col-lg-9 pl-0">
                                {this.renderContent()}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-md-6 py-5 pl-4">
                  <div className='cart-head-cart mt-0'>
                    Your Cart <span className='cart-head-sub'>(3 items)</span>
                  </div>
                  <div className='cart-item-outer'>
                <div className='cart-item'>
                  <div className='cart-item-row'>
                  <div>
                    <img src={Dot} alt="Dot" width="10" height="10" />
                  </div>
                  <div className='cart-item-name'>Indian Tomato  (Tamatar)</div>
                  </div>
                  <div className='cart-qnty-data'>500g </div>
                  <div className="Quantity">
                    <div className='quantity-red qnty-left'>
                      <img src={Minus} alt="Minus" width="20" height="20" />
                    </div>
                    <div className='text-center d-flex align-items-center quatity-no'>1</div>
                    <div className='quantity-red qnty-right'>
                      <img src={Plus} alt="Plus" width="20" height="20" />
                    </div>
                  </div>
                  <div className='cart-value'>₹ 49</div>
                </div>
                <div className='cart-item'>
                  <div className='cart-item-row'>
                  <div>
                    <img src={Dot} alt="Dot" width="10" height="10" />
                  </div>
                  <div className='cart-item-name'>Indian Tomato  (Tamatar)</div>
                  </div>
                  <div className='cart-qnty-data'>500g </div>
                  <div className="Quantity">
                    <div className='quantity-red qnty-left'>
                      <img src={Minus} alt="Minus" width="20" height="20" />
                    </div>
                    <div className='text-center d-flex align-items-center quatity-no'>1</div>
                    <div className='quantity-red qnty-right'>
                      <img src={Plus} alt="Plus" width="20" height="20" />
                    </div>
                  </div>
                  <div className='cart-value'>₹ 49</div>
                </div>
                <div className='cart-item'>
                  <div className='cart-item-row'>
                  <div>
                    <img src={Dot} alt="Dot" width="10" height="10" />
                  </div>
                  <div className='cart-item-name'>Indian Tomato  (Tamatar)</div>
                  </div>
                  <div className='cart-qnty-data'>500g </div>
                  <div className="Quantity">
                    <div className='quantity-red qnty-left'>
                      <img src={Minus} alt="Minus" width="20" height="20" />
                    </div>
                    <div className='text-center d-flex align-items-center quatity-no'>1</div>
                    <div className='quantity-red qnty-right'>
                      <img src={Plus} alt="Plus" width="20" height="20" />
                    </div>
                  </div>
                  <div className='cart-value'>₹ 49</div>
                </div>
                </div>
                  <div className='any-instructions'>
                    <span className='mr-3'><FiEdit /></span>
                    Any Instructions? Eg: Don’t ring the doorbell
                  </div>
                  <div className='cart-head-cart'>
                    Invoice <span className='cart-head-sub'>
                      <img src={Invoice} alt="Invoice" className='img-fluid' width="20" height="20" />
                    </span>
                  </div>
                  <div className='grandtotal-div'>
                    <div className='grand-total'>
                      <div className="row each-item-list">
                        <div className="col-lg-6 col-md-6 col-12 cart-items-label">
                          Item total
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 text-right cart-items-label">
                          ₹390
                        </div>
                      </div>
                      <div className="row each-item-list">
                        <div className="col-lg-6 col-md-6 col-12 cart-items-label">
                          Handling Fee
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 text-right cart-items-label">
                          ₹0
                        </div>
                      </div>
                      <div className="row each-item-list">
                        <div className="col-lg-6 col-md-6 col-12 cart-items-label">
                          Delivery Fee
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 text-right cart-items-label">
                          ₹45
                        </div>
                      </div>
                    </div>
                    <div className="cart-footer">
                      <div>To Pay</div>
                      <div>₹640</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
          <Footer />
        </Suspense>
        {showPopup && (
                    <div className="popup">
                        <div className="popup-content">
                            <div className='close-menu' onClick={this.closePopup}>
                                <img src={Close} alt="Close" className='img-fluid' width="20" height="20" />
                            </div>
                            <div className='popup-body pb-0'>                               
                              <div class="mb-3">
                                <label class="form-label">* Card Number</label>
                                <input type="email" class="form-control" id="cardNumber" placeholder='**** **** **** 1234'/>
                              </div> 
                              <div className="row">
                                <div className="col-6">
                                  <div class="mb-3">
                                    <label class="form-label">* Expiry Date</label>
                                    <div className="row position-relative">
                                      <div className="col-6">
                                        <input type="email" class="form-control" placeholder='MM'/>
                                      </div>
                                      <div className='date-seperator'>/</div>
                                      <div className="col-6">
                                        <input type="email" class="form-control" placeholder='YY'/>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div class="mb-3">
                                    <label class="form-label">* Expiry Date</label>
                                    <input type="email" class="form-control"  placeholder='e.g. 123'
                                      aria-describedby="emailHelp" />
                                  </div>
                                </div>
                                </div> 
                                <div class="mb-4">
                                <label class="form-label">* Name on Card</label>
                                <input type="email" class="form-control"  placeholder='e.g. Rahul Mishra'/>
                              </div>                  
                            </div>
                            <div className="popup-footer border-0 pt-0">                               
                                <div className='w-100'>
                                    <button className='add-to-cart w-100 py-3'>Add Card & Pay</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
      </div>
    );
  }
}
