import React, { Component } from 'react';
import $ from "jquery";
import Parser from 'html-react-parser';
export default class addcartBtn extends Component {
  constructor(props) {
    super(props)

    this.state = {
      itemId: this.props.itemId,
      customize: this.props.customize,
      isAdded: false,
      quantity: '',

    };

    this.addToCartRestaurant = this.addToCartRestaurant.bind(this);
    this.isNumberRestaurant = this.isNumberRestaurant.bind(this);
  }
  componentDidMount() {

  }

  addToCartRestaurantSize(itemId){
    var storeItemSizeId = $("#hidStoreItemSizeId"+itemId).val();
    var toppingsData = $("#hidToppingsData"+storeItemSizeId).val();
    var totalToppingPrice = $("#totalToppingPrice"+storeItemSizeId).val(); 
   // alert("toppingsData..."+toppingsData)
    if(this.props.customize == true){
              
        let isDelete = false;
        $("#customize"+storeItemSizeId).show();       
        if(toppingsData != null && toppingsData != ''){
          //toppingsData = toppingsData.slice(1)
         // alert('totalToppingPrice...............'+totalToppingPrice)
          this.updateCartRestaurant(this.props.storeId, this.props.itemId, this.props.itemName, '1', this.props.itemSize, totalToppingPrice, this.props.itemImage, storeItemSizeId,isDelete,toppingsData)    
          
        }       
        else{         
          var cmbSize = $("#cmbSize"+itemId).val().split('-');
          this.updateCartRestaurant(this.props.storeId, this.props.itemId, this.props.itemName, '1', cmbSize[0], cmbSize[1], this.props.itemImage, cmbSize[2],isDelete,'')
        } 
        
        $("#customize"+storeItemSizeId).hide();
    }
    else{
     
        let isDelete = false;
        var cmbSize = $("#cmbSize"+itemId).val().split('-');
        this.updateCartRestaurant(this.props.storeId, this.props.itemId, this.props.itemName, '1', cmbSize[0], cmbSize[1], this.props.itemImage, cmbSize[2],isDelete,'')    
    }   
    
    

    // var itemCount = 0;
    // var selectRestaurantCart = JSON.parse(localStorage.getItem('selectRestaurantCart')) || []
    // for (var i = 0; i < selectRestaurantCart.length; i++) {
    //   var cookieItemId = selectRestaurantCart[i].itemId
    //   if (itemId == cookieItemId){
    //     itemCount = parseInt(itemCount) + 1
    //   }
    // }
    // if(itemCount == 0){
    //   itemCount = 1;
    // }
    // $("#itemCount" + this.props.itemId).text(itemCount)
    // $("#itemAddedColour" + this.props.itemId).show()




    $("#hidToppingsData"+storeItemSizeId).val('')
    this.props.itemLoad()
  }

  addToCartRestaurant(type) {
    //alert("this.props.itemId......."+this.props.itemId)
    let isDelete = false;
  //  alert(" this.props.itemSize.."+ this.props.itemSize)
    let itemNo = this.props.index
    //alert("itemNo...."+itemNo)
    if (type == "addToCart") {
    //  alert("type.."+type)
     // $('#addToCart' + itemNo).addClass("cart_clk");
     // alert("itemnoooo.."+  $('#addToCart' + itemNo))
      if ($('.cartInput' + itemNo).val() == 0) {
      //  alert("4555..."+$('.cartInput' + itemNo).val())
        $('.cartInput' + itemNo).val(1);

      }
    }
    else if (type == "inc") {
     //  alert("22type..."+type)
      //alert("3444.."+  $('#inc' + itemNo))
      //// alert("222..........."+$('.cartInput' + itemNo).val())
      var addItem = $('.cartInput' + itemNo).val()
      if (addItem > 0) {//alert("addItem..."+addItem)
        addItem++;
        $('.cartInput' + itemNo).val(addItem);
      }
      else if (addItem == 0) {
        //alert("0222222")
        $('.cartInput' + itemNo).val(1);
      }

    }
    else if (type == "dec") {
      // alert("33")
      //alert("666.."+  $('#dec' + itemNo))
      var removeItem = $('.cartInput' + itemNo).val()
      removeItem--;
      if (removeItem == 0) {
        isDelete = true;
       // $('#addToCart' + itemNo).removeClass("cart_clk");
      }
      $('.cartInput' + itemNo).val(removeItem);
    }
    else if (type == "txtChange") {
      //alert("444")
      // alert("77777.."+  $('#txtChange' + itemNo))
      //alert("9999..."+ $('.cartInput' + itemNo).val())
      var txtItem = $('.cartInput' + itemNo).val()
      // alert("22txtItem..."+txtItem)
      if (txtItem == 0) {
        //alert("0999..."+txtItem)
      //  $('#addToCart' + itemNo).removeClass("cart_clk");
        //alert("txtItem.."+txtItem)
      }
      else {
        // alert("0888..."+txtItem)
        $('.cartInput' + itemNo).val(txtItem);

      }

    }
    //alert("1111111.1..."+$('.cartInput' + itemNo).val())
    if ($('.cartInput' + itemNo).val() == 0) {
      
     //  $("#itemCount" + this.props.itemId).text("");
     // $("#itemAddedColour" + this.props.itemId).hide()
       
       this.setState({
         isAdded: false
       });
      // alert("isAdded..."+this.state.isAdded)
       isDelete = true;
      // alert("isDelete.."+isDelete)
     }
     else {
      // alert("222222222else..")
     //  $("#itemCount" + this.props.itemId).text($('.cartInput' + itemNo).val())
     //  $("#itemAddedColour" + this.props.itemId).show()
       this.setState({
         isAdded: true
       });
     //  alert("isAdded.else....."+this.state.isAdded)
     }

    this.updateCartRestaurant(this.props.storeId, this.props.itemId, this.props.itemName, $('.cartInput' + itemNo).val(), this.props.itemSize, this.props.itemMrp, this.props.itemImage, this.props.storeItemSizeId,isDelete,'')
    this.props.itemLoad()
  }
  updateCartRestaurant(storeId, itemId, itemName, quantity, itemSize, itemMrp, itemImage, storeItemSizeId,isDelete,toppingsData) {//alert("update..........")
  //alert('storeId : '+storeId+'...itemId : '+itemId+'...itemName : '+itemName+'...quantity : '+quantity+'...itemSize : '+itemSize+'...itemMrp : '+itemMrp+'...storeItemSizeId : '+storeItemSizeId+'...toppingsData : '+toppingsData)
  // let storeId = window.sessionStorage.getItem('storeId');
    // let userId = localStorage.getItem('userId');
    //alert("toppingsData."+toppingsData)
    var selectRestaurantCart = JSON.parse(localStorage.getItem('selectRestaurantCart')) || []
    let itemNo = itemId+'-'+storeItemSizeId
    if (localStorage.getItem('selectRestaurantCart') == null) {
    //  alert('selectRestaurantCart.....if...'+ quantity)
     // alert("isDelete..."+isDelete)
      if (isDelete == false || quantity > 0) {
          var selectedRestaurantProduct = {
            storeId: storeId,
            itemId: itemId,
            itemName: itemName,
            quantity: quantity,
            itemSize: itemSize,
            itemMrp: itemMrp,
            itemImage: itemImage,
            storeItemSizeId: storeItemSizeId,
            itemNo: itemNo,
            toppingsData:toppingsData
          }
          selectRestaurantCart.push(selectedRestaurantProduct)
          localStorage.setItem('selectRestaurantCart', JSON.stringify(selectRestaurantCart))
      }
    }
    else {
     // alert('selectRestaurantCart.....else...')
      var cookieNewRestaurantValue = [];
      var cookieRestaurantArray = selectRestaurantCart;
      var cookieRestaurantUpdated = false
      var cookieRestaurantNewValueCount = 0;
      //alert("cookieRestaurantArray.length..."+cookieRestaurantArray.length)
      for (var i = 0; i < cookieRestaurantArray.length; i++) {
        if (cookieRestaurantArray[i] != null && cookieRestaurantArray[i] != "") {
          var cookieStoreId = cookieRestaurantArray[i].storeId
          var cookieItemId = cookieRestaurantArray[i].itemId
          var cookieSizeId = cookieRestaurantArray[i].storeItemSizeId
          var toppingsDataFromCart = cookieRestaurantArray[i].toppingsData
          var newTopping = false
          if(null != toppingsData && 'null' != toppingsData && '' != toppingsData){
           // alert("if 1")
            var toppingsDataFromCartSplit = toppingsData.split(',')
            for (var t = 0; t < toppingsDataFromCartSplit.length; t++) {
               // alert("toppingsData......."+toppingsData)
               // alert("isexists...."+toppingsDataFromCart.indexOf(toppingsDataFromCartSplit[t]))
                if(toppingsDataFromCart.indexOf(toppingsDataFromCartSplit[t]) == '-1'){
                 // alert("if t")
                  newTopping = true
                }
                else{
                 // alert("else t")
                }
            }
          }
          else{
            if(null != toppingsDataFromCart && 'null' != toppingsDataFromCart && '' != toppingsDataFromCart){
              newTopping = true
            }
           // alert("else 1")
             //newTopping = true
          }
          // if(null == toppingsDataFromCart || 'null' == toppingsDataFromCart || '' == toppingsDataFromCart){
          //   newTopping = false
          // }
           //alert("newTopping..."+newTopping)
          // alert("cookieItemId...."+cookieItemId)
          if (itemId == cookieItemId && storeId == cookieStoreId && storeItemSizeId == cookieSizeId && newTopping == false) {
            //alert("if....update.......")
            if (isDelete == false && quantity > 0) {
             // alert("if....update....1...")
              cookieNewRestaurantValue[cookieRestaurantNewValueCount] =
              {
                storeId: storeId,
                itemId: itemId,
                itemName: itemName,
                quantity: quantity,
                itemSize: itemSize,
                itemMrp: itemMrp,
                itemImage: itemImage,
                storeItemSizeId: storeItemSizeId,
                itemNo: itemNo,
                toppingsData:toppingsData
              }
              cookieRestaurantNewValueCount++;
            }
            cookieRestaurantUpdated = true
          }
          else {
            //alert("else....update.......")
            cookieNewRestaurantValue[cookieRestaurantNewValueCount] = cookieRestaurantArray[i];

            cookieRestaurantNewValueCount++;
          }

        }

      }

//alert("cookieRestaurantUpdated......."+cookieRestaurantUpdated)
      if (cookieRestaurantUpdated == false) {//alert('selectRestaurantCart.....updated...')
        if (quantity > 0) {
          cookieNewRestaurantValue[cookieRestaurantNewValueCount] = {
            storeId: storeId,
            itemId: itemId,
            itemName: itemName,
            quantity: quantity,
            itemSize: itemSize,
            itemMrp: itemMrp,
            itemImage: itemImage,
            storeItemSizeId: storeItemSizeId,
            itemNo: itemNo,
            toppingsData:toppingsData
          }
          cookieRestaurantUpdated = true
        }
      }

      localStorage.setItem('selectRestaurantCart', JSON.stringify(cookieNewRestaurantValue))

    }



    let cartLength = 0;
    if (localStorage.getItem('selectedCartProduct') != null) {
      cartLength = parseInt(cartLength) + (JSON.parse(localStorage.getItem('selectedCartProduct')).length);
    }
    if (localStorage.getItem('selectRestaurantCart') != null) {
      cartLength = parseInt(cartLength) + (JSON.parse(localStorage.getItem('selectRestaurantCart')).length);
    }
    if (localStorage.getItem('selectCommonItemCart') != null) {
      cartLength = parseInt(cartLength) + (JSON.parse(localStorage.getItem('selectCommonItemCart')).length);
    }

    $("#cartCount").text(cartLength)

  }
  isNumberRestaurant = (e) => {
    var itemNo = this.props.index;
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      var prnum = $('.cartInput' + itemNo).val();
    }
    else {
      var prnum = e.target.value.replace(/[^0-9\.]/g, '');
      $('.cartInput' + itemNo).val(prnum)
    }
    this.addToCartRestaurant("txtChange")
  }
  render() {
    let themeId = localStorage.getItem('themeId')
    return (
      <div>    
        {(() => {
          if (this.props.isMultiSize > 1 || this.props.customize == true) {
            return (
              <div className="addcartbtn">
                <div class="cartButton" onClick={(e) => this.addToCartRestaurantSize(this.props.itemId)}>{Parser(this.props.cartLabel)}</div>
              </div>
            )
          }
          else{
            return (
              <div className="addcartbtn">
                <div class="qty">
                  <span class="dec" onClick={(e) => this.addToCartRestaurant("dec")} id={"minus" + this.props.index}>-</span>
                  <span class="cartNumber">
                    <input type="text" value={this.props.cartSize} className={"cartInput cartInput" + this.props.index} id={"txtQty" + this.props.index} onChange={this.isNumberRestaurant} onClick={(e) => this.addToCartRestaurant("txtChange")} /></span>
                  <span class="inc addBtn" onClick={(e) => this.addToCartRestaurant("inc")} id={"add" + this.props.index}>+</span>
                </div>
                <div id={"addToCart" + this.props.index} class={"cartButton cartButtonRestaurant "+this.props.isCartItemOpenClass} onClick={(e) => this.addToCartRestaurant("addToCart")}>{Parser(this.props.cartLabel)}</div>
                {/* {(() => {
                  if(themeId == '34'){
                    return (
                      <div id={"addToCart" + this.props.index} class={"cartButton cartButtonRestaurant"+this.props.isCartItemOpenClass} onClick={(e) => this.addToCartRestaurant("addToCart")}>{!this.state.isAdded ? "ADD TO CART" : <img src="https://storedigfiles.s3.amazonaws.com/production/themes/cartBtnLokal.png" />}</div>
                    )
                  }
                  else{
                    return (
                      <div id={"addToCart" + this.props.index} class={"cartButton cartButtonRestaurant"+this.props.isCartItemOpenClass} onClick={(e) => this.addToCartRestaurant("addToCart")}>{!this.state.isAdded ? "ADD TO CART" : "ADDED"}</div>
                    )
                  }
                })()}                   */}
              </div>
            )
          }
        })()}         
      </div>
    )
  }
}