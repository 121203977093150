import React, { Component,Suspense } from 'react';
// import Header from "../layout/header"
// import Footer from "../layout/footer"
import quote from "../images/quote.png"
import quoteleft from "../images/quote-left.png"
import linkedin from "../images/linkedin.png";
import balaji from "../images/team/balaji.png"
import rana from "../images/team/rana.jpg"
import gopalakrishnan from "../images/team/gopalakrishnan.png"
import neha from "../images/team/neha.png"
import prakash from "../images/team/prakash.png"
import ajayShetty from "../images/team/ajayShetty.jpg"
import divya from "../images/team/divya.jpg"
import shameer from "../images/team/shameer.jpg"
import vibhu from "../images/team/vibhu.jpg"
import shahi from "../images/team/shahi.jpg"
import shaheen from "../images/team/shaheen.jpg"
import srinath from "../images/team/srinath.jpg"
import sajin from "../images/team/sajin.jpg"
import unni from "../images/team/unni.jpg"
import siva from "../images/team/siva.jpg"
import soumya from "../images/team/soumya.jpg"
import geethu from "../images/team/geethu.jpg"
import sabari from "../images/team/sabari.jpg"
import merin from "../images/team/merin.jpg"
import arunlal from "../images/team/arunlal.jpg"
import ramsi from "../images/team/ramsi.jpg"
import abhilash from "../images/team/abhilash.jpg"
import rahul from "../images/team/rahul.jpg"
import bindu from "../images/team/bindu.jpg"
import sneha from "../images/team/sneha.jpg"
import anandM from "../images/team/anandM.jpg"
import reshma from "../images/team/reshma.jpg"
import alzaid from "../images/team/alzaid.jpg"
import Anand from "../images/team/Anand.jpg"
let Header = React.lazy(() => import("../header"));
let Footer = React.lazy(() => import("../footer"));
export default class Team extends Component {
    render() {
        return (
            <div class="myyshoppWrapperInner myyshopp-main-wrapper">
                <Suspense fallback={<div></div>}>
                <Header></Header>
                <div class="container">
                    <section class="">
                        <div class="col-md-12">
                            <h1 class="main-head text-center mt-md-0 mt-3">How we are? </h1>
                        </div>
                        <div class="gray-text-dim-div">
                            <img src={quote} class="quote-img1" alt=""></img>
                            <div class="gray-text-dim"> You drive the business.<br></br> We help accelerate it.  </div>
                            <img src={quoteleft} class="quote-img2" alt=""></img>
                        </div>
                    </section>
                    <section class="mt-3">
                        <h1 class="head-bold-normal text-center text-capitalize">The Leadership Team</h1>
                        <div class="mshopp-team">
                            <div class="mshopp-team-list">
                                <img src={balaji} class="img-fluid" alt="" ></img>
                                <div className="mshopp-team-name text-truncate">Balaji Jagannathan</div>
                                <p>Founder & CEO</p>
                                <div class="mshopteam-socialDiv">
                                    <a href="https://www.linkedin.com/in/balajijagannathan">
                                        <img src={linkedin} width="25" alt="" ></img>
                                    </a>
                                </div>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={gopalakrishnan} class="img-fluid" alt=""></img>
                                <div className="mshopp-team-name text-truncate">GV Gopalakrishnan</div>
                                <p>Co-Founder</p>
                                <div class="mshopteam-socialDiv">
                                    <a href="https://www.linkedin.com/in/g-v-gopalakrishnan-9770819">
                                        <img src={linkedin} width="25" alt="" ></img>
                                    </a>
                                </div>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={prakash} class="img-fluid" alt="" ></img>
                                <div className="mshopp-team-name text-truncate">Prakash Sathyapalan</div>
                                <p>Chief Technical Advisor</p>
                                <div class="mshopteam-socialDiv">
                                    <a href="https://www.linkedin.com/in/prakashsathyapalan">
                                        <img src={linkedin} width="25" alt="" ></img>
                                    </a>
                                </div>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={neha} class="img-fluid" alt="" ></img>
                                <div className="mshopp-team-name text-truncate">Neha Malhotra</div>
                                <p>Head – Products</p>
                                <div class="mshopteam-socialDiv">
                                    <a href="https://www.linkedin.com/in/nehamalhotra1985">
                                        <img src={linkedin} width="25" alt="" ></img>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="mt-5">
                        <h1 class="head-bold-normal text-center text-capitalize">The Passionate Team</h1>
                        <div class="mshopp-team mshopp-passionate-team">
                            <div class="mshopp-team-list">
                                <img src={ajayShetty} class="img-fluid" alt="" ></img>
                                <div className="mshopp-team-name text-truncate">Ajay</div>
                                <p>AVP Corporate Relations</p>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={Anand} class="img-fluid" alt="" ></img>
                                <div className="mshopp-team-name text-truncate">Anand Radhakrishnan</div>
                                <p>Director Sales</p>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={rana} class="img-fluid" alt="" ></img>
                                <div className="mshopp-team-name text-truncate">Rana</div>
                                <p>Director Sales & Operations</p>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={divya} class="img-fluid" alt="" ></img>
                                <div className="mshopp-team-name text-truncate">Divya</div>
                                <p>Manager - Development</p>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={shameer} class="img-fluid" alt="" ></img>
                                <div className="mshopp-team-name text-truncate">Shameer</div>
                                <p>Manager - QA</p>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={vibhu} class="img-fluid" alt=""></img>
                                <div className="mshopp-team-name text-truncate">Vibhu</div>
                                <p>Manager - IT Infrastructure</p>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={unni} class="img-fluid" alt=""></img>
                                <div className="mshopp-team-name text-truncate">Unni</div>
                                <p>Sr. Software Engineer</p>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={shaheen} class="img-fluid" alt=""></img>
                                <div className="mshopp-team-name text-truncate">Shaheen</div>
                                <p>Sr. Software Engineer</p>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={srinath} class="img-fluid" alt=""></img>
                                <div className="mshopp-team-name text-truncate">Srinath</div>
                                <p>Sr. Software Engineer</p>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={sajin} class="img-fluid" alt=""></img>
                                <div className="mshopp-team-name text-truncate">Sajin</div>
                                <p>Sr. Software Engineer</p>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={arunlal} class="img-fluid" alt=""></img>
                                <div className="mshopp-team-name text-truncate">Arunlal</div>
                                <p>Sr. UI Developer</p>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={shahi} class="img-fluid" alt=""></img>
                                <div className="mshopp-team-name text-truncate">Shahi</div>
                                <p>Sr. Software Tester</p>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={siva} class="img-fluid" alt=""></img>
                                <div className="mshopp-team-name text-truncate">Siva</div>
                                <p>Software Engineer</p>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={soumya} class="img-fluid" alt=""></img>
                                <div className="mshopp-team-name text-truncate">Soumya</div>
                                <p>Software Engineer</p>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={geethu} class="img-fluid" alt=""></img>
                                <div className="mshopp-team-name text-truncate">Geethu</div>
                                <p>Software Engineer</p>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={sabari} class="img-fluid" alt=""></img>
                                <div className="mshopp-team-name text-truncate">Sabari</div>
                                <p>Software Engineer</p>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={merin} class="img-fluid" alt=""></img>
                                <div className="mshopp-team-name text-truncate">Merin</div>
                                <p>Software Engineer</p>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={ramsi} class="img-fluid" alt=""></img>
                                <div className="mshopp-team-name text-truncate">Ramsi</div>
                                <p>UI Developer</p>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={abhilash} class="img-fluid" alt=""></img>
                                <div className="mshopp-team-name text-truncate">Abhilash</div>
                                <p>Software Tester</p>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={rahul} class="img-fluid" alt=""></img>
                                <div className="mshopp-team-name text-truncate">Rahul</div>
                                <p>Software Tester</p>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={bindu} class="img-fluid" alt=""></img>
                                <div className="mshopp-team-name text-truncate">Bindu</div>
                                <p>Graphic Designer</p>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={sneha} class="img-fluid" alt=""></img>
                                <div className="mshopp-team-name text-truncate">Sneha</div>
                                <p>Android Developer</p>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={alzaid} class="img-fluid" alt=""></img>
                                <div className="mshopp-team-name text-truncate">Alzaid</div>
                                <p>Business Development Executive</p>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={reshma} class="img-fluid" alt=""></img>
                                <div className="mshopp-team-name text-truncate">Reshma</div>
                                <p>Process Executive</p>
                            </div>
                            <div class="mshopp-team-list">
                                <img src={anandM} class="img-fluid" alt=""></img>
                                <div className="mshopp-team-name text-truncate">Anand</div>
                                <p>Process Executive</p>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer></Footer>
                </Suspense>
            </div>
        )
    }
}