import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import { getData } from '../services';
import cancel from "../images/icons/close-sm.png";
import mslist from "../images/icons/ms-list.png";
import orderitemrating from "../images/icons/order-item-rating.png";
import reorder from "../images/icons/reorder.png";
export default class myorders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tableData: [],
      search: '',
      tableDataTemp: [],
      orderDetails: [],
      orderNumber: '',
      mainCategoryGroupId: ''
    };
    this.searchValueOnChange = this.searchValueOnChange.bind(this);
    this.editValueOnChange = this.editValueOnChange.bind(this);
    this.cancelValueOnChange = this.cancelValueOnChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    localStorage.setItem('storeId', window.sessionStorage.getItem('storeId'))
    let currentUserId = localStorage.getItem('userId');
    const postData = {
      functionName: "userdashboarddetails/getBuyerOrder",
      currentUserId: currentUserId,
      orderId: '',
      textId: '',
      fromDateQuery: '',
      toDateQuery: '',
      statusQuery: '',
      cmbselect: '',
      txtInputId: '',
      cmbstatus: ''
    }
    getData(postData).then((data) => {
      console.log("dataaa", data)
      if (data.data.success === "1") {
        this.setState({
          orderDetails: data.data.result.getBuyerOrder,
        });
      }
    })
  }
  changeText = (event) => {
    if (document.getElementById("cmbselect").value !== '0') {
      document.getElementById("txtInputId").disabled = false
      document.getElementById('txtInputId').focus();
    }
    else {
      document.getElementById("txtInputId").disabled = true
    }
    document.getElementById("txtInputId").value = ""
  }
  searchValueOnChange = (event) => {
    debugger
    var cmbselect = document.getElementById("cmbselect").value;
    var txtInputId = document.getElementById("txtInputId").value;
    var cmbstatus = document.getElementById('cmbstatus').value;
    var statusQuery = ""
    if (document.getElementById("cmbselect").value !== "0" && document.getElementById("txtInputId").value === "") {
      var t = document.getElementById("cmbselect");
      var selectedText = t.options[t.selectedIndex].text;
      alert(selectedText + " Required");
      document.getElementById("txtInputId").focus();
      return false;
    }
    if (cmbselect === 'id') {
      var orderId = document.getElementById("txtInputId").value;
    }
    if (cmbselect === 'name') {
      var textId = document.getElementById("txtInputId").value;
    }
    if (cmbstatus === '1') {
      statusQuery = document.getElementById("cmbstatus").value;
    }
    if (cmbstatus === '2') {
      statusQuery = document.getElementById("cmbstatus").value;
    }
    if (cmbstatus === '3') {
      statusQuery = document.getElementById("cmbstatus").value;
    }
    if (cmbstatus === '4') {
      statusQuery = document.getElementById("cmbstatus").value;
    }
    if (cmbstatus === '5') {
      statusQuery = document.getElementById("cmbstatus").value;
    }
    if (cmbstatus === '6') {
      statusQuery = document.getElementById("cmbstatus").value;
    }
    if (cmbstatus === '8') {
      statusQuery = document.getElementById("cmbstatus").value;
    }
    if (cmbstatus === '9') {
      statusQuery = document.getElementById("cmbstatus").value;
    }
    if (cmbstatus === '10') {
      statusQuery = document.getElementById("cmbstatus").value;
    }
    var fromDateQuery = '';
    var toDateQuery = '';
    let currentUserId = localStorage.getItem('userId');
    const postData = {
      functionName: "userdashboarddetails/getBuyerOrder",
      currentUserId: currentUserId,
      orderId: orderId,
      textId: textId,
      fromDateQuery: fromDateQuery,
      toDateQuery: toDateQuery,
      statusQuery: statusQuery,
      cmbselect: cmbselect,
      txtInputId: txtInputId,
      cmbstatus: cmbstatus
    }
    getData(postData).then((data) => {
      console.log("data10", data)
      if (data.data.success === "1") {
        this.setState({
          orderDetails: data.data.result.getBuyerOrder,
        });
      }
    })
  }
  editValueOnChange = (orderId) => {
    debugger
    const postData = {
      "functionName": (window.sessionStorage.getItem('mainGroupCategory') === '5') ? "myordersave" : "cmMyOrderSave",
      "orderId": orderId
    }
    if (window.confirm("Editing the order will cancel your order and add all your items to cart.Do you want to edit the order?")) {
      if (window.sessionStorage.getItem('mainGroupCategory') === '5') {
        getData(postData).then((data) => {
          console.log("daaaaaaaaaa,data", data)
          if (data.data.success === "1") {
            if (data.data.result.orderEditDetails) {
              var selectRestaurantCart = JSON.parse(localStorage.getItem('selectRestaurantCart')) || []
              if (localStorage.getItem('selectRestaurantCart') === null) {
                for (var i = 0; i < data.data.result.orderEditDetails.length; i++) {
                  var selectedRestaurantProduct = {
                    storeId: data.data.result.orderEditDetails[i].storeId,
                    itemId: data.data.result.orderEditDetails[i].itemId,
                    itemName: data.data.result.orderEditDetails[i].itemName,
                    quantity: data.data.result.orderEditDetails[i].quantity,
                    itemSize: data.data.result.orderEditDetails[i].itemSize,
                    itemMrp: data.data.result.orderEditDetails[i].price,
                    itemImage: data.data.result.orderEditDetails[i].imagename,
                    storeItemSizeId: data.data.result.orderEditDetails[i].storeItemSizeId,
                    itemNo: data.data.result.orderEditDetails[i].itemId
                  }
                  selectRestaurantCart.push(selectedRestaurantProduct)
                }
                localStorage.setItem('selectRestaurantCart', JSON.stringify(selectRestaurantCart))
                window.location.reload();
              }
              else {
                for (var i = 0; i < data.data.result.orderEditDetails.length; i++) {
                  var selectedRestaurantProduct = {
                    storeId: data.data.result.orderEditDetails[i].storeId,
                    itemId: data.data.result.orderEditDetails[i].itemId,
                    itemName: data.data.result.orderEditDetails[i].itemName,
                    quantity: data.data.result.orderEditDetails[i].quantity,
                    itemSize: data.data.result.orderEditDetails[i].itemSize,
                    itemMrp: data.data.result.orderEditDetails[i].price,
                    itemImage: data.data.result.orderEditDetails[i].imagename,
                    storeItemSizeId: data.data.result.orderEditDetails[i].storeItemSizeId,
                    itemNo: data.data.result.orderEditDetails[i].itemId
                  }
                  selectRestaurantCart.push(selectedRestaurantProduct)
                }
                localStorage.setItem('selectRestaurantCart', JSON.stringify(selectRestaurantCart))
                window.location.reload();
              }
            }
          }
        })
      }
      else if (window.sessionStorage.getItem('mainGroupCategory') === '8') {
        getData(postData).then((data) => {
          console.log("daaaaaataaa", data)
          if (data.data.success === "1") {
            if (data.data.result.orderCommonEditDetails) {
              var selectCommonItemCart = JSON.parse(localStorage.getItem('selectCommonItemCart')) || []
              if (localStorage.getItem('selectCommonItemCart') === null) {
                for (var i = 0; i < data.data.result.orderCommonEditDetails.length; i++) {
                  var selectedCommonItemProduct = {
                    storeId: data.data.result.orderCommonEditDetails[i].storeId,
                    itemId: data.data.result.orderCommonEditDetails[i].itemId,
                    itemName: data.data.result.orderCommonEditDetails[i].itemName,
                    quantity: data.data.result.orderCommonEditDetails[i].quantity,
                    mrp: data.data.result.orderCommonEditDetails[i].itemMrp,
                    itemImage: data.data.result.orderCommonEditDetails[i].imagename,
                    itemMasterId: data.data.result.orderCommonEditDetails[i].cmItemMasterId,
                    itemNo: data.data.result.orderCommonEditDetails[i].itemId + '-' + ((data.data.result.orderCommonEditDetails[i].cmItemSizeId == null || data.data.result.orderCommonEditDetails[i].cmItemSizeId == '') ? '' : data.data.result.orderCommonEditDetails[i].cmItemSizeId),
                    cmItemSizeId: data.data.result.orderCommonEditDetails[i].cmItemSizeId,
                    itemTitle: data.data.result.orderCommonEditDetails[i].itemTitle,
                    brand: data.data.result.orderCommonEditDetails[i].brand,
                    sizeName: data.data.result.orderCommonEditDetails[i].sizeName,
                    sizeId: data.data.result.orderCommonEditDetails[i].sizeId,
                    netAmount: data.data.result.orderCommonEditDetails[i].price
                  }
                  selectCommonItemCart.push(selectedCommonItemProduct)
                }
                localStorage.setItem('selectCommonItemCart', JSON.stringify(selectCommonItemCart))
                window.location.reload();
              }
              else {
                for (var i = 0; i < data.data.result.orderCommonEditDetails.length; i++) {
                  var selectedCommonItemProduct = {
                    storeId: data.data.result.orderCommonEditDetails[i].storeId,
                    itemId: data.data.result.orderCommonEditDetails[i].itemId,
                    itemName: data.data.result.orderCommonEditDetails[i].itemName,
                    quantity: data.data.result.orderCommonEditDetails[i].quantity,
                    mrp: data.data.result.orderCommonEditDetails[i].itemMrp,
                    itemImage: data.data.result.orderCommonEditDetails[i].imagename,
                    itemMasterId: data.data.result.orderCommonEditDetails[i].cmItemMasterId,
                    itemNo: data.data.result.orderCommonEditDetails[i].itemId + '-' + ((data.data.result.orderCommonEditDetails[i].cmItemSizeId == null || data.data.result.orderCommonEditDetails[i].cmItemSizeId == '') ? '' : data.data.result.orderCommonEditDetails[i].cmItemSizeId),
                    cmItemSizeId: data.data.result.orderCommonEditDetails[i].cmItemSizeId,
                    itemTitle: data.data.result.orderCommonEditDetails[i].itemTitle,
                    brand: data.data.result.orderCommonEditDetails[i].brand,
                    sizeName: data.data.result.orderCommonEditDetails[i].sizeName,
                    sizeId: data.data.result.orderCommonEditDetails[i].sizeId,
                    netAmount: data.data.result.orderCommonEditDetails[i].price
                  }
                  selectCommonItemCart.push(selectedCommonItemProduct)
                }
                localStorage.setItem('selectCommonItemCart', JSON.stringify(selectCommonItemCart))
                window.location.reload();
              }
            }
          }
        })
      }
      return true;
    }
    else {
      return false;
    }
  }
  cancelValueOnChange = (orderId) => {
    var x = false;
    x = window.confirm("Do you want to Cancel this order?")
    if (x === true) {
      const postData = {
        functionName: "checkout/cancelOrder",
        orderId: orderId
      }
      getData(postData).then((data) => {
        console.log("dataaa", data)
        if (data.data.success === "1") {
          alert("Successfully Cancelled the Order")
          let currentUserId = localStorage.getItem('userId');
          const postData = {
            functionName: "userdashboarddetails/getBuyerOrder",
            currentUserId: currentUserId,
            orderId: '',
            textId: '',
            fromDateQuery: '',
            toDateQuery: '',
            statusQuery: '',
            cmbselect: '',
            txtInputId: '',
            cmbstatus: ''
          }
          getData(postData).then((data) => {
            if (data.data.success === "1") {
              this.setState({
                orderDetails: data.data.result.getBuyerOrder,
              });
            }
          })
          // window.location.reload();
        }
      })
    }
  }
  ValueOnChange = (orderId) => {
    alert("orderId..." + orderId)
    window.location.href = "/dashboard/feedback/";
  }
  handleChange = date => {
    this.setState({
      fromDateQuery: date
    });
  };
  render() {
    let themeId = localStorage.getItem('themeId')
    return (
      <div className="accountdetails">
        {console.log("pppppppppp", window.sessionStorage.getItem('mainGroupCategory'))}
        <div className="dashTitle">Buyer Order Report</div>
        <div className="dashSection">
          <div className="searchSectionDiv">
            <div className="searchItemDiv">
              <select className="searchInputField" id="cmbselect" name="cmbselect" onChange={() => this.changeText()} >
                <option value="0">Search In</option>
                <option value="id"># Order  </option>
                <option value="name">Store Name</option>
              </select>
            </div>
            <div className="searchItemDiv">
              <input type="text" className="searchInputField" id="txtInputId" />
            </div>
            <div className="searchItemDiv">
              <select className="searchInputField" name="cmbstatus" id="cmbstatus">
                <option value="0">Status</option>
                <option value="1">Open</option>
                <option value="2">In Process</option>
                <option value="3">Out for Delivery</option>
                <option value="4">Delivered</option>
                <option value="5">Cancelled</option>
                <option value="6">Returned</option>
                <option value="8">Verified</option>
                <option value="9">Refund Requested</option>
                <option value="10">Refund Accepted</option>
              </select>
            </div>
            <div className="searchItemDiv">
              <span className="commonButton" onClick={() => this.searchValueOnChange()}>Search</span>
            </div>
          </div>
          <div className="dashboardTable dashboardTableMain">
            <table class="table table-bordered">
              <thead>
                <th># Order</th>
                <th>Store Name</th>
                <th>Order Date</th>
                <th>Delivery Date</th>
                <th>Status</th>
                <th>Amount</th>
                <th>Action</th>
              </thead>
              {this.state.orderDetails.length > 0 ?
                <tbody>
                  {this.state.orderDetails.map((orderDetails, i) => (
                    <tr>
                      <td>{orderDetails.orderNumber}</td>
                      <td>{orderDetails.storeName}</td>
                      <td>{orderDetails.orderDate}</td>
                      <td>{orderDetails.deliveryDate}</td>
                      <td>{orderDetails.orderStatus}</td>
                      <td>{orderDetails.currencySymbol} {parseFloat(orderDetails.amount).toLocaleString('en-IN', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                      <td>
                        {console.log("orderDetails",orderDetails,window.sessionStorage.getItem('mainGroupCategory'),typeof(window.sessionStorage.getItem('mainGroupCategory')))}
                        <span className="actionSection11 d-flex">
                          {orderDetails.orderStatus === 'Open' ? <span className="closeimgmargin ms-cancel" onClick={() => this.cancelValueOnChange(orderDetails.orderNumber)}><img src={cancel} alt="Cancel" title="Cancel" style={{ width: "100%" }} /></span> : ""}
                          {window.sessionStorage.getItem('mainGroupCategory') === '5' || window.sessionStorage.getItem('mainGroupCategory') === '8' ?
                            orderDetails.orderStatus === 'Open' ? <span className="dashButton dashButtonEdit" onClick={() => this.editValueOnChange(orderDetails.orderNumber)} >Edit</span> : ""
                            : ''}
                          {/* {orderDetails.orderStatus === 'Delivered' ? <Link className="dashButton dashfeedBackBtn" to={"/myorderFeedBack/" + orderDetails.orderNumber}>FeedBack</Link> : ""} */}
                          {orderDetails.orderStatus === "Delivered" || orderDetails.orderStatus === "Returned" || orderDetails.orderStatus === "Cancelled" ? <Link className="dashButton ms-cancel" to={"/myorderFeedBack/" + orderDetails.orderNumber} ><img src={orderitemrating} alt="Order Item Rating" title="Order Item Rating" /></Link> : ""}
                          {(orderDetails.orderStatus === "Verified" || orderDetails.orderStatus === "Returned" || orderDetails.orderStatus === "Cancelled") && (themeId !== '28' && themeId !== '37') ? <Link className="dashButton ms-cancel" to={{
  pathname: `/reOrder/${orderDetails.orderNumber}/${orderDetails.mainCategoryGroupId}`,
  state: { mainCategoryGroupId: orderDetails.mainCategoryGroupId },
  }}><img src={reorder} alt="Reorder" title="Reorder" />
                          </Link> : ''}
                          <Link className="dashButton ms-cancel" to={orderDetails.isMyyshoppInvoice === "1" ? "/orderInvoice/" + orderDetails.encryptedorderId : "/orderDetails/" + orderDetails.encryptedorderId} target="_blank"> <img src={mslist} alt="Details" title="Details" /></Link>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
                : <tbody><tr class='text-danger text-center'><td colspan="7">No data Found</td> </tr></tbody>}
            </table>
          </div>
        </div>
      </div>
    )
  }
}
