import React, { Component, Suspense } from 'react'
import Success from './success'
import { getData, getCartItemsMultiple } from '../services'
import { browserName } from "react-device-detect";
import $ from 'jquery'
let MainHeader = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/header"));
let MainFooter = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/footer"));
export default class OrdersummarySuccess extends Component {
    componentDidMount() {
        debugger
        var storeId = localStorage.getItem('hidStoreIdForCheckout');
        var hasMultipleCheckout = window.sessionStorage.getItem('hasMultipleCheckout')
        const test = JSON.parse(localStorage.getItem('orderSummery'));
        $("#paymentDiv").hide();
        $("#successDiv").show();
        // getPageHitAPICall();
        $("#checkOutHeadingDiv").text("Finished!")
        $("#checkOutStep4").addClass("orderIndicationActive")
        if (hasMultipleCheckout === "0") {
            var userOrderID = ""
            var userDeliveryTime = ""
            if (test[0].commonItems.length > 0) {
                userOrderID = test[0].commonItems[0].saleOrderId
                userDeliveryTime = test[0].commonItems[0].slotNames
            }
            else if (test[0].restaurant.length > 0) {
                userOrderID = test[0].restaurant[0].saleOrderId
                userDeliveryTime = test[0].restaurant[0].slotNames
            }
            else if (test[0].grocery.length > 0) {
                userOrderID = test[0].grocery[0].saleOrderId
                userDeliveryTime = test[0].grocery[0].slotNames
            }
            $("#divOrderId").text("Order ID : " + userOrderID)
            $("#divDeliveryTime").text("Delivery Time : " + userDeliveryTime)
        }
        if (storeId != '') {
            var selectGroceryItemCart = JSON.parse(localStorage.getItem('selectedCartProduct')) || []
            var cookieNewGroceryItemValue = []
            var j = 0
            for (var i = 0; i < selectGroceryItemCart.length; i++) {
                if (storeId != selectGroceryItemCart[i].storeId) {
                    cookieNewGroceryItemValue[j] =
                    {
                        storeId: selectGroceryItemCart[i].storeId.toString().trim(),
                        item_names: selectGroceryItemCart[i].item_names.toString().trim(),
                        item_ids: selectGroceryItemCart[i].item_ids.toString().trim(),
                        quantity: selectGroceryItemCart[i].quantity.toString().trim(),
                        item_prices: selectGroceryItemCart[i].item_prices.toString().trim(),
                        store_item_stock_ids: selectGroceryItemCart[i].store_item_stock_ids.toString().trim(),
                        pack_ids: selectGroceryItemCart[i].pack_ids.toString().trim(),
                        stock_quantity: selectGroceryItemCart[i].stock_quantity.toString().trim(),
                        item_image: selectGroceryItemCart[i].item_image.toString().trim(),
                        itemNo: selectGroceryItemCart[i].itemNo.toString().trim()
                    }
                    j = parseInt(j) + 1
                }
            }
            localStorage.setItem('selectedCartProduct', JSON.stringify(cookieNewGroceryItemValue))

            var selectCommonItemCart = JSON.parse(localStorage.getItem('selectCommonItemCart')) || []
            var cookieNewCommonItemValue = []
            j = 0
            for (var i = 0; i < selectCommonItemCart.length; i++) {
                if (storeId != selectCommonItemCart[i].storeId) {
                    cookieNewCommonItemValue[j] =
                    {
                        storeId: selectCommonItemCart[i].storeId.toString().trim(),
                        itemId: selectCommonItemCart[i].itemId.toString().trim(),
                        itemName: selectCommonItemCart[i].itemName.toString().trim(),
                        quantity: selectCommonItemCart[i].quantity.toString().trim(),
                        itemTitle: selectCommonItemCart[i].itemTitle,
                        brand: selectCommonItemCart[i].brand,
                        sizeName: selectCommonItemCart[i].sizeName.toString().trim(),
                        sizeId: selectCommonItemCart[i].sizeId.toString().trim(),
                        cmItemSizeId: selectCommonItemCart[i].cmItemSizeId.toString().trim(),
                        itemMasterId: selectCommonItemCart[i].itemMasterId.toString().trim(),
                        netAmount: selectCommonItemCart[i].netAmount.toString().trim(),
                        mrp: selectCommonItemCart[i].mrp.toString().trim(),
                        itemImage: selectCommonItemCart[i].itemImage.toString().trim(),
                        itemNo: selectCommonItemCart[i].itemNo.toString().trim()
                    }
                    j = parseInt(j) + 1
                }
            }
            localStorage.setItem('selectCommonItemCart', JSON.stringify(cookieNewCommonItemValue))

            var selectRestaurantCart = JSON.parse(localStorage.getItem('selectRestaurantCart')) || []
            var cookieNewRestaurantValue = []
            j = 0
            for (var i = 0; i < selectRestaurantCart.length; i++) {
                if (storeId != selectRestaurantCart[i].storeId) {
                    cookieNewRestaurantValue[j] =
                    {
                        storeId: selectRestaurantCart[i].storeId.toString().trim(),
                        itemId: selectRestaurantCart[i].itemId.toString().trim(),
                        itemName: selectRestaurantCart[i].itemName.toString().trim(),
                        quantity: selectRestaurantCart[i].quantity.toString().trim(),
                        itemSize: selectRestaurantCart[i].itemSize.toString().trim(),
                        itemMrp: selectRestaurantCart[i].itemMrp.toString().trim(),
                        itemImage: selectRestaurantCart[i].itemImage.toString().trim(),
                        storeItemSizeId: selectRestaurantCart[i].storeItemSizeId.toString().trim(),
                        itemNo: selectRestaurantCart[i].itemNo.toString().trim(),
                        toppingsData: selectRestaurantCart[i].toppingsData.toString().trim()
                    }
                    j = parseInt(j) + 1
                }
            }
            localStorage.setItem('selectRestaurantCart', JSON.stringify(cookieNewRestaurantValue))
        }
        else {
            localStorage.removeItem('selectedCartProduct')
            localStorage.removeItem('selectCommonItemCart')
            localStorage.removeItem('selectRestaurantCart')
        }
        window.scrollTo(0, 0)
        $('body').removeClass('orderProcessingDisable')
        this.sendMail();       

        
        window.sessionStorage.setItem('browserName', browserName)
        const postData = {
            "functionName": "getStoreHomeData/configuration",
            "configKey": "WorldlineUrl"
        }
        getData(postData).then((data) => {
            if (data.data.success === "1") {
                window.sessionStorage.setItem('WorldlineUrl', data.data.result[0].config_value)
            }
        });
        localStorage.removeItem('hidStoreIdForCheckout')
    }
    sendMail() {
        var orderSummery = localStorage.getItem('orderSummery')
        var orderSummeryData = JSON.parse(orderSummery);
        var postData = {
            functionName: "sendMail",
            result: orderSummeryData
        }
        getCartItemsMultiple(postData).then((data) => {

        })

    }
    render() {
        let themeId = localStorage.getItem('themeId')
        // let fromPageVal = '';
        // if (typeof this.props.location.state !== "undefined") {
        //     const { fromPage } = this.props.location.state;
        //     fromPageVal = fromPage
        // }
        return (
            <div className="coverWraper">
                <Suspense fallback={<div></div>}>
                    <MainHeader></MainHeader>
                    <div className={themeId === "38" ? "schoolUniforOrdersmmry" : ""}>
                        <div className={themeId === "28" ? "contentWrap clContentWrap" : "contentWrap"}>
                            <div className={themeId === "29" ? "MraOrderSummarySection orderSummarySection" : "orderSummarySection"}>

                                <Success />
                            </div>
                        </div>
                    </div>
                    <MainFooter />
                </Suspense>
            </div>
        )
    }
}