import React, { Component , Suspense } from 'react'
import Success from '../lokalIn/success'
// import MainHeader from "../headerComponents/mainHeader"
// import MainFooter from "../headerComponents/mainFooter"
let MainHeader = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/header"));
let MainFooter = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/footer"));
export default class StripeOrderSuccess extends Component {
    render() {       
        return (
            <div className="coverWraper">
                 <Suspense fallback={<div></div>}>
                <MainHeader></MainHeader>
                <div className="contentWrap">
                    <div className="orderSummarySection">
                        <div className="orderIndication">
                            <div id="checkOutStep1" className="orderIndicationBar orderIndicationActive">
                                <span className="orderIndicationCircle"><span></span></span>
                            </div>
                            <div id="checkOutStep2" className="orderIndicationBar">
                                <span className="orderIndicationCircle"><span></span></span>
                            </div>
                            <div id="checkOutStep3" className="orderIndicationBar">
                                <span className="orderIndicationCircle"><span></span></span>
                            </div>
                            <div id="checkOutStep4" className="orderIndicationBar orderIndicationActive">
                                <span className="orderIndicationCircle"><span></span></span>
                            </div>
                        </div>                        
                        <Success fromPage="Worldline"/>
                    </div>
                </div>
                <MainFooter />
                </Suspense>
            </div>
        )
    }
}