import React, { Component } from 'react';
import close from "../images/icons/close.png"
import $ from "jquery";
import { getData } from '../services';
export default class contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      emailId: '',
      name: '',
      phoneNumber: '',
      comments: '',
      disabledName: true,
    }
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.contactForm = this.contactForm.bind(this);
  }
  componentDidMount() {
    $(".saveClose").click(function () {
      $(".popupWrap").hide();
    });
  }
  contactForm = (event) => {
    event.preventDefault();
    var contactMsgError = "";
    let formIsValid;
    $(".contactMsgError").text("");
    let name = this.state.name;
    let emailId = this.state.emailId;
    let phoneNumber = this.state.phoneNumber;
    let comments = this.state.comments;
    if (!name) {
      contactMsgError = "Please enter Name";
      formIsValid = false;
    }
    else if (!emailId) {
      contactMsgError = "Please enter Email Id";
      formIsValid = false;
    }
    else if (!phoneNumber) {
      contactMsgError = "Please enter Phone Number";
      formIsValid = false;
    }
    else if (!comments) {
      contactMsgError = "Please enter Comments";
      formIsValid = false;
    }
    else {
      formIsValid = true;
    }
    if (!formIsValid) {
      $(".contactMsgError").text(contactMsgError);
      return false;
    }
    else {
      const postData = {
        functionName: "itemContactUsSave/grocery",
        storeId: window.sessionStorage.getItem('storeId'),
        sellerId: window.sessionStorage.getItem('sellerId'),
        userId: localStorage.getItem('userId'),
        itemId: this.props.storeItemStockId,
        emailId: this.state.emailId,
        name: this.state.name,
        phone: this.state.phoneNumber,
        comments: this.state.comments,
        moduleType: (this.props.moduleType === "common" ? "common" : "grocery")
      }
      getData(postData).then((data) => {
        if (data.data.success === 1) {
          alert("Your request has been sent successfully");
          window.location.reload();
        }
      });
    }
  }
  handleNameChange = (event) => {
    this.setState({
      name: event.target.value
    });
  }
  handleEmailChange = (event) => {
    this.setState({
      emailId: event.target.value
    });
  }
  handlePhoneChange = (event) => {
    this.setState({
      phoneNumber: event.target.value
    });
  }
  handleCommentChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value
    });
  }
  render() {
    return (
      <div>
        <div className="popOverlay"></div>
        <div className="loginPop">
          <div className="loginHead">
            <span className="saveClose"><img src={close} alt="" /></span>
            <div className="loginText">
              {this.props.storeButtonName}
              <span>ItemName : {this.props.ItemName}</span>
            </div>
          </div>
          <div className="loginForm">
            <form>
              <div className="errorMsg contactMsgError"></div>
              <div className="loginFormList">
                <input type="text" className="inputTextField" id="name" name="name" value={this.state.name} onChange={this.handleNameChange} required placeholder="Name" />
              </div>
              <div className="loginFormList">
                <input type="text" className="inputTextField" id="emailId" name="emailId" value={this.state.emailId} onChange={this.handleEmailChange} required placeholder="Email Id" />
              </div>
              <div className="loginFormList">
                <input type="text" className="inputTextField" id="phoneNumber" name="phoneNumber" value={this.state.phoneNumber} onChange={this.handlePhoneChange} required placeholder="Phone Number" />
              </div>
              <div className="loginFormList">
                <textarea type="text" className="inputTextField" id="comments" name="comments" onChange={this.handleCommentChange} required placeholder="Comments" />
              </div>
              <div className="formButton"><input className="loginButton" onClick={(e) => this.contactForm(e)} value="Send" /></div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
