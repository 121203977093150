import React, { Component, Suspense } from 'react'
import Arrow from "../images/lokal/ph_arrow-up.png"
import Grocery from "../images/lokal/Groceries-near.png"
import Close from "../images/lokal/close.png"
import ArwBottom from "../images/lokal/arow-down.png"
import Store from "../images/lokal/tomato.png"
import Minus from "../images/lokal/minus.png"
import Plus from "../images/lokal/plus.png"
import Dot from "../images/lokal/dot.png"

export default class StoreProfilelist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVegetablesOpen: false,
            isSpicesOpen: false,
            isPackedFoodOpen: false,
            isHouseholdItemsOpen: true,
            showPopup: false
        };
    }

    handleAddClick = (itemName) => {
        this.setState({
            showPopup: true,
            selectedItem: itemName,
        });
    };

    closePopup = () => {
        this.setState({
            showPopup: false,
            selectedItem: null,
        });
    };

    toggleSection = (section) => {
        this.setState((prevState) => ({
            [section]: !prevState[section]
        }));        
    };

    // toggleSection = (section) => {
    //     console.log(section);
    //     this.setState((prevState) => ({
    //         isVegetablesOpen: false,
    //         isSpicesOpen: false,
    //         isPackedFoodOpen: false,
    //         isHouseholdItemsOpen: false,
    //         [section]: !prevState[section] // Toggle the clicked section
    //     }));
    // };
    

    render() {
        const { isVegetablesOpen, isSpicesOpen, isPackedFoodOpen, isHouseholdItemsOpen, showPopup, } = this.state;
        return (
            <div>
                <div className='top-header'>
                    <div className='container-fluid outer-padding'>
                        <div className="row m-0">
                            <div className='bread-crumb col-12'>
                                <span className='link'>Home</span>
                                <span className='px-1'>
                                    <img src={Arrow} alt="Arrow" className='img-responsive' width="15" height="15" />
                                </span>
                                <span className='link'>Mumbai (Saki Vihar)</span>
                                <span className='px-1'>
                                    <img src={Arrow} alt="Arrow" className='img-responsive' width="15" height="15" />
                                </span>
                                <span className='link'>Groceries</span>
                                <span className='px-1'>
                                    <img src={Arrow} alt="Arrow" className='img-responsive' width="15" height="15" />
                                </span>
                                <span className='link-active'>Real Suvidha Store</span>
                            </div>
                            <div className='grocery-near col-12'>
                                <div className="">
                                    <div className='store-listimg-bg'>
                                        <img src={Grocery} alt="Grocery" className='img-fluid' width="70" height="70" />
                                    </div>
                                </div>
                                <div className="">
                                    <div className='grocery-head'>Real Suvidha Store</div>
                                    <div className='grocery-no'>
                                        Saki Vihar Road
                                        <span className='px-3'>37 mins</span>

                                        <span className='delivery-type'>
                                            <span className='px-2'>.</span>
                                            Free Delivery</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='store-profile'>
                    <div className='container-fluid outer-padding'>
                        <div className="col-12 store-profile-div">
                            <div className="store-profile-left">
                                <div className="store-profile-head">Pick Your Category</div>
                                 <ul className="pick-category">
                                    <li className="store-profile-li">
                                        <div onClick={() => this.toggleSection('isVegetablesOpen')} className="li-header">
                                            <span className='cat-list'>Vegetables</span>
                                            {isVegetablesOpen ?
                                                <span className="cat-list-img">
                                                    <img src={ArwBottom} alt="Arwright" className='img-fluid rotate-arrow' />
                                                </span>
                                                :
                                                <span className="cat-list-img">
                                                    <img src={ArwBottom} alt="ArwBottom" className='img-fluid' />
                                                </span>
                                            }
                                        </div>
                                        {isVegetablesOpen && (
                                            <ul className="subList">
                                                <li><input type="checkbox" /> Vegetables1</li>
                                                <li><input type="checkbox" /> Vegetables2</li>
                                            </ul>
                                        )}
                                    </li>
                                    <li className="store-profile-li">
                                        <div onClick={() => this.toggleSection('isSpicesOpen')} className="li-header">

                                            <span className='cat-list'>Spices</span>
                                            {isSpicesOpen ?
                                                <span className="cat-list-img">
                                                    <img src={ArwBottom} alt="Arwright" className='img-fluid rotate-arrow' />
                                                </span>
                                                :
                                                <span className="cat-list-img">
                                                    <img src={ArwBottom} alt="ArwBottom" className='img-fluid' />
                                                </span>}
                                        </div>
                                        {isSpicesOpen && (
                                            <ul className="subList">
                                                <li><input type="checkbox" />Spices</li>
                                                <li><input type="checkbox" /> Spices</li>
                                            </ul>
                                        )}
                                    </li>
                                    <li className="store-profile-li">
                                        <div onClick={() => this.toggleSection('isPackedFoodOpen')} className="li-header">

                                            <span className='cat-list'> Packed Food</span>
                                            {isPackedFoodOpen ?
                                                <span className="cat-list-img">
                                                    <img src={ArwBottom} alt="Arwright" className='img-fluid rotate-arrow' />
                                                </span>
                                                :
                                                <span className="cat-list-img">
                                                    <img src={ArwBottom} alt="ArwBottom" className='img-fluid' />
                                                </span>
                                            }
                                        </div>
                                        {isPackedFoodOpen && (
                                            <ul className="subList">
                                                <li><input type="checkbox" />  Packed Food</li>
                                                <li><input type="checkbox" />  Packed Food</li>
                                            </ul>
                                        )}
                                    </li>
                                    <li className="store-profile-li active">
                                        <div onClick={() => this.toggleSection('isHouseholdItemsOpen')} className="li-header">

                                            <span className='cat-list'>Household Items </span>
                                            {isHouseholdItemsOpen ?
                                                <span className="cat-list-img">
                                                    <img src={ArwBottom} alt="Arwright" className='img-fluid rotate-arrow' />
                                                </span>
                                                :
                                                <span className="cat-list-img">
                                                    <img src={ArwBottom} alt="ArwBottom" className='img-fluid' />
                                                </span>
                                            }
                                        </div>
                                        {isHouseholdItemsOpen && (
                                            <ul className="subList">
                                                <li><input type="checkbox" /> Toilet & Floor Cleaners</li>
                                                <li><input type="checkbox" /> Pooja Items</li>
                                                <li><input type="checkbox" /> Cleaning Accessories</li>
                                            </ul>
                                        )}
                                    </li>
                                </ul>
                            </div>
                            <div className="store-profile-right">
                                <div className="store-profile-head">Vegetables</div>
                                <div className="row m-0">

                                    <div className="col-12 col-lg-6">
                                        <div className="col-12 col-lg-12 col-md-12 mb-4 pl-0">
                                            <div className='store-profile-list'>
                                                <div className='store-profile-img'>
                                                    <img src={Store} alt="store" className='img-fluid'
                                                        width="80" height="80" />
                                                </div>
                                                <div className='store-profile-deatils'>
                                                    <div className='store-item'>
                                                        <div className='store-item-topbar'>
                                                            <div className='store-profile-name'>
                                                                Indian Tomato (Tamatar)
                                                            </div>
                                                            <div>
                                                                <select className='store-select-qnty'>
                                                                    <option value="option1">500 g</option>
                                                                    <option value="option2">1 kg</option>
                                                                    <option value="option3">750 g</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='store-add-btn' onClick={() => this.handleAddClick('Indian Tomato')}>
                                                            + Add
                                                        </div>
                                                    </div>
                                                    <div className='store-profile-price'>
                                                        ₹49
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-12 col-md-12 mb-4 pl-0">
                                            <div className='store-profile-list'>
                                                <div className='store-profile-img'>
                                                    <img src={Store} alt="store" className='img-fluid'
                                                        width="80" height="80" />
                                                </div>
                                                <div className='store-profile-deatils'>
                                                    <div className='store-item'>
                                                        <div className='store-item-topbar'>
                                                            <div className='store-profile-name'>
                                                                Indian Tomato (Tamatar)
                                                            </div>
                                                            <div>
                                                                <select className='store-select-qnty'>
                                                                    <option value="option1">500 g</option>
                                                                    <option value="option2">1 kg</option>
                                                                    <option value="option3">750 g</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='store-add-btn' onClick={() => this.handleAddClick('Indian Tomato')}>
                                                            + Add
                                                        </div>
                                                    </div>
                                                    <div className='store-profile-price'>
                                                        ₹49
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className='cart-head'>
                                            Your Cart <span className='cart-head-sub'>(3 item)</span>
                                        </div>
                                        <div className='cart-item'>
                                            <div>
                                                <img src={Dot} alt="Dot" width="10" height="10" />
                                            </div>
                                            <div className='cart-item-name'>Indian Tomato  (Tamatar)</div>
                                            <div className='cart-qnty-data'>500g </div>
                                            <div className="Quantity">
                                                <div className='quantity-red qnty-left'>
                                                    <img src={Minus} alt="Minus" width="20" height="20" />
                                                </div>
                                                <div className='text-center d-flex align-items-center quatity-no'>1</div>
                                                <div className='quantity-red qnty-right'>
                                                    <img src={Plus} alt="Plus" width="20" height="20" />
                                                </div>
                                            </div>
                                            <div className='cart-value'>₹ 49</div>
                                        </div>
                                        <div className='cart-item'>
                                            <div>
                                                <img src={Dot} alt="Dot" width="10" height="10" />
                                            </div>
                                            <div className='cart-item-name'>Indian Tomato  (Tamatar)</div>
                                            <div className='cart-qnty-data'>500g </div>
                                            <div className="Quantity">
                                                <div className='quantity-red qnty-left'>
                                                    <img src={Minus} alt="Minus" width="20" height="20" />
                                                </div>
                                                <div className='text-center d-flex align-items-center quatity-no'>1</div>
                                                <div className='quantity-red qnty-right'>
                                                    <img src={Plus} alt="Plus" width="20" height="20" />
                                                </div>
                                            </div>
                                            <div className='cart-value'>₹ 49</div>
                                        </div>
                                        <div className='cart-item'>
                                            <div>
                                                <img src={Dot} alt="Dot" width="10" height="10" />
                                            </div>
                                            <div className='cart-item-name'>Indian Tomato  (Tamatar)</div>
                                            <div className='cart-qnty-data'>500g </div>
                                            <div className="Quantity">
                                                <div className='quantity-red qnty-left'>
                                                    <img src={Minus} alt="Minus" width="20" height="20" />
                                                </div>
                                                <div className='text-center d-flex align-items-center quatity-no'>1</div>
                                                <div className='quantity-red qnty-right'>
                                                    <img src={Plus} alt="Plus" width="20" height="20" />
                                                </div>
                                            </div>
                                            <div className='cart-value'>₹ 49</div>
                                        </div>
                                        <div className="cart-footer">
                                            <div>To Pay</div>
                                            <div>₹640</div>
                                        </div>
                                    </div>

                                    
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {showPopup && (
                    <div className="popup">
                        <div className="popup-content">
                            <div className='close-menu' onClick={this.closePopup}>
                                <img src={Close} alt="Close" className='img-fluid' width="20" height="20" />
                            </div>
                            <div className='popup-body'>
                                <img src={Grocery} alt="Grocery" className='img-fluid' width="70" height="70" />
                                <div className='popup-suhead'>
                                    Available quantities
                                </div>
                                <ul className='col-12'>
                                    <li className='popup-list'>Indian Tomato (Tamatar)</li>
                                </ul>
                                <div>
                                    <div className='popup-suhead'>Choose Variant</div>
                                    <div className='mb-3'>
                                        <div className='mb-2 radio-outer'>
                                            <input type="radio" name="weight" id="" checked />
                                             <span className='variant-data'>1 kg
                                                <span className='popup-price'>₹ 120</span>
                                            </span>
                                        </div>
                                        <div className='mb-2 radio-outer'>
                                            <input type="radio" name="weight" id="" /> <span className='variant-data'>500 Gms
                                                <span className='popup-price'>₹ 70</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="popup-footer">
                                <div>
                                    <div className='popup-price-bottom'>1 kg</div>
                                    <div className="footer-price">MRP • <span className='popup-green-text'>₹120</span></div>
                                </div>
                                <div>
                                    <button className='add-to-cart'>Add to Cart</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

        )
    }
}