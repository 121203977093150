import React, { Component } from 'react'
import Slider from "react-slick";
import { getData } from '../services';
import $ from "jquery";
export default class Storebanner extends Component {
    constructor(props) {
        super(props)
        this.state = {
            storeData: []
        }
    }
    componentDidMount() {
        let storeId = window.sessionStorage.getItem('storeId')
        const postData = {
            "functionName": "lokal/storeDetails",
            "storeId": storeId
        }
        getData(postData).then((data) => {
            console.log("dataaa",data)
            if (data.data.success === "1") {
                this.setState({
                    storeData: data.data.result.storeData
                });
                window.sessionStorage.setItem('storeMobile', data.data.result.storeData[0].mobileNumber);
            }
        })
    }
    addDefaultSrc(image) {
        $("#storeImg").attr('src', image)
    }
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 200,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            arrow: false,
            pauseOnHover: false
        };
        let themeId = localStorage.getItem('themeId')
        return (
            this.state.storeData.map((storeObj, index) => (
                <div className="storeBanner">
                    <Slider {...settings}>
                        {storeObj.banner.map((bannerObj, index1) => (
                            <div>
                                <div className="storeItem">
                                    <div className="storeImage"><img src={bannerObj.image_path} alt="" /></div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                    {themeId !== '33' && 
                    <div className="storeContent">
                        <div className="container">
                            <div className="storeContentBottom">
                                <div className="storeBannerLogo">
                                    <div className="storeLogoImage">
                                        <img id="storeImg" alt="" onError={(e) => this.addDefaultSrc(storeObj.sellerLogoPathLarge)} src={storeObj.storeLogoPathLarge} />
                                    </div>
                                    <div className="storeLogoDetails">
                                        <h4>{storeObj.storeName}</h4>
                                        <p>{storeObj.locality}, {storeObj.cityName}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            ))
        )
    }
}