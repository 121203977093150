import React, { Component } from 'react'
import { getData, pageHitAPICall, } from '../services'
import $ from 'jquery'
import Payment from './payment'
import GoogleMap from './googleMap.js'
import Edit from '../images/icons/edit-icon.png'
import Delete from '../images/icons/delete-icon.png'
import Add from '../images/icons/add-icon.png'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
export default class Shipping extends Component {

    constructor(props) {
        super(props)
        this.state = {
            addressList: [],
            mobile: '',
            alternateno: '',
            storeContactMobile: '',
            storeContactEmail: '',
            latitude: '',
            longitude: '',
            googleAddressPin: '',
            city: '',
            locality: '',
            pinCode: '',
            pincodeData1: '',
            addressDetail: '',
            fullAddress: '',
            latLng: '',
            sameAddressSelected: null,
            selectedAddressId: ""

        }
        this.saveAddressDetails = this.saveAddressDetails.bind(this);
        this.getBuyerAddress = this.getBuyerAddress.bind(this);
        this.deleteAddress = this.deleteAddress.bind(this);
        this.handleMobileChange = this.handleMobileChange.bind(this);
        this.handleAltMobileChange = this.handleAltMobileChange.bind(this);
        this.handleAddressSelection = this.handleAddressSelection.bind(this)
    }
    deleteAddress(buyerDeliveryAddressId, i) {
        const confirmDelete = window.confirm("Are you sure you want to delete this address?");
        if (confirmDelete) {
            const postData = {
                functionName: "checkout/deleteDeliveryAddress",
                id: buyerDeliveryAddressId
            }
            getData(postData).then((data) => {
                this.getBuyerAddress();
            });
        } else {

        }
    }
    newAddress() {
        $("#shippingAddressListDiv").hide();
        $("#shippingAddressForm").show();
        $("#continueButton").hide();
        $("#txtTitle").val('')
        $("#txtName").val('')
        $("#txtAddress1").val('')
        $("#txtAlterNum").val('')
        $("#txtAddress2").val('')
        $("#txtMobile").val('')
        $("#hidAddressID").val('')
        $("#newAddress").css("display", "block");
        $("#txtName").focus();
        $("#chechk1").prop("checked", false);
    }
    // newBillingClick(){
    //     $("#shippingAddressForm").show();  
    // }
    backToPage() {
        $("#checkOutHeadingDiv").text("Checkout")
        $("#itemSummeryDiv").show();
        $("#shippingDiv").hide();
        $("#checkOutStep2").removeClass("orderIndicationActive")
        window.scrollTo(0, 0)
    }
    cancelAddress() {
        $("#shippingAddressListDiv").show();
        $("#continueButton").show();
        var sellerID = "";
        var moduleID = 6;
        var siteCategoryGroupId = "";
        if (window.sessionStorage.getItem('siteCategoryId') !== "0") {
            siteCategoryGroupId = window.sessionStorage.getItem('siteCategoryId');
        }
        else {
            sellerID = window.sessionStorage.getItem('sellerId')
        }
        pageHitAPICall(sellerID, moduleID, siteCategoryGroupId)
        $("#shippingAddressForm").hide();
    }
    saveAddress() {
        $("#shippingAddressForm").hide();
        $("#shippingAddressListDiv").show();
        $("#continueButton").show();
    }
    deliveryHere(buyerDeliveryAddressId) {
        var flag = "ture";
        var mainCategoryId = window.sessionStorage.getItem('mainCategoryId')
        var siteCategoryId = window.sessionStorage.getItem('siteCategoryId')
        if (mainCategoryId === "27" && siteCategoryId === "0") {
            if ((!$("input:radio[name='rdOrderOptions']").is(":checked")) || (!$("input:radio[name='rdReceiverOptions']").is(":checked"))) {
                alert("Please select order and receiver options")
                flag = "false";
            }
            else if ($("#txtAlcoholReceiverName").val() === "") {
                alert("Receiver name required")
                $("#txtAlcoholReceiverName").focus()
                flag = "false";
            }
        }
        if (flag === "ture") {
            var storeId = $("#hidStoreIdForCheckout").val()
            if (storeId !== '') {
                $("#txtTottal" + storeId).val()
                $("#hidStoreIdForCheckout").val()
                $("#txtTottalCon").val('')
                $("#hidStoreIdsInCart").val(storeId)
                this.refs.payment.getPaymentTypes(storeId);
            }
            else {
                var storeId = []
                var selectGroceryItemCart = JSON.parse(localStorage.getItem('selectedCartProduct')) || []
                for (var i = 0; i < selectGroceryItemCart.length; i++) {
                    storeId.push(selectGroceryItemCart[i].storeId)
                }
                var selectCommonItemCart = JSON.parse(localStorage.getItem('selectCommonItemCart')) || []
                for (var i = 0; i < selectCommonItemCart.length; i++) {
                    storeId.push(selectCommonItemCart[i].storeId)
                }
                var selectRestaurantCart = JSON.parse(localStorage.getItem('selectRestaurantCart')) || []
                for (var i = 0; i < selectRestaurantCart.length; i++) {
                    storeId.push(selectRestaurantCart[i].storeId)
                }
                var unique = storeId.filter(function (itm, i, storeId) {
                    return i == storeId.indexOf(itm);
                });
                var storeIdSplit = unique.toString().replace(/[\[\]']+/g, '')
                $("#hidStoreIdsInCart").val(storeIdSplit)
                this.refs.payment.getPaymentTypes(storeIdSplit);
            }
            $("#itemSummeryDiv").hide();
            $("#shippingDiv").hide();
            $("#paymentDiv").show();
            $("#checkOutHeadingDiv").text("Select a payment method")
            $("#txtTottal" + storeId).val()
            $("#txtTottalCon").val()
            $("#checkOutStep3").addClass("orderIndicationActive")
            window.scrollTo(0, 0)
            $("#deliveryAddressId").val(buyerDeliveryAddressId)
            $(".shippingAddressItem").removeClass("shippingAddressActive")
            $("#addressList" + buyerDeliveryAddressId).addClass("shippingAddressActive")
        }
    }

    componentDidMount() {
      
        try {

            window.scrollTo(0, 0)
            this.getBuyerAddress()
            this.setState({
                city: localStorage.getItem('cityText'),
                locality: localStorage.getItem('locationText').split('(')[0].split(')')[0],
                pincodeData1: localStorage.getItem('locationText').split('(')[1].split(')')[0],
            }, () => {
                setTimeout(() => {
                    const fullAddress = this.state.locality + ', ' + this.state.city + ', ' + this.state.pincodeData1;
                    geocodeByAddress(fullAddress)
                        .then((results) => getLatLng(results[0]))
                        .then((latLng) => {
                            this.setState({
                                latLng: latLng, // Set latLng in the state
                            });
                            console.log("latLng", latLng);
                        })
                }, 1000);

            });
        }
        catch (e) {
            console.error("Found error in ComponentDidMount", e)
        }
    }


    handleAddressChange = (e) => {
        const addresstext = e.target.value;
        const fullAddress = addresstext + ', ' + this.state.locality + ', ' + this.state.city + ', ' + this.state.pincodeData1;
        geocodeByAddress(fullAddress)
            .then((results) => getLatLng(results[0]))
            .then((latLng) => {
                this.setState({
                    latLng: latLng, // Set latLng in the state
                });
                console.log("latLng", latLng);
            })
    };
    //   {}
    getBuyerAddress() {
        const postData = {
            functionName: "checkout/getDeliveryAddressList",
            buyerId: localStorage.getItem('userId'),
            locationId: localStorage.getItem('locationId')
        }
        getData(postData).then((data) => {
            console.log("data map", data.data.result.deliveryAddressList.length);
            if (data.data.result.deliveryAddressList) {
                debugger
                if(data.data.result.deliveryAddressList.length > 0){
                $("#continueButton").show();
            }else{
                $("#continueButton").hide();
            }
                this.setState({
                    addressList: data.data.result.deliveryAddressList,
                });
            }
        });
        var sellerID = "";
        var moduleID = 6;
        var siteCategoryGroupId = "";
        if (window.sessionStorage.getItem('siteCategoryId') !== "0") {
            siteCategoryGroupId = window.sessionStorage.getItem('siteCategoryId');
        }
        else {
            sellerID = window.sessionStorage.getItem('sellerId')
        }
        pageHitAPICall(sellerID, moduleID, siteCategoryGroupId)
    }
    checkedMobile() {
        if ($("#chechk1").is(':checked') === true) {
            if (window.sessionStorage.getItem('mobileNumber') !== null && window.sessionStorage.getItem('mobileNumber') !== 'null' && window.sessionStorage.getItem('mobileNumber') !== '') {
                $("#txtMobile").val(window.sessionStorage.getItem('mobileNumber'))
                this.setState({
                    mobile: window.sessionStorage.getItem('mobileNumber')
                })
            }
            else {
                $("#txtMobile").val(window.sessionStorage.getItem('userMobileNumber'))
                this.setState({
                    mobile: window.sessionStorage.getItem('userMobileNumber')
                })
            }
        }
        else {
            this.setState({
                mobile: ''
            })
            $("#txtMobile").val('')
        }
    }
    checkedAddress() {
        if ($("#chechkAddress").is(':checked') === true) {
            this.setState({
                isDefaultAddress: "true"
            })
        }
        else {
            this.setState({
                isDefaultAddress: "false"
            })
        }
    }
    saveAddressDetails(fromButton) {
        debugger
        if ((fromButton === "continue" && this.state.selectedAddressId === "" && this.state.sameAddressSelected === null) || (fromButton === "continue" && this.state.selectedAddressId === "" && this.state.sameAddressSelected === true) || (fromButton === "continue" && this.state.selectedAddressId === "" && this.state.sameAddressSelected === false)) {
            alert("Please choose a shipping address")
            return false;
        }
        if ((fromButton === "continue" && this.state.selectedAddressId !== "" && this.state.sameAddressSelected === null)) {
            alert("Billing address required")
            return false;
        }
        if (this.state.sameAddressSelected === true && this.state.selectedAddressId !== "") {
            const filteredAddressList = this.state.addressList.filter(addressObj => {
                return addressObj.buyerDeliveryAddressId === this.state.selectedAddressId;
            });
            console.log("filteredAddressList", filteredAddressList)
            var addressTitle = filteredAddressList[0].title
            var name = filteredAddressList[0].name
            var address1 = filteredAddressList[0].address1
            var zip = filteredAddressList[0].zip
            var mobile = filteredAddressList[0].telephoneNumber
            var alternateno = filteredAddressList[0].altNum
            var address2 = filteredAddressList[0].address2
            var location = filteredAddressList[0].locationId
            // var hasLocation = filteredAddressList[0].locationId
            var city = filteredAddressList[0].cityId
            var lat = filteredAddressList[0].latitude
            var longi = filteredAddressList[0].longitude
        }
        else if (this.state.sameAddressSelected === false && this.state.selectedAddressId !== "") {
            const filteredAddressList = this.state.addressList.filter(addressObj => {
                return addressObj.buyerDeliveryAddressId === this.state.selectedAddressId;
            });
            console.log("filteredAddressList", filteredAddressList)
            var addressTitle = filteredAddressList[0].title
            var name = filteredAddressList[0].name
            var address1 = filteredAddressList[0].address1
            var zip = filteredAddressList[0].zip
            var mobile = filteredAddressList[0].telephoneNumber
            var alternateno = filteredAddressList[0].altNum
            var address2 = filteredAddressList[0].address2
            var location = filteredAddressList[0].locationId
            // var hasLocation = filteredAddressList[0].locationId
            var city = filteredAddressList[0].cityId
            var lat = filteredAddressList[0].latitude
            var longi = filteredAddressList[0].longitude

            var addressTitle1 = $("#txtTitle").val()
            var name1 = $("#txtName").val()
            var address11 = $("#txtAddress1").val()
            var zip1 = $("#txtPin").val()
            var mobile1 = $("#txtMobile").val()
            var alternateno1 = $("#txtAlterNum").val()
            var address21 = $("#txtAddress2").val()
            var location1 = localStorage.getItem('locationId')
            var hasLocation1 = localStorage.getItem('hasLocation')
            var city1 = localStorage.getItem('cityId');
            if (hasLocation1 === '0') {
                city = localStorage.getItem('cityIdGlobal');
            }
            if (name1 === '') {
                alert("Name required");
                $("#txtName").focus();
                return false;
            }
            if (name1 === '') {
                alert("Name required");
                $("#txtName").focus();
                return false;
            }
            else if (zip1 === '') {
                alert("Pin required");
                $("#txtPin").focus();
                return false;
            }
            else if (mobile1 === '') {
                alert("Mobile Number required");
                $("#txtMobile").focus();
                return false;
            }
            else if (address11 === '') {
                alert("Address required");
                $("#txtAddress1").focus();
                return false;
            }
            else if (city1 === '0') {
                alert("City required");
                return false;
            }
            else if (location1 === '0') {
                alert("Location required");
                $("#txtLocality").focus();
                return false;
            }
        }

        else {
            var addressTitle = $("#txtTitle").val()
            var name = $("#txtName").val()
            var address1 = $("#txtAddress1").val()
            var zip = $("#txtPin").val()
            var mobile = $("#txtMobile").val()
            var alternateno = $("#txtAlterNum").val()
            var address2 = $("#txtAddress2").val()
            var location = localStorage.getItem('locationId')
            var hasLocation = localStorage.getItem('hasLocation')
            var city = localStorage.getItem('cityId');
            if (hasLocation === '0') {
                city = localStorage.getItem('cityIdGlobal');
            }
            if (name === '') {
                alert("Name required");
                $("#txtName").focus();
                return false;
            }
            if (name === '') {
                alert("Name required");
                $("#txtName").focus();
                return false;
            }
            else if (zip === '') {
                alert("Pin required");
                $("#txtPin").focus();
                return false;
            }
            else if (mobile === '') {
                alert("Mobile Number required");
                $("#txtMobile").focus();
                return false;
            }
            else if (address1 === '') {
                alert("Address required");
                $("#txtAddress1").focus();
                return false;
            }
            else if (city === '0') {
                alert("City required");
                return false;
            }
            else if (location === '0') {
                alert("Location required");
                $("#txtLocality").focus();
                return false;
            }
        }
        const postData = {
            functionName: 'savedeliveryaddress',
            addressTitle: addressTitle,
            name: name,
            address1: address1,
            zip: zip,
            mobile: mobile,
            buyerId: localStorage.getItem('userId'),
            alternateno: alternateno,
            address2: address2,
            locationId: location,
            buyerDeliveryAddressId: $("#hidAddressID").val() ? $("#hidAddressID").val() : this.state.selectedAddressId,
            isDefaultAddress: this.state.isDefaultAddress ? this.state.isDefaultAddress : "false",
            latitude: this.state.latLng.lat ? this.state.latLng.lat : lat,
            longitude: this.state.latLng.lng ? this.state.latLng.lng : longi,
            billingSameAsShipping: this.state.sameAddressSelected,
            billingName: name1 ? name1 : '',
            billingAddress1: address11 ? address11 : "",
            billingZip: zip1 ? zip1 : "",
            billingMobile: mobile1 ? mobile1 : "",
            billingAlternateno: alternateno1 ? alternateno1 : "",
            billingAddress2: address21 ? address21 : "",
            billinglocationId: location1 ? location1 : ""
        }
        console.log("#############################", postData)
        getData(postData).then((data) => {
            if (data.data.success === '1') {
                if (this.state.sameAddressSelected !== null && this.state.selectedAddressId !== "") {
                    this.deliveryHere(this.state.selectedAddressId)
                    console.log("14444444444444444444444444444", data.data.result)
                    window.sessionStorage.setItem('buyerDeliveryAddressId', data.data.result.buyerDeliveryAddressId)
                    window.sessionStorage.setItem('buyerBillingAddressId', data.data.result.buyerBillingAddressId)
                }
                else {
                    window.sessionStorage.setItem('addressId', data.data.result)
                    $("#newAddress").css("display", "none");
                    $("#txtTitle").val('')
                    $("#txtName").val('')
                    $("#txtAddress1").val('')
                    $("#txtPin").val('')
                    $("#txtAlterNum").val('')
                    $("#txtAddress2").val('')
                    $("#txtMobile").val('')
                    $("#hidAddressID").val('')
                    alert("Address Saved Successfully");
                    this.getBuyerAddress();
                    $("#shippingAddressForm").hide();
                    $("#shippingAddressListDiv").show();
                }
            }
            else {
                alert(data.data.errorMessage)
            }
        });
    }
    editAddres = (addressObj) => {
        console.log("pppppppppppppppppppp", addressObj)
        $("#shippingAddressListDiv").hide();
        $("#continueButton").hide();
        $("#shippingAddressForm").show();
        $("#hidAddressID").val(addressObj.buyerDeliveryAddressId)
        $("#txtName").focus();
        $("#txtTitle").val(addressObj.title)
        $("#txtName").val(addressObj.name)
        $("#txtAddress1").val(addressObj.address1)
        $("#txtPin").val(addressObj.zip)
        $("#txtMobile").val(addressObj.telephoneNumber)
        $("#txtAlterNum").val(addressObj.altNum)
        $("#txtAddress2").val(addressObj.address2)
        var hasLocation = localStorage.getItem('hasLocation')
        var cityId = localStorage.getItem('cityId');
        let pincodeBased = localStorage.getItem('isPincodeBased')
        if (hasLocation === '0' && pincodeBased === "false") {
            cityId = localStorage.getItem('cityIdGlobal');
        }
        $("#txtCity").val(cityId)
        $("#chechkAddress").val(addressObj.isDefaultAddress)
        $("#txtName").focus();
        $("#newAddress").css("display", "block");
        this.setState({
            latLng: {
                lat: addressObj.latitude,
                lng: addressObj.longitude
            }
        })
    }
    handleMobileChange = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ mobile: e.target.value })
            $("#txtMobile").val(e.target.value)
        }
    }
    handleAltMobileChange = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ alternateno: e.target.value })
            $("#txtAlterNum").val(e.target.value)
        }
    }
    markerDragFunction = (lat, lng) => {
        this.setState({
            latLng: {
                lat: lat,
                lng: lng
            }
        })
    }
    handleSameAddressChange = () => {
        this.setState({ sameAddressSelected: true });
        $("#shippingAddressForm").hide();
    };

    // Function to handle radio button change for "Add New Billing Address"
    handleNewBillingAddressChange = () => {
        this.setState({ sameAddressSelected: false });
        $("#shippingAddressForm").show();
        $("#continueButton").show();
    };
    handleAddressSelection = (addressId) => {
        debugger
        this.setState({ selectedAddressId: addressId });
    };
    render() {
        const { sameAddressSelected } = this.state;
        // const {selectedAddressId} = this.state
        let hasLocation = localStorage.getItem('hasLocation')
        let pincodeBased = localStorage.getItem('isPincodeBased')
        let cityId = localStorage.getItem('cityId');
        let cityText = localStorage.getItem('cityText');
        let pinCode = ''
        let locationText = localStorage.getItem('locationText')
        if (hasLocation === '0' && pincodeBased === "false") {
            cityId = localStorage.getItem('cityIdGlobal');
            cityText = window.sessionStorage.getItem('cityTextGlobal');
            pinCode = window.sessionStorage.getItem('pinCode');

        } let pincodeData = locationText.match(/\((\d{6})\)/);

        if (pincodeData && pincodeData.length > 1) {
            pincodeData = pincodeData[1];
        } else {
            pincodeData = "";
        }

        console.log(pincodeData);
        return (
            <div>
                <input type="hidden" id="hidStoreIdsInCart" />
                <div className="coverWraper" id="shippingDiv" style={{ display: 'none' }}>
                    <div className="checkoutSection">
                        <input type="hidden" id="deliveryAddressId" />
                        <div className="shippingAddressSec">
                            <div id="shippingAddressListDiv" className="shippingAddressListDiv">
                                <div className="shippingButtonAdd">
                                    <span className="commonButton checkBorderBtn" onClick={this.backToPage}>Back to Checkout</span>
                                    {/* <span className="commonButton checkBorderBtn" onClick={this.newAddress}>Add new address</span> */}
                                </div>
                                <div className="shippingAddressList shippingAddressList-outer-w shipDataWrP">
                                    <h3 className="head-shippingaddress">Shipping Address</h3>
                                    {this.state.addressList.slice(0, 5).map((addressObj, i) => (
                                        <div className="checkoutItemDetailList justify-spacebtwn" id={"addressList" + addressObj.buyerDeliveryAddressId}>
                                            <div class="paymentMethodList shippingadressList-radio">
                                                <span class="radioSection">
                                                    <input type="radio" id={'radiopaymentShipping' + addressObj.buyerDeliveryAddressId} name="radiopaymentShipping" checked={this.state.selectedAddressId === addressObj.buyerDeliveryAddressId}
                                                        onChange={() => this.handleAddressSelection(addressObj.buyerDeliveryAddressId)} />
                                                    <label for={'radiopaymentShipping' + addressObj.buyerDeliveryAddressId}></label>
                                                </span>
                                            </div>
                                            <div className="shipAddressName mb-0 px-2">{addressObj.name}</div>
                                            <div className="shippingAddressContent shippingAddressContent-width">

                                                {/* <p>{addressObj.address1}</p> */}
                                                <p className='mb-0'>{addressObj.address1}, {addressObj.locationName}, {addressObj.cityName}, Pin Code: {addressObj.zip}</p>
                                                <p className='mb-0'>Phone: {addressObj.telephoneNumber}</p>
                                                {/* <p>{addressObj.zip}</p> */}
                                                {/* <span className="commonButton" onClick={(e) => this.deliveryHere(addressObj.buyerDeliveryAddressId)}>Deliver Here</span> */}
                                            </div>
                                            <div className="shipAddressAction shipAddressActionposition no-wrap">
                                                <span className="shipAddressEdit" onClick={(e) => { this.editAddres(addressObj) }}><img src={Edit} className='img-fluid' width={18} height={18} title='Edit' /></span>
                                                <span className="shipAddressRemove" onClick={(e) => { this.deleteAddress(addressObj.buyerDeliveryAddressId, i) }}><img src={Delete} className='img-fluid' width={20} height={20} title='Remove' /></span>
                                            </div>
                                        </div>
                                    ))}

                                    <div className="add-address-box" onClick={this.newAddress}>
                                        <img src={Add} className='img-fluid mr-2' width={22} height={22} />Add new address
                                    </div>
                                    {this.state.addressList.length > 0 && 
                                    <div className="shppngList-bottm-wraper">
                                        <div className="shppngList-bottm-sec">
                                            <div class="paymentMethodList shippingadressList-radio">
                                                <span class="radioSection">
                                                    <input type="radio" id='radiopaymentShipping1' name="radiopaymentShipping1" checked={this.state.sameAddressSelected === true}
                                                        onChange={this.handleSameAddressChange} />
                                                    <label for="radiopaymentShipping1"></label>
                                                </span>
                                            </div>
                                            <div className="text-shippingadd-new">My billing and shipping address are the same</div>
                                        </div>
                                        <div className="shppngList-bottm-sec">
                                            <div class="paymentMethodList shippingadressList-radio" onChange={this.newBillingClick}>
                                                <span class="radioSection">
                                                    <input type="radio" id='radiopaymentShipping2' name="radiopaymentShipping2" checked={this.state.sameAddressSelected === false}
                                                        onChange={this.handleNewBillingAddressChange} />
                                                    <label for="radiopaymentShipping2"></label>
                                                </span>
                                            </div>
                                            <div className="text-shippingadd-new">Add New Billing Address</div>
                                        </div>
                                    </div>}
                                </div>
                            </div>

                            <div id="shippingAddressForm" style={{ display: (this.state.addressList.length === 0 ? 'none' : 'none') }}>
                                <div className="shippingAddressForm" >
                                    <input type="hidden" className="formInputField" id="hidAddressID" />
                                    <div className="shippingAddresItem">
                                        <input type="text" className="checkInputField" autoComplete="off" placeholder="Office/Home/Clinic etc..." id="txtTitle" />
                                    </div>
                                    <div className="shippingAddresItem">
                                        <input type="text" className="checkInputField" autoComplete="off" placeholder="Name*" id="txtName" />
                                    </div>
                                    <div className="shippingAddresItem">
                                        <select className="checkInputField" id="txtCity" disabled>
                                            <option value={cityId}>{cityText}</option>
                                        </select>
                                    </div>
                                    <div className="shippingAddresItem">
                                        <div className="shippingAddreshalf">
                                            <select className="checkInputField" id="txtLocality" disabled>
                                                <option value={localStorage.getItem('locationText')}>{localStorage.getItem('locationText')}</option>
                                            </select>
                                        </div>
                                        {console.log("pincode", pincodeData)}
                                        <div className="shippingAddreshalf">
                                            {pinCode !== "" && pinCode !== null && pinCode !== 'null' ?
                                                <input type="text" className="checkInputField" placeholder="Pin*" readOnly id="txtPin" value={pinCode} />
                                                :
                                                <input type="text" className="checkInputField" placeholder="Pin*" autoComplete="off" id="txtPin" defaultValue={pincodeData} />
                                            }
                                        </div>
                                    </div>
                                    <div className="shippingAddresItem">
                                        <span class="shippingNumberField">
                                            {window.sessionStorage.getItem('countryCode') === null ? "+91" : window.sessionStorage.getItem('countryCode')}<input type="text" className="checkInputField" autoComplete="off" placeholder="Mobile Number*" id="txtMobile" value={this.state.mobile} onChange={this.handleMobileChange} />
                                        </span>
                                        <span class="shippingNumberDefult">
                                            <span class="checkBoxSquare">
                                                <input type="checkbox" id="chechk1" name="check" value="None" onClick={(e) => this.checkedMobile()} />
                                                <label for="chechk1"></label>
                                            </span>Same as registered mobile
                                        </span>
                                    </div>
                                    <div className="shippingAddresItem">
                                        <input type="text" className="checkInputField" autoComplete="off" placeholder="Alternate Number" id="txtAlterNum" value={this.state.alternateno} onChange={this.handleAltMobileChange} />
                                    </div>
                                    <div className="shippingAddresItem">
                                        <input type="text" className="checkInputField" autoComplete="off" id="txtAddress1" placeholder="House/Street/Area/Locality*" onChange={this.handleAddressChange} />
                                    </div>
                                    <div className="shippingAddresItem">
                                        <input type="text" className="checkInputField" autoComplete="off" placeholder="Landmark" id="txtAddress2" />
                                    </div>
                                    {this.state.sameAddressSelected === false ? "" :
                                        <>
                                            <div className="shippingAddresItem">
                                                <span className="numberDefult">
                                                    <span className="checkBoxSquare">
                                                        <input type="checkbox" tabindex='12' id="chechkAddress" name="chechkAddress" value="None" defaultChecked={this.state.isDefaultAddress} onClick={(e) => this.checkedAddress()} />
                                                        <label for="chechkAddress"></label>
                                                    </span>
                                                    Make this my default address
                                                </span>
                                            </div>
                                            <div className='gmap-divouter'>
                                                <GoogleMap key={this.state.latLng} latLng={this.state.latLng}
                                                    onMarkerDragEnd={this.markerDragFunction} width="500" />
                                            </div>
                                            {console.log("latitude", this.state.latLng)}

                                            <div className="shippingButton">
                                                <span className="commonButton checkBorderBtn" onClick={(e) => this.cancelAddress()}>Cancel</span>
                                                <span className="commonButton" id="saveAddressDetail" onClick={(e) => this.saveAddressDetails()}>Save </span>
                                            </div>
                                        </>}
                                </div>
                            </div>
                            <div  className="shippingAddressListDiv continueBtn">
                            <div className="text-right"><span className="commonButton " id="continueButton" onClick={(e) => this.saveAddressDetails("continue")} >Continue</span></div>
                            </div>
                         </div>
                    </div>
                </div>
                <Payment ref="payment" />
            </div>
        )
    }
}