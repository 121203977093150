 import React, { Component ,Suspense} from "react";
// import MraHeader from "./header";
// import MraFooter from "./footer";
import bannerImage from "./assets/images/bannerImage.jpg"
import BorderImg from "./assets/images/separator.svg";
import pattern from "./assets/images/pattern-9.svg" 
let MraHeader = React.lazy(() => import("./header"));
let MraFooter = React.lazy(() => import("./footer"));
export default class Csr extends Component {
    render() {
        return (
            <>
                <div className="mraInnerSec">
                <Suspense fallback={<div></div>}>
                    <div className="coverWraper">
                        <MraHeader />
                        <div className="contentWrap">
                            <div className="bannerImginner">
                                <img src={bannerImage} alt="" />
                                <div className="mraTitle">
                                    {/* <span>OUR STORY</span>
                                    <img src={BorderImg} alt="" /> */}
                                    <div className="contentSectn">
                                        <h1>CSR</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="mraBorders">
                                <div className="borderLeftImg">
                                    <img src={pattern} alt="" />
                                </div>
                                <div className="borderRightImg">
                                    <img src={pattern} alt="" />
                                </div>
                            </div>
                            <div className="storeCoverWrap mraAbt">

                                <div className="container">
                                    <div className="mraTitlesInner mraCsr">
                                        <div className="mraTitle">
                                        <span>OUR RECENT</span> 
                                            <img src={BorderImg} alt="" />
                                            <div className="contentSectn" style={{ margin: "0px" }}>
                                                <h1 >CSR</h1>
                                            </div>
                                        </div>
                                        <h1>We care - MRA CSR during Kerala Flood 2018</h1>
                                        <h4>MARCH 19, 2019</h4>
 <p>With more than half of the state of Kerala reeling under severe floods, one of the worst floods to hit the state since 1924, several people have been displaced and left homeless. Roads, bridges, buildings, and infrastructure have also been severely impacted, bringing the state to a standstill. Amidst this, thousands of people across India and around the world began to mobilize.</p>
 <p>It is with the joined effort of each human who cared for the people of Kerala, the phase survived. We were struggling with what the needs were. Each place had different needs, depending on the local situation. We, MRA Group is proud to say that we also were a part of the relief activity by sending essentials to the relief camps all over Kerala. We are aware of our responsibility back to our society who supported us grow to what we are now.

</p>
                                    </div>
                                </div> 
                            </div>    
                        </div>
                        <MraFooter />
                    </div>
                    </Suspense>
                </div>
            </>
        )
    }
}