import React, { Component } from 'react'
export default class WorldLine extends Component {
    componentDidMount(){
     
    }  
    render() {     
        return (
            <div id="worldLine" >
                <input type="hidden" value="" id="mid" name="mid" />
                <input type="hidden" value="" id="encKey" name="encKey" />
                <input type="hidden" value="" id="hdnOrderID" name="hdnOrderID" />
                <input type="hidden" value="" id="trnAmt" name="trnAmt" />
                <input type="hidden" value="INR" id="currency" name="currency" />
                <input type="hidden" value="S" id="meTransReqType" name="meTransReqType" />
                <input type="hidden" value="" id="trnRemarks" name="trnRemarks" />
                <input type="hidden" value="" id="recPeriod" name="recPeriod" />
                <input type="hidden" value="" id="recDay" name="recDay" />
                <input type="hidden" value="" id="noOfRec" name="noOfRec" />
                <input type="hidden" value="" id="resUrl" name="resUrl" />
                <input type="hidden" value="" id="addField1" name="addField1" />
                <input type="hidden" value="" id="addField2" name="addField2" />
                <input type="hidden" value="" id="addField3" name="addField3" />
                <input type="hidden" value="" id="addField4" name="addField4" />
                <input type="hidden" value="" id="addField5" name="addField5" />
                <input type="hidden" value="" id="addField6" name="addField6" />
                <input type="hidden" value="" id="addField7" name="addField7" />
                <input type="hidden" value="" id="addField8" name="addField8" />          
                <input type="hidden" value="" id="addField9" name="addField9" />   
                <input type="hidden" value="" id="addField10" name="addField10" />  
                <div id="worldLineSubmit" >
                </div>       
            </div>
        )
    }
}