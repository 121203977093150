import React, { Component ,Suspense} from "react";
// import MraHeader from "./header";
// import MraFooter from "./footer";
import bannerImage from "./assets/images/bannerImage.jpg"
import BorderImg from "./assets/images/separator.svg";
import blog4 from "./assets/images/blogcake.jpg"
import blog1Sub from "./assets/images/blogcake_image1.jpg"
let MraHeader = React.lazy(() => import("./header"));
let MraFooter = React.lazy(() => import("./footer"));
 
export default class BlogsDetails extends Component {
    render() {
        return (
            <>
                <div className="mraInnerSec">
                <Suspense fallback={<div></div>}>
                    <div className="coverWraper">
                        <MraHeader />
                        <div className="contentWrap">
                            <div className="bannerImginner">
                                <img src={bannerImage} alt="" />
                                <div className="mraTitle">
                                    <div className="contentSectn">
                                        <h1>Blog</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="mraBorders">
                               
                            </div>
                            <div className="storeCoverWrap mraAbt">

                                <div className="container">
                                    <div className="mraTitlesInner mraBlogs">
                                        <div className="mraTitle">
                                            <span>OUR LATEST</span>
                                            <img src={BorderImg} alt="" />
                                            <div className="contentSectn" style={{ margin: "0px" }}>
                                                <h1 >Blogs</h1>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4  ">
                                                <div className="mraBlogItem">
                                                    <img src={blog4} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="mraBlogTxt">
                                                    <h1>Importance of cakes to make any celebrations more special</h1>
                                                    <h4>MARCH 19, 2022</h4>
                                                    <p>A special occasion or celebration cannot imagine without a beautiful cake. A small cake makes any normal party more interesting and spreads happiness everywhere. Everyone spends a lot of time just to find the right or beautiful cake for dear one's special day and also for their special day. It is one of the keys to happiness which is a great way to express our emotions to our dear ones through a loving or special message on top of it. There are different yummy flavors of cake in the market such as butterscotch, fruit cake, chocolate, vanilla, pineapple, strawberry, and mangos. Cakes are also available in different shape, design, and size. Everyone is for the special day or moments when they blow out the candles and cut the wonderful cake.</p>
                                                    <div className="col-md-4  ">
                                                        <div className="row">
                                                            <div className="mraBlogItem">
                                                                <img src={blog1Sub} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p>Nowadays everyone prefers or want the beautiful wedding cake to celebrate their new beginning of life with a new relation. Because the tradition of cutting the cake as a couple in a wedding is more popular. The most wonderful designs and yummy taste of the wedding cake add to the relationship. At the time of cutting a wedding cake, the groom will place his hand over the bride to help her in slicing the first piece of cake that symbolizes the continuity of their relationship with his support over his bride.</p>
                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <MraFooter />
                    </div>
                    </Suspense>
                </div>
            </>
        )
    }
}