import React, { Component, Suspense } from 'react';
import SubItems from "./subItems"
import $ from "jquery";
import { getData, pageHitAPICall } from '../services';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Loader from "../headerComponents/loader"
import { elasticLink, elasticHostURL } from "../config";
import ProductDefault from "../images/product-default.png"
import { setData } from '../setDefaultData'
let MainHeader = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/header"));
let MainFooter = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/footer"));
const AWS = require('aws-sdk');
const elasticsearch = require("elasticsearch");
AWS.config.region = 'us-west-1';
export default class profile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      itemDetails: [],
      breadCrumbDetails: [],
      storeResults: [],
      isAdded: false,
      store_item_stock_ids: '',
      item_ids: '',
      unit: '',
      stock_quantities: '',
      item_names: '',
      logoPath: '',
      pack_ids: '',
      price: '',
      pack: '',
      isLoading: '0',
      category: []
    };
    this.addToCart = this.addToCart.bind(this);
    this.isNumber = this.isNumber.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    window.location.reload();
  }
  async componentDidMount() {
    debugger
    var slug = this.props.match.params.slug
    var cartItemFlag = this.props.cartItemFlag;
    if (cartItemFlag == 'true') {
      $('#addToCart' + this.props.index).addClass("cart_clk");
      $('#txtQtyProfile' + this.props.index).val(this.props.qty);
      $('#addToCart' + this.props.index).text("Added")
    }
    let storeItemStockId = slug.split('-')[0];
    let storeId = window.sessionStorage.getItem('storeId');
    if (storeId === null || storeId === "null" || storeId === "") {
      await setData.call()
      const postData = {
        functionName: "common/getStoreData",
        storeItemStockId: storeItemStockId,
      }
      getData(postData).then((data) => {
        if (data.data.success === "1") {
          localStorage.setItem('locationId', data.data.data[0].locationId);
          localStorage.setItem('locationText', data.data.data[0].locationName)
          window.sessionStorage.setItem('storeId', data.data.data[0].storeId)
          window.sessionStorage.setItem('storeName', data.data.data[0].storeName)
          window.sessionStorage.setItem('mainGroupCategory', data.data.data[0].main_group_category_id)
          window.sessionStorage.setItem('mainCategoryId', data.data.data[0].main_category_id);
          window.sessionStorage.setItem('mainCategoryIdLokal', data.data.data[0].main_category_id)
          localStorage.setItem('cityId', data.data.data[0].cityId)
          localStorage.setItem('cityText', data.data.data[0].cityName)
          window.location.reload()
        }
      })
    }
    else {
      this.loadData(storeItemStockId)
      this.getCategory()
      var sellerID = "";
      var moduleID = 3;
      var siteCategoryGroupId = "";
      if (window.sessionStorage.getItem('siteCategoryId')) {
        siteCategoryGroupId = window.sessionStorage.getItem('siteCategoryId');
      }
      else {
        sellerID = window.sessionStorage.getItem('sellerId')
      }
      pageHitAPICall(sellerID, moduleID, siteCategoryGroupId)
    }
  }
  async getCategory() {
    let storeId = window.sessionStorage.getItem('storeId');
    const client = new elasticsearch.Client({
      host: elasticHostURL(),
      amazonES: {
        region: 'us-west-1',
        credentials: new AWS.Credentials(this.state.s3AccessKey, this.state.s3SecretKey)
      }
    });
    const body = await client.search({
      index: elasticLink() + '-autocomplete-loose',
      from: 0, size: 0,
      body: {
        query:
        {
          bool: {

            filter:
            {
              bool:
              {
                must: [{
                  terms: {
                    store_id: [storeId]
                  }
                }
                ]
              }
            }
          }
        },
        sort: [
          {
            category_level1_id: "asc"
          },
          {
            "category_level2_name.keyword": "asc"
          },
          {
            "category_level3_name.keyword": "asc"
          }
        ],
        "aggs": {
          "category_level1": {
            terms: {
              field: "category_level1_id",
              order: {
                "_term": "asc"
              }
            },
            "aggs": {
              "category_level1_name": {
                terms: {
                  field: "category_level1_name.keyword"
                },
                "aggs": {
                  "category_level1_image": {
                    terms: {
                      field: "category_level1_img.keyword"
                    },
                    "aggs": {
                      "category_level2": {
                        terms: {
                          field: "category_level2_id"
                        },
                        "aggs": {
                          "category_level2_name": {
                            terms: {
                              field: "category_level2_name.keyword"
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });
    var categoryLevel1 = []
    for (const category_level1Id of body.aggregations.category_level1.buckets) {
      var category1 = {
        name: category_level1Id.category_level1_name.buckets[0].key,
        category_level1_id: category_level1Id.key
      }
      categoryLevel1.push(category1)
    }
    this.setState({
      category: categoryLevel1
    });
  }
  loadData(storeItemStockId) {
    const postData = {
      functionName: "getitemdetailsbyid/getItemDetailsById",
      storeItemStockId: storeItemStockId,
    }
    getData(postData).then((data) => {
      if (data.data.success === "1") {
        this.setState({
          itemDetails: data.data.result.itemDetail,
          breadCrumbDetails: data.data.result.getBreadCrumbData,
          storeResults: data.data.result.storeResults,
          isLoading: '1'
        });
      }
    })
  }
  addToCart(index, type, isLoose, packIndex) {
    debugger
    var storeItemStockId = $("#sisIdProfile" + index).val();
    var itemName = $("#nameProfile" + index).val();
    var itemId = $("#itemIdProfile" + index).val();
    var stockQuantity = $("#itemStockQuantityProfile" + index).val();
    var netAmount = $("#priceProfile" + index).val();
    var logoPath = $("#imageProfile" + index).val();
    var packId = $("#packIdProfile" + index).val();
    if (type === "addToCart") {
      $('#addToCart' + storeItemStockId).addClass("cart_clk");
      $('#addToCart' + storeItemStockId).text("Added")
      if ($('#txtQtyProfile' + storeItemStockId).val() == 0) {
        $('#txtQtyProfile' + storeItemStockId).val(1);
      }
      $("#radio" + packIndex).attr('checked', true);
      if (isLoose === '0') {
        $("#profileCartBtn" + packIndex).addClass("addcartActive");
      }
    }
    else if (type === "inc") {
      var prnum = $('#txtQtyProfile' + storeItemStockId).val();
      if (parseInt(prnum) >= parseInt(stockQuantity)) {
        prnum = stockQuantity;
        $("#qtyErrorProfile" + index).show();
        $("#qtyErrorProfile" + index).text("Only " + stockQuantity + " qty available")
      }
      else {
        $("#" + this.props.qtyError + storeItemStockId).hide();
        $("#" + this.props.qtyError + storeItemStockId).text("")
        if (prnum > 0) {
          prnum++;
          $('#txtQtyProfile' + storeItemStockId).val(prnum);
        }
        else if (prnum == 0) {
          $('#txtQtyProfile' + storeItemStockId).val(1);
        }
      }
    }
    else if (type === "dec") {
      $("#qtyErrorProfile" + index).hide();
      $("#qtyErrorProfile" + index).text("")
      var prnum = $('#txtQtyProfile' + storeItemStockId).val();
      prnum--;
      if (prnum == 0) {
        $('#addToCart' + storeItemStockId).removeClass("cart_clk");
        $('#addToCart' + storeItemStockId).text("ADD TO CART")
        if (isLoose === '0') {
          $("#profileCartBtn" + packIndex).removeClass("addcartActive");
        }
      }
      $('#txtQtyProfile' + storeItemStockId).val(prnum);
    }
    else if (type === "decZero") {
      $("#qtyErrorProfile" + index).hide();
      $("#qtyErrorProfile" + index).text("")
      var prnum = $('#txtQtyProfile' + storeItemStockId).val();
      if (prnum == 0) {
        $('#addToCart' + storeItemStockId).removeClass("cart_clk");
        if (isLoose === '0') {
          $("#profileCartBtn" + packIndex).removeClass("addcartActive");
        }
      }
      $('#txtQtyProfile' + storeItemStockId).val(prnum);
    }
    if ($('#txtQtyProfile' + storeItemStockId).val() == 0) {
      $("#" + this.props.itemCount + storeItemStockId).text("");
      $("#" + this.props.itemAddedColour + storeItemStockId).hide()
      this.setState({
        isAdded: false
      });
    }
    else {
      $("#" + this.props.itemCount + storeItemStockId).text($('#txtQtyProfile' + storeItemStockId).val())
      $("#" + this.props.itemAddedColour + storeItemStockId).show()
      this.setState({
        isAdded: true
      });
    }
    // alert(netAmount)
    this.updateCart(itemName, itemId, $('#txtQtyProfile' + storeItemStockId).val(), netAmount, storeItemStockId, packId, false, logoPath, stockQuantity)
  }
  updateCart(item_names, item_ids, quantity, item_prices, store_item_stock_ids, pack_ids, isDelete, logoPath, stock_quantity) {
    let storeId = window.sessionStorage.getItem('storeId');
    var selectedCartProduct = JSON.parse(localStorage.getItem('selectedCartProduct')) || []
    if (localStorage.getItem('selectedCartProduct') === null || localStorage.getItem('selectedCartProduct') === '' || selectedCartProduct === '') {
      if (isDelete === false || quantity > 0) {
        var selectedProduct = {
          storeId: storeId,
          item_names: item_names,
          item_ids: item_ids,
          quantity: quantity,
          stock_quantity: stock_quantity,
          item_prices: item_prices,
          store_item_stock_ids: store_item_stock_ids,
          pack_ids: pack_ids,
          item_image: logoPath,
          itemNo: item_ids
        }
        selectedCartProduct.push(selectedProduct)
        localStorage.setItem('selectedCartProduct', JSON.stringify(selectedCartProduct))
      }
    }
    else {
      var selectedCartProduct = JSON.parse(localStorage.getItem('selectedCartProduct')) || []
      var cookieNewValue = [];
      var cookieArray = selectedCartProduct;
      var cookieUpdated = false
      var cookieNewValueCount = 0;
      for (var i = 0; i < cookieArray.length; i++) {
        if (cookieArray[i] !== null && cookieArray[i] !== 'null' && cookieArray[i] !== "") {
          var cookieStoreId = cookieArray[i].storeId
          var cookieItemId = cookieArray[i].item_ids
          if (item_ids === cookieItemId && storeId === cookieStoreId) {
            if (isDelete === false && quantity > 0) {
              cookieNewValue[cookieNewValueCount] =
              {
                storeId: storeId,
                item_names: item_names,
                item_ids: item_ids,
                quantity: quantity,
                stock_quantity: stock_quantity,
                item_prices: item_prices,
                store_item_stock_ids: store_item_stock_ids,
                pack_ids: pack_ids,
                item_image: logoPath,
                itemNo: item_ids
              }
            }
            cookieUpdated = true
          }
          else {
            cookieNewValue[cookieNewValueCount] = cookieArray[i];
          }
          cookieNewValueCount++;
        }
      }
      if (cookieUpdated === false) {
        if (quantity > 0) {
          cookieNewValue[cookieNewValueCount] = {
            storeId: storeId,
            item_names: item_names,
            item_ids: item_ids,
            quantity: quantity,
            stock_quantity: stock_quantity,
            item_prices: item_prices,
            store_item_stock_ids: store_item_stock_ids,
            pack_ids: pack_ids,
            item_image: logoPath,
            itemNo: item_ids
          }
          cookieUpdated = true
        }
      }
      localStorage.setItem('selectedCartProduct', JSON.stringify(cookieNewValue))
    }
    $("#cartCount").text((JSON.parse(localStorage.getItem('selectedCartProduct')).length))
  }
  isNumber(index, mode, isLoose, packIndex) {
    var storeItemStockId = $("#sisIdProfile" + index).val();
    var itemName = $("#nameProfile" + index).val();
    var itemId = $("#itemIdProfile" + index).val();
    var stockQuantity = $("#itemStockQuantityProfile" + index).val();
    var netAmount = $("#priceProfile" + index).val();
    var logoPath = $("#imageProfile" + index).val();
    var packId = $("#packIdProfile" + index).val();
    var val = $("#txtQtyProfile" + storeItemStockId).val()
    const re = /^[0-9\b]+$/;
    if (val === '' || re.test(val)) {
      var prnum = $('#txtQtyProfile' + storeItemStockId).val();
      if (parseInt(prnum) >= parseInt(stockQuantity)) {
        prnum = stockQuantity;
        $("#" + this.props.qtyError + storeItemStockId).show();
        $("#" + this.props.qtyError + storeItemStockId).text("Only " + stockQuantity + " qty available")
        $("#" + this.props.itemCount + storeItemStockId).text(stockQuantity)
        $('#txtQtyProfile' + storeItemStockId).val(stockQuantity)
      }
      else if (prnum == 0) {
        this.addToCart(storeItemStockId, "decZero", isLoose, packIndex)
      }
      else {
        $("#" + this.props.qtyError + storeItemStockId).hide();
        $("#" + this.props.qtyError + storeItemStockId).text("");
        $("#" + this.props.itemCount + storeItemStockId).text(val)
        $('#txtQtyProfile' + storeItemStockId).val(val)
      }
    }
    else {
      var prnum = val.replace(/[^0-9\.]/g, '');
      $('#txtQtyProfile' + storeItemStockId).val(prnum)
    }
    if (val < 1) {
      $('#addToCart' + storeItemStockId).removeClass("cart_clk");
      if (isLoose === '0') {
        $("#profileCartBtn" + packIndex).removeClass("addcartActive");
      }
    }
    this.updateCart(itemName, itemId, val, netAmount, storeItemStockId, packId, false, stockQuantity, logoPath)
  }
  packClick(imagePath, price, mrp, currencySymbol, itemIndex, store_item_stock_ids, item_ids, unit, stock_quantities, item_names, logoPath, pack_ids, packIndex, is_loose_item, is_display_item_price) {
    $("#itemImage" + itemIndex).prop('src', imagePath + '/' + logoPath)
    if (is_display_item_price == true) {
      $("#oldPriceProfile" + itemIndex).show()
      $("#newPriceProfile" + itemIndex).text(currencySymbol + ' ' + price)
      $("#oldPriceProfile" + itemIndex).text(currencySymbol + ' ' + mrp)
    } else {
      $("#oldPriceProfile" + itemIndex).hide()
      $("#newPriceProfile" + itemIndex).text(currencySymbol + ' ' + mrp)
      // $("#oldPriceProfile" + itemIndex).text(currencySymbol + ' ' + mrp)
    }
    $("#sisIdProfile" + itemIndex).val(store_item_stock_ids)
    $("#itemIdProfile" + itemIndex).val(item_ids)
    $("#priceProfile" + itemIndex).val(price)
    $("#unitProfile" + itemIndex).val(unit)
    $("#itemStockQuantityProfile" + itemIndex).val(stock_quantities)
    $("#nameProfile" + itemIndex).val(item_names)
    $("#imageProfile" + itemIndex).val(imagePath + '/' + logoPath)
    $("#packIdProfile" + itemIndex).val(pack_ids)
    $(".profileCartBtn").hide();
    $("#profileCartBtn" + packIndex).show();
    this.setState({
      store_item_stock_ids: store_item_stock_ids,
      item_ids: item_ids,
      unit: unit,
      stock_quantities: stock_quantities,
      item_names: item_names,
      logoPath: imagePath + '/' + logoPath,
      pack_ids: pack_ids,
      price: price
    })
    if (is_loose_item == true && stock_quantities > 0) {
      this.addToCart(0, 'addToCart', '1', packIndex)
    }
    $(".profileCartBtn").addClass("hideClass")
    $("#profileCartBtn" + packIndex).removeClass("hideClass")
  }
  addDefaultSrc(ev) {
    ev.target.src = ProductDefault
  }
  render() {
    let themeId = localStorage.getItem('themeId')
    return (
      <div>
        <Suspense fallback={<div></div>}>
          {this.state.isLoading === '0' ? <Loader></Loader>
            :
            <div className="coverWraper">
              {this.state.itemDetails.map((itemObject, itemIndex) => (
                <div>
                  <MainHeader></MainHeader>
                  <Helmet>
                    <title>{itemObject.seo_title}</title>
                    <meta name="description" content={itemObject.seo_description} ></meta>
                    <meta name="keywords" content={itemObject.seo_keywords} ></meta>
                    <meta name="twitter:title" content={itemObject.seo_title}></meta>
                    <meta name="twitter:description" content={itemObject.seo_description}></meta>
                    <meta name="twitter:image:src" content={this.state.storeResults[0].imagePath + '/' + itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].logoPath}></meta>
                    <meta name="facebook:title" content={itemObject.seo_title}></meta>
                    <meta name="facebook:description" content={itemObject.seo_description}></meta>
                    <meta name="facebook:image:src" content={this.state.storeResults[0].imagePath + '/' + itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].logoPath}></meta>
                    <meta property="og:title" content={itemObject.seo_title} ></meta>
                    <meta property="og:image" content={this.state.storeResults[0].imagePath + '/' + itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].logoPath} ></meta>
                    <meta property="og:description" content={itemObject.seo_description} ></meta>
                    <meta property="og:url" content={itemObject.slug} ></meta>
                  </Helmet>
                  <div className={themeId === '29' ? "contentWrap mraCoverWraper1":"contentWrap"}>
                    <div className={(themeId === '34' || themeId === '29'|| themeId === '4') ? "profileContainer container " : "profileInnerContainer"}>
                      <div className="itemListTop itemListTopProfile">
                        <div className="tagSection">
                          {this.state.category.slice(0, 8).map((category1, index) => (
                            <Link to={"/listing/" + category1.category_level1_id + "/0/0//" + category1.name.split(' ').join('')}>
                              <span className="tag topCategory">{category1.name}</span>
                            </Link>
                          ))}
                        </div>
                      </div>
                      {this.state.breadCrumbDetails.map((breadCrumbObject, breadCrumbIndex) => (
                        <div className="tagSection profileTagSection">
                          {(() => {
                            if (breadCrumbObject.categoryLevel1 !== null && breadCrumbObject.categoryLevel1 !== '' && breadCrumbObject.categoryLevel1 !== 'null') {
                              return (
                                <Link to={"/listing/" + breadCrumbObject.catId1 + "/0/0//" + breadCrumbObject.categoryLevel1.split(' ').join('')} className="text-decoration-none tag">{breadCrumbObject.categoryLevel1}</Link>
                              )
                            }
                          })()}
                          {(() => {
                            if (breadCrumbObject.categoryLevel2 !== null && breadCrumbObject.categoryLevel2 !== '' && breadCrumbObject.categoryLevel2 !== 'null') {
                              return (
                                <Link to={"/listing/" + breadCrumbObject.catId1 + "/" + breadCrumbObject.catId2 + "/0//" + breadCrumbObject.categoryLevel1 + '-' + breadCrumbObject.categoryLevel2.split(' ').join('')} className="text-decoration-none tag">   {breadCrumbObject.categoryLevel2 != '' ? breadCrumbObject.categoryLevel2 : ''} </Link>
                              )
                            }
                          })()}
                          {(() => {
                            if (breadCrumbObject.categoryLevel3 !== null && breadCrumbObject.categoryLevel3 !== '' && breadCrumbObject.categoryLevel3 !== 'null') {
                              return (
                                <Link to={"/listing/" + breadCrumbObject.catId1 + "/" + breadCrumbObject.catId2 + "/" + breadCrumbObject.catId3 + '//' + breadCrumbObject.categoryLevel1 + '-' + breadCrumbObject.categoryLevel2 + '-' + breadCrumbObject.categoryLevel3.split(' ').join('')} className="text-decoration-none tag">   {breadCrumbObject.categoryLevel3 != '' ? breadCrumbObject.categoryLevel3 : ''} </Link>
                              )
                            }
                          })()}
                        </div>
                      ))}
                      <div className="itemProfile">
                        <div className="profileImage">
                          <img onError={this.addDefaultSrc} alt="" id={"itemImage" + itemIndex} src={this.state.storeResults[0].imagePath + '/' + itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].logoPath} />
                        </div>
                        <div className="profileDetails">
                          <div
                            id="portal"
                            className="portal"
                          />
                          {itemObject.is_offers_with_seperator === '1' ? <div className="offerTag">{itemObject.offer_text_with_seperator}</div> : ''}
                          {itemObject.brand_name !== '' ? <div className="profileItemBrand">{itemObject.brand_name}</div> : ''}
                          <div className="profileItemName">{itemObject.finalDataSetSub[0].item_names}</div>
                          <div className="profileItemQty">
                            {itemObject.finalDataSetSub.map((packObject, packIndex) => (
                              <div className="radioList">
                                {(() => {
                                  if (itemObject.finalDataSetSub.length === 1) {
                                    return (
                                      <span className="radioSection">
                                        <input type="radio" id={"radio" + packIndex} defaultChecked={true} name="rdPack" value={packObject.pack_names} onClick={(e) => this.packClick(this.state.storeResults[0].imagePath, parseFloat(packObject.item_prices).toFixed(window.sessionStorage.getItem('decimalCount')), parseFloat(packObject.mrps).toFixed(window.sessionStorage.getItem('decimalCount')), this.state.storeResults[0].currencySymbol, itemIndex, packObject.store_item_stock_ids, packObject.item_ids, packObject.unit, packObject.stock_quantities, packObject.item_names, packObject.logoPath, packObject.pack_ids, packIndex, itemObject.is_loose_item, packObject.is_display_item_price)} />
                                        <label for={"radio" + packIndex}></label>
                                        <span className="profilePackQty">{packObject.pack_names}</span>
                                        <span className="newPrice profilePackQtyPrice"> {this.state.storeResults[0].currencySymbol} {packObject.is_display_item_price === true ? parseFloat(packObject.item_prices).toFixed(window.sessionStorage.getItem('decimalCount')) : parseFloat(packObject.mrps).toFixed(window.sessionStorage.getItem('decimalCount'))} </span>
                                      </span>
                                    )
                                  }
                                  else {
                                    if ((itemObject.finalDataSetSub.length - 1) === packIndex) {
                                      return (
                                        <span className="radioSection">
                                          <input type="radio" id={"radio" + packIndex} name="rdPack" defaultChecked={this.props.location.state == packIndex ? true : this.props.location.state == undefined ? true : false} value={packObject.pack_names} onClick={(e) => this.packClick(this.state.storeResults[0].imagePath, parseFloat(packObject.item_prices).toFixed(window.sessionStorage.getItem('decimalCount')), parseFloat(packObject.mrps).toFixed(window.sessionStorage.getItem('decimalCount')), this.state.storeResults[0].currencySymbol, itemIndex, packObject.store_item_stock_ids, packObject.item_ids, packObject.unit, packObject.stock_quantities, packObject.item_names, packObject.logoPath, packObject.pack_ids, packIndex, itemObject.is_loose_item, packObject.is_display_item_price)} />
                                          <label for={"radio" + packIndex}></label>
                                          <span className="profilePackQty">{packObject.pack_names}</span>
                                          <span className="newPrice profilePackQtyPrice"> {this.state.storeResults[0].currencySymbol} {packObject.is_display_item_price === true ? parseFloat(packObject.item_prices).toFixed(window.sessionStorage.getItem('decimalCount')) : parseFloat(packObject.mrps).toFixed(window.sessionStorage.getItem('decimalCount'))}</span>
                                        </span>
                                      )
                                    }
                                    else {
                                      return (
                                        <span className="radioSection">
                                          <input type="radio" id={"radio" + packIndex} name="rdPack" defaultChecked={this.props.location.state == packIndex ? true : false} value={packObject.pack_names} onClick={(e) => this.packClick(this.state.storeResults[0].imagePath, parseFloat(packObject.item_prices).toFixed(window.sessionStorage.getItem('decimalCount')), parseFloat(packObject.mrps).toFixed(window.sessionStorage.getItem('decimalCount')), this.state.storeResults[0].currencySymbol, itemIndex, packObject.store_item_stock_ids, packObject.item_ids, packObject.unit, packObject.stock_quantities, packObject.item_names, packObject.logoPath, packObject.pack_ids, packIndex, itemObject.is_loose_item, packObject.is_display_item_price)} />
                                          <label for={"radio" + packIndex}></label>
                                          <span className="profilePackQty">{packObject.pack_names}</span>
                                          <span className="newPrice profilePackQtyPrice"> {this.state.storeResults[0].currencySymbol} {packObject.is_display_item_price === true ? parseFloat(packObject.item_prices).toFixed(window.sessionStorage.getItem('decimalCount')) : parseFloat(packObject.mrps).toFixed(window.sessionStorage.getItem('decimalCount'))}</span>
                                        </span>
                                      )
                                    }
                                  }
                                })()}
                              </div>
                            ))}
                          </div>
                          <input type="hidden" id={"sisIdProfile" + itemIndex} value={this.state.store_item_stock_ids !== '' ? this.state.store_item_stock_ids : itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].store_item_stock_ids} />
                          <input type="hidden" id={"itemIdProfile" + itemIndex} value={this.state.item_ids !== '' ? this.state.item_ids : itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].item_ids} />
                          <input type="hidden" id={"priceProfile" + itemIndex} value={this.state.price !== '' ? this.state.price : itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].item_prices} />
                          <input type="hidden" id={"unitProfile" + itemIndex} value={this.state.unit !== '' ? this.state.unit : itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].unit} />
                          <input type="hidden" id={"itemStockQuantityProfile" + itemIndex} value={this.state.stock_quantities !== '' ? this.state.stock_quantities : itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].stock_quantities} />
                          <input type="hidden" id={"imageProfile" + itemIndex} value={this.state.logoPath !== '' ? this.state.logoPath : this.state.storeResults[0].imagePath + '/' + itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].logoPath} />
                          <input type="hidden" id={"nameProfile" + itemIndex} value={this.state.item_names !== '' ? this.state.item_names : itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].item_names} />
                          <input type="hidden" id={"packIdProfile" + itemIndex} value={this.state.pack_ids !== '' ? this.state.pack_ids : itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].pack_ids} />
                          <div className="productDetailPrice">
                            <span id={"newPriceProfile" + itemIndex} className="newPrice">{this.state.storeResults[0].currencySymbol} {itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].is_display_item_price == true ? parseFloat(itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].item_prices).toFixed(window.sessionStorage.getItem('decimalCount')) : parseFloat(itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].mrps).toFixed(window.sessionStorage.getItem('decimalCount'))}</span>
                            {/* {(parseFloat(itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].mrps) > parseFloat(itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].item_prices) && itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].is_display_item_price == true) ? */}
                            <span style={{ display: ((parseFloat(itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].mrps) > parseFloat(itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].item_prices) && itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].is_display_item_price == true) ? 'block' : 'none') }} id={"oldPriceProfile" + itemIndex} className="oldPrice">{this.state.storeResults[0].currencySymbol} {parseFloat(itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].mrps).toFixed(window.sessionStorage.getItem('decimalCount'))}</span>
                            {/* : ''} */}
                          </div>
                          {(() => {
                            if (itemObject.is_loose_item == true) {
                              return (
                                <div className={!this.state.isAdded ? "addcartbtn addcartbtnTheme" + itemIndex : "addcartbtn addcartActive addcartbtnTheme" + itemIndex}>
                                  <div class="qty">
                                    <span class="dec" onClick={(e) => this.addToCart(itemIndex, "dec", '1', (itemObject.finalDataSetSub.length - 1))} id={"minus" + itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].store_item_stock_ids}>-</span>
                                    <span class="cartNumber"><input type="text" className="cartInput" id={"txtQtyProfile" + itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].store_item_stock_ids} onChange={(e) => this.isNumber(itemIndex)} /></span>
                                    <span class="inc addBtn" onClick={(e) => this.addToCart(itemIndex, "inc", '1', (itemObject.finalDataSetSub.length - 1))} id={"add" + itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].store_item_stock_ids}>+</span>
                                  </div>
                                  {(() => {
                                    if (itemObject.stock_quantities_with_seperator > 0) {
                                      if (themeId === '36') {
                                        return (
                                          <div id={"addToCart" + itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].store_item_stock_ids} class="cartButton" onClick={(e) => this.addToCart(itemIndex, "addToCart", '1', (itemObject.finalDataSetSub.length - 1))}>ADD</div>
                                        )
                                      }
                                      else if (themeId === '34' || themeId === '29'|| themeId === '4') {
                                        return (
                                          <div id={"addToCart" + itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].store_item_stock_ids} class="cartButton" onClick={(e) => this.addToCart(itemIndex, "addToCart", '1', (itemObject.finalDataSetSub.length - 1))}><img src="https://storedigfiles.s3.amazonaws.com/production/themes/cartBtnLokal.png" alt="" /> ADD TO CART</div>
                                        )
                                      }
                                      else {
                                        return (
                                          <div id={"addToCart" + itemObject.finalDataSetSub[itemObject.finalDataSetSub.length - 1].store_item_stock_ids} class={((themeId == '31' || themeId == '30') ? "cartButton cartButtonDefault" : "cartButton")} onClick={(e) => this.addToCart(itemIndex, "addToCart", '1', (itemObject.finalDataSetSub.length - 1))}>ADD TO CART</div>
                                        )
                                      }
                                    }
                                    else {
                                      return (
                                        <div class="comingSoon"><b>Out Of Stock</b></div>
                                      )
                                    }
                                  })()}
                                </div>
                              )
                            }
                            else {
                              return (
                                itemObject.finalDataSetSub.map((packObject, packIndex) => (
                                  (() => {
                                    if (packObject.stock_quantities > 0) {
                                      return (
                                        <div class={(itemObject.finalDataSetSub.length - 1) == packIndex ? "addcartbtn profileCartBtn" : "addcartbtn profileCartBtn hideClass"} id={"profileCartBtn" + packIndex}>
                                          <div class="qty">
                                            <span class="dec" onClick={(e) => this.addToCart(itemIndex, "dec", '0', packIndex)} id={"minus" + packObject.store_item_stock_ids}>-</span>
                                            <span class="cartNumber"><input type="text" className="cartInput" id={"txtQtyProfile" + packObject.store_item_stock_ids} onChange={(e) => this.isNumber(itemIndex, "change", '0', packIndex)} /></span>
                                            <span class="inc addBtn" onClick={(e) => this.addToCart(itemIndex, "inc", '0', packIndex)} id={"add" + packObject.store_item_stock_ids}>+</span>
                                          </div>
                                          {(() => {
                                            if (themeId === '36') {
                                              return (
                                                <div id={"addToCart" + packObject.store_item_stock_ids} class="cartButton" onClick={(e) => this.addToCart(itemIndex, "addToCart", '0', packIndex)}>ADD</div>
                                              )
                                            }
                                            else {
                                              return (
                                                <div id={"addToCart" + packObject.store_item_stock_ids} class={((themeId === '31' || themeId === '30') ? "cartButton cartButtonDefault" : "cartButton")} onClick={(e) => this.addToCart(itemIndex, "addToCart", '0', packIndex)}>ADD TO CART</div>
                                              )
                                            }
                                          })()}
                                        </div>
                                      )
                                    }
                                    else {
                                      return (
                                        <div class={(itemObject.finalDataSetSub.length - 1) == packIndex ? "profileCartBtn " : "profileCartBtn  hideClass"} id={"profileCartBtn" + packIndex}><b>Out Of Stock</b></div>
                                      )
                                    }
                                  })()
                                ))
                              )
                            }
                          })()}
                          <div class="itemAlertText" id={"qtyErrorProfile" + itemIndex}></div>
                          {itemObject.description !== '' && (themeId !== '34' && themeId !== '29' &&  themeId !== '4') ?
                            <div className="productAbout">
                              <br></br>
                              <h3>About the Product</h3>
                              <p>{itemObject.description}</p>
                            </div>
                            : ''
                          }
                          <div className="productNote">
                            <h6>Important Note</h6>
                            <p>Product information or packaging displayed may not be current or complete. For additional information, contact the retailer.</p>
                          </div>
                        </div>
                        {itemObject.description !== '' && (themeId === '34' || themeId === '29')|| themeId === '4' ?
                          <div className="productDetailAbout">
                            <h3>Description</h3>
                            <p>{itemObject.description}</p>
                          </div>
                          : ''
                        }
                      </div>
                    </div>
                    <SubItems itemId={itemObject.finalDataSetSub[0].item_ids} cityId={itemObject.city_id} ></SubItems>
                  </div>
                  <MainFooter></MainFooter>
                </div>
              ))}
            </div>
          }
        </Suspense>
      </div>
    )
  }
}
