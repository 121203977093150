import React, { Component } from 'react';
import LoaderLollipop from "../images/loader/loaderLollipop.gif"
import LoaderFragrance from "../fragrance/images/LoadingFragrance.gif"
//const isTheme = localStorage.getItem('themeId');
//const theme = (isTheme !== null && isTheme !== "null" && isTheme == "36") ? import('../pothys/pothys.css') : ''
//const themeResponsive = (isTheme !== null && isTheme !== "null" && isTheme == "36") ? import('../pothys/pothys-responsive.css') : ''



export default class Loader extends Component {
  constructor(props) {
    super(props)

  }
  render() {
    // const isTheme = localStorage.getItem('themeId');
    // const theme = (isTheme !== null && isTheme !== "null" && isTheme == "36") ? import('../pothys/pothys.css') : ''
    // const themeResponsive = (isTheme !== null && isTheme !== "null" && isTheme == "36") ? import('../pothys/pothys-responsive.css') : ''

    let siteId = localStorage.getItem('siteId')
    let themeId = localStorage.getItem('themeId')
    let sellerId = window.sessionStorage.getItem('sellerId')

    let loaderImage = 'https://storedigfiles.s3.amazonaws.com/production/themes/'+sellerId+'-seller-loader.png'
    return (
      (() => {
        if(themeId == '36'){
          return (<div className={siteId == '249' ? "loaderImage loaderImageBottle" : "loaderImage"}><img src={loaderImage} alt=""/><div className="loaderImageSpan"><span></span></div></div>)
        }
         if(themeId == '30'){
          return (<div className={siteId == '254' ? "loaderImage loaderImageBottle fragranceLoader" : "loaderImage fragranceLoading"}><img src={LoaderFragrance} alt=""/><div className="loaderImageSpan"><span></span></div></div>)
        }
 
        else{
          {console.log("themeeieeiiii",themeId)}
          return (<div class={themeId == '29' ? " mraLoader loader " : "loader"}>Loading...</div>)
        }
      })()
    )
  }
}
