import React, { Component } from 'react';
import SimilarItems from "./similarItems"
import OtherItems from "./otherItems"
import OfferItems from "./offerItems"
import PersonalizedItems from "./personalizedItems"
import { getData } from '../services';
export default class subItems extends Component {
    constructor(props) {
        super(props)
        this.state = {
            similarProductsItems: [],
            relatedProductsItems: [],
            otherProductsItems: [],
            offerProductsItems: [],
            personalizedProductsItems: [],
            storeResults: []
        };
    }
    componentDidMount() {
        let itemId = this.props.itemId
        let buyerId
        if (localStorage.getItem('userId') != null && localStorage.getItem('userId') != '') {
            buyerId = localStorage.getItem('userId')
        }
        else {
            buyerId = '0'
        }
        let itemCount = '10'
        let cityId = this.props.cityId
        let storeId = window.sessionStorage.getItem('storeId')
        let itemIds = this.props.itemId
        const postData = {
            functionName: "getitemdetailsbyid/getSimilarItemsById",
            itemId: itemId,
            buyerId: buyerId,
            itemCount: itemCount,
            cityId: cityId,
            storeId: storeId,
            itemIds: itemIds
        }
        getData(postData).then((data) => {
            if (data.data.success === "1") {
                this.setState({
                    similarProductsItems: data.data.result.similarProducts,
                    relatedProductsItems: data.data.result.relatedProducts,
                    otherProductsItems: data.data.result.otherProducts,
                    offerProductsItems: data.data.result.offerProducts,
                    personalizedProductsItems: data.data.result.personalizedProducts,
                    storeResults: data.data.result.storeResults
                });
            }
        })
    }
    render() {
        return (
            <div>
                <SimilarItems similarProductsItems={this.state.similarProductsItems} storeResults={this.state.storeResults}></SimilarItems>
                <OtherItems similarProductsItems={this.state.otherProductsItems} storeResults={this.state.storeResults}></OtherItems>
                <OfferItems similarProductsItems={this.state.offerProductsItems} storeResults={this.state.storeResults}></OfferItems>
                <PersonalizedItems similarProductsItems={this.state.personalizedProductsItems} storeResults={this.state.storeResults}></PersonalizedItems>
            </div>
        )
    }
}
