import React, { Component, Suspense } from "react";
import bannerImage from "./assets/images/bannerImage.jpg"
import MRA_PIC1 from "./assets/images/MRA_PIC1.jpeg"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
let MraHeader = React.lazy(() => import("./header"));
let MraFooter = React.lazy(() => import("./footer"));
export default class MenuStaticDetails extends Component {
    render() {
        return (
            <>
                <div className="mraInnerSec mraMenuStaticInfo">
                    <Suspense fallback={<div></div>}>
                        <div className="coverWraper mramenuStaticDet categorOuter menuOuter">
                            <MraHeader />
                            <div className="contentWrap"  >
                                <div className="bannerImginner">
                                    <img src={bannerImage} alt="" />
                                    <div className="mraTitle">
                                        <div className="contentSectn">
                                            <h1>STORE</h1>
                                        </div>
                                    </div>
                                </div>

                                <div className="storeCoverWrap mraAbt" >
                                    <div className="specialDishOuter mra">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="specialDishImg">
                                                    <img src={MRA_PIC1} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 mraDcenter">
                                                <div className="mraTitle" style={{ textAlign: "left" }}>
                                                    <div className="contentSectn" style={{ margin: "0px" }}>
                                                        <h1 style={{ textAlign: "left" }}>MRA LA CUISINE RESTAURANT</h1>
                                                        <p style={{ margin: "inherit" }}>Ambara Suites, Near KIMS Hospital, Venpalavattom, Anayara P.O., Thiruvananthapuram</p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Tabs>
                                        <TabList>
                                            <Tab>Menu</Tab>
                                            <Tab>Gallery</Tab>
                                        </TabList>
                                        <TabPanel>
                                            <Tabs className="menuStaticTabInner">
                                                <TabList>
                                                    <Tab>Salads</Tab>
                                                    <Tab>Soups</Tab>
                                                    <Tab>Starters</Tab>
                                                    <Tab>Chicken(South Indian)</Tab>
                                                    <Tab>Chicken (Chinese)</Tab>
                                                    <Tab>Chicken (North Indian)</Tab>
                                                    <Tab>Tandoor/Arabic</Tab>
                                                    <Tab>Squid</Tab>
                                                    <Tab>Beef</Tab>
                                                    <Tab>Sweet Delights</Tab>
                                                    <Tab>Lacuisine Special Cakes</Tab>
                                                    <Tab>Lacuisine Special Shakes</Tab>
                                                    <Tab>Lacuisine Special Ice Creams</Tab>
                                                    <Tab>Milk Shakes</Tab>
                                                    <Tab>Faloodas</Tab>
                                                    <Tab>Smoothies</Tab>
                                                    <Tab>Hot Beverages</Tab>
                                                </TabList>
                                                <TabPanel>
                                                    <div className="container">
                                                        <div className="row mramenuu">
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Fresh Garden Salad</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 80</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Greek Salad</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 100</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Greek Salad</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 110</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Mexican Salad</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 160</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Russian Salad</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 160</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Arabic Mezzah(Hummus,Baba Ghanoush & Mutabal)</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 160</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Masala Tea</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 25</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel>
                                                    <div className="container">
                                                        <div className="row mramenuu">
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Naadan Mutton Soup</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 130</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Lemon Coriander Veg/Non Veg</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 100</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Cream Of Tomato</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 100</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Cream Of Vegetables/Chicken</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 100</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Sweet Corn Veg/Chicken</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 100</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Hot n Sour Veg/Chicken</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 100</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Manchow Soup Veg/Chicken</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 100</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Cream Of Spinach & Mushroom</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 110</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel>
                                                    <div className="container">
                                                        <div className="row mramenuu">
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Ammachi Chicken Fry</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 220</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Kerala Chilli Chicken</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 200</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Chicken Kondattam</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 210</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Chicken Kondattam</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 220</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Chicken Lollipop</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 200</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Crispy Chicken</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 240</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Chicken 65</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 200</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Dragon Chicken</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 240</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Fish finger</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 370</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Fish Tikka</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 380</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Squid Tawa Fry</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 290</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Chellanam Fish</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 340</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel>
                                                    <div className="container">
                                                        <div className="row mramenuu">
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Travancore Chicken Roast</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 260</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Kuttanadan Chicken Roast</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 250</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Chicken Varutharachathu</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 240</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Chicken Varutharachathu</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 250</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Chicken Ularthiyath</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 260</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Nadan Chicken Curry</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 220</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Kumarakom Chicken</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 240</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Chicken Porichathu</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 250</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Chicken Sukka</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 230</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel>
                                                    <div className="container">
                                                        <div className="row mramenuu">
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Chilli Chicken</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 190</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Manchurian Chicken</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 190</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Lemon Chicken</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 200</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Schzwan Chicken</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 200</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Honey Chicken</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 260</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Ginger Chicken</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 180</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Garlic Chicken</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 200</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel>
                                                    <div className="container">
                                                        <div className="row mramenuu">
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Butter Chicken</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 220</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Mughalai Chicken</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 250</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Chicken Tikka Masala</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 250</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Chicken Tikka Masala</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 250</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Kadai Chicken</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 230</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Kolhapuri Chicken</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 240</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Chicken Shahi Korma</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 270</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Chicken Nawabi</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 250</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Chicken Rara Masala</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 250</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel>
                                                    <div className="container">
                                                        <div className="row mramenuu">
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Chicken Tikka</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 250</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Al Fahm(F)</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 480</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Tandoor Chicken(F)</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 480</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Malai Kebab</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 270</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Irani Kebab</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 260</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Kariyali Kebab</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 260</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Kariyali Kebab</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 280</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Chicken Seekh Kebab</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 260</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Banjara Kebab</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 260</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Tangdi Kebab</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 280</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Mutton Seekh</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 300</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>La Cuisine Non Veg Platter(F)</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 950</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>

                                                <TabPanel>
                                                    <div className="container">
                                                        <div className="row mramenuu">
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Squid Roast</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 270</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Squid Ularthiyath</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 270</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Squid Masala</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 260</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Squid Thoran</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 260</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Squid Masala Fry</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 270</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Squid Ondhen Fry</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 270</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel>
                                                    <div className="container">
                                                        <div className="row mramenuu">
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Beef Chilli</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 180</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Beef Roast</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 190</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Beef Masala</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 220</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Beef Ularthiyath</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 230</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Beef Coconut Fry</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 230</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Beef Perattu</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 230</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Beef Nadan Curry</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 230</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Beef Pepper Masala</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 240</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Beef Deep Fry</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 200</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Dragon Beef</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 240</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Syrian Beef</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 250</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel>
                                                    <div className="container">
                                                        <div className="row mramenuu">
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>MRA Special Jackfruit Ice Cream</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 90</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Fruit Salad With Ice Cream</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377;90</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Choice Of Ice Cream</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 80</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Sizzling Brownie</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 160</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Gulab Jamun</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 70</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Banana Split</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 130</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Gud Bud</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 120</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Choice Of MOJITO</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 90</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Gulab Jamun With Ice Cream</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 100</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel>
                                                    <div className="container">
                                                        <div className="row mramenuu">
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Jaget Special</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 120</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Sheikh Shake(Dates Combination)</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377;120</h1>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Freedom Shake (Orange)</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 120</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>American Beauty</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 120</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Romantic Replay</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 130</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Cold Coffee With Ice Cream</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 100</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Laila Majnu</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 120</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Valentino</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 120</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>

                                                <TabPanel>
                                                    <div className="container">
                                                        <div className="row mramenuu">
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Banthe Banana</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 110</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Queen Of Hearts</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377;130</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Bad Boy</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 120</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Love In Love Out</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 130</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Bonanza/Marry Mad</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 110</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Fig n Honey</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 120</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Fifty Fifty</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 110</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Blue Dot</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 120</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Marry Mad9Watermelon,Ice Cream)</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 110</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>La Cuisinous</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 140</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel>
                                                    <div className="container">
                                                        <div className="row mramenuu">
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Mix Fruit Cream</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 100 </h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Dry Fruit Cream</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 100</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Custard Apple Cream(seasonal)</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 100</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Strawberry Cream (seasonal)</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 100</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Chickoo Cream</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 90</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Arabic Mezzah(Hummus,Baba Ghanoush & Mutabal)</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 160</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Masala Tea</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 25</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel>
                                                    <div className="container">
                                                        <div className="row mramenuu">
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Sharja Shake (chill out with banana)</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 80 </h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Touches of Windsor (Apple,Fig)</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 100</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Abood (Mango,Chickoo,Shamam)</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 130</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Lebanese (Apple,Dry Fruits)</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 90</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Spanish Delight</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 90</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Chocolate Shake</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 90</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel>
                                                    <div className="container">
                                                        <div className="row mramenuu">
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>MRA Special Falooda</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 150 </h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Fancy Falooda</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 90</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Royal Falooda</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 90</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Capital Falooda</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 90</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Falooda Jumeirah</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 80</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel>
                                                    <div className="container">
                                                        <div className="row mramenuu">
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Mango Banana</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 90 </h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Guava Banana</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 90</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Apple Dry Fruits</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 110</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel>
                                                    <div className="container">
                                                        <div className="row mramenuu">
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Tea</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 15 </h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Dancing Tea</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 60</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Coffee </p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 20</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p>Black Coffee</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 20</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-8">
                                                                        <p> Lemon Tea/Black Tea</p>
                                                                    </div>
                                                                    <div className="col-sm-3 col-4">
                                                                        <h1>&#8377; 15</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                            </Tabs>
                                        </TabPanel>
                                    </Tabs>
                                </div>
                            </div>
                            <MraFooter />
                        </div>
                    </Suspense>
                </div>
            </>
        )
    }
}