import React, { Component, Suspense } from 'react';
let Header = React.lazy(() => import("../header"));
let Footer = React.lazy(() => import("../footer"));
export default class Privacy extends Component {
    render() {
        return (
            <div class="myyshoppWrapperInner myyshopp-main-wrapper">
                <Suspense fallback={<div></div>}>
                    <Header></Header>
                    <div class="container myyshopp-container">
                        <section>
                            <div class="col-md-12">
                                <h1 class="main-head text-center mb-2 mb-lg-5">Privacy Policy</h1>
                                <div class="privacyHolder">
                                    <div class="mshop_termsHead mb-5"><strong>MyyShopp Privacy Policy</strong></div>
                                    <div class="mshop_termsHeadSml">What information does MyyShopp collect from users?</div>
                                    <div class="mshop_termsPara">MyyShopp collects information from the registration process that we need to be able to deliver products and collect payment from users of the website and apps. This includes information like names, emails, phone numbers, addresses, financial payment information and others. We may survey our membership on other demographic information like gender, age etc… in order to improve our products and services. We will also track how users actually engage our sites and apps in terms of what they search and click on. We use this information to design better services and ongoing improvements. We use site cookies in order to deliver better information to users, to help our in-site advertising efforts and for our analytics.</div>
                                    <div class="mshop_termsHeadSml">Who do we share information with?</div>
                                    <div class="mshop_termsPara">MyyShopp DOES NOT sell or share information that can personally identify you with 3rd party advertisers. As we are a platform for delivery of products and services, we only share your information as necessary to ensure the purchase and delivery of products to you. For example, we may need to share information with 3rd party delivery services that will bring groceries to your door.</div>
                                    <div class="mshop_termsPara">We will occasionally share aggregate data (not individual) with our business partners in order to improve our services as needed. In addition we may use the data that we collect in order to prevent illegal, fraudulent and criminal activities, violation of site policies and anything legally required by government institutions, authorized by law enforcement authorities and by court orders.</div>
                                    <div class="mshop_termsHeadSml">Protection of Data</div>
                                    <div class="mshop_termsPara">MyyShopp uses physical as well as technological controls to protect your data. For example, use industry leading software and hardware services in our cloud data centres. We will continue to upgrade and improve our systems and technologies to protect our customers.</div>
                                    <div class="mshop_termsHeadSml">Changes to Policy</div>
                                    <div class="mshop_termsPara">MyyShopp reserves the right to change our Privacy Policy without notice</div>
                                    <div class="mshop_termsBlock mt-4"><strong>MyyShopp.com and its trademarks and images are owned entirely by MyyShopp eCommerce Technologies Pvt. Ltd.</strong></div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer></Footer>
                </Suspense>
            </div>
        )
    }
}