var VtransactSecurity = require('./VtransactSecurity.js');
var vTransactSecurity = new VtransactSecurity();
function AWLMEAPI() {}
AWLMEAPI.prototype.generateTrnReqMsg = function(reqMsgDTO) {
    if (!(reqMsgDTO.getAddField10())) {
        reqMsgDTO.setAddField10("NA");
    }
    if (!(reqMsgDTO.getAddField9())) {
        reqMsgDTO.setAddField9("NA");
    }
    try {
        if (!(reqMsgDTO) ||
            !(reqMsgDTO.getMid()) ||
            !(reqMsgDTO.getOrderId()) ||
            !(reqMsgDTO.getTrnAmt()) ||
            !(reqMsgDTO.getMeTransReqType()) ||
            !(reqMsgDTO.getEnckey()) ||
            !(reqMsgDTO.getResponseUrl()) ||
            !(reqMsgDTO.getTrnCurrency())
        ) {
            console.log('reqMsgDTO.....',reqMsgDTO)
            console.log('reqMsgDTO..getMid()....',reqMsgDTO.getMid())
            console.log('reqMsgDTO..getOrderId()....',reqMsgDTO.getOrderId())
            console.log('reqMsgDTO..getTrnAmt()....',reqMsgDTO.getTrnAmt())
            console.log('reqMsgDTO..getMeTransReqType()....',reqMsgDTO.getMeTransReqType())
            console.log('reqMsgDTO..getEnckey()....',reqMsgDTO.getEnckey())
            console.log('reqMsgDTO..getResponseUrl()....',reqMsgDTO.getResponseUrl())
            console.log('reqMsgDTO..getTrnCurrency()....',reqMsgDTO.getTrnCurrency())
            reqMsgDTO.setStatusDesc("Mandatory fields are missing");
             console.log("test1");
            return reqMsgDTO;
        }
        if (!((reqMsgDTO.getMeTransReqType() === "R") || (reqMsgDTO.getMeTransReqType() === "P") || (reqMsgDTO.getMeTransReqType() === "S"))) {
            reqMsgDTO.setStatusDesc("Mandatory fields are missing");
             console.log("test2");
            return reqMsgDTO;
        }
        if ((reqMsgDTO.getMeTransReqType() === "R") &&
            (!(reqMsgDTO.getRecurrPeriod()) ||
                !(reqMsgDTO.getRecurrDay()) ||
                !(reqMsgDTO.getNoOfRecurring()))) {
             console.log("test3");
            reqMsgDTO.setStatusDesc("Mandatory fields are missing for recurring payment");
            return reqMsgDTO;
        }
        var merchantReqStr = reqMsgDTO.getMid() +
            "|" + reqMsgDTO.getOrderId() +
            "|" + reqMsgDTO.getTrnAmt() +
            "|" + reqMsgDTO.getTrnCurrency() +
            "|" + reqMsgDTO.getTrnRemarks() +
            "|" + reqMsgDTO.getMeTransReqType() +
            "|" + reqMsgDTO.getRecurrPeriod() +
            "|" + reqMsgDTO.getRecurrDay() +
            "|" + reqMsgDTO.getNoOfRecurring() +
            "|" + reqMsgDTO.getResponseUrl() +
            "|" + reqMsgDTO.getAddField1() +
            "|" + reqMsgDTO.getAddField2() +
            "|" + reqMsgDTO.getAddField3() +
            "|" + reqMsgDTO.getAddField4() +
            "|" + reqMsgDTO.getAddField5() +
            "|" + reqMsgDTO.getAddField6() +
            "|" + reqMsgDTO.getAddField7() +
            "|" + reqMsgDTO.getAddField8() +
            "|" + reqMsgDTO.getAddField9() +
            "|" + reqMsgDTO.getAddField10();
        var enc_data = vTransactSecurity.encryptValue(merchantReqStr, reqMsgDTO.getEnckey());
        reqMsgDTO.setReqMsg(enc_data);
        reqMsgDTO.setStatusDesc("Success");
    } catch (ex) {
        reqMsgDTO.setStatusDesc("Error Occurred during Creating request message " + ex);
    }
    return reqMsgDTO;
}
module.exports = AWLMEAPI;