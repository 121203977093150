import React, { Component, Suspense } from 'react';
import investor from "../images/investor.png"
let Header = React.lazy(() => import("../header"));
let Footer = React.lazy(() => import("../footer"));
export default class OurInvestor extends Component {
    render() {
        return (
            <div class="myyshoppWrapperInner myyshopp-main-wrapper">
                <Suspense fallback={<div></div>}>
                    <Header></Header>
                    <div class="container myyshopp-container">
                        <section class="investor-section1">
                            <div class="row">
                                <div class="col-md-12">
                                    <h1 class="main-head text-center mt-0 mb-0 mb-lg-5">Our Investor: </h1><br></br>
                                </div>
                                <div class="col-lg-5">
                                    <a href="https://katabole.com/" target="_blank"><img src={investor} alt="" class="img-fluid" width="220"></img></a>
                                    <h4 class="investor-logotext"> Katabole team totally gets the challenges a start-up would likely face. </h4>
                                </div>
                                <div class="col-lg-7">
                                    <div className="myystoryContent">
                                        <p>Katabole is a unique venture firm with a simple, crystal-clear purpose: pick smart people with fresh, disruptive, technology-driven ideas and help convert those ideas into big, phenomenally big, really, really, really big businesses, worldwide, that challenge and obliterate the status quo. With technology that creates exhilarating experiences, Katabole transforms the way people live, work and play.</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <section class="mshopp-investorBg">
                        <div class="container">
                            <p>As successful serial entrepreneurs, the Katabole team totally gets the challenges a start-up would likely face. And knows how to solve them. Katabole organizes seed funding or angel capital, brings in technical expertise and quality mentorship. Katabole’s uniqueness stems from the fact that they provide 100% hands-on support across all business functions (from strategy to brand communications to business development to accounting and legal), which helps plug any gaps that could potentially affect the startup’s ability to grow and scale.</p>
                        </div>
                    </section>
                    <Footer></Footer>
                </Suspense>
            </div>
        )
    }
}