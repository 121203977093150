import React, { Component, Suspense } from "react";
import bannerImage from "./assets/images/bannerImage.jpg"
import pattern from "./assets/images/pattern-9.svg"
let MraHeader = React.lazy(() => import("./header"));
let MraFooter = React.lazy(() => import("./footer"));
export default class ContactDetails extends Component {
    render() {
        return (
            <>
                <div className="mraInnerSec">
                    <Suspense fallback={<div></div>}>
                        <div className="coverWraper">
                            <MraHeader />
                            <div className="contentWrap">
                                <div className="bannerImginner">
                                    <img src={bannerImage} alt="" />
                                    <div className="mraTitle">
                                        {/* <span>OUR STORY</span>
                                    <img src={BorderImg} alt="" /> */}
                                        <div className="contentSectn">
                                            <h1>Contact </h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="mraBorders">
                                    <div className="borderLeftImg">
                                        <img src={pattern} alt="" />
                                    </div>
                                    <div className="borderRightImg">
                                        <img src={pattern} alt="" />
                                    </div>
                                </div>
                                <div className="storeCoverWrap mraAbt">

                                    <div className="container">
                                        <div className="mraTitlesInner mraContacts">

                                            <h1>MRA Bakery Corporate Office</h1>
                                            <p>  pTC 20/15 (5, 6), Sastha Nagar, Karamana P.O.,<br /> Thiruvananthapuram, Kerala,<br /> India 695002<br />
                                                Phone : 0471 4000602,9388 009 223<br />
                                                Email : info.tvm@mrabakery.com</p>
                                        </div>
                                        <div className="iframeMaps">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3946.1842836676574!2d76.96300051478278!3d8.481454993901844!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05baffc338e9ff%3A0x46755df0c86a6f7d!2sMRA%20Bakery%20%26%20Sweets%20(Karamana)!5e0!3m2!1sen!2sin!4v1683658060217!5m2!1sen!2sin" title="map" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <MraFooter />
                        </div>
                    </Suspense>
                </div>
            </>
        )
    }
}