import React, { Component ,Suspense} from "react";
// import MraHeader from "./header";
// import MraFooter from "./footer";
import bannerImage from "./assets/images/bannerImage.jpg"
import BorderImg from "./assets/images/separator.svg";
import blog2 from "./assets/images/mraBlog2.jpg"
import blog1Sub from "./assets/images/catering_image1.png"
import blog2Sub from "./assets/images/catering_image2.png"
let MraHeader = React.lazy(() => import("./header"));
let MraFooter = React.lazy(() => import("./footer"));
export default class blogDetailsCatering extends Component {
    render() {
        return (
            <>
                <div className="mraInnerSec">
                <Suspense fallback={<div></div>}>
                    <div className="coverWraper">
                        <MraHeader />
                        <div className="contentWrap">
                            <div className="bannerImginner">
                                <img src={bannerImage} alt="" />
                                <div className="mraTitle">
                                    <div className="contentSectn">
                                        <h1>Blog</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="mraBorders">
                               
                            </div>
                            <div className="storeCoverWrap mraAbt">

                                <div className="container">
                                    <div className="mraTitlesInner mraBlogs">
                                        <div className="mraTitle">
                                            <span>OUR LATEST</span>
                                            <img src={BorderImg} alt="" />
                                            <div className="contentSectn" style={{ margin: "0px" }}>
                                                <h1 >Blogs</h1>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4  ">
                                                <div className="mraBlogItem">
                                                    <img src={blog2} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="mraBlogTxt">
                                                    <h1>Catering</h1>
                                                    <h4>MARCH 19, 2022</h4>
                                                    <p>From Birthday parties to Corporate Conferences, Happening@MRA is your one-stop event planner and organiser. Let us be the hosts and deal with the Space and Food.We service cater of all kinds from our central production kitchens, using our fleet of vans to deliver freshly cooked hot and cold food. Our kitchen is built to be flexible – ready for three meals a day, snacks, fine dining and creative hospitality. Just let us know what you need.</p>
                                                    <p>We cook all our food from scratch, every day. Our trusted network of suppliers brings us the freshest ingredients, sourced from around the region. Our goal is to change the perception of what office food can be.</p>
                                                    <div className="col-md-4  ">
                                                        <div className="row">
                                                            <div className="mraBlogItem">
                                                                <img src={blog1Sub} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/*  */}
                                                    <p>We can supply food and service to your guests, clients and the public from any business location, building, office, marquee or from the widest choice of innovative temporary catering outlets and at any time of day and night too.Our complete Institutional Catering solutions include various meal services in the form of buffet / packed meals. The menus are designed keeping in mind the client profile.</p>
                                                    <div className="col-md-4  ">
                                                        <div className="row">
                                                            <div className="mraBlogItem">
                                                                <img src={blog2Sub} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <MraFooter />
                    </div>
                    </Suspense>
                </div>
            </>
        )
    }
}