import React, { Component } from 'react';
import Customize from "../restaurant/customize"
import RestaurantDetails from "../restaurant/restaurantDetails"
import $ from "jquery";
import offer from "../images/icons/star.png"
import CartBtn from "../restaurant/addcartBtn"
import Pagination from "react-js-pagination";
import heart from "../images/icons/heart-icon.png"
import chilli from "../images/icons/chilli.png"
import { getData, pageHitAPICall } from '../services';
import Slider from "react-slick";
import triangle from '../images/icons/triangle.png'
import lence from '../images/icons/lence.png'
import Storebanner from "../lokalIn/storeBanner"
import arowdown from '../images/lokal/arow-down.png'
import { elasticLink, elasticHostURL } from "../config";
import Parser from 'html-react-parser';
const AWS = require('aws-sdk');
const elasticsearch = require("elasticsearch");
AWS.config.region = 'us-west-1';
export default class restaurantListingSubdomain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            leftMenuDataArray: [],
            courseTypeData: [],
            bannerLogo: [],
            rowCount: [],
            bannerImage: [],
            itemListingArray: [],
            sortActive: false,
            divPagination: '0',
            sizePrice: 0,
            sizeName: '',
            sizeId: '',
            isData: '1',
            itemOffset: 0
        };
        this.wishListClick = this.wishListClick.bind(this);
        this.categoryFilter = this.categoryFilter.bind(this);
        this.sortListClick = this.sortListClick.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.itemLoad = this.itemLoad.bind(this);
    }
    saveMore(itemId, amount) {
        var storeItemSizeId = $("#hidStoreItemSizeId" + itemId).val();
        $("#customize" + storeItemSizeId).show();
        $(".chkTopping").prop('checked', false);
        var storeItemSizeId = $("#hidStoreItemSizeId" + itemId).val();
        $("#customId" + storeItemSizeId).text(amount)
        $("#totalToppingPrice" + storeItemSizeId).val(amount)
        $("#hidToppingsData" + storeItemSizeId).val('')
    }
    setId(itemId, offer, offertextData, itemListingArrayIndex) {
        var cmbSize = $("#cmbSize" + itemId).val().split('-');
        $("#hidStoreItemSizeId" + itemId).val(cmbSize[2]);
        if (cmbSize[3] > 0) {
            $("#customizeLink" + itemId).show();
            $("#customizeLinkAddCartBtnStatic" + itemId).show();
            $("#customizeLinkAddCartBtnDynamic" + itemId).hide();
        }
        else {
            $("#customizeLink" + itemId).hide();
            $("#customizeLinkAddCartBtnStatic" + itemId).hide();
            $("#customizeLinkAddCartBtnDynamic" + itemId).show();
        }
        this.offerText(parseInt(cmbSize[4]), offer, offertextData, itemListingArrayIndex)

    }
    offerText(index, isOffer, offerTextData, itemListingIndex) {
        let offersplit = isOffer.split('|')
        let offerTextsplit = offerTextData.split('|')
        if (offersplit[index] === '1') {
            const offerSpan = document.getElementById(`offerSpan${itemListingIndex}`);
            if (offerSpan) {
                offerSpan.innerHTML = `<img src="${offer}" alt="" /> ${offerTextsplit[index]}`;
            }
        }
        else {
            const offerSpan = document.getElementById(`offerSpan${itemListingIndex}`);
            if (offerSpan) {
                offerSpan.innerHTML = ` `;
            }
        }
        // if (offersplit[index] === '1') {
        //     return (<span id={'offerSpan'} className="offerText"><img src={offer} alt="" /> {offerTextsplit[index]}</span>)
        // }
        // else {
        //     return (<span id={'offerSpan'} className="offerText"></span>)
        // }
    }

    readMore(itemID) {
        $("#readmore" + itemID).show();
    }
    sortClick() {
        const currentState = this.state.sortActive;
        this.setState({ sortActive: !currentState });
    }
    categoryFilter(type, cuisineId, courseId, courseTypeId, sortBy, offset, max, index) {
        debugger
        this.setState({
            activePage: 1
        })
        $(".topCategory").removeClass('tagActive')
        if (index.toString() !== '') {
            $(".topCategoryItem" + index).addClass('tagActive')
        }
        let themeId = localStorage.getItem('themeId')
        if (themeId === "34" || themeId === '29') {
            window.scrollTo(0, 350)
            $(".filterListName").removeClass('filterListActive')
            $("#categoryList" + cuisineId.toString() + courseId.toString() + courseTypeId.toString()).addClass('filterListActive')
        }
        else if (themeId === "32") {

        }
        else {
            window.scrollTo(0, 0)
        }
        window.sessionStorage.setItem('searchKeyword', '')
        // let userId = ((localStorage.getItem('userId') != '' && localStorage.getItem('userId') != null || localStorage.getItem('userId') != 'null') ? localStorage.getItem('userId') : '0');
        let storeId = window.sessionStorage.getItem('storeId');
        window.sessionStorage.setItem('sortBy', sortBy)
        if ((cuisineId === null || cuisineId === '' || cuisineId === 'null') && (courseId === null || courseId === '' || courseId === 'null') && (courseTypeId === null || courseTypeId === '' || courseTypeId === 'null')) {
            window.sessionStorage.setItem('cuisineId', '')
            window.sessionStorage.setItem('courseId', '')
            window.sessionStorage.setItem('courseTypeId', '')
        }
        if (cuisineId !== null && cuisineId !== '' && cuisineId !== 'null') { //alert("cuisineId..12..."+cuisineId)
            window.sessionStorage.setItem('cuisineId', cuisineId)
        }
        if (courseId !== null && courseId !== '' && courseId !== 'null') {
            window.sessionStorage.setItem('courseId', courseId)
        }
        if (courseTypeId !== null && courseTypeId !== '' && courseTypeId !== 'null') {
            window.sessionStorage.setItem('courseTypeId', courseTypeId)
        }
        if (offset !== null && offset !== '' && offset !== 'null') {
            window.sessionStorage.setItem('offset', offset)
        }
        if (max !== null && max !== '' && max !== 'null') {
            window.sessionStorage.setItem('max', max)
        }
        cuisineId = ((cuisineId === '' || cuisineId === null || cuisineId === 'null') ? (window.sessionStorage.getItem('cuisineId') === null ? '' : window.sessionStorage.getItem('cuisineId')) : cuisineId);
        courseId = ((courseId === '' || courseId === null || courseId === 'null') ? (window.sessionStorage.getItem('courseId') === null ? '' : window.sessionStorage.getItem('courseId')) : courseId);
        var courseTypeId1 = ((courseTypeId === '' || courseTypeId === null || courseTypeId === 'null') ? (window.sessionStorage.getItem('courseTypeId') === null ? '' : window.sessionStorage.getItem('courseTypeId')) : courseTypeId);
        var offset = 0
        var max = 45
        var sortBy = ''
        this.itemListing(storeId, cuisineId, courseId, courseTypeId, sortBy, offset, max, '', '')
        $("#clearAllFilter").show();
    }
    sortListClick(sortBy, type) {
        let themeId = localStorage.getItem('themeId')
        if (themeId === "34" || themeId === '29') {
            window.scrollTo(0, 350)
        }
        else {
            window.scrollTo(0, 0)
        }
        $("#sortBy").text(type);
        window.sessionStorage.setItem('sortBy', sortBy)
        // let userId = ((localStorage.getItem('userId') != '' && localStorage.getItem('userId') != null || localStorage.getItem('userId') != 'null') ? localStorage.getItem('userId') : '0');
        let storeId = window.sessionStorage.getItem('storeId');
        let offset = 0
        let max = 45
        let cuisineId = (window.sessionStorage.getItem('cuisineId') === null ? '' : window.sessionStorage.getItem('cuisineId'));
        let courseId = (window.sessionStorage.getItem('courseId') === null ? '' : window.sessionStorage.getItem('courseId'));
        let courseTypeId = (window.sessionStorage.getItem('courseTypeId') === null ? '' : window.sessionStorage.getItem('courseTypeId'));
        let searchKeyword = (window.sessionStorage.getItem('searchKeyword') === null ? '' : window.sessionStorage.getItem('searchKeyword'));
        this.itemListing(storeId, cuisineId, courseId, courseTypeId, sortBy, offset, max, '', searchKeyword)
        $("#clearAllFilter").show();
    }
    wishListClick(mode, item, wishId) {
        let userId = localStorage.getItem('userId');
        let secUserId = userId
        let storeId = window.sessionStorage.getItem('storeId');
        let itemId = item
        if (userId !== null && userId !== '') {
            if (mode === 'Add') {
                $(".wishList").show();
                const postData = {
                    functionName: 'wishlistsave',
                    currentUserId: secUserId,
                    storeId: storeId,
                    itemId: itemId
                }
                getData(postData).then((data) => {
                    console.log("datawish", data)
                    if (data.data.success === '1') {
                    }
                });
                if ($("#wishListItemId" + item).hasClass("wishListActive") == false) {
                    alert("Item Successfully Added to Wish List")
                    $("#wishListItemId" + item).addClass("wishListActive")
                }
                else {
                    alert("Item Successfully Removed from Wish List")
                    $("#wishListItemId" + item).removeClass("wishListActive")
                }
            }
            else {
                const postData = {
                    functionName: 'wishlistsave',
                    currentUserId: secUserId,
                    storeId: storeId,
                    itemId: itemId
                }
                getData(postData).then((data) => {
                    if (data.data.success === '1') {
                    }
                });
                if ($("#wishListItemId" + item).hasClass("wishListActive") == false) {
                    alert("Item Successfully Added to Wish List")
                    $("#wishListItemId" + item).addClass("wishListActive")
                }
                else {
                    alert("Item Successfully Removed from Wish List")
                    $("#wishListItemId" + item).removeClass("wishListActive")
                }
            }
        }
        else {
            $(".wishList").hide();
            $(".loginPopUp").show();
        }
    }


    itemLoad() {
        var itemOffset = this.state.itemOffset
        let storeId = window.sessionStorage.getItem('storeId');
        let cuisineId = (window.sessionStorage.getItem('cuisineId') === null ? '' : window.sessionStorage.getItem('cuisineId'));
        let courseId = (window.sessionStorage.getItem('courseId') === null ? '' : window.sessionStorage.getItem('courseId'));
        let courseTypeId = (window.sessionStorage.getItem('courseTypeId') === null ? '' : window.sessionStorage.getItem('courseTypeId'));
        let sortBy = (window.sessionStorage.getItem('sortBy') === null ? '' : window.sessionStorage.getItem('sortBy'));
        let searchKeyword = (window.sessionStorage.getItem('searchKeyword') === null ? '' : window.sessionStorage.getItem('searchKeyword'));
        this.itemListing(storeId, cuisineId, courseId, courseTypeId, sortBy, itemOffset, 45, '', searchKeyword)
    }


    async itemListing(storeId, cuisineId, courseId, courseTypeId, sortBy, offset, max, itemId, searchKeyword) {
        debugger
        let themeId = localStorage.getItem('themeId')
        const client = new elasticsearch.Client({
            host: elasticHostURL(),
            amazonES: {
                region: 'us-west-1',
                credentials: new AWS.Credentials(this.state.s3AccessKey, this.state.s3SecretKey)
            }
        });
        var alcoholContent = window.sessionStorage.getItem('alcohol_content')
        if (alcoholContent !== null && alcoholContent !== "") {
            alcoholContent = JSON.parse(alcoholContent)
        }
        var filters = [];
        var fields = ['storeid', 'cuisineid', 'courseid', 'coursetypeid', 'itemid', 'alcoholcontent'];
        var paramsVal = [storeId, cuisineId, courseId, courseTypeId, itemId, alcoholContent]
        for (var k = 0; k < fields.length; k++) {
            if (paramsVal[k] !== 'null' && paramsVal[k] !== '' && paramsVal[k] !== null) {
                if (fields[k] === 'alcoholcontent') {
                    var termq = { terms: {} };
                    termq.terms[fields[k]] = paramsVal[k];
                    filters.push(termq);
                }
                else {
                    if (paramsVal[k] !== '0' && paramsVal[k] !== 0) {
                        var termq = { term: {} };
                        termq.term[fields[k]] = paramsVal[k];
                        filters.push(termq);
                    }
                }
            }
        }
        var sortByFilter
        if (sortBy === 'asc' || sortBy === 'desc') {
            sortByFilter = [{ "amount": sortBy }]
        }
        var itemListingResults = [];
        var totalCount
        console.log('filters......res...',filters);
        if (searchKeyword != 'null' && searchKeyword != '' && searchKeyword != null) {
            const body = await client.search({
                index: elasticLink() + '-rt-search',
                from: offset, size: max,
                body: {
                    query:
                    {
                        bool: {
                            "must": {
                                "multi_match": {
                                    "query": searchKeyword,
                                    "type": "phrase_prefix",
                                    "fields": ["searchstring"]
                                }
                            },
                            filter:
                            {
                                bool:
                                {
                                    must: filters
                                }
                            }
                        }
                    },
                    sort: sortByFilter
                }
            })
            console.log("pppppppppppppppppppppppkkkkkkkkkkkkk",body.hits.hits)
            itemListingResults = body.hits.hits
            totalCount = body.hits.total.value
        }
        else {
            const body = await client.search({
                index: elasticLink() + '-rt-search',
                from: offset, size: max,
                body: {
                    query:
                    {
                        bool: {
                            filter:
                            {
                                bool:
                                {
                                    must: filters
                                }
                            }
                        }
                    },
                    sort: sortByFilter
                }
            })
            console.log("pppppppppppppppppppppppkkkkkkkkkkkkk222222222222222",body.hits.hits)
            itemListingResults = body.hits.hits
            totalCount = body.hits.total.value
        }
        var itemListingData = []
        var selectRestaurantCart = JSON.parse(localStorage.getItem('selectRestaurantCart'))
        if (itemListingResults.length > 0) {
            for (var i = 0; i < itemListingResults.length; i++) {
                var toppingData = [];
                var itemsizenameSplt = itemListingResults[i]._source.itemsizename.split('|')
                var storeItemSizeIdSplt = itemListingResults[i]._source.storeitemsize.split('|')
                var priceSplt = itemListingResults[i]._source.netamount.split('|')
                var rtItemSizeIdSplt = itemListingResults[i]._source.rtitemsizeid.split('|')
                var level1RtItemSizeIdSplit = []
                var level1idSplit = []
                var level1nameSplit = []
                var level1multiselectSplit = []
                var costSplit = []
                var toppingidSplit = []
                var toppingnameSplit = []
                var toppingpriceSplit = []
                if (null != itemListingResults[i]._source.level1rtitemsizeid) {
                    level1RtItemSizeIdSplit = itemListingResults[i]._source.level1rtitemsizeid.split(',') // [6|NA|29,6|NA|29]
                    level1idSplit = itemListingResults[i]._source.level1id.split(',')
                    level1nameSplit = itemListingResults[i]._source.level1name.split(',')
                    level1multiselectSplit = itemListingResults[i]._source.level1multiselect.split(',')
                    costSplit = itemListingResults[i]._source.cost.split(',')
                    toppingidSplit = (itemListingResults[i]._source.toppingid !== null) ? itemListingResults[i]._source.toppingid.split(',') : '' // [2903|2904|2905-2906|2907-3805,2903|2904|2905-2906|2907-3805]
                    toppingnameSplit = (itemListingResults[i]._source.toppingname !== null) ? itemListingResults[i]._source.toppingname.split(',') : ''
                    toppingpriceSplit = (itemListingResults[i]._source.toppingprice !== null) ? itemListingResults[i]._source.toppingprice.split(',') : ''
                }
                var sizeArray = []
                var isTopping = false;
                for (var s = 0; s < itemsizenameSplt.length; s++) {
                    var sizeObj = {
                        itemMrp: parseFloat(priceSplt[s]).toFixed(2),
                        itemPrice: parseFloat(priceSplt[s]).toFixed(2),
                        itemsizname: itemsizenameSplt[s],
                        storeItemSizeId: storeItemSizeIdSplt[s],
                        rtItemSizeId: rtItemSizeIdSplt[s],
                        toppingData: []
                    }
                    toppingData = [];
                    var level1RtItemSizeIdSplitEach = [];
                    var level1idSplitEach = [];
                    var level1nameSplitEach = [];
                    var level1multiselectSplitEach = [];
                    var costSplitEach = [];
                    var toppingidSplitEach = [];
                    var toppingnameSplitEach = [];
                    var toppingpriceSplitEach = [];
                    if (null != level1RtItemSizeIdSplit[s]) {
                        level1RtItemSizeIdSplitEach = level1RtItemSizeIdSplit[s].split('|') // [6,NA,29]
                        level1idSplitEach = level1idSplit[s].split('|')
                        level1nameSplitEach = level1nameSplit[s].split('|')
                        level1multiselectSplitEach = level1multiselectSplit[s].split('|')
                        costSplitEach = costSplit[s].split('|')
                        toppingidSplitEach = (toppingidSplit[s] === "" || toppingidSplit[s] === undefined || toppingidSplit[s] === "undefined") ? "" : toppingidSplit[s].split('-') // [2903|2904|2905,2906|2907,3805]
                        toppingnameSplitEach = (toppingnameSplit[s] === "" || toppingnameSplit[s] === undefined || toppingnameSplit[s] === "undefined") ? "" : toppingnameSplit[s].split('-')
                        toppingpriceSplitEach = (toppingpriceSplit[s] === "" || toppingpriceSplit[s] === undefined || toppingpriceSplit[s] === "undefined") ? "" : toppingpriceSplit[s].split('-')
                    }
                    for (var l = 0; l < level1RtItemSizeIdSplitEach.length; l++) {
                        isTopping = true;
                        if (rtItemSizeIdSplt[s] == level1RtItemSizeIdSplitEach[l] || level1RtItemSizeIdSplitEach[l] == 'NA') {
                            var toppingIdSplit = toppingidSplitEach[l].split('|')
                            var toppingNameSplit = toppingnameSplitEach[l].split('|')
                            var toppingPriceSplit = toppingpriceSplitEach[l].split('|')
                            var toppingArray = [];
                            for (var t = 0; t < toppingIdSplit.length; t++) {
                                var toppingObject = {
                                    name: toppingNameSplit[t],
                                    id: toppingIdSplit[t],
                                    price: parseFloat(toppingPriceSplit[t]).toFixed(2)
                                }
                                toppingArray.push(toppingObject)
                            }
                            var itemToppingDetails = {
                                storeItemSizeId: storeItemSizeIdSplt[s],
                                storeItemSizePrice: priceSplt[s],
                                photoCake: '',
                                level1id: level1idSplitEach[l],
                                multiSelect: level1multiselectSplitEach[l],
                                name: level1nameSplitEach[l],
                                toppingCost: costSplitEach[l],
                                toppingArray: toppingArray
                            }
                            toppingData.push(itemToppingDetails)
                            sizeObj.toppingData = toppingData
                        }
                    }
                    sizeArray.push(sizeObj)
                }
                var itemImageSplit
                if (itemListingResults[i]._source.itemimage != null && itemListingResults[i]._source.itemimage != '') {
                    itemImageSplit = itemListingResults[i]._source.itemimage.split('|')[0]
                }

                var cartLabel = ""
                if (themeId === '34' || themeId === '29'|| themeId === '4') {
                    cartLabel = '<span><img src="https://storedigfiles.s3.amazonaws.com/production/themes/cartBtnLokal.png" /> ADD TO CART</span>'
                }
                else {
                    cartLabel = 'ADD TO CART'
                }
                var isCart = false;
                var cartQuantity = 0;
                var isCartItemColorShow = ""
                var isCartItemOpenClass = ""
                if (selectRestaurantCart != null && selectRestaurantCart != '' && selectRestaurantCart != 'null') {
                    for (let n = 0; n < selectRestaurantCart.length; n++) {
                        if (selectRestaurantCart[n].itemId == itemListingResults[i]._source.itemid) {
                            isCart = true;
                            cartQuantity = parseInt(cartQuantity) + parseInt(selectRestaurantCart[n].quantity)
                            isCartItemColorShow = "block"
                            isCartItemOpenClass = "cart_clk"
                            if (themeId === '34' || themeId === '29'|| themeId === '4') {
                                cartLabel = '<span><img src="https://storedigfiles.s3.amazonaws.com/production/themes/cartBtnLokal.png" /> ADDED</span>'
                            }
                            else {
                                cartLabel = 'ADDED'
                            }
                        }
                    }
                }
                var itemListingDetails = [];
                itemListingDetails = {
                    storeId: itemListingResults[i]._source.storeid,
                    itemId: itemListingResults[i]._source.itemid,
                    itemName: itemListingResults[i]._source.itemname,
                    itemCode: itemListingResults[i]._source.itemdesc,
                    category: itemListingResults[i]._source.coursetypecategory,
                    imageName: itemImageSplit,
                    wishListId: itemListingResults[i]._source.wishlistid,
                    itemDescription: itemListingResults[i]._source.itemdesc,
                    currencySymbol: itemListingResults[i]._source.currencysymbol,
                    itemMrp: parseFloat(priceSplt[0]).toFixed(2),
                    itemPrice: parseFloat(priceSplt[0]).toFixed(2),
                    itemsizname: itemsizenameSplt[0],
                    storeItemSizeId: storeItemSizeIdSplt[0],
                    rtItemSizeId: itemListingResults[i]._source.rtitemsizeid,
                    isoffer: itemListingResults[i]._source.isoffer,
                    offertext: itemListingResults[i]._source.offertext,
                    spicetype: itemListingResults[i]._source.spicetype,
                    isTopping: isTopping,
                    sizeGroup: sizeArray,
                    isCart: isCart,
                    cartSize: cartQuantity,
                    isCartItemColorShow: isCartItemColorShow,
                    isCartItemOpenClass: isCartItemOpenClass,
                    cartLabel: cartLabel
                }
                itemListingData.push(itemListingDetails)
            }
            this.setState({
                itemListingArray: itemListingData,
                rowCount: totalCount,
                divPagination: '1',
                isData: '1',
                itemOffset: offset
            });
        }
        else {
            this.setState({
                isData: '0'
            });
        }
    }
    async leftMenu(storeId, keyWord, courseId) {
        debugger
        var alcoholContent = window.sessionStorage.getItem('alcohol_content')
        if (alcoholContent !== null && alcoholContent !== "" && alcoholContent !== "null") {
            alcoholContent = JSON.parse(alcoholContent)
        }
        var filters = [];
        var fields = ['storeid', 'courseid', 'alcoholcontent'];
        var paramsVal = [storeId, courseId, alcoholContent]
        for (var k = 0; k < fields.length; k++) {
            if (paramsVal[k] !== '' && paramsVal[k] !== 'null' && paramsVal[k] !== null) {
                if (fields[k] === 'alcoholcontent') {
                    var termq = { terms: {} };
                    termq.terms[fields[k]] = paramsVal[k];
                    filters.push(termq);
                }
                else {
                    var termq = { term: {} };
                    termq.term[fields[k]] = paramsVal[k];
                    filters.push(termq);
                }
            }
        }
        if (keyWord != '' && typeof keyWord !== 'undefined' && keyWord != null) {
            var matchq = { match_phrase_prefix: {} };
            matchq.match_phrase_prefix['searchstring'] = keyWord;
            filters.push(matchq);
        }
        const client = new elasticsearch.Client({
            host: elasticHostURL(),
            amazonES: {
                region: 'us-west-1',
                credentials: new AWS.Credentials(this.state.s3AccessKey, this.state.s3SecretKey)
            }
        });
        const body = await client.search({
            index: elasticLink() + '-rt-search',
            from: 0, size: 0,
            body: {
                query:
                {
                    bool: {

                        filter:
                        {
                            bool:
                            {
                                must: filters
                            }

                        }
                    }
                },
                "aggs": {
                    "cuisinesortorder": {
                        terms: {
                            field: "cusinesortorder",
                            order: {
                                "_key": "asc"
                            },
                            size: 50 
                        },
                        "aggs": {
                            "cuisineid": {
                                terms: {
                                    field: "cuisineid",
                                    size: 50 
                                },
                                "aggs": {
                                    "cuisinename": {
                                        terms: {
                                            field: "cuisinename.keyword",
                                            size: 50 
                                        },
                                        "aggs": {
                                            "coursesortorder": {
                                                terms: {
                                                    field: "coursesortorder",
                                                    order: {
                                                        "_key": "asc"
                                                    },
                                                    size: 50
                                                },
                                                "aggs": {
                                                    "courseid": {
                                                        terms: {
                                                            field: "courseid",
                                                            size: 50
                                                        },
                                                        "aggs": {
                                                            "coursename": {
                                                                terms: {
                                                                    field: "coursename.keyword",
                                                                    size: 50
                                                                },
                                                                "aggs": {
                                                                    "coursetypesortorder": {
                                                                        terms: {
                                                                            field: "coursetypesortorder",
                                                                            order: {
                                                                                "_key": "asc"
                                                                            },
                                                                            size: 50
                                                                        },
                                                                        "aggs": {
                                                                            "coursetypeid": {
                                                                                terms: {
                                                                                    field: "coursetypeid",
                                                                                    size: 50
                                                                                },
                                                                                "aggs": {
                                                                                    "coursetypename": {
                                                                                        terms: {
                                                                                            field: "coursetypename.keyword",
                                                                                            size: 50
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        });
        debugger
        var leftMenuLevelDetails1 = [];
        var leftMenuLevelDetails2 = [];
        var leftMenuLevelDetails3 = [];
        for (const cuisinesort of body.aggregations.cuisinesortorder.buckets) {
            for (const cuisineid of cuisinesort.cuisineid.buckets) {
                leftMenuLevelDetails2 = [];
                for (const coursesort of cuisineid.cuisinename.buckets[0].coursesortorder.buckets) {
                    for (const courseid of coursesort.courseid.buckets) {
                        leftMenuLevelDetails3 = [];
                        for (const coursetypesort of courseid.coursename.buckets[0].coursetypesortorder.buckets) {
                            for (const coursetypeid of coursetypesort.coursetypeid.buckets) {
                                var level3 = {
                                    categoryName: coursetypeid.coursetypename.buckets[0].key,
                                    categoryType: 'level3',
                                    level1Id: cuisineid.key,
                                    level2Id: courseid.key,
                                    level3Id: coursetypeid.key,
                                    levelSub: []
                                }
                                leftMenuLevelDetails3.push(level3)
                            }
                        }
                        var level2 = {
                            categoryName: courseid.coursename.buckets[0].key,
                            categoryType: 'level2',
                            level1Id: cuisineid.key,
                            level2Id: courseid.key,
                            level3Id: '',
                            levelSub: leftMenuLevelDetails3
                        }
                        leftMenuLevelDetails2.push(level2)
                    }
                }
                var level1 = {
                    categoryName: cuisineid.cuisinename.buckets[0].key,
                    categoryType: 'level1',
                    level1Id: cuisineid.key,
                    level2Id: '',
                    level3Id: '',
                    levelSub: leftMenuLevelDetails2
                }
                leftMenuLevelDetails1.push(level1)
            }
        }
        this.setState({
            leftMenuDataArray: leftMenuLevelDetails1,
        }, () => {
            $(".filter-option-heading").click(function () {
                $(this)
                    .toggleClass("activeFilter")
                    .next(".filter-option-content")
                    .stop()
                    .slideToggle(500);
            });
        });
    }
    componentDidUpdate() {
        $(".popOverlay").click(function () {
            $(".popupWrap").hide();
        }.bind(this));
    }
    componentDidMount() {
   
        window.scrollTo(0, 0)
        let itemId = ''
        let categoryLevel1Id = ''
        let searchKeyword = ''
        // if (this.props.fromPage == 'Home') {
        //     itemId = this.props.itemId
        //     categoryLevel1Id = this.props.itemId
        //     searchKeyword = this.props.itemId
        // }
        // else if (typeof this.props.match === "undefined") {
        //     itemId = "0"
        //     categoryLevel1Id = "0"
        //     searchKeyword = ""
        // }
        // if (typeof this.props.match === "undefined") {
        itemId = "0"
        categoryLevel1Id = "0"
        searchKeyword = ""
        // }
        // else {
        //     const { match: { params } } = this.props;
        //     itemId = params.itemId
        //     categoryLevel1Id = params.categoryLevel1Id
        //     searchKeyword = params.searchKeyword
        // }
        let storeId = window.sessionStorage.getItem('storeId');
        let offset = 0;
        let max = 45;
        let cuisineId = (window.sessionStorage.getItem('cuisineId') === null ? '' : window.sessionStorage.getItem('cuisineId'));
        let courseId = (window.sessionStorage.getItem('courseId') === null ? '' : window.sessionStorage.getItem('courseId'));
        let courseTypeId = (window.sessionStorage.getItem('courseTypeId') === null ? '' : window.sessionStorage.getItem('courseTypeId'));
        if (categoryLevel1Id === '0') {
            cuisineId = 0;
            courseId = 0;
            courseTypeId = 0;
            window.sessionStorage.setItem('cuisineId', '')
            window.sessionStorage.setItem('courseId', '')
            window.sessionStorage.setItem('courseTypeId', '')
        } else {
            cuisineId = categoryLevel1Id
            window.sessionStorage.setItem('cuisineId', categoryLevel1Id)
            courseId = 0;
            courseTypeId = 0;
            window.sessionStorage.setItem('courseId', '')
            window.sessionStorage.setItem('courseTypeId', '')
            window.sessionStorage.setItem('searchKeyword', '')
            searchKeyword = ''
        }
        let sortBy = (window.sessionStorage.getItem('sortBy') === null ? '' : window.sessionStorage.getItem('sortBy'));
        if (null != searchKeyword && 'null' != searchKeyword && '' != searchKeyword) {
            window.sessionStorage.setItem('searchKeyword', searchKeyword)
            cuisineId = 0;
            courseId = 0;
            courseTypeId = 0;
            sortBy = ''
        }
        var alcoholContent = window.sessionStorage.getItem('alcohol_content')
        if (alcoholContent !== null && alcoholContent !== "") {
            alcoholContent = JSON.parse(alcoholContent)
        }
        else {
            alcoholContent = []
        }
        const postData = {
            "functionName": "rtstoredetails",
            "storeId": storeId,
            "alcoholContent": alcoholContent
        }
        getData(postData).then((data) => {
            console.log("pppppppppppppppppp", data)
            if (data.data.success === "1") {
                this.setState({
                    courseTypeData: data.data.result.coursetypeResult,
                    bannerLogo: data.data.result.storeResult,
                    bannerImage: data.data.result.storeBannerResult
                });
            }
        })
        if (itemId !== '' && itemId !== '0') {
            $("#clearAllFilter").show();
        }
        this.itemListing(storeId, cuisineId, courseId, courseTypeId, sortBy, offset, max, itemId, searchKeyword)
        this.leftMenu(storeId, searchKeyword, '')

        var sellerID = "";
        var moduleID = 2;
        var siteCategoryGroupId = "";

        if (window.sessionStorage.getItem('siteCategoryId') !== "0") {
            siteCategoryGroupId = window.sessionStorage.getItem('siteCategoryId');
        }
        else {
            sellerID = window.sessionStorage.getItem('sellerId')
        }
        pageHitAPICall(sellerID, moduleID, siteCategoryGroupId)
        // Sort by
        $('.sortListDiv ').click(function () {
            $('span').toggleClass('active');
        });
        // Filter
    }

    handlePageChange(pageNumber) {
        debugger
        window.scrollTo(0, 0)
        // let userId = ((localStorage.getItem('userId') != '' && localStorage.getItem('userId') != null || localStorage.getItem('userId') != 'null') ? localStorage.getItem('userId') : '0');
        let storeId = window.sessionStorage.getItem('storeId');
        let cuisineId = (window.sessionStorage.getItem('cuisineId') === null ? '' : window.sessionStorage.getItem('cuisineId'));
        let courseId = (window.sessionStorage.getItem('courseId') === null ? '' : window.sessionStorage.getItem('courseId'));
        let courseTypeId = (window.sessionStorage.getItem('courseTypeId') === null ? '' : window.sessionStorage.getItem('courseTypeId'));
        let sortBy = (window.sessionStorage.getItem('sortBy') === null ? '' : window.sessionStorage.getItem('sortBy'));
        let searchKeyword = (window.sessionStorage.getItem('searchKeyword') === null ? '' : window.sessionStorage.getItem('searchKeyword'));
        var offset;
        var max;
        if (pageNumber == "1") {
            offset = "0";
            max = "45";
            this.setState({
                activePage: pageNumber
            })
        }
        else {
            offset = parseInt(pageNumber - 1) * 45;
            max = "45";
            this.setState({
                activePage: pageNumber
            })
        }
        this.itemListing(storeId, cuisineId, courseId, courseTypeId, sortBy, offset, max, '', searchKeyword)
    }
    clearAllFilter() {
        $(".filterListName").removeClass('filterListActive')
        $(".topCategory").removeClass('tagActive')
        $("#clearAllFilter").hide();
        let storeId = window.sessionStorage.getItem('storeId');
        let offset = 0
        let max = 45
        this.itemListing(storeId, '', '', '', '', offset, max, '', '')
        this.leftMenu(storeId, '', '')
    }
    fliterAccordion(index,categoryType,level1Id,courseId,courseTypeId,sortBy,offset,max,searchKeyword) {
        debugger
        this.categoryFilter(categoryType,level1Id,courseId,courseTypeId,sortBy,offset,max,searchKeyword)
        $("#accordionList" + index).toggleClass("fliterAccordionOpen");
        $("#accordionName" + index).toggleClass("accordionFliterActive");

    }
    filterOpen() {
        $("#fliterCover").toggleClass("fliterMenuOpen")
    }

    render() {
        let themeId = localStorage.getItem('themeId')
        const bannersettings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            arrow: false
        };

        return (
            <div className="coverWraper">

                <div className="contentWrap contentWrapRestaurant">
                    {(() => {
                        if (themeId === '34' || themeId === '29'|| themeId === '4') {
                            return (
                                <Storebanner />
                            )
                        }
                    })()}
                    <div className="itemCoverDiv">
                        <div className={(themeId === '34' || themeId === '29'|| themeId === '4') ? ("container") : (themeId === '32' ? "proWrap" : "")}>
                            <div class="restaurantBanner">
                                {(() => {
                                    if (themeId !== '34' && themeId !== '29' && themeId !== '4') {
                                        return (
                                            <Slider {...bannersettings}>
                                                {this.state.bannerImage.map((bannerImageObject, bannerImageIndex) => (
                                                    <div>
                                                        <div className="restaurantBannerItem">
                                                            <img src={bannerImageObject.image_path} alt="" />
                                                        </div>
                                                    </div>
                                                ))}
                                            </Slider>
                                        )
                                    }
                                })()}
                            </div>
                            <div className="itemListTop itemTopListing">
                                <div className="tagSection">
                                    <span className="tag topCategory topCategoryItemP" onClick={(e) => { this.categoryFilter('', '', '', '', '', '', '', 'P') }}>Popular Items</span>
                                    {this.state.courseTypeData.map((courseTypeDataObject, courseTypeDataIndex) => (
                                        <span className={"tag topCategory topCategoryItem" + courseTypeDataIndex} onClick={(e) => { this.categoryFilter('', '', courseTypeDataObject.courseid, '', '', '', '', courseTypeDataIndex) }}>{courseTypeDataObject.coursename}</span>
                                    ))}
                                    {(() => {
                                        if (themeId === '34' || themeId === '29'|| themeId === '4') {
                                            return (
                                                <span className="tag filterCategoryName" onClick={(e) => this.clearAllFilter()} id="clearAllFilter" style={{ display: 'none' }}>Clear All</span>
                                            )
                                        }
                                    })()}
                                </div>
                                {/* <div className="filterSection">
                                        <div className="sortList">
                                            <div className={this.state.sortActive ? 'sortListDiv sortListActive' : 'sortListDiv'} onClick={(e) => this.sortClick()}>
                                                <span id="sortBy">Sort By</span>
                                                <span onClick={(e) => this.sortListClick('new', 'Newst First')}>Newest First</span>
                                                <span onClick={(e) => this.sortListClick('asc', 'Price - Low to High')}>Price - Low to High</span>
                                                <span onClick={(e) => this.sortListClick('desc', 'Price - High to Low')}>Price - High to Low</span>
                                            </div>
                                        </div>
                                    </div> */}
                            </div>
                            <div className="itemListDiv">
                                <div className="filterSection">
                                    <div className="sortList">
                                        <div className={this.state.sortActive ? 'sortListDiv sortListActive' : 'sortListDiv'} onClick={(e) => this.sortClick()}>
                                            <span id="sortBy">Sort By</span>
                                            <span onClick={(e) => this.sortListClick('new', 'Newst First')}>Newest First</span>
                                            <span onClick={(e) => this.sortListClick('asc', 'Price - Low to High')}>Price - Low to High</span>
                                            <span onClick={(e) => this.sortListClick('desc', 'Price - High to Low')}>Price - High to Low</span>
                                        </div>
                                    </div>
                                </div>
                                {this.state.leftMenuDataArray.length > 0 ?
                                    <div className="itemFilterSection">
                                        <span className="mobileFilter" onClick={this.filterOpen}>Filter <img src={arowdown} alt="" /></span>
                                        <div className="fliterCoverDiv" id="fliterCover" >
                                            {themeId === '32' ?
                                                <div id="accordian">
                                                    {this.state.leftMenuDataArray.map((itemLevelArrayObject, itemLevelArrayIndex) => (
                                                        <span>
                                                            <p class="filter-option-heading" onClick={(e) => { this.categoryFilter(itemLevelArrayObject.categoryType, itemLevelArrayObject.level1Id, '', '', '', '', '', '') }}>
                                                                {itemLevelArrayObject.categoryName}
                                                            </p>
                                                            <ul class="filter-option-content" id="step1">
                                                                {itemLevelArrayObject.levelSub.map((itemLevelArrayObjectSub, itemLevel1Index) => (
                                                                    <span>
                                                                        <p class="filter-option-heading" style={{ padding: '10px 25px' }} onClick={(e) => { this.categoryFilter(itemLevelArrayObjectSub.categoryType, itemLevelArrayObject.level1Id, itemLevelArrayObjectSub.level2Id, '', '', '', '', '') }}>{itemLevelArrayObjectSub.categoryName}</p>
                                                                        <ul class="filter-option-content" id="step1">
                                                                            <li>
                                                                                <ul id="ContentPlaceHolder1_ulFabricFilter" class="filter-wrap" style={{ padding: '10px 20px' }}>
                                                                                    {itemLevelArrayObjectSub.levelSub.map((itemLevel2ArrayObject, itemLevel2Index) => (
                                                                                        <li onClick={(e) => { this.categoryFilter(itemLevel2ArrayObject.categoryType, itemLevel2ArrayObject.level1Id, itemLevel2ArrayObject.level2Id, itemLevel2ArrayObject.level3Id, '', '', '', '') }}> {itemLevel2ArrayObject.categoryName} </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </li>
                                                                        </ul>
                                                                    </span>
                                                                ))}
                                                            </ul>
                                                        </span>
                                                    ))}
                                                </div>
                                                :
                                                <div className="fliterList">
                                                    {this.state.leftMenuDataArray.map((itemLevelArrayObject, itemLevelArrayIndex) => (
                                                        <div>
                                                            {console.log("yvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv",itemLevelArrayObject)}
                                                            {(() => {
                                                                if (themeId === '34' || themeId === '29'|| themeId === '4') {
                                                                    return (
                                                                        <h5 className="filterCategoryName" onClick={(e) => this.fliterAccordion(itemLevelArrayIndex,itemLevelArrayObject.categoryType, itemLevelArrayObject.level1Id, '', '', '', '', '', '')} id={"accordionName" + itemLevelArrayIndex}>{itemLevelArrayObject.categoryName}<img src={arowdown} alt="" /></h5>
                                                                    )
                                                                }
                                                                else {
                                                                    return (
                                                                        <h5 onClick={(e) => { this.categoryFilter(itemLevelArrayObject.categoryType, itemLevelArrayObject.level1Id, '', '', '', '', '', '') }}>{itemLevelArrayObject.categoryName}</h5>
                                                                    )
                                                                }
                                                            })()}
                                                            <ul id={"accordionList" + itemLevelArrayIndex}>
                                                                {itemLevelArrayObject.levelSub.map((itemLevelArrayObjectSub, itemLevel1Index) => (
                                                                    <li>
                                                                          {(itemLevelArrayObjectSub.categoryName === "None") ? "" : 
                                                                        <a class="filterListName" id={"categoryList" + itemLevelArrayObject.level1Id + itemLevelArrayObjectSub.level2Id} href="javascript:void(0);" onClick={(e) => { this.categoryFilter(itemLevelArrayObjectSub.categoryType, itemLevelArrayObject.level1Id, itemLevelArrayObjectSub.level2Id, '', '', '', '', '') }}> {itemLevelArrayObjectSub.categoryName}</a>
                                                                }
                                                                        <ul>
                                                                            {itemLevelArrayObjectSub.levelSub.map((itemLevel2ArrayObject, itemLevel2Index) => (
                                                                                <>
                                                                                {(itemLevel2ArrayObject.categoryName === "None") ? "" : 
                                                                                <li><a class="filterListName" id={"categoryList" + itemLevelArrayObject.level1Id + itemLevelArrayObjectSub.level2Id + itemLevel2ArrayObject.level3Id} href="javascript:void(0);" onClick={(e) => { this.categoryFilter(itemLevel2ArrayObject.categoryType, itemLevel2ArrayObject.level1Id, itemLevel2ArrayObject.level2Id, itemLevel2ArrayObject.level3Id, '', '', '', '') }}> {(itemLevel2ArrayObject.categoryName === "None") ? "" : itemLevel2ArrayObject.categoryName} </a></li>
                                                                            }
                                                                                </>
                                                                           ))}
                                                                        </ul>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    : ''}
                                <div className="itemListSection">
                                    {(() => {
                                        if (this.state.itemListingArray.length > 0) {
                                            return (
                                                <div>
                                                    {this.state.itemListingArray.map((itemListingArrayObject, itemListingArrayIndex) => (
                                                        <div className="productList">
                                                            <div className="productItem restaurantItem">
                                                                {themeId !== '32' ?
                                                                    (itemListingArrayObject.wishListId == 'null' || itemListingArrayObject.wishListId == '' || itemListingArrayObject.wishListId == null) ?
                                                                        <span id={"wishListItemId" + itemListingArrayObject.itemId} className="wishList ">
                                                                            <img src={heart} alt="" onClick={(e) => { this.wishListClick('Add', itemListingArrayObject.itemId, '') }} />
                                                                        </span>
                                                                        :
                                                                        <span id={"wishListItemId" + itemListingArrayObject.itemId} className="wishList wishListActive">
                                                                            <img src={heart} alt="" onClick={(e) => { this.wishListClick('Remove', itemListingArrayObject.itemId, itemListingArrayObject.wishListId) }} />
                                                                        </span>
                                                                    : ""
                                                                }
                                                                {console.log("itemListingArrayObject", itemListingArrayObject)}
                                                                {this.offerText(0, itemListingArrayObject.isoffer, itemListingArrayObject.offertext, itemListingArrayIndex)}
                                                                <span id={`offerSpan${itemListingArrayIndex}`} className="offerText"></span>
                                                                {/* {this.offerText(0, itemListingArrayObject.isoffer, itemListingArrayObject.offertext)} */}
                                                                {/* {itemListingArrayObject.isoffer == "1" ?
                                                                        <span id={'offerSpan'} className="offerText"><img src={offer} alt="" /> {itemListingArrayObject.offertext}</span>
                                                                        : ""} */}


                                                                <div className="productImage">
                                                                    <img src={itemListingArrayObject.imageName} alt="" />
                                                                </div>
                                                                <div className="productDetails">
                                                                    <div className="restaurantFoodType">
                                                                        {(itemListingArrayObject.spicetype == 'Low') ?
                                                                            <span className="spicySec" title="Low Spicy">
                                                                                <img src={chilli} alt="" ></img>
                                                                            </span>
                                                                            :
                                                                            (itemListingArrayObject.spicetype == 'Medium') ?
                                                                                <span className="spicySec" title="Medium Spicy">
                                                                                    <img src={chilli} alt="" ></img>
                                                                                    <img src={chilli} alt="" ></img>
                                                                                </span>
                                                                                :
                                                                                (itemListingArrayObject.spicetype == 'High') ?
                                                                                    <span className="spicySec" title="High Spicy">
                                                                                        <img src={chilli} alt=""></img>
                                                                                        <img src={chilli} alt=""></img>
                                                                                        <img src={chilli} alt=""></img>
                                                                                    </span>
                                                                                    :
                                                                                    ''
                                                                        }
                                                                        {(itemListingArrayObject.category == 'Veg') ?
                                                                            <span className="vegFood" title="vegetarian"></span> :
                                                                            (itemListingArrayObject.category == 'Non Veg') ?
                                                                                <span className="nonVegFood" title="Non-vegetarian"></span> : ''}
                                                                    </div>
                                                                    <div className="productName">
                                                                        <a href="javascript:void(0);" className="elipsis">{itemListingArrayObject.itemName}</a>
                                                                    </div>
                                                                    <div className="productRestaurantDescription">
                                                                        <div className="productDescriptionText">{itemListingArrayObject.itemDescription}</div>
                                                                        <span className="productReadMore" onClick={(e) => this.readMore(itemListingArrayObject.itemId)}>read more</span>
                                                                    </div>
                                                                    <div className="productRestaurantPrice">
                                                                        {(() => {
                                                                            if (itemListingArrayObject.sizeGroup.length > 1) {
                                                                                return (
                                                                                    <select id={"cmbSize" + itemListingArrayObject.itemId} onChange={(e) => this.setId(itemListingArrayObject.itemId, itemListingArrayObject.isoffer, itemListingArrayObject.offertext)}>
                                                                                        {itemListingArrayObject.sizeGroup.map((sizeName, sizeIndex) => (
                                                                                            <option value={sizeName.itemsizname + '-' + sizeName.itemPrice + '-' + sizeName.storeItemSizeId + '-' + sizeName.toppingData.length + '-' + sizeIndex}>{sizeName.itemsizname} - {itemListingArrayObject.currencySymbol} {parseFloat(sizeName.itemMrp).toFixed(window.sessionStorage.getItem('decimalCount'))}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <div>
                                                                                        <span>{itemListingArrayObject.itemsizname} - {itemListingArrayObject.currencySymbol} {parseFloat(itemListingArrayObject.itemMrp).toFixed(window.sessionStorage.getItem('decimalCount'))}
                                                                                        </span>
                                                                                        {itemListingArrayObject.sizeGroup.map((sizeName, sizeIndex) => (
                                                                                            <input type="hidden" id={"cmbSize" + itemListingArrayObject.itemId} value={sizeName.itemsizname + '-' + sizeName.itemPrice + '-' + sizeName.storeItemSizeId + '-' + sizeName.toppingData.length} />
                                                                                        ))}
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })()}
                                                                        <input type="hidden" id={"hidStoreItemSizeId" + itemListingArrayObject.itemId} value={itemListingArrayObject.storeItemSizeId} ></input>
                                                                    </div>
                                                                    <div className="addCartButton">
                                                                        {(itemListingArrayObject.isTopping == true) ?
                                                                            <div>
                                                                                <div className="saveMore" id={"customizeLink" + itemListingArrayObject.itemId} onClick={(e) => this.saveMore(itemListingArrayObject.itemId, itemListingArrayObject.itemMrp)}>Customize</div>
                                                                                <div className="addcartbtn" id={"customizeLinkAddCartBtnStatic" + itemListingArrayObject.itemId}>
                                                                                    <div class="cartButton" onClick={(e) => this.saveMore(itemListingArrayObject.itemId, itemListingArrayObject.itemMrp)}>{Parser(itemListingArrayObject.cartLabel)}</div>
                                                                                </div>
                                                                                <div style={{ display: "none" }} id={"customizeLinkAddCartBtnDynamic" + itemListingArrayObject.itemId}>
                                                                                    <CartBtn cartSize={itemListingArrayObject.cartSize} cartLabel={itemListingArrayObject.cartLabel} isCartItemColorShow={itemListingArrayObject.isCartItemColorShow} isCartItemOpenClass={itemListingArrayObject.isCartItemOpenClass} itemLoad={this.itemLoad} isMultiSize={itemListingArrayObject.sizeGroup.length} storeId={itemListingArrayObject.storeId} itemId={itemListingArrayObject.itemId} customize={itemListingArrayObject.isTopping} index={itemListingArrayObject.itemId + '-' + itemListingArrayObject.storeItemSizeId} itemName={itemListingArrayObject.itemName} itemSize={itemListingArrayObject.itemsizname} itemMrp={itemListingArrayObject.itemMrp} itemImage={itemListingArrayObject.imageName} storeItemSizeId={itemListingArrayObject.storeItemSizeId} itemAddedColour="itemAddedColour" qtyError="qtyError" itemCount="itemCount"></CartBtn>
                                                                                </div>
                                                                            </div>
                                                                            : <CartBtn cartSize={itemListingArrayObject.cartSize} cartLabel={itemListingArrayObject.cartLabel} isCartItemColorShow={itemListingArrayObject.isCartItemColorShow} isCartItemOpenClass={itemListingArrayObject.isCartItemOpenClass} itemLoad={this.itemLoad} isMultiSize={itemListingArrayObject.sizeGroup.length} storeId={itemListingArrayObject.storeId} itemId={itemListingArrayObject.itemId} customize={itemListingArrayObject.isTopping} index={itemListingArrayObject.itemId + '-' + itemListingArrayObject.storeItemSizeId} itemName={itemListingArrayObject.itemName} itemSize={itemListingArrayObject.itemsizname} itemMrp={itemListingArrayObject.itemMrp} itemImage={itemListingArrayObject.imageName} storeItemSizeId={itemListingArrayObject.storeItemSizeId} itemAddedColour="itemAddedColour" qtyError="qtyError" itemCount="itemCount"></CartBtn>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="popupWrap restaurantDetailsPopUp" id={"readmore" + itemListingArrayObject.itemId}>
                                                                <RestaurantDetails itemId={itemListingArrayObject.itemId} itemName={itemListingArrayObject.itemName} itemDescription={itemListingArrayObject.itemDescription} ></RestaurantDetails>
                                                            </div>
                                                            {itemListingArrayObject.sizeGroup.map((toppingsObject, toppingsIndex) => (
                                                                <div>
                                                                    {toppingsObject.toppingData.length > 0 ?
                                                                        <div class="popupWrap customizePopUp" id={"customize" + toppingsObject.storeItemSizeId}>
                                                                            <Customize currencySymbol={itemListingArrayObject.currencySymbol} cartSize={itemListingArrayObject.cartSize} cartLabel={itemListingArrayObject.cartLabel} isCartItemColorShow={itemListingArrayObject.isCartItemColorShow} isCartItemOpenClass={itemListingArrayObject.isCartItemOpenClass} itemLoad={this.itemLoad} isMultiSize={itemListingArrayObject.sizeGroup.length} storeId={itemListingArrayObject.storeId} itemId={itemListingArrayObject.itemId} customize={itemListingArrayObject.isTopping} index={itemListingArrayObject.itemId + '-' + itemListingArrayObject.storeItemSizeId} toppings={toppingsObject.toppingData} storeItemSizeId={toppingsObject.storeItemSizeId} itemSize={toppingsObject.itemsizname} itemName={itemListingArrayObject.itemName} itemPrice={toppingsObject.itemMrp} itemImage={itemListingArrayObject.imageName} itemAddedColour="itemAddedColour" qtyError="qtyError" itemCount="itemCount"></Customize>
                                                                        </div>
                                                                        :
                                                                        ''
                                                                    }
                                                                </div>
                                                            ))}
                                                            {itemListingArrayObject.cartSize > 0 ?
                                                                <div>
                                                                    <div>
                                                                        <div className="itemAdded itemDisplay" id={"itemAddedColour" + parseInt(itemListingArrayObject.itemId)}><span className="itemAddCount" id={"itemCount" + parseInt(itemListingArrayObject.itemId)}>{itemListingArrayObject.cartSize}</span></div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div>
                                                                    <div className="itemAdded" id={"itemAddedColour" + parseInt(itemListingArrayObject.itemId)}><span className="itemAddCount" id={"itemCount" + parseInt(itemListingArrayObject.itemId)}>{ }</span></div>
                                                                </div>}
                                                        </div>
                                                    ))}
                                                    {this.state.rowCount > 45 ?
                                                        <div className="paginationSection" id="divPagination">
                                                            {this.state.divPagination == '1' ? <Pagination
                                                                activePage={this.state.activePage}
                                                                itemsCountPerPage={45}
                                                                totalItemsCount={this.state.rowCount}
                                                                pageRangeDisplayed={10}
                                                                onChange={this.handlePageChange}
                                                            /> : ''}
                                                        </div>
                                                        :
                                                        ""
                                                    }
                                                </div>
                                            )
                                        }
                                        else if (this.state.isData == '0') {
                                            return (
                                                <div className="itemSection productItemListing">
                                                    <div className="no-item-found noDataDiv">
                                                        <img src={lence} alt="" />
                                                        <div className="noDataText"><img src={triangle} alt="" />No item found</div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })()}
                                    <div className="showMoreDiv">
                                        <span className="showMore">Show more</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}