import React, { Component } from 'react'
export default class RegisterText extends Component {
    render() {
        return (
            <div className="lkRegisterSection">
                <div className="container">
                    <div>
                        <div className="lktitleHeading mt-0 mb-5">
                            <h2>Registering with Lokal</h2>
                        </div>
                        <div className="col-md-12 lkRegisterSectionSub">
                            <p className="lkSubHeading">How can I sign up?</p>
                            <p>with basic information  and product details to become a certified Lokal Seller, enabling you to grow your business & expand your reach.</p>
                        </div>
                        <div className="col-md-12 lkRegisterSectionSub">
                            <p className="lkSubHeading">Can I manage my own store?</p>
                            <p>Yes. You can do everything from updating products, prices and related information. In case you wish for us to help manage your platform we will do it for you. Just drop us a line on our Contact us Page and someone will get back to you.</p>
                        </div>
                        <div className="col-md-12 lkRegisterSectionSub">
                            <p className="lkSubHeading">I don’t know technology, How will I manage my online Store?</p>
                            <p>For Lokal, we have a team dedicated to working with our Lokal Sellers. Please drop us a message and someone will get back to you and guide you through the process or easier still, we’ll run your online store for you. All you need to do is simply check your orders and deliver.</p>
                        </div>
                        <div className="col-md-12 lkRegisterSectionSub">
                            <p className="lkSubHeading">How much will it cost me to sell on Lokal?</p>
                            <p>Absolutely Nothing! You only pay when you earn. </p>
                        </div>
                    </div>
                    <div>
                        <div className="lktitleHeading mb-5">
                            <h2>Setting up the online store </h2>
                        </div>
                        <div className="col-md-12 lkRegisterSectionSub">
                            <p className="lkSubHeading">Will I be able to customise my product offering and delivery management rules?</p>
                            <p>Yes. While you are online your business is available 24x7 for orders to come in. However, is system is built to ensure that  you can set your delivery rules, timings, charges and areas of operations as per your convenience so customers are always aware of when you will service their orders.</p>
                        </div>
                        <div className="col-md-12 lkRegisterSectionSub">
                            <p className="lkSubHeading">What Products can I Sell on Lokal?</p>
                            <p>www.Lokal.in is built to connect local Merchants with their own community, At lokal we ensure products for all daily needs categories, such as groceries & staples, nutrition, stationeries, meats etc. Even local cafes and restaurants can sign up, build and customise their menus. </p>
                        </div>
                        <div className="col-md-12 lkRegisterSectionSub">
                            <p className="lkSubHeading">How can I manage my inventory?</p>
                            <p>Lokal is built to serve as an extension to your physical store. We do not replace your Point of Sale or existing Inventory Management Systems. However, for Business Owners who do not have any existing POS system, the platform can handle basic inventory related processes, such as managing stock, enabling, disabling items, editing & updating price, running discounts etc. </p>
                        </div>
                        <div className="col-md-12 lkRegisterSectionSub">
                            <p className="lkSubHeading">I have multiple stores/outlets, will I be able to show them online?</p>
                            <p>Yes. The platform is geared to create and manage multiple stores, if you have them. You will be able to create each outlet differently and manage the different products, inventories etc. for each outlet individually. </p>
                        </div>
                    </div>
                    <div>
                        <div className="lktitleHeading mb-5">
                            <h2>Running my Online Business </h2>
                        </div>
                        <div className="col-md-12 lkRegisterSectionSub">
                            <p className="lkSubHeading">Can I promote my store online?</p>
                            <p>Yes. You can choose from our inbuilt campaign management tools and promote your store via SMS or email. Our platform is also SEO enabled. Also you can choose to connect your online store with Social Media and share & promote your store there. In addition, at Lokal we run camapigns to ensure customer traffic and sales for our merchants. </p>
                        </div>
                        <div className="col-md-12 lkRegisterSectionSub">
                            <p className="lkSubHeading">Will I be able to add, edit and manage my own products?</p>
                            <p>Yes. You can log into your retailer dashboard at any point in time and manage everything from your products & prices, stock and orders as well as managing discounts and promitions. Our managed services model ensures that our customer services executives will also help you manage or run any updates you wish to make to your store and products. </p>
                        </div>
                        <div className="col-md-12 lkRegisterSectionSub">
                            <p className="lkSubHeading">Will I be able to update order status and keep my customers informed?</p>
                            <p>Yes. Our Order Management System, allows you to keep your customers informed at all times. Email & SMS notifications are sent by default at key stages of order processing. All you have to do is update the order status from your dashboard. </p>
                        </div>
                        <div className="col-md-12 lkRegisterSectionSub">
                            <p className="lkSubHeading">Will I be able to run promotions and discounts?</p>
                            <p>Yes. Our in built promotion builder allows you to run promotions, discounts so you can give your customers the best offerings. </p>
                        </div>
                    </div>
                    <div>
                        <div className="lktitleHeading mb-5">
                            <h2>Selling & Delivery </h2>
                        </div>
                        <div className="col-md-12 lkRegisterSectionSub">
                            <p className="lkSubHeading">I am concerned about orders coming from areas that I do not operate in.</p>
                            <p>With the Lokal Platform you can choose the areas you operate in and are comfortable servicing. You can also decide the delivery timings, charges etc. ensuring that you streamline your business operations, and your customers are aware of your service policies. </p>
                        </div>
                        <div className="col-md-12 lkRegisterSectionSub">
                            <p className="lkSubHeading">Will you do deliveries for me?</p>
                            <p>We do not do deliveries for you. At Lokal, we focus on managing technology so your online business can run smoothly and efficiently while you do what you do best, selling, delivering and growing your business. </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}