import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { addToCart } from "./addToCart";
import ProductDefault from "../images/product-default.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import menuClose from "../images/icons/close-sm.png"
import dentalBg from "../images/dental-bg-login.jpg"
import $ from "jquery"
import { getData } from "../services"
export default class globalSearchData extends Component {
    constructor(props) {
        super(props)
        this.state = {
            itemDetails: {},
            breadCrumbDetails: [],
            storeResults: [],
        };

    }
    componentDidMount() {
        $('#GlobalDataPop').hide();
    }
    goProfile(sectionName, slug, itemId, mainCategoryId, mainGroupCategoryId, storeId, storeName) {
        // debugger                     
        window.sessionStorage.setItem('mainCategoryIdLokal', mainCategoryId)
        window.sessionStorage.setItem('mainCategoryId', mainCategoryId)
        window.sessionStorage.setItem('mainGroupCategory', mainGroupCategoryId)
        window.sessionStorage.setItem('storeName', storeName)
        window.sessionStorage.setItem('storeId', storeId)
        if (sectionName === "Grocery") {
            window.location.href = `${process.env.PUBLIC_URL}/${slug}`
        }
        else if (sectionName === "Restaurant") {
            window.location.href = `${process.env.PUBLIC_URL}/restaurantListing/${itemId}/0/`
        }
        else {
            window.location.href = `${process.env.PUBLIC_URL}/cmItemProfile/${itemId}`
        }
    }
    setData(mainCategoryId, mainGroupCategoryId, storeId, storeName) {
        window.sessionStorage.setItem('mainCategoryIdLokal', mainCategoryId)
        window.sessionStorage.setItem('mainCategoryId', mainCategoryId)
        window.sessionStorage.setItem('mainGroupCategory', mainGroupCategoryId)
        window.sessionStorage.setItem('storeName', storeName)
        window.sessionStorage.setItem('storeId', storeId)
    }
    addItemsToCart() {
        addToCart(this.props.dataObject, this.props.index)
        document.getElementById("addedBtn" + this.props.index).style.display = "none";
        document.getElementById("addedText" + this.props.index).style.display = "block";
    }
    addDefaultSrc(ev) {
        ev.target.src = ProductDefault
    }
    prodviewPopUp(data) {
        console.log("dataaaaaaaaaaaaaaa", data)
        this.setState({
            itemDetails: data
        }, () => {
            $('#GlobalDataPop').show();
        });
        // loadData(storeItemStockId) {
        // const postData = {
        //   functionName: "getitemdetailsbyid/getItemDetailsById",
        //   storeItemStockId: storeItemStockId,
        // }
        // console.log("vvvvvvvvvvvvvvvvvvv",postData)
        // getData(postData).then((data) => {
        //     console.log("#################################vvvvvvvvvvvvvvvvvvv",data)
        //   if (data.data.success === "1") {
        //     this.setState({
        //       itemDetails: data.data.result.itemDetail,
        //       breadCrumbDetails: data.data.result.getBreadCrumbData,
        //       storeResults: data.data.result.storeResults,
        //     });
        //    
        //   }
        // })
        //   }
    }
    GlobalDataPopClose() {
        $('#GlobalDataPop').hide();
        this.setState({
            itemDetails: {},
            breadCrumbDetails: [],
            storeResults: [],
        });

    }

    // componentDidMount() {
    //     this.zoomer.addEventListener('mousemove', this.zoom);
    //     this.zoomer.addEventListener('touchmove', this.zoom);
    // }

    // componentWillUnmount() {
    //     this.zoomer.removeEventListener('mousemove', this.zoom);
    //     this.zoomer.removeEventListener('touchmove', this.zoom);
    // }
    // zoom = (e) => {
    //     const zoomer = this.zoomer;
    //     let offsetX, offsetY, x, y;
    //     if (e.offsetX) {
    //         offsetX = e.offsetX;
    //         offsetY = e.offsetY;
    //     } else {
    //         offsetX = e.touches[0].pageX;
    //         offsetY = e.touches[0].pageY;
    //     }
    //     x = offsetX / zoomer.offsetWidth * 100;
    //     y = offsetY / zoomer.offsetHeight * 100;
    //     zoomer.style.backgroundPosition = x + '% ' + y + '%';
    // }
    render() {
        let section_name = this.props.dataObject._source.section_name;
        let slug = this.props.dataObject._source.slug;
        let item_id = this.props.dataObject._source.item_id;
        let maincategoryid = this.props.dataObject._source.maincategoryid;
        let maingroupcategoryid = this.props.dataObject._source.maingroupcategoryid;
        let store_id = this.props.dataObject._source.store_id;
        let store_name = this.props.dataObject._source.store_name;
        let store_slug = this.props.dataObject._source.store_slug
        return (
            <>
                <div className="addMoreItemList">
                    <div className="globalSearchItem">
                        <div className={"globalItemContent glsearchFlexRes " + this.props.extraClassName}>
                            <a onClick={() => localStorage.getItem('hasGlobalSearch') === "false" && this.goProfile(section_name, slug, item_id, maincategoryid, maingroupcategoryid, store_id, store_name)}>
                                <div className="globalItemImage 111">
                                    {console.log("dataObject._source.item_image", this.props.dataObject)}
                                    <img onError={this.addDefaultSrc} src={this.props.dataObject._source.item_image} alt="" style={{ cursor: "pointer" }} onClick={() => this.prodviewPopUp(this.props.dataObject)} />
                                    {/* <span className='ViewMoreIcon'>
                                        <FontAwesomeIcon icon={faEye} title='View Details'
                                            onClick={() => this.prodviewPopUp()}
                                        />
                                    </span> */}

                                </div>
                                <span>
                                    <div className="globalItemName" title={this.props.dataObject._source.item_name} style={{ cursor: "pointer" }} onClick={() => this.prodviewPopUp(this.props.dataObject)}>{this.props.dataObject._source.item_name}</div>
                                    {this.props.dataObject._source.pack_name !== '' && section_name === 'Grocery' ?
                                        <div className="globalItemSize">size :
                                            <span className="sizeEllipsis" title={this.props.dataObject._source.pack_name}>{this.props.dataObject._source.pack_name}</span>
                                        </div>
                                        : ''}
                                    {this.props.dataObject._source.size_name !== '' && section_name === 'Restaurant' ?
                                        <div className="globalItemSize">size :
                                            <span className="sizeEllipsis" title={this.props.dataObject._source.size_name}>{this.props.dataObject._source.size_name}</span>
                                        </div>
                                        : ''}
                                    <div className="globalItemPrice">
                                        {this.props.dataObject._source.currencysymbol}{' '}
                                        {parseFloat(this.props.dataObject._source.net_amount).toLocaleString('en-IN', { minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount') })}
                                    </div>
                                </span>
                            </a>
                            <span className='resCartBtns'>
                                <div id={"addedBtn" + this.props.index} class="cartButton cartButtonSearchHome globalCartButton" onClick={(e) => this.addItemsToCart()}><img src="https://storedigfiles.s3.amazonaws.com/production/themes/cartBtnLokal.png" alt="" /></div>
                                <span id={"addedText" + this.props.index} className="addedText" style={{ display: 'none' }}>Added</span>
                                {(() => {
                                    if (this.props.dataObject._source.section_name === 'Restaurant') {
                                        if (this.props.dataObject._source.coursetypecategory === "Veg") {
                                            return (
                                                <span className="vegIcon"></span>
                                            )
                                        }
                                        else if (this.props.dataObject._source.coursetypecategory === "Non Veg") {
                                            return (
                                                <span className="nonVegIcon"></span>
                                            )
                                        }
                                    }
                                })()}
                            </span>

                        </div>
                        <span className="text-decoration"  >
                            <div className="globalItemStore">
                                <div className="globalItemStoreLogo"><img src={this.props.dataObject._source.sellerlogopath} alt="" /></div>
                                <div className="globalItemStoreDetails">
                                    <strong title={store_name}>{store_name}</strong>
                                    {/* <span>{this.props.dataObject._source.locality}</span> */}
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                {/* Popup begins here */}

                {this.state.itemDetails && this.state.itemDetails._source && (
                    <div className="popupWrap locationPopUpsImg popupWrapoverlay  GlobalDataPopWrp " id="GlobalDataPop"  >
                        <div className="confirmWrappers sizechart-width">
                            <button
                                className='videoPopupClose globalDataPopClose' id="GlobalDataPopClose" onClick={() => this.GlobalDataPopClose()}>
                                <span class="close">
                                    <img src={menuClose} alt="" />
                                </span></button>
                            {this.state.itemDetails && this.state.itemDetails._source && (
                                <>
                                    {console.log("llllllllllllllllll", this.state.itemDetails)}
                                </>
                            )}
                            <div className='dentalprodInfos'>
                                <figure
                                // ref={(ref) => { this.zoomer = ref; }}
                                // className='goOnZoom' 
                                //  style={{ backgroundImage: `url(${dentalBg})` }}
                                >
                                    <img id={"itemImage"} onError={this.addDefaultSrc} src={this.state.itemDetails._source.item_image} />
                                </figure>
                                <div className='goOnZoomInfo'>
                                    <h3>{this.state.itemDetails._source.item_name} </h3>
                                    <p style={{ color: "#099995" }}><b>{this.state.itemDetails._source.brand_name}</b></p>
                                    <div className="d-flex mt-2 mb-2">
                                        <p style={{width:"165px"}}><b>Price</b> : {this.state.itemDetails._source.currencysymbol} {parseFloat(this.state.itemDetails._source.mrp).toLocaleString('en-IN', { minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount') })}</p>
                                        <p><b>Size</b> : {this.state.itemDetails._source.pack_name}</p>
                                    </div>
                                    {this.state.itemDetails._source.description &&
                                    <p><b>Description</b> : {this.state.itemDetails._source.description} </p>}

                                </div>
                            </div>


                        </div>
                    </div>
                )}


            </>
        )
    }
}