import React, { Component } from 'react'
import Slider from "react-slick";
import bannerImg from '../images/lokal-seller/banner.png'
import arrowImg from '../images/lokal-seller/arrow-white.png'
import { Link } from 'react-router-dom';
export default class banner extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 200,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
        };
        return (
            <div className="lkbannerSection">
                <Slider {...settings}>
                <div>
                    <div className="lkbannerItem">
                        <div className="lkbannerImage"><img src={bannerImg} alt="" /></div>
                        <div className="lkbannerContent">
                            <h1>Become a<br></br>
                                Lokal Seller
                            </h1>
                            <p className="lkbannerContent-p">Your Identity. Your Store. Your Products. <br></br>Sell online to your Local community, on your terms. </p>
                            <div className="bannerSignup">
                                <Link to="./signUpAsSeller"><span className="bannerButton mr-3">SIGN UP <img src={arrowImg} alt=""/></span>  </Link>
                                <Link to="/"><span className="bannerButtonBorder">LEARN MORE</span>  </Link>             
                            </div>
                        </div>
                    </div>
                </div>
                </Slider>
                <div className="bannerSignupFixed text-center" style={{backgroundColor:"#feeee2"}}>
                    <p>Your Identity. Your Store. Your Products. <br></br>Sell online to your Local community, on your terms. </p>
                    <Link to="./signUpAsSeller"><span className="bannerButton mr-3">SIGN UP <img src={arrowImg} alt=""/></span>  </Link>
                    <Link to="/"><span className="bannerButtonBorder">LEARN MORE</span>  </Link>          
                </div>          
            </div>
        )
    }
}