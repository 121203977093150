import React, { Component, Suspense } from "react";
import bannerImage from "./assets/images/bannerImage.jpg"
import BorderImg from "./assets/images/separator.svg";
import blog1 from "./assets/images/mraBlog.jpg"
import blog2 from "./assets/images/mraBlog2.jpg"
import blog3 from "./assets/images/mraBlog3.jpg"
import blog4 from "./assets/images/mraBlog4.jpg"
import blog5 from "./assets/images/blogcake.jpg"
import { Link } from 'react-router-dom';
let MraHeader = React.lazy(() => import("./header"));
let MraFooter = React.lazy(() => import("./footer"));
export default class Blogs extends Component {
    render() {
        return (
            <>
                <div className="mraInnerSec">
                    <Suspense fallback={<div></div>}>
                        <div className="coverWraper">
                            <MraHeader />
                            <div className="contentWrap">
                                <div className="bannerImginner">
                                    <img src={bannerImage} alt="" />
                                    <div className="mraTitle">
                                        <div className="contentSectn">
                                            <h1>Blog</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="storeCoverWrap mraAbt">
                                    <div className="container">
                                        <div className="mraTitlesInner mraBlogs mraBlogMain">
                                            <div className="mraTitle">
                                                <span>OUR LATEST</span>
                                                <img src={BorderImg} alt="" />
                                                <div className="contentSectn" style={{ margin: "0px" }}>
                                                    <h1 >Blogs</h1>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 mraBlgSec">
                                                    <div className="mraBlogItem">
                                                        <img src={blog1} alt="" />
                                                    </div>
                                                    <div className="mraBlogTxt">
                                                        <h1>Chaats</h1>
                                                        <h4>MARCH 19, 2022</h4>
                                                        <p>In India, chaat is a word that describes more than just a set of snacks: It’s a way of life, and a category of food that hits practically every element that makes something craveable sweet, sour, tangy, spicy, and crunchy. Chaat can be enjoyed anytime. It’s light enough to be eaten for breakfast or as an afternoon snack, </p>
                                                        {/* <a href="/blogsDetails">Read More</a> */}
                                                        <Link to="/blogsDetails">Read More</Link>
                                                    </div>

                                                </div>
                                                <div className="col-md-4 mraBlgSec">
                                                    <div className="mraBlogItem">
                                                        <img src={blog2} alt="" />
                                                    </div>
                                                    <div className="mraBlogTxt">
                                                        <h1>Catering</h1>
                                                        <h4>MARCH 12, 2022</h4>
                                                        <p>From Birthday parties to Corporate Conferences, Happening@MRA is your one-stop event planner and organiser. Let us be the hosts and deal with the Space and Food.We service cater of all kinds from our central production kitchens,using our fleet of vans to deliver freshly cooked hot and cold food. Our kitchen is built to be fl </p>
                                                        <Link to="/blogDetailsCatering">Read More</Link>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 mraBlgSec">
                                                    <div className="mraBlogItem">
                                                        <img src={blog3} alt="" />
                                                    </div>
                                                    <div className="mraBlogTxt">
                                                        <h1>Bakery Goods    </h1>
                                                        <h4>MARCH 19, 2022</h4>
                                                        <p>A wave of innovation sweeping the bakery market includes a revival of old classics, the rise of the individual portion, and new dining occasions to enjoy them. By working hard to stay bakery experts on ingredients, their functionalities, interactions, and their related processes. The complexity of applying knowledge in the de </p>
                                                        <a href="/blogsDetailsBakery">Read More</a>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mraBlgSec">
                                                    <div className="mraBlogItem">
                                                        <img src={blog4} alt="" />
                                                    </div>
                                                    <div className="mraBlogTxt">
                                                        <h1>Delicious Malabar Snacks </h1>
                                                        <h4>MARCH 19, 2022</h4>
                                                        <p>South Indian cuisine is full of delicacies that are not only laced with spellbinding flavors but also use robust ingredients. Malabar food exhibits a vibrant range of preparations that give great precedence to the coastal bounty of the region and along with vegetarian fare using exotic local produce. It often gets difficult</p>
                                                        <a href="/blogsDetailsSnacks">Read More</a>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mraBlgSec">
                                                    <div className="mraBlogItem">
                                                        <img src={blog5} alt="" />
                                                    </div>
                                                    <div className="mraBlogTxt">
                                                        <h1>Importance of cakes to make any celebrations more special</h1>
                                                        <h4>MARCH 19, 2022</h4>
                                                        <p>A special occasion or celebration cannot imagine without a beautiful cake. A small cake makes any normal party more interesting and spreads happiness everywhere. Everyone spends a lot of time just to find the right or beautiful cake for dear one's special day and also for their special day. It is one of the keys to happiness </p>
                                                        <a href="/blogsDetailsCakes">Read More</a>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <MraFooter />
                        </div>
                    </Suspense>
                </div>
            </>
        )
    }
}