import axios from "axios";
var CryptoJS = require("crypto-js");
const proxyurl = "https://cors-anywhere.herokuapp.com/";
//development only
const awsLambdUrlOld1 = 'https://yp4d64aaig.execute-api.us-west-2.amazonaws.com/dev'
const awsLambdUrlOld = 'https://bwds5ou08h.execute-api.us-west-2.amazonaws.com/dev'
const awsLambdUrl = 'https://bwds5ou08h.execute-api.us-west-2.amazonaws.com/dev'
//const awsLambdUrlReport = 'https://ddbhnfigh3.execute-api.us-west-2.amazonaws.com/PROD' //PROD
const awsLambdUrlReportNew = 'https://bwds5ou08h.execute-api.us-west-2.amazonaws.com/dev'

// // const awsLambdUrlReport = 'https://2hd4acl52d.execute-api.us-west-2.amazonaws.com/PROD'
// // const awsLambdUrlReport1 = 'https://2hd4acl52d.execute-api.us-west-2.amazonaws.com/PROD'
// // const apiUrl = 'https://2hd4acl52d.execute-api.us-west-2.amazonaws.com/PROD/myyshopp'
// // const apiUrl1 = 'https://2hd4acl52d.execute-api.us-west-2.amazonaws.com/PROD/cartitemcommon'

// const awsLambdUrlReport = 'https://oce25gmzf8.execute-api.ap-south-1.amazonaws.com/testProd'
// const awsLambdUrlReport1 = 'https://oce25gmzf8.execute-api.ap-south-1.amazonaws.com/testProd'
// const apiUrl = 'https://oce25gmzf8.execute-api.ap-south-1.amazonaws.com/testProd/myyshopp'
// const apiUrl1 = 'https://oce25gmzf8.execute-api.ap-south-1.amazonaws.com/testProd/cartitemcommon'
// const easyPayURL = 'https://01lrnzzpui.execute-api.ap-south-1.amazonaws.com/default/PaymentGateway'
// const stripeURL = 'https://5prf7l5vs8.execute-api.ca-central-1.amazonaws.com/test/stripe'



const awsLambdUrlReport = 'https://oce25gmzf8.execute-api.ap-south-1.amazonaws.com/PROD_TOKEN'
const awsLambdUrlReport1 = 'https://oce25gmzf8.execute-api.ap-south-1.amazonaws.com/PROD_TOKEN'
const apiUrl = 'https://oce25gmzf8.execute-api.ap-south-1.amazonaws.com/PROD_TOKEN/myyshopp'
const pageHitAPICallUrl = 'https://oce25gmzf8.execute-api.ap-south-1.amazonaws.com/PROD/myyshopp'
const apiUrl1 = 'https://oce25gmzf8.execute-api.ap-south-1.amazonaws.com/PROD_TOKEN/cartitemcommon'
const easyPayURL = 'https://01lrnzzpui.execute-api.ap-south-1.amazonaws.com/default/PaymentGateway'
const stripeURL = 'https://5prf7l5vs8.execute-api.ca-central-1.amazonaws.com/PROD_TOKEN/stripe'

// const awsLambdUrlReport = 'https://oce25gmzf8.execute-api.ap-south-1.amazonaws.com/testProd'
// const awsLambdUrlReport1 = 'https://oce25gmzf8.execute-api.ap-south-1.amazonaws.com/testProd'
// const apiUrl = 'https://oce25gmzf8.execute-api.ap-south-1.amazonaws.com/testProd/myyshopp'
// const pageHitAPICallUrl = 'https://oce25gmzf8.execute-api.ap-south-1.amazonaws.com/testProd/myyshopp'
// const apiUrl1 = 'https://oce25gmzf8.execute-api.ap-south-1.amazonaws.com/testProd/cartitemcommon'
// const easyPayURL = 'https://01lrnzzpui.execute-api.ap-south-1.amazonaws.com/testProd/PaymentGateway'
// const stripeURL = 'https://5prf7l5vs8.execute-api.ca-central-1.amazonaws.com/test/stripe'

//...........do not change.....................////////////////////////// 
const url='https://fdfet8n3ui.execute-api.us-west-2.amazonaws.com/dev/pothys'
/////////////////////////////////////////////////////////////////////////////////


// api call in every page 
export function pageHitAPICall(sellerId,moduleId,siteCategoryGroupId){
    // const postData = {
    //     functionName: "moduleView",
    //     sellerId: sellerId,
    //     moduleId: moduleId,
    //     siteCategoryGroupId: siteCategoryGroupId
    //   }
    //   return axios.post(pageHitAPICallUrl, postData)
    //   .then(data => {
    // })
    //       .then((res) => res)
  }

  export function callStripe(postData) {  
    var tokenData;
    const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY// 32 DIGITS
        // if(!postData == "")
        // {
        //     var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(JSON.stringify(postData)),ENC_KEY).toString();
        //     tokenData = {
        //         "token": ciphertext
        //     }
        // }
        // else{
        //     tokenData = ""
        // } 
    return axios.post(stripeURL, postData)  
    .then(data => {
        let obj = {}
        var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
        var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
        obj = {
            data:decryptedData
        }
        // console.log("decrypted",decryptedData)
        return obj;
    })
        .then((res) => res)
}

// export function getData(postData) {   
//     return axios.post(apiUrl, postData)  
//         .then((data) => data)
//         .then((res) => res)
// }
export function getData(postData) {
    // console.log("postData",postData)
    // var tokenData;
    const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY// 32 DIGITS
        // if(!postData == "")
        // {
        //     var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(JSON.stringify(postData)),ENC_KEY).toString();
        //     tokenData = {
        //         "token": ciphertext
        //     }
        //      console.log(postData,"tokenDatagetdataaaa",tokenData)
        // }
        // else{
        //     tokenData = ""
        // }
    return axios.post(apiUrl, postData)
    .then(data => {
                    let obj = {}
                    var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
                    var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
                    obj = {
                        data:decryptedData
                    }
                    console.log("apiUrl",postData,decryptedData)
                    return obj;
                })
        // .then((data) => data)
        .then((res) => res)
}

export function callEasyPay(postData) { 
    let tokenData;
    const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY// 32 DIGITS
        if(!postData == "")
        {
            var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(JSON.stringify(postData)),ENC_KEY).toString();
            tokenData = {
                "token": ciphertext
            }
        }
        else{
            tokenData = ""
        }   
    return axios.post(easyPayURL, tokenData)  
    .then(data => {
        let obj = {}
        var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
        var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
        obj = {
            data:decryptedData
        }
        // console.log("decrypted",decryptedData)
        return obj;
    })
        .then((res) => res)
}

//***  api for getting cartItems in header
//const cartitemcommonUrl = awsLambdUrl + '/cartitemcommon'
//alert("cartitemcommonUrl..."+cartitemcommonUrl)
export function getCartItemsMultiple(postData) {
    // let tokenData;
    const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY// 32 DIGITS
        // if(!postData == "")
        // {
        //     var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(JSON.stringify(postData)),ENC_KEY).toString();
        //     tokenData = {
        //         "token": ciphertext
        //     }
        //     console.log(tokenData,'postData..getCartItemsMultiple..token', postData)
        // }
        // else{
        //     tokenData = ""
        // }   
    return axios.post(apiUrl1, postData) 
    .then(data => {
        let obj = {}
        var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
        var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
        obj = {
            data:decryptedData
        }
        return obj;
    }) 
        .then((res) => res)
}


// export function stockReport(postData) {
//     return axios.post(stockApiUrl, postData)
//         .then((data) => data)
//         .then((res) => res)
// }

// export function stockNewReports(postData) {
//     return axios.post(stockApiUrlNew, postData)
//         .then((data) => data)
//         .then((res) => res)
// }

export function getSiteDetails(postData) {  
    // let tokenData;
    const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY// 32 DIGITS
        // if(!postData == "")
        // {
        //     var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(JSON.stringify(postData)),ENC_KEY).toString();
        //     tokenData = {
        //         "token": ciphertext
        //     }
        // }
        // else{
        //     tokenData = ""
        // }    
    return axios.post(apiUrl, postData)
    .then(data => {
        let obj = {}
        var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
        var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
        obj = {
            data:decryptedData
        }
        // console.log("decrypted",decryptedData)
        return obj;
    }) 
    .then((res) => res)
}

//***  api for getting city details
const homePageUrl = awsLambdUrl + '/homepagedata?functionName'
export function getCityDetails(sellerId, key) {

    return fetch(homePageUrl + '=getCityDetails&sellerId=' + sellerId + '&cityKeyWord=' + key)

        .then((data) => data.json())
        .then((res) => res)
}

//***  api for getting location details
export function getLocationDetails(sellerId, cityId, key) {
    return fetch(homePageUrl + '=getLocationDetails&sellerId=' + sellerId + '&cityId=' + cityId + '&locationKeyWord=' + key)
        .then((data) => data.json())
        .then((res) => res)
}

//***  api for getting topLocations details

export function getTopLocations(sellerId) {
    return fetch(homePageUrl + '=getTopLocations&sellerId=' + sellerId)
        .then((data) => data.json())
        .then((res) => res)
}

//**api for getting storedetails */
export function getStoreDetails(storeId) {
    var pathhh = homePageUrl + '=getStoreDetails&storeId=' + storeId
    return fetch(homePageUrl + '=getStoreDetails&storeId=' + storeId)   
        .then((data) => data.json())
        .then((res) => res)
}

export function getStoreDetailsById(functionName,storeId) {    
    return fetch(awsLambdUrlOld + '/lokal?functionName=' + functionName+ '&storeId=' + storeId)
        .then((data) => data.json())
        .then((res) => res)
}

//**api for getting storedetails */
export function getBannerDetails(storeId,bannerType) {    
    return fetch(homePageUrl + '=banner&storeId=' + storeId+'&bannerType=' + bannerType)   
        .then((data) => data.json())
        .then((res) => res)
}

//***  api for getting popular items details
const getitemresultUrl = awsLambdUrl + '/getitemresult'
export function getPopularItems(postData) {
    return axios.post(getitemresultUrl, postData)
        .then((data) => data)
        .then((res) => res)
}

//Api For Buyer Address List
// const addressListUrl = awsLambdUrl + '/checkout?functionName'
// console.log("addressListUrl..."+addressListUrl)
// export function getBuyerAddress(buyerId) {
//     console.log("getBuyerAddress..."+addressListUrl + '=getDeliveryAddressList&buyerId=' + buyerId)
//     return fetch(addressListUrl + '=getDeliveryAddressList&buyerId=' + buyerId)
//         .then((data) => data.json())
//         .then((res) => res)

// }

const addressListUrl = awsLambdUrl + '/checkout'
export function getBuyerAddress(postData) {
    return axios.post(addressListUrl, postData)
        .then((data) => data)
        .then((res) => res)
}


/*export function getThemeData(functionName,siteId,themeId) {
    var getThemeResultURL = awsLambdUrl + "/themeList?functionName="+functionName+"&siteId="+siteId+"&themeId="+themeId
    console.log('getThemeResultURL................',getThemeResultURL)
    return fetch(getThemeResultURL)   
        .then((data) => data.json())
        .then((res) => res)
}*/

const getThemeResultURL = awsLambdUrl + '/lokal'
//alert("getThemeResultURL..."+getThemeResultURL)
export function getThemeData(postData) { 
 // alert("postData...."+postData)  
    return axios.post(getThemeResultURL, postData)
    
        .then((data) => data)
        .then((res) => res)
}

// const getLokalDataUrl = awsLambdUrl + '/lokal'
// export function getLokalData(postData) { 
//     console.log("getLokalData.."+getLokalDataUrl, postData)  
//     return axios.post(getLokalDataUrl, postData)
//         .then((data) => data)
//         .then((res) => res)
// }


//***  api for getting popular items details
// export function getitemProfileDetailsbyid(storeItemStockId, functionName, itemId, buyerId, itemCount, cityId, storeId, itemIds) {
//     console.log('getitemdetailsbyid......', awsLambdUrl + '/getitemdetailsbyid?functionName=' + functionName + '&storeItemStockId=' + storeItemStockId + '&itemId=' + itemId + '&buyerId=' + buyerId + '&itemCount=' + itemCount + '&cityId=' + cityId + '&storeId=' + storeId + '&itemIds=' + itemIds)
//     return fetch(awsLambdUrl + '/getitemdetailsbyid?functionName=' + functionName + '&storeItemStockId=' + storeItemStockId + '&itemId=' + itemId + '&buyerId=' + buyerId + '&itemCount=' + itemCount + '&cityId=' + cityId + '&storeId=' + storeId + '&itemIds=' + itemIds)
//         .then((data) => data.json())
//         .then((res) => res)
// }

const getitemdetailsUrl = awsLambdUrl + '/getitemdetailsbyid'
export function getitemProfileDetailsbyid(postData) {
    return axios.post(getitemdetailsUrl, postData)
        .then((data) => data)
        .then((res) => res)
}
//***  api for getting saveMore item details
const getsaveMoreUrl = awsLambdUrl + '/getitemresult'
export function saveMore(postData) {
    return axios.post(getsaveMoreUrl, postData)
        .then((data) => data)
        .then((res) => res)
}



//Api For Delete Buyer Address List
export function deleteBuyerAddress(buyerDeliveryAddressId) {
    return fetch(addressListUrl + '=deleteDeliveryAddress&id=' + buyerDeliveryAddressId)
        .then((data) => data.json())
        .then((res) => res)
}

//Api For get store serving locations

// export function getServingLocations(storeId, cityId) {
//     console.log('sdadsadasdaasda', awsLambdUrl + '/getstoreservinglocations?storeId=' + storeId + '&cityId=' + cityId)
//     return fetch(awsLambdUrl + '/getstoreservinglocations?storeId=' + storeId + '&cityId=' + cityId)
//         .then((data) => data.json())
//         .then((res) => res)
// }
const getservingMoreUrl = awsLambdUrl + '/getstoreservinglocations'
export function getServingLocations(postData) {
    return axios.post(getservingMoreUrl, postData)
        .then((data) => data)
        .then((res) => res)
}





//** api gor login  */

const userloginUrl = awsLambdUrl + '/userlogin'
export function getuserauthentication(postData) {
 // alert("userloginUrl.."+userloginUrl)
    return axios.post(userloginUrl, postData)
        .then((data) => data)
        .then((res) => res)
}

/*export function getuserauthentication(siteId, token) {
    return fetch(awsLambdUrl + '/getuserauthentication?siteId=' + siteId + '&token=' + token)
        .then((data) => data.json())
        .then((res) => res)

}*/



const usersignupUrl = awsLambdUrl + '/usersignup'
export function usersignup(postData) {
    return axios.post(usersignupUrl, postData)
        .then((data) => data)
        .then((res) => res)
}


//** save user */
export function userRegistration(token, siteId, otpFlag,functionName) {
    return fetch(awsLambdUrl + '/userregistration?token=' + token + '&siteId=' + siteId + '&otpFlag=' + otpFlag+ '&functionName=' + functionName, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

    })
        .then((data) => data.json())
        .then((res) => res)
}

export function getItemdetailsbyid(storeItemStockId) {
    return fetch(addressListUrl + '=getCheckOutItemDetails&storeItemStockId=' + storeItemStockId)
        .then((data) => data.json())
        .then((res) => res)
}



//***  api for getting cartItems in header
const getCartDetails = awsLambdUrl + '/cartitem'
export function getCartItems(postData) {
    return axios.post(getCartDetails, postData)
        .then((res) => res)
}



//***  api for saving fblogin/googlelogin
const getsaveUserUrl = awsLambdUrl + '/saveuser'
export function saveUserSocialLogin(postData) {
    return axios.post(getsaveUserUrl, postData)
        .then((data) => data)
        .then((res) => res)
}

//***  api for saving savedeliveryaddress
const savedeliveryaddressUrl = awsLambdUrl + '/savedeliveryaddress'
export function savedeliveryaddress(postData) {
    return axios.post(savedeliveryaddressUrl, postData)
        .then((data) => data)
        .then((res) => res)
}


//tax api
export function getStoreTax(storeId) {
    return fetch(awsLambdUrl + '/storetaxdetails?storeId=' + storeId)
        .then((data) => data.json())
        .then((res) => res)
}


export function getPaymentDetails(storeId) {
    return fetch(addressListUrl + '=getPaymentOptions&storeId=' + storeId)
        .then((data) => data.json())
        .then((res) => res)
}

export function getDeliverySlot(storeId, locationId) {
    return fetch(addressListUrl + '=getDeliverySlots&storeId=' + storeId + '&locationId=' + locationId)
        .then((data) => data.json())
        .then((res) => res)
}

export function getStoreResult(storeId) {
    return fetch(addressListUrl + '=getStoreDetails&storeId=' + storeId)
        .then((data) => data.json())
        .then((res) => res)

}




export function sendMailOrder(postData) {
    //return axios.post('https://u7o349k9t8.execute-api.us-east-1.amazonaws.com/restaurant/sendmailordersummery', postData)
    return axios.post('https://mhflkxjz9a.execute-api.us-east-1.amazonaws.com/test/sendmailordersummery', postData)
    // return fetch(awsLambdUrl+'/sendmail?subject='+subject+'&mail_text='+mail_text+'&to='+to, {
    //method: 'POST',
}

export function sendsuccessmail(postData) {
    //var sms ='https://mhflkxjz9a.execute-api.us-east-1.amazonaws.com/test/sendsuccessmail'

var sms = awsLambdUrl+'/sendsuccessmail'
   // return axios.post('https://u7o349k9t8.execute-api.us-east-1.amazonaws.com/restaurant/sendsuccessmail', postData)
    return axios.post(sms, postData)
    // return fetch(awsLambdUrl+'/sendmail?subject='+subject+'&mail_text='+mail_text+'&to='+to, {
    //method: 'POST',
}

export function savedAmount(inputData) {
    return axios.post(awsLambdUrl + '/cartitem', inputData)
        .then((res) => res)
}



//orederReview


// export function orderReviewResult(saleId, cityId) {
//     console.log('orderReviewResult.......',awsLambdUrl + '/ordersummery?saleId=' + saleId + '&cityId=' + cityId)
//     return fetch(awsLambdUrl + '/ordersummery?saleId=' + saleId + '&cityId=' + cityId)
//         .then((data) => data.json())
//         .then((res) => res)
// }

const getOrderSummaryDetails = awsLambdUrl + '/ordersummery'
export function orderReviewResult(postData) {
    //alert("cartItems............")
//    console.log("cartitem..ressssssss..."+awsLambdUrl + '/ordersummery')
//     console.log('ordersummery.....postData........',postData)
    // let tokenData;
    const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY// 32 DIGITS
        // if(!postData == "")
        // {
        //     var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(JSON.stringify(postData)),ENC_KEY).toString();
        //     tokenData = {
        //         "token": ciphertext
        //     }
        // }
        // else{
        //     tokenData = ""
        // }  
    return axios.post(getOrderSummaryDetails, postData)
    .then(data => {
        let obj = {}
        var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
        var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
        obj = {
            data:decryptedData
        }
        // console.log("decrypted",decryptedData)
        return obj;
    }) 
        .then((res) => res)
}

//***  api for  otp sms
// export function getOTPVerification(siteId, type, mobileNumber, otp, functionName) {
//     console.log(awsLambdUrl + '/userverification?siteIdInSms=' + siteId + '&typeInSms=' + type + '&mobileNumberInSms=' + mobileNumber + '&otpInSms=' + otp + '&functionName=' + functionName)
//     return fetch(awsLambdUrl + '/userverification?siteIdInSms=' + siteId + '&typeInSms=' + type + '&mobileNumberInSms=' + mobileNumber + '&otpInSms=' + otp + '&functionName=' + functionName)
//         .then((data) => data.json())
//         .then((res) => res)
// }

const getotpdetailsURl = awsLambdUrl + '/userverification'
export function getOTPVerification(postData) {
    return axios.post(getotpdetailsURl, postData)
        .then((data) => data)
        .then((res) => res)
}

//**   api for sendmail*/
export function sendMail(subject, mail_text, to) {
    return fetch(awsLambdUrl + '/sendmail?subject=' + subject + '&mail_text=' + mail_text + '&to=' + to, {
        //method: 'POST',
    })
        .then((data) => data.json())
        .then((res) => res)
}
//**   api for forgotPassword*/
// export function forgotPassword(siteId, userInput, functionName) {
//     return fetch(awsLambdUrl + '/userverification?siteIdInForgot=' + siteId + '&userInputInForgot=' + userInput + '&functionName=' + functionName)
//         //method: 'POST',
//         .then((data) => data.json())
//         .then((res) => res)
// }

const forgotdetailsURl = awsLambdUrl + '/userverification'
export function forgotPassword(postData) {
    return axios.post(forgotdetailsURl, postData)
        .then((data) => data)
        .then((res) => res)
}

const cartDetaResult = awsLambdUrl + '/saleconfirm'

export function saleConfirm(postData) {
    return axios.post(cartDetaResult, postData)
        .then((res) => res)
}
const cmSaleCon = awsLambdUrlOld + '/cmsaleconfirmsave'
export function cmSaleConfirmSave(postData) {
    return axios.post(cmSaleCon, postData)
      .then((res) => res)
}

const rtSaleCon = awsLambdUrlOld + '/rtsaleconfirmsave'
export function rtSaleConfirmSave(postData) {
    return axios.post(rtSaleCon, postData)
      .then((res) => res)
}

//***  api for  otp/email/sms in virginia location
const awsLambdForSmsOtpEmailUrl = 'https://u7o349k9t8.execute-api.us-east-1.amazonaws.com/restaurant/'
export function getSmsOtpEmail(siteId, type, mobileNumber, otp) {
    return fetch(awsLambdForSmsOtpEmailUrl + '/otpsmsandemail?siteId=' + siteId + '&type=' + type + '&mobileNumber=' + mobileNumber + '&otp=' + otp)
        .then((data) => data.json())
        .then((res) => res)
}


//**   api for resetPassword*/
// export function resetPassword(userId, confirmPassword, functionName) {
//     return fetch(awsLambdUrl + '/userverification?userIdInResetPwrd=' + userId + '&newPasswordInResetPwrd=' + confirmPassword + '&functionName=' + functionName)
//         .then((data) => data.json())
//         .then((res) => res)
// }


const restpasswordSaveUrl = awsLambdUrl + '/userverification'
export function resetPassword(postData) {
    return axios.post(restpasswordSaveUrl, postData)
        .then((data) => data)
        .then((res) => res)
}

//***  api for saving contactUsItem
const itemContactUsSaveUrl = awsLambdUrl + '/itemcontactussave'
export function itemContactUsSave(postData) {
    return axios.post(itemContactUsSaveUrl, postData)
        .then((data) => data)
        .then((res) => res)
}




// export function getAccountDetails(currentUserId, functionName) {
//     return fetch(awsLambdUrl + '/userdashboarddetails?currentUserId=' + currentUserId + '&functionName=' + functionName)
//         .then((data) => data.json())
//         .then((res) => res)
// }

const accountSaveUrl = awsLambdUrl + '/userdashboarddetails'
export function getAccountDetails(postData) {
    return axios.post(accountSaveUrl, postData)
        .then((data) => data)
        .then((res) => res)
}




export function myOrderSave(orderId) {
    return fetch(awsLambdUrl + '/myordersave?orderId=' + orderId)
       
        .then((data) => data.json())
        .then((res) => res)
}

export function cmMyOrderSave(orderId) {
    return fetch(awsLambdUrlOld + '/cmmyordersave?orderId=' +orderId)
       
        .then((data) => data.json())
        .then((res) => res)
}



const searchresultURL = awsLambdUrl + '/searchresult'
export function searchresult(postData) {
    return axios.post(searchresultURL, postData)
        .then((data) => data)
        .then((res) => res)
}


/*export function searchresultitemlisting(functionName, category1Id, category2Id, category3Id, storeId, sortBy, brandId, minPrice, maxPrice, offset, max, searchKeyword) {
    console.log("searchresultitemlisting......", awsLambdUrl + '/searchresultitemlisting?functionName=' + functionName + '&category1Id=' + category1Id + '&category2Id=' + category2Id + '&category3Id=' + category3Id + '&storeId=' + storeId + '&sortBy=' + sortBy + '&brandId=' + brandId + '&minPrice=' + minPrice + '&maxPrice=' + maxPrice + '&offset=' + offset + '&max=' + max + '&searchKeyword=' + searchKeyword)
    return fetch(awsLambdUrl + '/searchresultitemlisting?functionName=' + functionName + '&category1Id=' + category1Id + '&category2Id=' + category2Id + '&category3Id=' + category3Id + '&storeId=' + storeId + '&sortBy=' + sortBy + '&brandId=' + brandId + '&minPrice=' + minPrice + '&maxPrice=' + maxPrice + '&offset=' + offset + '&max=' + max + '&searchKeyword=' + searchKeyword)
        .then((data) => data.json())
        .then((res) => res)
}*/

//***  api for getting personalInformation

const personalinUrl = awsLambdUrl + '/userdashboarddetails'
export function getpersonalinformation(postData) {
    return axios.post(personalinUrl, postData)
        .then((data) => data)
        .then((res) => res)
}

/*export function getpersonalinformation(currentUserId, functionName) {
    return fetch(awsLambdUrl + '/userdashboarddetails?currentUserId=' + currentUserId + '&functionName=' + functionName)
        .then((data) => data.json())
        .then((res) => res)
}*/

//***  api for updating personalInformation

const saveUserDashboardDetailsUrl = awsLambdUrl + '/saveuserdashboarddetails'
export function updatePersonalInfo(postData) {
    return axios.post(saveUserDashboardDetailsUrl, postData)
        .then((data) => data)
        .then((res) => res)
}



//***  api for Buyer Order Report
// export function getbuyerorder(currentUserId, orderId, textId, fromDateQuery, toDateQuery, statusQuery,functionName) {
//     console.log("getbuyerorder...",awsLambdUrl + '/userdashboarddetails?currentUserId=' + currentUserId + '&orderId=' + orderId + '&textId=' + textId + '&fromDateQuery=' + fromDateQuery + '&toDateQuery=' + toDateQuery + '&statusQuery' + statusQuery+ '&functionName=' + functionName)
//     return fetch(awsLambdUrl + '/userdashboarddetails?currentUserId=' + currentUserId + '&orderId=' + orderId + '&textId=' + textId + '&fromDateQuery=' + fromDateQuery + '&toDateQuery=' + toDateQuery + '&statusQuery' + statusQuery+ '&functionName=' + functionName)
//         .then((data) => data.json())
//         .then((res) => res)
// }

const getBuyerDetailsUrl = awsLambdUrl + '/userdashboarddetails'
export function getbuyerorder(postData) {
    return axios.post(getBuyerDetailsUrl, postData)
        .then((data) => data)
        .then((res) => res)
}

const gettrackOrderUrl = awsLambdUrl + '/userdashboarddetails'
export function trackOrder(postData) {
    return axios.post(gettrackOrderUrl, postData)
        .then((data) => data)
        .then((res) => res)
}






//awsLambdUrlReportNew/cartitemcommon
const getUrlReport = awsLambdUrlReport + '/myshopp'
export function report(postData) {
    // console.log("report..."+getUrlReport)
    // console.log("report1111111111111111111..."+postData)
    // var tokenData;
    const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY// 32 DIGITS
    //     if(!postData == "")
    //     {
    //         var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(JSON.stringify(postData)),ENC_KEY).toString();
    //         tokenData = {
    //             "token": ciphertext
    //         }
    //         console.log(postData,"report1111111111111111111..." , tokenData)
    //     }
    //     else{
    //         tokenData = ""
    //     }   
    return axios.post(getUrlReport, postData)
    .then(data => {
        let obj = {}
        var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
        var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
        obj = {
            data:decryptedData
        }
        // console.log("decrypted",decryptedData)
        return obj;
    }) 
        .then((res) => res)
}
const getUrlReport1 = awsLambdUrlReport1 + '/myyshopp'
export function report1(postData) {
    // console.log("report..."+getUrlReport1)
    // console.log("report1111111111111111111..."+postData)
    // var tokenData;
    const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY// 32 DIGITS
        // if(!postData == "")
        // {
        //     var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(JSON.stringify(postData)),ENC_KEY).toString();
        //     tokenData = {
        //         "token": ciphertext
        //     }
        // }
        // else{
        //     tokenData = ""
        // }   
    return axios.post(getUrlReport1, postData)
    .then(data => {
        let obj = {}
        var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
        var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
        obj = {
            data:decryptedData
        }
        // console.log("decrypted",decryptedData)
        return obj;
    }) 
        .then((res) => res)
}

const getUrlReportNew = apiUrl1 + '/cartitemcommon'
export function reportNew(postData) {
   // console.log("report..."+getUrlReportNew)
    //console.log("report1111111111111111111..."+postData)
    // var tokenData;
    const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY// 32 DIGITS
        // if(!postData == "")
        // {
        //     var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(JSON.stringify(postData)),ENC_KEY).toString();
        //     tokenData = {
        //         "token": ciphertext
        //     }
        // }
        // else{
        //     tokenData = ""
        // }   
    return axios.post(apiUrl1, postData)
    .then(data => {
        let obj = {}
        var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
        var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
        obj = {
            data:decryptedData
        }
        // console.log("decrypted",decryptedData)
        return obj;
    }) 
        .then((res) => res)
}

const updateaccountdetailsURl = awsLambdUrl + '/updateaccountdetails'
export function updateaccountdetails(postData) {
    // console.log("updateaccountdetailsURl..."+updateaccountdetailsURl)
    // console.log("postData..."+postData)
        return axios.post(updateaccountdetailsURl, postData)
        .then((data) => data)
        .then((res) => res)
}

//***  api for updating password(changepassword)

// export function changepassword(userId, oldPassword, confirmPassword, functionName) {
//     return fetch(awsLambdUrl + '/userverification?userIdInChangePwrd=' + userId + '&oldPasswordInChangePwrd=' + oldPassword + '&newPasswordInChangePwrd=' + confirmPassword + '&functionName=' + functionName)
//         .then((data) => data.json())
//         .then((res) => res)
// }


const changePassworddetailsURl = awsLambdUrl + '/userverification'
// console.log("changePassworddetailsURl.."+changePassworddetailsURl)
export function changepassword(postData) {
    // console.log('postData........1111..............', postData)
    return axios.post(changePassworddetailsURl, postData)
        .then((data) => data)
        .then((res) => res)
}



// export function getfeedback(currentUserId, functionName) {
//     console.log("getfeed...."+awsLambdUrl + '/userdashboarddetails?currentUserId=' + currentUserId + '&functionName=' + functionName)
//     return fetch(awsLambdUrl + '/userdashboarddetails?currentUserId=' + currentUserId + '&functionName=' + functionName)
//         .then((data) => data.json())
//         .then((res) => res)
// }

// export function getfeedback(buyerId) {
//     console.log("getfeed...."+awsLambdUrl + '/getfeedback?buyerId=' + buyerId)
//     return fetch(awsLambdUrl + '/getfeedback?buyerId=' + buyerId)
//         .then((data) => data.json())
//         .then((res) => res)
// }

const getFeedBackURl = awsLambdUrl + '/getfeedback '
export function getfeedback(postData) {
    // console.log('postData........1111..............',getFeedBackURl, postData)
    return axios.post(getFeedBackURl, postData)
        .then((data) => data)
        .then((res) => res)
}

export function getaddressdetails(currentUserId) {
    return fetch(awsLambdUrl + '/getaddressdetails?currentUserId=' + currentUserId)
        .then((data) => data.json())
        .then((res) => res)
}

const updateAddressDetailsURl = awsLambdUrl + '/updateaddressdetails '
export function updateaddressdetails(postData) {
    return axios.post(updateAddressDetailsURl, postData)
        .then((data) => data)
        .then((res) => res)
}

const saveFeedbackDetailsUrl = awsLambdUrl + '/updatefeedback'
export function updatefeedback(postData) {
    return axios.post(saveFeedbackDetailsUrl, postData)
        .then((data) => data)
        .then((res) => res)
}


export function restaurantHome(sellerId) {
    return fetch(awsLambdUrlOld + '/restauranthome?sellerId=' + sellerId)
        .then((data) => data.json())
        .then((res) => res)

}

export function rtLeftMenu(storeId) {
    return fetch(awsLambdUrlOld1 + '/rtleftmenu?storeId=' + storeId)
        .then((data) => data.json())
        .then((res) => res)
}

const rtBookYourTableUrl = awsLambdUrlOld + '/rtbookyourtable'
export function rtBookYourTable(postData) {
    return axios.post(rtBookYourTableUrl, postData)
        .then((data) => data)
        .then((res) => res)
}
export function rtStoreDetails(storeId) {
    return fetch(awsLambdUrlOld + '/rtstoredetails?storeId=' + storeId)
        .then((data) => data.json())
        .then((res) => res)

}

// export function rtItemListing(storeId,userId,cuisineId,courseId,courseTypeId,sortBy,offset,max,itemId,selectRestaurantCart){
//       alert("selectRestaurantCart....."+selectRestaurantCart)      
//     if(userId==null || userId=='' || userId=='null')
//     {
//         userId=0
//     }
   
   
//     console.log("rtItemListing..cccc..",awsLambdUrl+'/rtitemlisting?storeId='+storeId+'&userId='+userId+'&cuisineId='+cuisineId+'&courseId='+courseId+'&courseTypeId='+courseTypeId+'&sortBy='+sortBy+'&offset='+offset+'&max='+max+'&itemId='+itemId+'&selectRestaurantCart='+selectRestaurantCart)
//     return fetch(awsLambdUrl+'/rtitemlisting?storeId='+storeId+'&userId='+userId+'&cuisineId='+cuisineId+'&courseId='+courseId+'&courseTypeId='+courseTypeId+'&sortBy='+sortBy+'&offset='+offset+'&max='+max+'&itemId='+itemId+'&selectRestaurantCart='+selectRestaurantCart)
//     .then((data)=>data.json())
//     .then((res)=>res)
// }

const rtItemListingUrl = awsLambdUrlOld + '/rtitemlisting'
export function rtItemListing(postData) {
    return axios.post(rtItemListingUrl, postData)
        .then((data) => data)
        .then((res) => res)
}

const wishListSaveUrl = awsLambdUrlOld + '/wishlistsave'
export function wishListSave(postData) {
    return axios.post(wishListSaveUrl, postData)
        .then((data) => data)
        .then((res) => res)
}

export function removeWishList(wishListId) {
    return fetch(awsLambdUrlOld + '/removewishlist?wishListId=' + wishListId)
        .then((data) => data.json())
        .then((res) => res)
}


// export function rtOrderDetails(orderId){
//     console.log("orderId,,..."+orderId)
//     console.log("rtorderdetails....",awsLambdUrl+'/rtorderdetails?orderId='+orderId)
//     return fetch(awsLambdUrl+'/rtorderdetails?orderId='+orderId)
//     .then((data)=>data.json())
//     .then((res)=>res)

// }

const rtOrderDetailsUrl = awsLambdUrlOld + '/rtorderdetails'
export function rtOrderDetails(postData) {
    return axios.post(rtOrderDetailsUrl, postData)
        .then((data) => data)
        .then((res) => res)
}

//lokal.in homedetails
export function getHomeLokalDetails(functionName,myCategoryId,locationId,categoryId,storeId,sellerId) {
    return fetch(awsLambdUrlOld + '/lokal?functionName=' + functionName+ '&myCategoryId=' + myCategoryId+ '&locationId=' + locationId+ '&categoryId=' + categoryId+ '&storeId=' + storeId+ '&sellerId=' + sellerId)
        .then((data) => data.json())
        .then((res) => res)

}

export function getLokalCityDetails(functionName,myCategoryId,key) {
    return fetch(awsLambdUrlOld + '/lokal?functionName=' + functionName+ '&myCategoryId=' + myCategoryId+ '&key=' + key)
        .then((data) => data.json())
        .then((res) => res)

}

// export function getLokalLocationDetails(functionName,myCategoryId,cityId,key) {
//     console.log("getLokalLocationDetails..."+awsLambdUrlOld + '/lokal?functionName=' + functionName+ '&myCategoryId=' + myCategoryId+ '&cityId=' + cityId + '&locationKeyword=' + key)
//     return fetch(awsLambdUrlOld + '/lokal?functionName=' + functionName+ '&myCategoryId=' + myCategoryId+ '&cityId=' + cityId + '&locationKeyword=' + key)
//         .then((data) => data.json())
//         .then((res) => res)

// }
            
//lokal.in homedetails

export function cmHome(storeId){
    return fetch(awsLambdUrlOld+'/cmhome?storeId='+storeId)
    .then((data)=>data.json())
    .then((res)=>res)

}

export function cmItemListing(storeId,sortBySearch,brandSearch,hidMinSearch,hidMaxSearch,featureSearch,colorSearch,sizeSearch,category1Id,category2Id,category3Id,offset,max){
    return fetch(awsLambdUrlOld+'/cmitemlisting?storeId='+storeId+'&sortBySearch='+sortBySearch+'&brandSearch='+brandSearch+'&hidMinSearch='+hidMinSearch+'&hidMaxSearch='+hidMaxSearch+'&featureSearch='+featureSearch+'&colorSearch='+colorSearch+'&sizeSearch='+sizeSearch+'&category1Id='+category1Id+'&category2Id='+category2Id+'&category3Id='+category3Id+'&offset='+offset+'&max='+max)
    .then((data)=>data.json())
    .then((res)=>res)

}

export function cmItemProfile(cmItemId,cmStoreId){
    return fetch(awsLambdUrlOld+'/cmitemprofile?cmItemId='+cmItemId+ '&cmStoreId=' + cmStoreId)
    .then((data)=>data.json())
    .then((res)=>res)


}

//export function getWishList(currentUserId, functionName){
  //  console.log("currentUserId,,..."+currentUserId)
  //  console.log("getWishList....",awsLambdUrlOld+'/userdashboarddetails?currentUserId=' + currentUserId + '&functionName=' + functionName)
  //  return fetch(awsLambdUrlOld+'/userdashboarddetails?currentUserId=' + currentUserId + '&functionName=' + functionName)
  //  .then((data)=>data.json())
  //  .then((res)=>res)
//}

const getWishListUrl = awsLambdUrl + '/userdashboarddetails'
export function getWishList(postData) {
    return axios.post(getWishListUrl , postData)
        .then((data) => data)
        .then((res) => res)
}

const slotUrl = awsLambdUrl + '/getstorerelateddata'
export function getStoreDeliverySlot(postData) {
    return axios.post(slotUrl, postData)
        .then((data) => data)
        .then((res) => res)
}

const configurationUrl = awsLambdUrl + '/getstorehomedata'
export function getConfiguration(postData) {    
    return axios.post(configurationUrl, postData)
        .then((data) => data)
        .then((res) => res)
}

export function getOrderItemList(postData) {
    return axios.post(url, postData)
        .then((data) => data)
        .then((res) => res)
}

export function getOrderItemDetails(postData) {
    return axios.post(url, postData)
        .then((data) => data)
        .then((res) => res)
}

export function getSellerStores(postData) {
    return axios.post(url, postData)
        .then((data) => data)
        .then((res) => res)
}


export function viewOrder(postData) {
    
    return axios.post(url, postData)
        .then((data) => data)
        .then((res) => res)
}

export function saveStatus(postData) {
    
    return axios.post(url, postData)
        .then((data) => data)
        .then((res) => res)
}


const getCmDeliverySlotUrl = awsLambdUrl + '/checkout'
export function getCmDeliverySlot(postData) {
    return axios.post(getCmDeliverySlotUrl, postData)
        .then((data) => data)
        .then((res) => res)
}

const promocodeData = awsLambdUrlOld + '/promocode'

export function promocodeUrl(postData) {
    return axios.post(promocodeData, postData)
    
        .then((data) => data)
        .then((res) => res)
}
const stateReport = awsLambdUrlReport + '/myshopp'
export function stateDetails(postData) {
   //console.log("report..."+getUrlReport)
    //console.log("report1111111111111111111..."+postData)
    return axios.post(stateReport, postData)
        .then((data) => data)
        .then((res) => res)
}
const storeReport = awsLambdUrlReport + '/myshopp'
export function storeDetails(postData) {
   // console.log("report..."+getUrlReport)
    //console.log("report1111111111111111111..."+postData)
    return axios.post(storeReport, postData)
        .then((data) => data)
        .then((res) => res)
}

const saverazorpaydetailsUrl = awsLambdUrl + '/saverazorpaydetails'
export function saveRazorPay(postData) {
   // console.log("saverazorpaydetailsUrl..."+saverazorpaydetailsUrl,postData)
    return axios.post(saverazorpaydetailsUrl, postData)
        .then((data) => data)
        .then((res) => res)
}
