import React, { Component,Suspense } from 'react'
import Checkout from './checkout'
import Shipping from './shipping'
import Success from './success'
// import OrdersummarySuccess from './orderSummarySucess'
import { getData } from '../services'
import { browserName } from "react-device-detect";
let MainHeader = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/header"));
let MainFooter = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/footer"));
export default class Ordersummary extends Component {
    componentDidMount() {
        window.sessionStorage.setItem('browserName', browserName)
        const postData = {
            "functionName": "getStoreHomeData/configuration",
            "configKey": "WorldlineUrl"
        }
        getData(postData).then((data) => {
            if (data.data.success === "1") {
                window.sessionStorage.setItem('WorldlineUrl', data.data.result[0].config_value)
            }
        });
    }
    render() {
        let themeId = localStorage.getItem('themeId')
        let fromPageVal = '';
        if (typeof this.props.location.state !== "undefined") {
            const { fromPage } = this.props.location.state;
            fromPageVal = fromPage
        }
        return (
            <div className="coverWraper">
                <Suspense fallback={<div></div>}>
                <MainHeader></MainHeader>
                <div className={themeId === "38" ? "schoolUniforOrdersmmry":  ""}>
                <div className={themeId === "28" ? "contentWrap clContentWrap":  "contentWrap"}>
                    <div className={themeId === "29"? "MraOrderSummarySection orderSummarySection": "orderSummarySection" }>
                        <div className="checkoutTitle" id="checkOutHeadingDiv">Proceed to checkout</div>
                        <div className="orderIndication">
                            <div id="checkOutStep1" className="orderIndicationBar orderIndicationActive">
                                <span className="orderIndicationCircle"><span></span></span>
                            </div>
                            <div id="checkOutStep2" className="orderIndicationBar">
                                <span className="orderIndicationCircle"><span></span></span>
                            </div>
                            <div id="checkOutStep3" className="orderIndicationBar">
                                <span className="orderIndicationCircle"><span></span></span>
                            </div>
                            <div id="checkOutStep4" className="orderIndicationBar">
                                <span className="orderIndicationCircle"><span></span></span>
                            </div>
                        </div>
                        <Checkout fromPage={fromPageVal} />
                        <Shipping />
                        {/* <OrdersummarySuccess /> */}
                    </div>
                </div>
                </div>
                <MainFooter />
                </Suspense>
            </div>
        )
    }
}