import React, { Component,Suspense } from 'react';
import StarRatings from "react-star-ratings"
import { report } from '../services';
import $ from "jquery";
import Leftmenu from "../dashboard/dashboardMenu"
let MainHeader = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/header"));
let MainFooter = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/footer"));
export default class feedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userFeedOrderDetails: [],
            userFeedbackDetails: [],
            buyerName: '',
            buyerTelephone: '',
            buyerEmail: '',
            ratingPunctuality: 0,
            ratingItemFreshness: 0,
            ratingItemWeight: 0,
            ratingDeliveryCourtesy: 0,
            ratingOverall: 0,
            orderId: ''
        };
        this.savefeedback = this.savefeedback.bind(this);
        this.ratingPunctuality = this.ratingPunctuality.bind(this);
        this.ratingItemFreshness = this.ratingItemFreshness.bind(this);
        this.ratingItemWeight = this.ratingItemWeight.bind(this);
        this.ratingDeliveryCourtesy = this.ratingDeliveryCourtesy.bind(this);
        this.overallRating = this.overallRating.bind(this);
    }
    onStoreTextChanged() {
        var NewId = $("#txtOrderId").val()
        $("#txtStoreName").text(NewId.split('-')[1])
    }
    savefeedback() {
        let buyerId = localStorage.getItem('userId');
        let storeId = localStorage.getItem('storeId');
        let orderId = this.state.orderId
        var comments = $("#txtComments").val()
        const postData = {
            functionName: "updatefeedback",
            buyerId: buyerId,
            comments: comments,
            createdBy: buyerId,
            createdOn: new Date(),
            modifedBy: buyerId,
            deliveryCourtsey: this.state.ratingDeliveryCourtesy,
            itemFreshness: this.state.ratingItemFreshness,
            itemWeight: this.state.ratingItemWeight,
            overallRating: this.state.ratingOverall,
            punctuality: this.state.ratingPunctuality,
            saleOrderId: orderId,
            storeId: storeId
        }
        report(postData).then((data) => {
            alert(" success")
            window.location.reload();
        });
    }
    componentDidMount() {
        const { match: { params } } = this.props;
        let orderId = params.orderId
        $(".dashboardMenuLink").removeClass("dashboardActive");
        $("#feedbackLink").addClass("dashboardActive");
        let buyerId = localStorage.getItem('userId');
        const postData = {
            functionName: "feedBack",
            buyerId: buyerId
        }
        report(postData).then((data) => {
            console.log("xxxxxxxxxxxxxxxxxxx",data)
            if (data.data.success === "1") {
                this.setState({
                    buyerName: data.data.result.userFeedbackDetails[0].buyerName,
                    buyerTelephone: data.data.result.userFeedbackDetails[0].buyerTelephone,
                    buyerEmail: data.data.result.userFeedbackDetails[0].buyerEmail,
                    storeName: data.data.result.userFeedbackDetails[0].storeName,
                    userFeedOrderDetails: data.data.result.userOrderDetails,
                    orderId: orderId
                });
            }
        })
    }
    ratingPunctuality(rate, name) {
        this.setState({
            ratingPunctuality: rate
        });
    }
    ratingItemFreshness(rate, name) {
        this.setState({
            ratingItemFreshness: rate
        });
    }
    ratingItemWeight(rate, name) {
        this.setState({
            ratingItemWeight: rate
        });
    }
    ratingDeliveryCourtesy(rate, name) {
        this.setState({
            ratingDeliveryCourtesy: rate
        });
    }
    overallRating(rate, name) {
        this.setState({
            ratingOverall: rate
        });
    }
    render() {
        let themeId = localStorage.getItem('themeId')
        const { Punctuality, itemFreshness, itemWeight, overallRating, deliveryCourtesy } = this.state;
        return (
            <div> 
                <div className={themeId === "28" ? "accountdetails claccountdetails": "accountdetails"}>
                <Suspense fallback={<div></div>}>
                <MainHeader></MainHeader>
                 <div className="dashboardmenu dashboardMenuWeb"><Leftmenu></Leftmenu></div> 
                    {/*  */}
                    <div className='dashboardContent'>
                    <div className="dashTitle">Feedback</div>
                    <div className="dashSection">
                        <div className="addressForm">
                            <div>
                                <div className="adressFormList">
                                    <label className="labelField">Your Name</label>
                                    <div className="formInputSection">
                                        <div >{this.state.buyerName}</div>
                                    </div>
                                </div>
                                <div className="adressFormList">
                                    <label className="labelField">Phone Number</label>
                                    <div className="formInputSection">
                                        <div className="formInputText">{this.state.buyerTelephone}</div>
                                    </div>
                                </div>
                                <div className="adressFormList">
                                    <label className="labelField">Email Address</label>
                                    <div className="formInputSection">
                                        <div className="formInputText">{this.state.buyerEmail}{this.state.userFeedOrderDetails.orderId}</div>
                                    </div>
                                </div>
                                <div className="adressFormList">
                                    <label className="labelField">Order<sup>*</sup></label>

                                    {this.state.orderId === null && this.state.orderId === '' && this.state.orderId === 'null' ?
                                        <div className="formInputSection">
                                            <select className="formInputField" id="txtOrderId" onChange={(e) => this.onStoreTextChanged()} tabindex='1'>
                                                <option value="">Order Number</option>
                                                {this.state.userFeedOrderDetails.map((userFeedOrderDetails, userFeedOrderDetailsIndex) => (
                                                    <option value={userFeedOrderDetails.orderId + '-' + userFeedOrderDetails.storeName}>{userFeedOrderDetails.orderId}</option>
                                                ))}
                                            </select>
                                        </div>
                                        :
                                        <div className="formInputSection">
                                            <div className="formInputText" id="txtOrderId">{this.state.orderId}</div>
                                        </div>
                                    }
                                </div>
                                <div className="adressFormList">
                                    <label className="labelField">Store<sup>*</sup></label>
                                    <div className="formInputSection">
                                        <div className="formInputText" id="txtStoreName">{this.state.storeName}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="adressFormList">
                                <label className="labelField">Punctuality</label>
                                <div className="formInputSection">
                                    <div className="starRating" value={Punctuality} id="txtpunct" >
                                        <StarRatings
                                            rating={this.state.ratingPunctuality}
                                            starRatedColor="#ffda42"
                                            starEmptyColor="#dddddd"
                                            starHoverColor="#ffda42"
                                            changeRating={this.ratingPunctuality}
                                            numberOfStars={5}
                                            name='rating'
                                            starDimension="20px"
                                            starSpacing="0px"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="adressFormList">
                                <label className="labelField">Item Freshness</label>
                                <div className="formInputSection">
                                    <div className="starRating" value={itemFreshness} id="txtitemFre" >
                                        <StarRatings
                                            rating={this.state.ratingItemFreshness}
                                            starRatedColor="#ffda42"
                                            starEmptyColor="#dddddd"
                                            starHoverColor="#ffda42"
                                            changeRating={this.ratingItemFreshness}
                                            numberOfStars={5}
                                            name='rating'
                                            starDimension="20px"
                                            starSpacing="0px"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="adressFormList">
                                <label className="labelField">Item Weight</label>
                                <div className="formInputSection">
                                    <div className="starRating" value={itemWeight} id="txtitemweight">
                                        <StarRatings
                                            rating={this.state.ratingItemWeight}
                                            starRatedColor="#ffda42"
                                            starEmptyColor="#dddddd"
                                            starHoverColor="#ffda42"
                                            changeRating={this.ratingItemWeight}
                                            numberOfStars={5}
                                            name='rating'
                                            starDimension="20px"
                                            starSpacing="0px"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="adressFormList">
                                <label className="labelField">Delivery Courtesy</label>
                                <div className="formInputSection">
                                    <div className="starRating" value={deliveryCourtesy} id="txtdelivery">
                                        <StarRatings
                                            rating={this.state.ratingDeliveryCourtesy}
                                            starRatedColor="#ffda42"
                                            starEmptyColor="#dddddd"
                                            starHoverColor="#ffda42"
                                            changeRating={this.ratingDeliveryCourtesy}
                                            numberOfStars={5}
                                            name='rating'
                                            starDimension="20px"
                                            starSpacing="0px"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="adressFormList">
                                <label className="labelField">Overall Rating</label>
                                <div className="formInputSection">
                                    <div className="starRating" value={overallRating} id="txtoverall">
                                        <StarRatings
                                            rating={this.state.ratingOverall}
                                            starRatedColor="#ffda42"
                                            starEmptyColor="#dddddd"
                                            starHoverColor="#ffda42"
                                            changeRating={this.overallRating}
                                            numberOfStars={5}
                                            name='rating'
                                            starDimension="20px"
                                            starSpacing="0px"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="adressFormList">
                                <label className="labelField">Comments....</label>
                                <div className="formInputSection">
                                    <textarea className="formInputTextarea" id="txtComments" tabindex='2'></textarea>
                                </div>
                            </div>
                            <div className="adressFormButton adressButtonTop">
                                <span class="commonButton" tabindex='3' id="btnSaveFeedback" onClick={(e) => { return this.savefeedback(this) }}>Submit</span>
                            </div>
                        </div>
                    </div>
                    </div>
                    <MainFooter></MainFooter>
                    </Suspense>
                </div>
                
            </div>
        )
    }
}
////////////////////////////////needed later
