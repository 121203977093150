import React, { Component } from 'react';
import close from "../images/icons/close.png"
import $ from "jquery";
export default class customize extends Component {
  constructor(props) {
    super(props);
    this.state = {          
    };
}
closeReadPopup(itemId){
  $("#readmore"+itemId).hide();
}
  render() {
    return (
      <div className="customize">
          <div className="popOverlay"></div>         
          <div className="loginPop popMediumDiv">
            <div className="loginHead">
                <span className="saveClose" onClick={(e)=>this.closeReadPopup(this.props.itemId)}><img src={close} alt="" /></span>
                <div className="loginText">{this.props.itemName}</div>
              </div>
              <div className="saveMoreContent customizeContent">
                  <div className="restaurantText">{this.props.itemDescription}</div>
              </div>                 
          </div>          
      </div>
    )
  }
}
