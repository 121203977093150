import React, { Component,Suspense } from 'react';
import Leftmenu from "../dashboard/dashboardMenu"
import Accountdetails from "./accountdetails"
import { Route } from 'react-router-dom'
import Addressdetails from './addressdetails';
import myorders from './myorders';
import personal from './personal';
import changepassword from './changepassword';
import feedback from './feedback';
import trackorder from './trackorder';
import wishlist from './wishlist';
import reorder from './reorder';
// import MainHeader from "../headerComponents/mainHeader"
// import MainFooter from "../headerComponents/mainFooter"
let MainHeader = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/header"));
let MainFooter = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/footer"));
export default class dashboard extends Component {
  constructor(props) {
    super(props)
    this.dashMenuClick = this.dashMenuClick.bind(this);
    this.dashOutsideClick = this.dashOutsideClick.bind(this);
    this.state = {
      dashmenuVisible: false
    };
  }
  dashMenuClick() {
    if (!this.state.dashmenuVisible) {
      document.addEventListener('click', this.dashOutsideClick, false);
    } else {
      document.removeEventListener('click', this.dashOutsideClick, false);
    }
    this.setState(prevState => ({
      dashmenuVisible: !prevState.dashmenuVisible,
    }));
  }
  dashOutsideClick(e) {
    if (this.dashboardNode) {
      if (this.dashboardNode.contains(e.target)) {
        return;
      }
      this.dashMenuClick();
    }
  }
  render() {
    // let siteLokalDetails = window.sessionStorage.getItem('siteLokalDetails');
    // var siteDetailsLokal = JSON.parse(siteLokalDetails);
    let themeId = localStorage.getItem('themeId') 
    return (
      <div className={themeId === "29" ? "coverWraper mraCoverWraper": "coverWraper"}>
          <Suspense fallback={<div></div>}>
        <MainHeader></MainHeader>
      
        <div className="contentWrap " style={{paddingTop:themeId === '38'&&'0px'}}>
          <div className={themeId === "28" ? "dashboardWrap clDashboardWrap" :"dashboardWrap"}>
            <span className="dashboardToggle" onClick={this.dashMenuClick} ref={dashboardNode => { this.dashboardNode = dashboardNode; }}>menu</span>
            <div className="dashboardmenu dashboardMenuWeb"><Leftmenu></Leftmenu></div>
            {this.state.dashmenuVisible && (
              <div className="dashboardmenu dashBoardMenuMobile"><Leftmenu></Leftmenu></div>
            )}
            <div className="dashboardContent">
              <Route path="/dashboard/accountdetails" component={Accountdetails} />
              <Route path="/dashboard/addressdetails" component={Addressdetails} />
              <Route path="/dashboard/personal" component={personal} />
              <Route path="/dashboard/changepassword" component={changepassword} />
              <Route path="/dashboard/feedback" component={feedback} />
              <Route path="/dashboard/trackorder" component={trackorder} />
              <Route path="/dashboard/myorders" component={myorders} />
              <Route path="/dashboard/wishlist" component={wishlist} />
              <Route path="/dashboard/reorder/:orderId?/:mainCategoryGroupId" component={reorder} />
            </div>
          </div>
        </div>
      
        
        <MainFooter></MainFooter>
        </Suspense>
      </div>
    )
  }
}
