export function addToCart(obj,index){   
    if(obj._source.section_name === "Grocery"){
        updateCart(obj._source.item_name, obj._source.item_id, "1", obj._source.net_amount, obj._source.store_item_stock_id, obj._source.pack_id, false, obj._source.stock_quantity, obj._source.item_image, index, obj._source.store_id.toString())
    }
    else if(obj._source.section_name === "Restaurant"){
        updateCartRestaurant(obj._source.store_id, obj._source.item_id, obj._source.item_name, "1", obj._source.size_name, obj._source.net_amount, obj._source.item_image, obj._source.storeitemsize, false, '')
    }
    else{
        updateCartCommonItem(obj._source.store_id, obj._source.item_id, obj._source.item_name, "1", obj._source.item_title, obj._source.brand_name, obj._source.size_name, obj._source.size_id, obj._source.cm_item_size_id, obj._source.item_master_id, obj._source.net_amount, obj._source.mrp, obj._source.item_image, false)
    }   
    setCartCount();
}

export function setCartCount(){
    let cartSize = 0;
    if (JSON.parse(localStorage.getItem('selectedCartProduct')) != null) {
        cartSize = JSON.parse(localStorage.getItem('selectedCartProduct')).length
    }
    if (JSON.parse(localStorage.getItem('selectRestaurantCart')) != null) {
        cartSize = cartSize + JSON.parse(localStorage.getItem('selectRestaurantCart')).length
    }
    if (JSON.parse(localStorage.getItem('selectCommonItemCart')) != null) {
        cartSize = cartSize + JSON.parse(localStorage.getItem('selectCommonItemCart')).length
    }  
    document.getElementById("cartCount").textContent = cartSize;
}


export function updateCart(item_names, item_ids, quantity, item_prices, store_item_stock_ids, pack_ids, isDelete, stock_quantity, itemImage, cartItemNo, storeId) {
    var selectedCartProduct = JSON.parse(localStorage.getItem('selectedCartProduct')) || []
    if (localStorage.getItem('selectedCartProduct') == null) {
        if (isDelete == false || quantity > 0) {
            var selectedProduct = {
                storeId: storeId,
                item_names: item_names,
                item_ids: item_ids,
                quantity: quantity,
                item_prices: item_prices,
                store_item_stock_ids: store_item_stock_ids,
                pack_ids: pack_ids,
                stock_quantity: stock_quantity,
                item_image: itemImage,
                itemNo: cartItemNo
            }
            selectedCartProduct.push(selectedProduct)            
            localStorage.setItem('selectedCartProduct', JSON.stringify(selectedCartProduct))
        }
    }
    else {
        var selectedCartProduct = JSON.parse(localStorage.getItem('selectedCartProduct')) || []
        var cookieNewValue = [];
        var cookieArray = selectedCartProduct;
        var cookieUpdated = false
        var cookieNewValueCount = 0;
        for (var i = 0; i < cookieArray.length; i++) {
            if (cookieArray[i] != null && cookieArray[i] != "") {
                var cookieStoreId = cookieArray[i].storeId
                var cookieItemId = cookieArray[i].item_ids
                if (item_ids == cookieItemId && storeId == cookieStoreId) {
                    if (isDelete == false && quantity > 0) {
                        cookieNewValue[cookieNewValueCount] =
                            {
                                storeId: storeId,
                                item_names: item_names,
                                item_ids: item_ids,
                                quantity: quantity,
                                item_prices: item_prices,
                                store_item_stock_ids: store_item_stock_ids,
                                pack_ids: pack_ids,
                                stock_quantity: stock_quantity,
                                item_image: itemImage,
                                itemNo: cartItemNo

                            }
                        cookieNewValueCount++;
                    }
                    cookieUpdated = true
                }
                else {
                    cookieNewValue[cookieNewValueCount] = cookieArray[i];
                    cookieNewValueCount++;
                }
            }
        }
        if (cookieUpdated == false) {
            if (quantity > 0) {
                cookieNewValue[cookieNewValueCount] = {
                    storeId: storeId,
                    item_names: item_names,
                    item_ids: item_ids,
                    quantity: quantity,
                    item_prices: item_prices,
                    store_item_stock_ids: store_item_stock_ids,
                    pack_ids: pack_ids,
                    stock_quantity: stock_quantity,
                    item_image: itemImage,
                    itemNo: cartItemNo
                }
                cookieUpdated = true
            }
        }
        localStorage.setItem('selectedCartProduct', JSON.stringify(cookieNewValue))
    }    
}


export function updateCartRestaurant(storeId, itemId, itemName, quantity, itemSize, itemMrp, itemImage, storeItemSizeId, isDelete, toppingsData) {
    let itemNo = itemId + '-' + storeItemSizeId    
    var selectRestaurantCart = JSON.parse(localStorage.getItem('selectRestaurantCart')) || []
    if (localStorage.getItem('selectRestaurantCart') == null) {
        if (isDelete == false || quantity > 0) {
            var selectedRestaurantProduct = {
                storeId: storeId,
                itemId: itemId,
                itemName: itemName,
                quantity: quantity,
                itemSize: itemSize,
                itemMrp: itemMrp,
                itemImage: itemImage,
                storeItemSizeId: storeItemSizeId,
                itemNo: itemNo,
                toppingsData: toppingsData
            }
            selectRestaurantCart.push(selectedRestaurantProduct)
            localStorage.setItem('selectRestaurantCart', JSON.stringify(selectRestaurantCart))
        }
    }
    else {
        var cookieNewRestaurantValue = [];
        var cookieRestaurantArray = selectRestaurantCart;
        var cookieRestaurantUpdated = false
        var cookieRestaurantNewValueCount = 0;
        for (var i = 0; i < cookieRestaurantArray.length; i++) {
            if (cookieRestaurantArray[i] != null && cookieRestaurantArray[i] != "") {
                var cookieStoreId = cookieRestaurantArray[i].storeId
                var cookieItemId = cookieRestaurantArray[i].itemId
                var cookieSizeId = cookieRestaurantArray[i].storeItemSizeId
                var toppingsDataFromCart = cookieRestaurantArray[i].toppingsData
                var newTopping = false
                if (null != toppingsData && 'null' != toppingsData && '' != toppingsData) {
                    var toppingsDataFromCartSplit = toppingsData.split(',')
                     for (var t = 0; t < toppingsDataFromCartSplit.length; t++) {
                        if (toppingsDataFromCart.indexOf(toppingsDataFromCartSplit[t]) == '-1') {
                            newTopping = true
                        }                       
                    }
                }
                else {
                    if (null != toppingsDataFromCart && 'null' != toppingsDataFromCart && '' != toppingsDataFromCart) {
                        newTopping = true
                    }
                }
                if (itemId == cookieItemId && storeId == cookieStoreId && storeItemSizeId == cookieSizeId && newTopping == false) {
                    if (isDelete == false && quantity > 0) {
                        cookieNewRestaurantValue[cookieRestaurantNewValueCount] =
                            {
                                storeId: storeId,
                                itemId: itemId,
                                itemName: itemName,
                                quantity: quantity,
                                itemSize: itemSize,
                                itemMrp: itemMrp,
                                itemImage: itemImage,
                                storeItemSizeId: storeItemSizeId,
                                itemNo: itemNo,
                                toppingsData: toppingsData
                            }
                        cookieRestaurantNewValueCount++;
                    }
                    cookieRestaurantUpdated = true
                }
                else {
                    cookieNewRestaurantValue[cookieRestaurantNewValueCount] = cookieRestaurantArray[i];
                    cookieRestaurantNewValueCount++;
                }
            }
        }
        if (cookieRestaurantUpdated == false) {
            if (quantity > 0) {
                cookieNewRestaurantValue[cookieRestaurantNewValueCount] = {
                    storeId: storeId,
                    itemId: itemId,
                    itemName: itemName,
                    quantity: quantity,
                    itemSize: itemSize,
                    itemMrp: itemMrp,
                    itemImage: itemImage,
                    storeItemSizeId: storeItemSizeId,
                    itemNo: itemNo,
                    toppingsData: toppingsData
                }
                cookieRestaurantUpdated = true
            }
        }
        localStorage.setItem('selectRestaurantCart', JSON.stringify(cookieNewRestaurantValue))
    }        
}


export function updateCartCommonItem(storeId, itemId, itemName, quantity, itemTitle, brand, sizeName, sizeId, cmItemSizeId, itemMasterId, netAmount, mrp, itemImage, isDelete) {
    var selectCommonItemCart = JSON.parse(localStorage.getItem('selectCommonItemCart')) || []
    let itemNo = itemId + '-' + cmItemSizeId
    if (localStorage.getItem('selectCommonItemCart') == null) {
        if (isDelete == false || quantity > 0) {
            var selectedCommonItemProduct = {
                storeId: storeId,
                itemId: itemId,
                itemName: itemName,
                quantity: quantity,
                itemTitle: itemTitle,
                brand: brand,
                sizeName: sizeName,
                sizeId: sizeId,
                cmItemSizeId: cmItemSizeId,
                itemMasterId: itemMasterId,
                netAmount: netAmount,
                mrp: mrp,
                itemImage: itemImage,
                itemNo: itemNo
            }
            selectCommonItemCart.push(selectedCommonItemProduct)
            localStorage.setItem('selectCommonItemCart', JSON.stringify(selectCommonItemCart))
        }
    }
    else {
        var cookieNewCommonItemValue = [];
        var cookieCommonItemArray = selectCommonItemCart;
        var cookieCommonItemUpdated = false
        var cookieCommonItemNewValueCount = 0;
        for (var i = 0; i < cookieCommonItemArray.length; i++) {
            if (cookieCommonItemArray[i] != null && cookieCommonItemArray[i] != "") {
                var cookieStoreId = cookieCommonItemArray[i].storeId
                var cookieItemId = cookieCommonItemArray[i].itemId
                var cookieCmItemSizeId = cookieCommonItemArray[i].cmItemSizeId               
                if (itemId == cookieItemId && storeId == cookieStoreId && cookieCmItemSizeId == cmItemSizeId) {                    
                    if (isDelete == false && quantity > 0) {
                        cookieNewCommonItemValue[cookieCommonItemNewValueCount] =
                            {
                                storeId: storeId,
                                itemId: itemId,
                                itemName: itemName,
                                quantity: quantity,
                                itemTitle: itemTitle,
                                brand: brand,
                                sizeName: sizeName,
                                sizeId: sizeId,
                                cmItemSizeId: cmItemSizeId,
                                itemMasterId: itemMasterId,
                                netAmount: netAmount,
                                mrp: mrp,
                                itemImage: itemImage,
                                itemNo: itemNo
                            }
                        cookieCommonItemNewValueCount++;
                    }
                    cookieCommonItemUpdated = true
                }
                else {
                    cookieNewCommonItemValue[cookieCommonItemNewValueCount] = cookieCommonItemArray[i];
                    cookieCommonItemNewValueCount++;
                }
            }
        }
        if (cookieCommonItemUpdated == false) {
            if (quantity > 0) {
                cookieNewCommonItemValue[cookieCommonItemNewValueCount] = {
                    storeId: storeId,
                    itemId: itemId,
                    itemName: itemName,
                    quantity: quantity,
                    itemTitle: itemTitle,
                    brand: brand,
                    sizeName: sizeName,
                    sizeId: sizeId,
                    cmItemSizeId: cmItemSizeId,
                    itemMasterId: itemMasterId,
                    netAmount: netAmount,
                    mrp: mrp,
                    itemImage: itemImage,
                    itemNo: itemNo
                }
                cookieCommonItemUpdated = true
            }
        }
        localStorage.setItem('selectCommonItemCart', JSON.stringify(cookieNewCommonItemValue))
    }   
}