import React, { Component, Suspense } from 'react';
import TrackLeft from "../images/lokal/track-order.png"
import TrackLine from "../images/lokal/track-order-line.png"
import Sort from "../images/lokal/sort.png"
import Arrow from "../images/lokal/ph_arrow-up.png";
import Home from "../images/lokal/home.png";
import Offer from "../images/lokal/offer.png"
import Deliverd from "../images/lokal/deliverd.png"
const Header = React.lazy(() => import("../luna/header"));
const Footer = React.lazy(() => import("../luna/footer"));
export default class Storelisting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 2,
    };
  }

  renderContent = () => {
    const { activeOption } = this.state;
  };
  render() {
    const { activeStep, showPopup } = this.state;

    return (
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Header />
          <div className='main-cart'>
            <div className='top-header'>
              <div className='container-fluid outer-padding'>
                <div className="row m-0">
                  <div className='bread-crumb col-12'>
                    <span className='link'>Home</span>
                    <span className='px-1'>
                      <img src={Arrow} alt="Arrow" className='img-responsive' width="15" height="15" />
                    </span>
                    <span className='link'>My Account</span>

                    <span className='px-1'>
                      <img src={Arrow} alt="Arrow" className='img-responsive' width="15" height="15" />
                    </span>
                    <span className='link-active'>Orders</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='container-fluid outer-padding'>
              <div className="d-md-flex justify-content-between align-items-center pt-3 pb-4 flex-wrap">
                <div className='d-flex align-items-center col-lg-8 col-12'>
                  <span className='mr-3'>
                    <img src={TrackLeft} alt="TrackLeft" width="20" height="20" />
                  </span>
                  <span className='mr-3'>
                    <img src={TrackLine} alt="TrackLine" width="auto" height="20" />
                  </span>
                  <div className='d-flex'>
                    <span>
                      <input type="text" name="" id="" className='form-control form-border' placeholder='search for your orders' />
                    </span>
                    <button className='btn-search'>Search</button>
                  </div>
                </div>
                <div className='sort-by-orders col-12 col-lg-4'>
                  <span>
                    <img src={Sort} alt="Sort" className='img-fluid mr-1' width="17" height="17" />
                  </span>
                  <span>Sort By</span>
                </div>
              </div>
              <div className='col-12'>
                <div className='row m-0 border-orders mb-3'>
                  <div className="col-lg-3 col-12 col-md-3 pl-md-0 pl-auto pb-4 pb-lg-0">
                    <div className='order-heads'>
                      <span className='mr-2'>
                        <img src={Home} alt="Home" className='img-fluid' width="20" height="20" />
                      </span>
                      Saki Vihar, Marol</div>
                    <div className='order-addr'>A-507, Hiranandani Lighthall, Saki Vihar Road, Saki Naka, Chandavali, Mumbai 400061</div>
                    <div className='d-flex order-date'>
                      <div>Aug 20, 2024</div>
                      <div>12:23 PM</div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-12 col-md-4 pb-4 pb-md-auto">
                    <div className="order-no">
                      Order no: <span>LKL100030045</span>
                    </div>
                    <div className='order-desc'>
                      Amul Masti Spiced Buttermilk x 1, Indian Tomato x 2, Modern 100% Whole Wheat - Bread x1, Epigamia Greek Yogurt - Mango x1
                    </div>
                  </div>
                  <div className="col-lg-2 col-12 col-md-2 pb-4 pb-md-auto">
                    <div className='order-price'>
                      <div>₹ 412.32</div>
                    </div>
                    <div className='order-desc-grey'>
                      <span>
                        <img src={Offer} alt="Offer" width="16" height="16" className='img-fluid mr-1' />
                      </span>
                      You’ve saved 47.12 on this order</div>
                  </div>
                  <div className="col-lg-3 col-12 col-md-3 pb-4 pb-md-auto">
                    <div className='preparing-order prepare-success'>Preparing Order</div>
                    <div className='order-desc-grey'>
                      Your item was delivered on Aug 20, 2024, 01:02 PM</div>
                  </div>
                </div>
                <div className='row m-0 border-orders mb-3'>
                  <div className="col-lg-3 col-12 col-md-3 pl-md-0 pl-auto">
                    <div className='order-heads'>
                      <span className='mr-2'>
                        <img src={Home} alt="Home" className='img-fluid' width="20" height="20" />
                      </span>
                      Saki Vihar, Marol</div>
                    <div className='order-addr'>A-507, Hiranandani Lighthall, Saki Vihar Road, Saki Naka, Chandavali, Mumbai 400061</div>
                    <div className='d-flex order-date'>
                      <div>Aug 20, 2024</div>
                      <div>12:23 PM</div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-12 col-md-4 pb-4 pb-md-auto">
                    <div className="order-no">
                      Order no: <span>LKL100030045</span>
                    </div>
                    <div className='order-desc'>
                      Amul Masti Spiced Buttermilk x 1, Indian Tomato x 2, Modern 100% Whole Wheat - Bread x1, Epigamia Greek Yogurt - Mango x 1
                    </div>
                  </div>
                  <div className="col-lg-2 col-12 col-md-2 pb-4 pb-md-auto">
                    <div className='order-price'>
                      <div>₹ 412.32</div>
                    </div>
                    <div className='order-desc-grey'>
                      <span>
                        <img src={Offer} alt="Offer" width="16" height="16" className='img-fluid mr-1' />
                      </span>
                      You’ve saved 47.12 on this order</div>
                  </div>
                  <div className="col-lg-3 col-12 col-md-3 pb-4 pb-md-auto">
                    <div className='preparing-order'>
                      <span>
                        <img src={Deliverd} alt="Deliverd" width="25" height="25" className='img-fluid mr-1' />
                      </span>
                      <span>Delivered</span>
                    </div>
                    <div className='order-desc-grey'>
                      Your item was delivered on Aug 20, 2024, 01:02 PM
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer/>
        </Suspense>
      </div>
    );
  }
}
