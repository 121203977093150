import React, { Component,Suspense } from "react";
// import MraHeader from "./header";
// import MraFooter from "./footer";
import bannerImage from "./assets/images/bannerImage.jpg"
import BorderImg from "./assets/images/separator.svg";
import pattern from "./assets/images/pattern-9.svg"
import mdImg from "./assets/images/mra_mdImg.jpg";
import ceoImg from "./assets/images/mra_ceoImg.jpg";
let MraHeader = React.lazy(() => import("./header"));
let MraFooter = React.lazy(() => import("./footer"));
export default class MraAbout extends Component {
    render() {
        return (
            <>
                <div className="mraInnerSec">
                <Suspense fallback={<div></div>}>
                    <div className="coverWraper">
                        <MraHeader />
                        <div className="contentWrap">
                            <div className="bannerImginner">
                                <img src={bannerImage} alt="" />
                                <div className="mraTitle">
                                    {/* <span>OUR STORY</span>
                                    <img src={BorderImg} alt="" /> */}
                                    <div className="contentSectn">
                                        <h1>About Us</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="mraBorders">
                                <div className="borderLeftImg">
                                    <img src={pattern} alt="" />
                                </div>
                                <div className="borderRightImg">
                                    <img src={pattern} alt="" />
                                </div>
                            </div>
                            <div className="storeCoverWrap mraAbt">

                                <div className="container">
                                    <div className="mraTitlesInner">
                                        <div className="mraTitle">
                                        <span>OUR STORY</span> 
                                            <img src={BorderImg} alt="" />
                                            <div className="contentSectn" style={{ margin: "0px" }}>
                                                <h1 >History</h1>
                                            </div>
                                        </div>
                                        <p>Over 2000 years of history have shaped the cuisine of the idyllic Indian coastal region of Malabar. Once the epicenter of the old spice trade, the settlers from the Arab world, Portugal, Turkey, and the Netherlands all helped create the delicious and unique dishes we see in the modern day state of Kerala.</p>
                                        <p>Mannil Rayroth Abdullla understood his homeland tradition of fusion better than the most. In 1965 he made it his mission to combine the best of Malabar Cuisine with Western-style bread and cakes, working day and night to develop new and innovative recipes to the highest culinary. Starting out from a single store, the MRA Bakery quickly spread throughout the entire region.</p>
                                        <p>Building on over 50 years of achieving excellence in the fast moving consumer goods (FMCG) market, MRA continued to improve its operating performance and its competitive position in this sector. An extensive review of the company’s organisational structure and business processes in the last couple of years has brought about significant changes in the company’s performance. Innovative strategies were developed and implemented, and our new state-of-the-art production complex started recently to operate.</p>
                                        <p>Since 1965, the company has seen a lot of expansion and change, but the way MRA Bakery and Foods operates is still based on the principles left by Mannil Rayroth Abdullah which emphasize family values and integrate those values into daily operations.</p>
                                        <p>We have young generations of Mannil family to take MRA legacy forward with pride. Our leaders are Mr. Mohamed Mannil (Chairman), Mr. Ayyoob Mannil (Executive Director), Mr. Abdul Nisar Mannil (Executive Director), and Mr. Abdul Gadhaf Mannil (Executive Director).</p>
                                        <div className="mraTitle mraCon"></div>

                                    </div>
                                </div>

                            </div>

                            <div className="mraAbtFeat">
                                <div className="contentSectn" style={{ margin: "0px" }}>
                                   <div className="container">
                                   <div className="row">
                                        <div className="col-lg-6 col-md-12 mraCeo">
                                            <div className="">
                                            <h1>MD MESSAGE</h1>
                                            <img src={mdImg} alt="" />
                                            <p><b>AYYOOB MANNIL</b></p>
                                            <p style={{padding:"0px"}}><b>Managing Director MRA (Trivandrum & South) <br/>& Group Executive Director</b></p>
                                            <p>Our tradition of serving flavourful delicacies started with my father Mannil Rayroth Abdullah (MRA). It was his vision to spread the culture of serving taste with passion. This gave us a sense of responsibility to expand our presence locally and globally. We, today stand in front of our satisfied customer with the strong belief that we are successful in executing his vision.</p>
                                            <p>We are proud to announce that we have more than 25 outlets including MRA Restaurant, Bakery and Cafe. With our plan to reachout to more locations, we have started our outlets in Trivandrum. We serve with passion and expect wholehearted support from Trivandrians who always support variety with quality.</p>
                                            
                                            </div>

                                        </div>
                                        <div className="col-lg-6 col-md-12 mraCeo">
                                        <div className="">
                                            <h1>CEO MESSAGE</h1>
                                            <img src={ceoImg} alt="" />
                                            <p><b>SAFNAD RAJA</b></p>
                                            <p style={{padding:"0px"}}><b>Chief Executive Officer<br/> MRA Trivandrumb</b></p>
                                            <p>Our rich history of success in this industry will continue to pursue opportunities for development. Our company's vision is strategically focused on customers and employees. We have many reasons to be proud of our results.</p>
                                            <p>I can assure you of the unlimited commitment of our teams at every level. Innovations are shared within units to support MRA Bakery's culture of continuous improvement. This would enable us to meet the Region's long -term challenges.</p>
                                            <p>Our passion is perfection and we are ready to deliver.</p>
                                           
                                        </div>
                                        </div>
                                    </div>
                                   </div> 
                                </div>
                            </div>

                            <div className="storeCoverWrap mraAbt">

                                <div className="container">
                                    <div className="mraTitlesInner mraFeatList">
                                        <div className="mraTitle">
                                        <span>WHO WE ARE</span>
                                            <img src={BorderImg} alt="" /> 
                                        </div>
                                        <h1 >MRA Bakery</h1>
                                    <p>We are proud to be one of the industry's premier confectionery full-service bakeries. Each piece of our edible art is beautifully handcrafted in our trademark style and individually tailored to our client's needs.</p>
                                    <p>
                                        The choice of offers at MRA Bakery is awe inspiring. From fresh wholesome bread to distinctively flaky and deliciously buttery pastries. Rich velvety cakes and sweets sit alongside healthy and appetizing sandwiches and savory snacks.
                                    </p>
                                    <h1>MRA Catering </h1>
                                    <p className="mraSub"><b>Event Catering</b></p>
                                    <p>Birthday I Anniversary I Family & Friends Gathering I School Function I Festive Gathering</p>
                                    <p>We explore all avenues of catering services to serve our clients. We cater to outdoor events such as corporate lunches, formal dinners and theme parties for our clientele. Special catering arrangements are made as per requests.</p>
                                    <p  className="mraSub"><b>Business Catering</b></p>
                                    <p>Corporate Events I Holiday Parties I Office Meetings I Employee Appreciation</p>
                                    <p>Our complete Institutional Catering solutions include various meal services in the form of the buffet or packed meals. The menus are designed keeping in mind the client profile. On our part, we believe we have upheld the tradition of delivering fine Indian Cuisine and great hospitality at all the events that we have catered to in the past years, and this has been reflected in the growth in reputation and goodwill we have experienced over the years.</p>
                                    <h5><b>CALL US FOR YOUR CATERING NEEDS - 9847 799 899, 9388 009 241 (TVM)</b></h5>
                                    <h1>MRA Restaurant  </h1>
                                    <p>We specialize in a wide range of cuisine and serves a multicultural clientele. Our chefs are reputed for Indian, Mediterranean, Continental, Oriental and Fusion cuisine. Some have tried to define MRA's culinary style as "Asian fusion," but we prefer to think of MRA as "multi-culturally influenced cuisine".</p>
                                    <p>The extensive lunch and dinner menus offer many variations on Asian and Continental dishes, taking the fresh ingredients to new heights.</p>
                                    <p>We offer a world of choice for vegetarians too and prepare exotic theme menus during festivals and special occasions.</p>
                                    <h1>Traditional Sweets</h1>
                                    <p>Ranging from Gulab Jamuns to Payasam, nostalgic favorites to modem avatars, we bring to you the most loved Indian desserts. Traditional Indian sweets with different blends of nuts and additional flavours to enrich the taste. We have cut down on sugar and enhanced Indian flavors of Rose, Saffron, Cardamom, Fennel, Orange, Mango, Guava, Lemon and Peppercorn. This Mithai is artistically crafted and unique in its presentation.</p>
                                    <h1>Snacks and Namkeen</h1>
                                    <p>Namkeens add a special touch to every occasion. MRA offers a wide range of namkeens and snacks. We use quality assured spices and nuts for preparation. All the required ingredients are sourced from the leading vendors so as to ensure the quality of the offered product as per the international food safety standards. This product is demanded by the clients for:</p>
                                    <ul>
                                        <li>High Nutrition Value</li>
                                        <li>Longer Shelf Life</li>
                                        <li>Excellent Taste</li>
                                    </ul>
                                    <h1>Pastries </h1>
                                    <p>Our freshly baked Cakes are a popular pastry. We make fresh cream cakes, tea cakes and plum cakes. From Weddings, Birthdays, holiday gatherings to office luncheons, our dessert and pastries are perfect for every occasion. All made fresh to order, so you know your crowd will be happy (and full). Our experienced cake consultants and decorators will create the perfect cake for your next event! We have tied up with established coffee chains to supply a range of confectionary and bakery delicacies which are served at their locations.</p>
                                    <h1>Management Policy</h1>
                                    <p>The management is dedicated towards the introduction of training programs at all levels to improve the skills and techniques of all employees. MRA possess a highly dedicated management team who overlooks the principles set in order to provide the highest customer satisfaction in terms of food quality and safety. The top management of MRA is committed to complying with the requirements of HACCP Standards, and continually improve the effectiveness of the Quality Management System through employee participation and regular reviews. This Quality Policy is communicated by the management to all employees to create and enhance awareness and is reviewed periodically for its suitability and stability.</p>
                                    <h1>Food Safety Policy</h1>
                                    <p>We ensure that all food supplied to our customers are produced hygienically to the highest standards of safety, unmatched quality, and dedicated services to achieve Total Customer Satisfaction.</p>
                                    <p>To be able to reach this goal, the company will ensure that our staff is well trained on complying with the legislative requirements set by the local regulatory unit as well as other pertinent standard related to the Safety and Health Environment. The company is dedicated to purchasing good quality raw materials from an approved supplier and handles food with utmost care.</p>
                                    <p>In essence, at MRA Bakery, we firmly believe that Safety is everyone's responsibility.</p>
                                       

                                    </div>
                                </div>

                            </div>
                            
                        </div>
                        <MraFooter />
                    </div>
                    </Suspense>
                </div>
            </>
        )
    }
}