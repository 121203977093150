import React, { Component, Suspense } from 'react';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import search from "../images/magnifying-glass.png"
import register from "../images/register.png"
import onlinestore from "../images/online-store.png"
import onlinecart from "../images/online-cart.png"
import onlinesell from "../images/online-sell.png"
let Header = React.lazy(() => import("../header"));
let Footer = React.lazy(() => import("../footer"));
export default class Helpcentre extends Component {
    render() {
        return (
            <div class="myyshoppWrapperInner myyshopp-main-wrapper mshopp-helpcentre">
                <Suspense fallback={<div></div>}>
                    <Header></Header>
                    <div class="container myyshopp-container pb-0">
                        <section class="">
                            <h1 class="head-bold-normal text-center">Help <span> Center </span></h1>
                            <div class="col-sm-12 px-0 mb-5">
                                <div class="mshopp-serch">
                                    <img src={search} width="22" class="mr-2" alt=""></img>
                                    <input type="text" class="mshopp-serch-input" placeholder="Search for questions"></input>
                                </div>
                            </div>
                            <h1 class="main-head head-bold-normal text-center">Topics</h1>
                            <div class="mshopp-accordion-tab">
                                <div class="row">
                                    <div class="col-lg-6 helpcentre-accordion-tab">
                                        <div class="topic-subhead">
                                            <img src={register} alt=""></img> Registering with us
                                        </div>
                                        <Accordion allowZeroExpanded={true}>
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <h4>How can I get online?</h4>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel >
                                                    <div>Simply click on the Get Started Button on our home page and fill in your details. Or you can just call us (+91 8282616161) or write in to us (support@myyshopp.com) and we’ll guide you and help you get online.</div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <h4>Can I manage my own platform?</h4>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel >
                                                    <div>Yes. You can do everything from building your own website to updating products, images and related information. In case you wish for us to help manage your platform we will do it for you. Just drop us a line on our Contact us Page and someone will get back to you.</div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <h4>Will you build my store for me?</h4>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel >
                                                    <div>Yes. MyyShopp has a team dedicated to working with our customers. Please drop us a message and someone will get back to you and guide you through the process.</div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <h4>How can I Pay?</h4>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel >
                                                    <div>You can choose from the various pricing plans available to you and simply make an online payment.</div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <h4>Do I have to buy the URL on my own?</h4>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel >
                                                    <div>Should you choose to opt for the MyyShopp DIY offering, you will need to purchase your own domain name. In case you are unsure of how to go about it, please reach out to us and we’ll guide you.</div>
                                                </AccordionItemPanel>
                                            </AccordionItem>

                                        </Accordion>
                                    </div>
                                    <div class="col-lg-6 helpcentre-accordion-tab">
                                        <div class="topic-subhead">
                                            <img src={onlinestore} alt=""></img> Setting up your online Store
                                        </div>
                                        <Accordion allowZeroExpanded={true}>
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <h4>Will I be able to customise my product offering and delivery management rules?</h4>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel >
                                                    <div>Yes. While you are online your business is available 24x7 for orders to come in. However, the system is built to ensure that you can set your delivery rules, timings, charges and areas of operations as per your convenience so customers are always aware of when you will service their orders.</div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <h4>What can I sell on MyyShopp?</h4>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel >
                                                    <div>The MyyShopp Platform is designed to be able to cater to both your product and services offerings. Please choose the category of products / services you want to sell and the platform will do the rest. In case you do not see your Business category in the list of categories available online, please do reach out us and we’ll create something for you.  </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <h4>Will I still need a POS to manage my inventory if I have the MyyShopp platform?</h4>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel >
                                                    <div>MyyShopp is built to serve as an extension to your physical store. We do not replace your Point of Sale or existing Inventory Management Systems. However, for Business Owners who do not have any existing POS system, the MyyShopp platform can handle basic inventory related information.</div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <h4>I have multiple stores, will I be able to show them online?</h4>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel >
                                                    <div>Yes. The platform is geared to create and manage multiple stores, if you have them. You will be able to create each outlet differently and manage the different products, inventories etc. for each outlet individually.</div>
                                                </AccordionItemPanel>
                                            </AccordionItem>

                                        </Accordion>
                                    </div>
                                    <div class="col-lg-6 helpcentre-accordion-tab">
                                        <div class="topic-subhead">
                                            <img src={onlinecart} alt=""></img> Going Online
                                        </div>
                                        <Accordion allowZeroExpanded={true}>
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <h4>Can I promote my store online?</h4>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel >
                                                    <div>Yes. You can choose from our inbuilt campaign management tools and promote your store via SMS or email. Our platform is also SEO enabled. Also you can choose to connect your online store with Social Media and share & promote your store there.</div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <h4>Will I be able to add, edit and manage my own products?</h4>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel >
                                                    <div>Yes. You can log into your retailer dashboard at any point in time and manage everything from your products, to your orders, campaigns and even the look and feel of your website.</div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <h4>Will I be able to update order status and keep my customers informed?</h4>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel >
                                                    <div>Yes. Our Order management System, allows you to keep your customers informed at all times. Email notifications are sent by default. However, should you choose to notify your customers via SMS as well, you could opt for transactional SMS packages.</div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <h4>Will I be able to run promotions and discounts?</h4>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel >
                                                    <div>Yes. Our in built promotion builder allows you to run promotions, discounts so you can give your customers the best offerings.</div>
                                                </AccordionItemPanel>
                                            </AccordionItem>

                                        </Accordion>
                                    </div>
                                    <div class="col-lg-6 helpcentre-accordion-tab">
                                        <div class="topic-subhead">
                                            <img src={onlinesell} alt=""></img> Selling & Delivering
                                        </div>
                                        <Accordion allowZeroExpanded={true}>
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <h4>I am concerned about orders coming from areas that I do not operate in.</h4>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel >
                                                    <div>With the MyyShopp Platform you can choose the areas you operate in and are comfortable servicing. You can also decide the delivery timings, charges etc. ensuring that you streamline your business operations and your customers are aware of your service policies.</div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <h4>Will you do deliveries for me?</h4>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel >
                                                    <div>We do not do deliveries for you. At MyyShopp we focus on managing technology so your online business can run smoothly and efficiently while you focus on selling, delivering and growing your business.</div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <h4>What are the charges of selling on the MyyShopp platform?</h4>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel >
                                                    <div>We are true to our word when we say there are no hidden charges. All the money generated from sales on the MyyShopp platform and from your website, will go directly to your bank account.</div>
                                                </AccordionItemPanel>
                                            </AccordionItem>

                                        </Accordion>
                                    </div>
                                    <div class="col-md-12 text-center pt-5 pb-2">
                                        <a href="/myyshopp/contact" class="theme-round-btn">CONTACT THE SUPPORT</a>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer></Footer>
                </Suspense>
            </div>
        )
    }
}