import React, { Component } from 'react'
import returnarrow from '../images/lokal-seller/return.png'
import profitableImg from '../images/lokal-seller/profitable.png'
import quickCustomerImg from '../images/lokal-seller/quickCustomer.png'
import reachCustomerImg from '../images/lokal-seller/reachCustomer.png'
export default class Steps extends Component {
    render() {
        return (
        <div className="lkstepBYSection">
            <div className="container">
                <div className="lkstepBYstep">
                    <div className="lkstepList">
                        <img src={profitableImg} alt=""/>
                        <span>Register  <br></br>your Store</span>
                        <p>with basic information  and product details to become a certified Lokal Seller, enabling you to grow your business & expand your reach.</p>
                        <img src={returnarrow} className="returnArrow" alt=""/>
                    </div>
                    <div className="lkstepList">
                        <img src={quickCustomerImg} alt=""/>
                        <span>Relax &  <br></br> Sit Back </span>
                        <p>as we connect you with Lokal Customers so they can conveniently browse through your store & products and build their cart 24X7.</p>
                        <img src={returnarrow} className="returnArrow" alt=""/>
                    </div>
                    <div className="lkstepList">
                        <img src={reachCustomerImg} alt="" />
                        <span>Watch your  <br></br>Business Grow</span>
                        <p>by simply checking your orders and delivering products to your customers via web and mobile app.</p>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}