import React, { Component, Suspense } from 'react'
import Category from "./category"
import { Helmet } from "react-helmet-async";
let Header = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/header"));
let Footer = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/footer"));
export default class CategoryList extends Component {
    render() {
        return (
            <div className="coverWraper">
                <Suspense fallback={<div></div>}>
                    <Header></Header>
                    <Helmet>
                        <link rel="canonical" href="/category" />
                        <title>Buy products from Groceries, Pets, Bakeries, Nutrition and Supplements, Restaurant, Fruits & Vegetables at Our Online Store - Lokal.in</title>
                        <meta name="description" content={window.sessionStorage.getItem("seoDescription")} />
                        <meta name="keywords" content={window.sessionStorage.getItem("seoKeyword")} ></meta>
                        <meta name="twitter:title" content={window.sessionStorage.getItem("seoTitle")}></meta>
                        <meta name="twitter:description" content={window.sessionStorage.getItem("seoDescription")}></meta>
                        <meta name="twitter:image:src" content={window.sessionStorage.getItem("seoImage")}></meta>
                        <meta name="facebook:title" content={window.sessionStorage.getItem("seoTitle")}></meta>
                        <meta name="facebook:description" content={window.sessionStorage.getItem("seoDescription")}></meta>
                        <meta name="facebook:image:src" content={window.sessionStorage.getItem("seoImage")}></meta>
                        <meta property='og:type' content='website' />
                        <meta property="og:title" content={window.sessionStorage.getItem("seoTitle")} ></meta>
                        <meta property="og:image" content={window.sessionStorage.getItem("seoImage")} ></meta>
                        <meta property="og:description" content={window.sessionStorage.getItem("seoDescription")} ></meta>
                        <meta property='og:site_name' content={window.sessionStorage.getItem("siteName")} />
                        <meta property="og:url" content={window.sessionStorage.getItem("seoUrl")} ></meta>
                    </Helmet>
                    <div className="contentWrap">
                        <div className="storeCoverWrap">
                            <div className="container">
                                <div className="categoryListTitle">
                                    <span>What are you looking for?</span>
                                </div>
                                <Category />
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </Suspense>
            </div>
        )
    }
}