import React, { Component,Suspense } from "react";
// import MraHeader from "./header";
// import MraFooter from "./footer";
import bannerImage from "./assets/images/bannerImage.jpg"
import BorderImg from "./assets/images/separator.svg";
import blog4 from "./assets/images/mraBlog4.jpg"
import blog1Sub from "./assets/images/snacks_image1.jpg"
import blog2Sub from "./assets/images/snacks_image2.jpg"
import blog3Sub from "./assets/images/snacks_image3.jpg"
let MraHeader = React.lazy(() => import("./header"));
let MraFooter = React.lazy(() => import("./footer"));
export default class BlogsDetails extends Component {
    render() {
        return (
            <>
                <div className="mraInnerSec">
                <Suspense fallback={<div></div>}>
                    <div className="coverWraper">
                        <MraHeader />
                        <div className="contentWrap">
                            <div className="bannerImginner">
                                <img src={bannerImage} alt="" />
                                <div className="mraTitle">
                                    <div className="contentSectn">
                                        <h1>Blog</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="mraBorders">
                               
                            </div>
                            <div className="storeCoverWrap mraAbt">

                                <div className="container">
                                    <div className="mraTitlesInner mraBlogs">
                                        <div className="mraTitle">
                                            <span>OUR LATEST</span>
                                            <img src={BorderImg} alt="" />
                                            <div className="contentSectn" style={{ margin: "0px" }}>
                                                <h1 >Blogs</h1>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4  ">
                                                <div className="mraBlogItem">
                                                    <img src={blog4} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="mraBlogTxt">
                                                    <h1>Delicious Malabar Snacks</h1>
                                                    <h4>MARCH 19, 2022</h4>
                                                    <p>South Indian cuisine is full of delicacies that are not only laced with spellbinding flavors but also use robust ingredients. Malabar food exhibits a vibrant range of preparations that give great precedence to the coastal bounty of the region and along with vegetarian fare using exotic local produce. It often gets difficult to look beyond the star preparations offered by the region's culinary repertoire - from fluffy, flakey parottas and meaty roast curries to redolent Pollichattu dishes of Kerala, and a staggering variety of seafood delicacies. As you delve deeper into the mind-boggling exhibition of local dishes, some of the lesser known delights pop up.</p>
                                                    <p>There are a number of Malabari snacks worth exploring that are capable of pleasing your taste buds. If you love coastal food, a hint of coconut in your meals, the subtle aroma of mustard seed tempering and all things typical of Malabar cuisine, we bet you will take equal delight in trying out some of our best loved snacks and starters straight from Kerala. From the widely popular banana chips to prawn fry and much more, these authentic Malabar snack recipes are perfect for the ongoing rainy season and promise you a delectable departure from the mundane, everyday snack bites.</p>
                                                    <p style={{ height: "auto" }}><b style={{fontWeight:"bold"}}>Pazham Pori</b></p>
                                                    <div className="col-md-4  ">
                                                        <div className="row">
                                                            <div className="mraBlogItem">
                                                                <img src={blog1Sub} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p>Made from ripe plantains, these deep fried golden fritters are a cult favorite all around the state. A classic tea-time companion, they are usually the default combination at almost all tea shops in every nook and corner of Kerala. Easy and quick to make, they are prepared by deep frying long slices of ripe plantains dipped in a batter of flour. It’s important that the plantains are ripe and juicy as this determines the taste of the fritters.</p>
                                                    <p style={{ height: "auto" }}><b style={{fontWeight:"bold"}}>Banana chips</b></p>
                                                    <div className="col-md-4  ">
                                                        <div className="row">
                                                            <div className="mraBlogItem">
                                                                <img src={blog2Sub} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p>Perhaps the most recognizable and well-known Keralan snack popular outside the state, banana chips are a much-loved savoury treat. They can be enjoyed at any time and are also an easy and excellent travel snack. Made from bananas that are thinly sliced into circles, they are dried in the sun and then deep fried in coconut oil. They are a notable part of Sadya, the traditional Kerala feast.</p>
                                                    <p style={{ height: "auto" }}><b style={{fontWeight:"bold"}}>Unnakaya</b></p>
                                                    <p>A delight from the Malabar region of Kerala, this is a traditional goody that is surprisingly lesser-known, even within other parts of the state. A mouthwatering indulgence, it is loaded with ingredients such as jaggery, grated coconut, mashed banana, egg white and cardamom, which are all wrapped inside a piece of plantain that is flattened and rolled out to form a covering layer. The outer cover is filled and rolled back into spindle shapes, which are then deep fried.

                                                    </p>

                                                    <div className="col-md-4  ">
                                                        <div className="row">
                                                            <div className="mraBlogItem">
                                                                <img src={blog3Sub} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/*  */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <MraFooter />
                    </div>
                    </Suspense>
                </div>
            </>
        )
    }
}