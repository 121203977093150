import React, { Component } from 'react';
import { Link } from 'react-router-dom';
export default class GlobalSearchStoreData extends Component {
    setData(mainCategoryId, mainGroupCategoryId, storeId, storeName) {   
        debugger     
        window.sessionStorage.setItem('mainCategoryIdLokal', mainCategoryId)
        window.sessionStorage.setItem('mainCategoryId', mainCategoryId)
        window.sessionStorage.setItem('mainGroupCategory', mainGroupCategoryId)
        window.sessionStorage.setItem('storeName', storeName)
        window.sessionStorage.setItem('storeId', storeId)     
        // this.setState({ groceryData: [], restaurantData: [], commonItemData: [], storeData: [], isData: '0' }) 
    }
    render() {
        return (
            <div className="addMoreItemList">
                {/* {console.log("@@@@@@@@@@@@@@@@@",this.props)} */}
                {/* <Link className="text-decoration" onClick={() => this.setData(this.props.dataObject._source.maincategoryid, this.props.dataObject._source.maingroupcategoryid, this.props.dataObject._source.store_id, this.props.dataObject._source.store_name)} to={this.props.dataObject._source.maingroupcategoryid == '5' ? "/restaurantListing/0/0/" : (this.props.dataObject._source.maingroupcategoryid == '8' ? "/cmItemListing/0/0/0" : "/" + this.props.dataObject._source.store_slug)}> */}
                <Link className="text-decoration"  onClick={() => {
                        // Call the setData function
                        this.setData(
                            this.props.dataObject._source.maincategoryid,
                            this.props.dataObject._source.maingroupcategoryid,
                            this.props.dataObject._source.store_id,
                            this.props.dataObject._source.store_name
                        );

                        // Call the onSeeMoreClick function passed from the parent
                        if (this.props.onSeeMoreClick) {
                            this.props.onSeeMoreClick();
                        }
                    }}
                    to={{
                        pathname: "/globalSearchResult",
                        state: { searchKey: this.props.searchKey, mode: 'Store' }
                    }}>
                    <div className="globalSearchItem">
                        <div className={"globalItemContent " + this.props.extraClassName}>
                            <div className="globalItemStoreImage">
                                <img src={this.props.dataObject._source.sellerlogopath} alt={this.props.dataObject._source.store_name} />
                            </div>
                            <div className="globalStoreItemName" title={this.props.dataObject._source.store_name}>{this.props.dataObject._source.store_name}</div>
                            {/* <div className="globalStoreItemLocation">{this.props.dataObject._source.locality}, {this.props.dataObject._source.cityname}</div> */}
                        </div>
                    </div>
                </Link>
            </div>
        )
    }
}