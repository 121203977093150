import React, { Component, Suspense } from 'react';
let Header = React.lazy(() => import("../header"));
let Footer = React.lazy(() => import("../footer"));
export default class TermsConditions extends Component {
    render() {
        return (
            <div class="myyshoppWrapperInner myyshopp-main-wrapper">
                <Suspense fallback={<div></div>}>
                    <Header></Header>
                    <div class="container myyshopp-container">
                        <section>
                            <div class="col-md-12">
                                <h1 class="main-head text-center mb-2 mb-lg-5">Terms of Service </h1>
                                <div class="termsHolder">
                                    <div class="mshop_termsBlock"><strong>These Terms and Conditions govern the use of the services by MyyShopp.com its websites / apps, related applications and any licensors or legal affiliates of MyyShopp.com (hereafter MyyShopp)</strong></div>
                                    <div class="mshop_termsHead"><strong>About the MyyShopp Platform</strong></div>
                                    <div class="mshop_termsPara">MyyShopp is a platform that helps connect customers to stores to order goods and services. </div>
                                    <div class="mshop_termsHeadSml">Legal Release</div>
                                    <div class="mshop_termsPara">As a customer of MyyShopp you agree that MyyShopp is not responsible for product pricing or the policies or the conduct of any retail shops or their employees. As such you agree that MyyShopp and its representatives, employees, affiliates, directors or licensors, are not legally liable for any retail stores’ actions or policies, who are on the platform. Moreover you agree that MyyShopp owes no financial penalty to you or anyone using the platform and in addition you agree without limitation that MyyShopp does not owe you any money from claims, damages, legal fees or any other fees that arise from any dispute with MyyShopp or its partners and vendors.</div>
                                    <div class="mshop_termsHeadSml">Pricing</div>
                                    <div class="mshop_termsPara">Prices on the MyyShopp site are given by our partner stores. Prices are generally MRP unless otherwise specified. In general, the prices shown on the site are what will be charged. Certain commodity foods (like vegetables, fruit etc…) may regularly vary in price on a daily basis. Any product or food ordered by weight will be priced at the given weight per metric but the actual delivered weight of the food may vary from the actual order , though it will be as close as the partner store can manage to the actual order, thus impacting the final price for such a commodity. As a customer, you agree to this potential price volatility. </div>
                                    <div class="mshop_termsHeadSml">Cancellation by Customer</div>
                                    <div class="mshop_termsPara">You can cancel you order anytime up to when the product is out for delivery.  Once the order is physically being processed, we can no longer cancel your order. As MyyShopp is a platform for our partner stores, we will do our very best to communicate a cancelled order with our platform stores but no guarantees of cancellation are given.</div>
                                    <div class="mshop_termsHeadSml">Cancellation by MyyShopp</div>
                                    <div class="mshop_termsPara">MyyShopp reserves the right to cancel orders due to suspected illegal, mischievous or fraudulent activities. Be aware that we will maintain a record of all transactions and maintain the right to prohibit or ban people from using our site, products and services.</div>
                                    <div class="mshop_termsHeadSml">Return &amp; Refunds</div>
                                    <div class="mshop_termsPara">Currently MyyShopp does not give refunds. If there is an issue with your order, please contact customer service and provide your order number with your complaint. We will do our best to satisfy reasonable requests but there is no guarantee of refunds.</div>
                                    <div class="mshop_termsHeadSml">Wrong Address</div>
                                    <div class="mshop_termsPara">You agree that if there is a delivery problem due to a mistake by you (i.e. wrong name or address or any other wrong information), you agree that any extra cost for redelivery shall be charged and recovered from you.</div>
                                    <div class="mshop_termsHead"><strong>Use of Material</strong></div>
                                    <div class="mshop_termsPara">All materials, content, layout, images, text, design, software of the MyyShopp website/apps are owned by MyyShopp and its owners. Any copying, reproduction, posting on any other website/apps, republication, downloading, uploading, encoding, modification, translation, public performance or display, distribution or transmission of any portion of this website/apps, or the making of any derivative works from this website/apps, is prohibited.</div>
                                    <div class="mshop_termsHead"><strong>User Submissions</strong></div>
                                    <div class="mshop_termsPara">This website/apps may now, or in the future, permit the submission, sharing or publishing of photographs, videos, comments, communications or other content submitted by you and other users ("User Submissions"). Other than personally identifiable information, which is covered under the MyyShopp Privacy Policy, any User Submission transmitted or posted to this website/apps will be considered non-confidential. As well, any User Submission to MyyShopp implies that  you hereby grant MyyShopp a perpetual, worldwide, non-exclusive, royalty-free, sub-licenseable and transferable license to use, reproduce, distribute, prepare derivative works of, display and perform in any capacity what has been submitted, in connection with the MyyShopp website/apps and MyyShopp's business. You hereby grant each User of the MyyShopp website/apps a non-exclusive license to access your User Submissions through the website/apps and to display and publicly perform such User Submissions as permitted through the functionality of the website/apps and under these Terms and Conditions.</div>
                                    <div class="mshop_termsPara">In connection with User Submissions, you agree that you will not submit material that: is subject to third party proprietary rights unless you have the rights to submit the material and to grant MyyShopp all of the license rights granted herein; MyyShopp reserves the right to remove any User Submissions at its sole discretion and without notice or liability to you or to any other person.</div>
                                    <div class="mshop_termsPara">MyyShopp does not endorse any User Submission or any opinion, recommendation or advice expressly disclaims any and all liability in connection with any User Submission. You understand and agree that you may be exposed to User Submissions that are inaccurate, offensive or otherwise objectionable, and you hereby agree to waive and hereby do waive any legal or equitable rights or remedies you may have against MyyShopp with respect thereto. MyyShopp may provide links to website/appss owned or operated by third parties but does not endorse the content or any products or services available on such website/appss and is not responsible for such content or its security. Your linking to any other website/apps from this website/apps is at your own risk.</div>
                                    <div class="mshop_termsHead"><strong>User Account</strong></div>
                                    <div class="mshop_termsPara">You agree that you are the sole authorized User of any account you create with MyyShopp. You are responsible for maintaining the confidentiality of any password or account number provided by you or MyyShopp. You are solely and fully responsible for all activities that occur under your password or account. MyyShopp has no control over the use of any User's account and expressly disclaims any liability derived therefrom. Should you suspect that any unauthorized party may be using your password or account, you will notify MyyShopp support immediately.</div>
                                    <div class="mshop_termsPara">You may opt-out of receiving text messages from us by emailing MyyShopp support. If you provide your cell phone number to MyyShopp to the use MyyShopp services, you hereby affirmatively consent to our use of your mobile number for calls and texts in order to perform the Services.</div>
                                    <div class="mshop_termsHead"><strong>Disclaimer</strong></div>
                                    <div class="mshop_termsPara">Use of the support is entirely at your own risk. Changes are periodically made to the website/apps and may be made at any time without notice to you. The support is provided on an "As is" basis without warranties of any kind. Myyshopp assumes no liability or responsibility for any (I) errors, mistakes, or inaccuracies of content; (ii) personal injury or property damage, of any nature whatsoever, resulting from your access to and use of the myyshopp website/apps or myyshopp support; (iii) any unauthorized access to or use of myyshopp's secure servers and/or any and all personal information and/or financial information stored therein.</div>
                                    <div class="mshop_termsPara">Myyshopp does not promise that the website/apps will operate error-free or that the website/apps and its server are free of computer viruses and other harmful goods. If your use of the myyshopp website/apps results in the need for servicing or replacing equipment or data, myyshopp shall not be responsible for those costs. Myyshopp, to the fullest extent permitted by law, disclaims all warranties, whether express or implied, including without limitation the warranty of merchantability, non-infringement of third party rights and the warranty of fitness for a particular purpose. Myyshopp makes no warranties about the accuracy, reliability, completeness or timeliness of the content, services, support, software, text, graphics or links. Myyshopp and its affiliates and licensors cannot and do not guarantee that any personal information supplied by you will not be misappropriated, intercepted, deleted, destroyed or used by others.</div>
                                    <div class="mshop_termsHead"><strong>Limitation of Liability</strong></div>
                                    <div class="mshop_termsPara">You agree that myyshopp shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages arising out of or in connection with use of the myyshopp website/apps, apps, services or affilated services. You further agree that myyshopp shall not be liable for any damages arising from interruption, suspension or termination of services, including without limitation any direct, indirect, incidental, special, consequential or exemplary damages, whether such interruption, suspension or termination was justified or not, negligent or intentional, inadvertent or advertent. In no event shall myyshopp’s total liability to you for any losses arising hereunder exceed the amounts paid by you to myyshopp hereunder.</div>
                                    <div class="mshop_termsPara">Some jurisdictions do not allow the limitation or exclusion of warranties or of liability for incidental or consequential damages so some of the above limitations may not apply to you. Should a jurisdiction be adverse to a limitation or exclusion of warranties, such provision shall be deemed severable from this agreement and the other provisions shall remain in full force and effect.</div>
                                    <div class="mshop_termsHead"><strong>Indemnity</strong></div>
                                    <div class="mshop_termsPara">You agree to defend, indemnify and hold harmless MyyShopp and it’s owners and officers, directors, employees, agents and affiliates (each, an "Indemnified Party"), from and against any losses, claims, actions, costs, damages, penalties, fines and expenses, including without limitation attorneys' fees and expenses, that may be incurred by an Indemnified Party arising out of, relating to or resulting from your unauthorized use of the website/apps or from any breach by you of these Terms and Conditions, including without limitation any actual or alleged violation of any federal, state or local statute, ordinance, administrative order, rule or regulation. </div>
                                    <div class="mshop_termsHeadSml">Termination</div>
                                    <div class="mshop_termsPara">At its sole discretion, MyyShopp may modify or discontinue the website/apps, or may modify, suspend or terminate your access to the website/apps or support, for any reason, with or without notice to you and without liability to you or any third party. </div>
                                    <div class="mshop_termsHeadSml">Dispute Resolution</div>
                                    <div class="mshop_termsPara">The parties shall first attempt to resolve any dispute related to this Agreement in an amicable manner by mediation with a mutually acceptable mediator. If unable to agree upon an acceptable mediator, either party may ask a mutually agreed upon mediation service to appoint a neutral mediator, and the mediation shall be conducted under the Commercial Mediation Rules of the mutually acceptable mediation service. Any disputes remaining unresolved after mediation shall be settled by binding arbitration conducted in Trivandrum, Kerala, India; utilizing a mutually agreed arbitrator or arbitration service. </div>
                                    <div class="mshop_termsHeadSml">Governing Law and Severability</div>
                                    <div class="mshop_termsPara">This Agreement is governed by the laws of the State of Kerala, India, without regards to its conflict of laws principles. If any provision of this Agreement is found to be invalid in any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of this Agreement, which shall remain in full force and effect. Any offer for any product, feature, service or application made on this website/apps is void where prohibited.</div>
                                    <div class="mshop_termsHead"><strong>No Agency</strong></div>
                                    <div class="mshop_termsPara">No independent contractor, agency, partnership, joint venture, employer-employee or franchiser-franchisee relationship is intended or created by this Agreement.</div>
                                    <div class="mshop_termsHead"><strong>Changes to the Agreement</strong></div>
                                    <div class="mshop_termsPara">MyyShopp reserves the right, at its sole and absolute discretion, to change, modify, add to, supplement or delete any of the terms and conditions of this Agreement, effective with or without prior notice. Your continued use of the website/apps or the Support following any revision to this Agreement constitutes your complete and irrevocable acceptance of any and all such changes.
                                    </div>
                                    <div class="mshop_termsHead"><strong>Images</strong></div>
                                    <div class="mshop_termsPara">MyyShopp shows images of product items to give customers an idea of what they are buying. Please note that the actual items delivered may not look exactly as the images shown, especially as it relates to items like fruits and vegetables. As well, packaged products sometimes will change their packaging and while MyyShopp will always aim to provide up to date images, there may be a chance of the packaging being different than the images shown.
                                    </div>
                                    <div class="mshop_termsHead"><strong>Offers and discounts</strong></div>
                                    <div class="mshop_termsPara">Offers and discounts are valid till stocks last.
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer></Footer>
                </Suspense>
            </div>
        )
    }
}