import React, { Component, Suspense } from 'react';
import AboutImg from "../images/lokal/about-new.png";
import Slider1 from "../images/lokal/about-img1.png";
import Slider from "react-slick";
let Header = React.lazy(() => import("../" + "luna" + "/header"));
let Footer = React.lazy(() => import("../" + "luna" + "/footer"));


export default class About extends Component {

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 200,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            arrows: false,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1,
                        swipeToSlide: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        swipeToSlide: true
                    }
                }
            ]
        };
        return (
            <Suspense fallback={<div></div>}>
                <Header></Header>
                <div className="col-12 p-0">
                    <div className='about-banner'>
                        <img src={AboutImg} alt="AboutImg" className='img-fluid' width="100%" height={300} />
                        <div className='about-text'>
                            About
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row py-5">

                        <div className="col-12 col-lg-7 col-md-6">
                            <p className='about-p'>Growing up, local stores weren’t just places to shop - they were a part of our neighbourhoods. These shops shaped the rhythm of our daily lives with more than just goods - they gave us a community.</p>
                            <p className='about-p'>
                                But in today’s digital-first world, convenience has become king. We can get what we need in seconds without stepping outside. While this shift is understandable, it raises an important question: What about our local communities? Are they keeping up with the rapid move to online shopping?
                            </p>
                            <p className='about-p'>
                                That’s how lokal was born. Our mission is simple yet powerful: to give local stores the platform they need to thrive in a world of online shopping. These businesses are the heart of our communities, and they deserve the opportunity to grow alongside the convenience of online shopping.
                            </p>
                            <p className='about-p'>
                                With lokal, you get the best of both worlds - convenient online shopping while staying connected to the shops that make your neighbourhood special. Every time you order through us, you’re supporting local grocers, bakers, restaurant owners, and more - those who have been part of your everyday life.
                            </p>
                            <p className='about-p'>
                                Today, become the change-maker our local businesses need.
                            </p>
                        </div>
                        <div className="col-12 col-lg-5 col-md-6 abt-right-img">
                            <Slider {...settings} className="main-abt">

                                <div>
                                    <img src={Slider1} alt="Slider1" className='img-fluid' width="100%" />
                                </div>
                                <div>
                                    <img src={Slider1} alt="Slider1" className='img-fluid' width="100%" />
                                </div>
                                <div>
                                    <img src={Slider1} alt="Slider1" className='img-fluid' width="100%" />
                                </div>

                            </Slider>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </Suspense>

        )
    }
}