import React, { Component } from 'react'
import Img1 from "../images/lokal/cat-1.png"
import ArrowRight from "../images/lokal/arrow-right-side.png"
import Fruit from "../images/lokal/fruit.png"
import Nutri from "../images/lokal/nutri.png"
import Restaurant from "../images/lokal/restaurant.png"
import Slider from "react-slick";
import Right from "../images/lokal/store-cat-right-arrow.png"
// Custom Next Arrow component
const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={`custom-next-arrow ${className}`}
            onClick={onClick}
        >
            <img src={Right} alt="Next" width="10" height="auto" />
        </div>
    );
};
export default class Category extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: false,
            speed: 200,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            arrows: true,
            nextArrow: <NextArrow />, 
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        swipeToSlide: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        swipeToSlide: true
                    }
                }
            ]
        };
        return (
            <div>
                <div className='container-fluid outer-padding store-listing'>
                    <div className="row m-0">
                        <div className="col-12">
                            <div className="sub-head">
                                Explore by categories
                            </div>
                        </div>
                        </div>
                        <Slider {...settings}>
                        <div className="col-12 col-lg-12 col-md-12 mt-4 ">
                            <div className='categories'>
                                <div className="row">
                                    <div className="col-8 small-head col-lg-8 col-md-8">
                                        Groceries
                                    </div>
                                    <div className="col-4 col-lg-4 col-md-4 ">
                                        <div className='img-shadow'>
                                            <img src={ArrowRight} alt="ArrowRight" className='img-fluid' width="40" height="40" />
                                        </div>

                                    </div>
                                </div>
                                <div className='cat-div'>
                                    <img src={Img1} alt="Img1" className='img-fluid banner-sub-img-category' width="100%" height="100%" />
                                </div>

                            </div>
                        </div>
                        <div className="col-12 col-lg-12 col-md-12 mt-4 ">
                            <div className='categories'>
                                <div className="row">
                                    <div className="col-8 small-head">
                                        Fruits &
                                        Vegetables
                                    </div>
                                    <div className="col-4">
                                        <div className='img-shadow'>
                                            <img src={ArrowRight} alt="ArrowRight" className='img-fluid' width="40" height="40" />
                                        </div>

                                    </div>
                                </div>
                                <div  className='cat-div'>
                                    <img src={Fruit} alt="Img1" className='img-fluid banner-sub-img-category' width="100%" height="100%" />
                                </div>

                            </div>
                        </div>
                        <div className="col-12 col-lg-12 col-md-12 mt-4 ">
                            <div className='categories'>
                                <div className="row">
                                    <div className="col-8 small-head">
                                        Nutrition
                                        and Supplements
                                    </div>
                                    <div className="col-4">
                                        <div className='img-shadow'>
                                            <img src={ArrowRight} alt="ArrowRight" className='img-fluid' width="40" height="40" />
                                        </div>

                                    </div>
                                </div>
                                <div  className='cat-div'>
                                    <img src={Nutri} alt="Img1" className='img-fluid banner-sub-img-category' width="100%" height="100%" />
                                </div>

                            </div>
                        </div>
                        <div className="col-12 col-lg-12 col-md-12 mt-4 ">
                            <div className='categories'>
                                <div className="row">
                                    <div className="col-8 small-head">
                                        Restaurants
                                    </div>
                                    <div className="col-4">
                                        <div className='img-shadow'>
                                            <img src={ArrowRight} alt="ArrowRight" className='img-fluid' width="40" height="40" />
                                        </div>

                                    </div>
                                </div>
                                <div  className='cat-div'>
                                    <img src={Restaurant} alt="Img1" className='img-fluid banner-sub-img-category' width="100%" height="100%" />
                                </div>

                            </div>
                        </div>
                        <div className="col-12 col-lg-12 col-md-12 mt-4 ">
                            <div className='categories'>
                                <div className="row">
                                    <div className="col-8 small-head">
                                        Restaurants
                                    </div>
                                    <div className="col-4">
                                        <div className='img-shadow'>
                                            <img src={ArrowRight} alt="ArrowRight" className='img-fluid' width="40" height="40" />
                                        </div>

                                    </div>
                                </div>
                                <div  className='cat-div'>
                                    <img src={Restaurant} alt="Img1" className='img-fluid banner-sub-img-category' width="100%" height="100%" />
                                </div>

                            </div>
                        </div>
                        </Slider>
                </div>
            </div>
        )
    }
}