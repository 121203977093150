import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import PopularItems from "../commonItem/cmItemList"
import arowdown from '../images/lokal/arow-down.png'
import { elasticLink,elasticHostURL } from "../config";
import { pageHitAPICall} from '../services';
const AWS = require('aws-sdk');
const elasticsearch = require("elasticsearch");
AWS.config.region = 'us-west-1';
export default class StoreProfileCommonItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            popularItem: []
        };
    }
    componentDidMount() {
        this.getCategory()
        var sellerID="";
        var moduleID=12;
        var siteCategoryGroupId="";
       
        if(window.sessionStorage.getItem('siteCategoryId') !=="0"){
            siteCategoryGroupId=window.sessionStorage.getItem('siteCategoryId');
        }
        else{
            sellerID=window.sessionStorage.getItem('sellerId')
        }
        pageHitAPICall(sellerID,moduleID,siteCategoryGroupId)
    }
    async getCategory(storeIdNew) {
        let storeId = window.sessionStorage.getItem('storeId');
        const client = new elasticsearch.Client({
            host: elasticHostURL(),
            amazonES: {
                region: 'us-west-1',
                credentials: new AWS.Credentials(this.state.s3AccessKey, this.state.s3SecretKey)
            }
        });
        var fields = ['store_id'];
        var filters = [];
        var paramsVal = [storeId]
        for (var k = 0; k < fields.length; k++) {
            if (paramsVal[k] !== '*' && paramsVal[k] !== '') {
                var termq = { term: {} };
                termq.term[fields[k]] = paramsVal[k];
                filters.push(termq);
            }
        }
        var sortByFilter = [{ sort_order: "asc" }]
        const body = await client.search({
            index: elasticLink() + '-cm-commonitem-search',
            from: 0, size: 40,
            body: {
                query:
                {
                    bool: {
                        filter:
                        {
                            bool:
                            {
                                must: filters
                            }
                        }
                    }
                },
                sort: sortByFilter
            }
        })
        this.setItemObject(body.hits.hits)
    }
    setItemObject(dataResult) {
        var itemrelatedData = []
        if (dataResult.length > 0) {
            for (var i = 0; i < dataResult.length; i++) {
                var sizeArray = []
                if (dataResult[i]._source.item_size !== null && dataResult[i]._source.item_size !== '') {
                    var item_size = dataResult[i]._source.item_size.split(',')
                    var item_size_id = dataResult[i]._source.item_size_id.split(',')
                    var item_size_quantity = dataResult[i]._source.item_size_quantity.split(',')
                    var cm_ItemSizeId = dataResult[i]._source.cm_item_size_id.split(',')
                    for (var s = 0; s < item_size.length; s++) {
                        var sizeVal = {
                            sizeName: item_size[s],
                            sizeId: item_size_id[s],
                            sizeQuantity: item_size_quantity[s],
                            cmItemSizeId: cm_ItemSizeId[s]
                        }
                        sizeArray.push(sizeVal)
                    }
                }
                var itemData = [];
                itemData = {
                    s3_folder_name: dataResult[i]._source.s3_folder_name,
                    item_name: dataResult[i]._source.item_name,
                    item_id: dataResult[i]._source.item_id,
                    store_id: dataResult[i]._source.store_id,
                    item_master_id: dataResult[i]._source.item_master_id,
                    item_title: dataResult[i]._source.item_title,
                    item_image: dataResult[i]._source.item_image,
                    mrp: dataResult[i]._source.mrp,
                    net_amount: dataResult[i]._source.net_amount,
                    brand_name: dataResult[i]._source.brand_name,
                    available_quantity: dataResult[i]._source.available_quantity,
                    slug: dataResult[i]._source.slug,
                    is_offer: dataResult[i]._source.is_offer,
                    offer_text: dataResult[i]._source.offer_text,
                    item_size: dataResult[i]._source.item_size,
                    item_size_id: dataResult[i]._source.item_size_id,
                    item_size_quantity: dataResult[i]._source.item_size_quantity,
                    sizeResult: sizeArray
                }
                itemrelatedData.push(itemData)
            }
            this.setState({
                popularItem: itemrelatedData,
                rowCount: itemrelatedData.length,
                divPagination: '1',
                isData: '1',
                itemOffset: '0'
            })
        }
    }
    render() {
        return (
            <div className="itemCoverDiv">
                <div className="container">
                    <div className="itemListTop">
                        <div className="tagSection">
                            <Link>
                                <span className="tag topCategory">C1</span>
                                <span className="tag topCategory">C2</span>
                            </Link>
                        </div>
                    </div>
                    <div className="itemListDiv">
                        <div className="itemFilterSection">
                            <span className="mobileFilter" onClick={this.filterOpen}>Filter <img src={arowdown} alt="arrow" /></span>
                            <div id="fliterCover" className="fliterCoverDiv">
                                <div className="itemFilterTitle">Category</div>
                            </div>
                        </div>
                        <div className="itemListSection"><PopularItems topCategoryResult1={this.state.popularItem}></PopularItems></div>
                    </div>
                </div>
            </div>
        )
    }
}