import React, { Component, Suspense } from 'react';
import Joincompany from "../home/joincompany"
import featuresbanner from "../images/features-banner.png"
import website_weap from "../images/website_weap.png"
import develop_app from "../images/develop_app.png"
import web_shopping from "../images/web_shopping.png"
import social_girl from "../images/social_girl.png"
import note_list from "../images/note_list.png"
import all_the_data from "../images/all_the_data.png"
let Header = React.lazy(() => import("../header"));
let Footer = React.lazy(() => import("../footer"));
export default class MshopFeatures extends Component {
    render() {
        return (
            <div class="myyshoppWrapperInner myyshopp-main-wrapper mshopp-section-top section-logobg">
                <Suspense fallback={<div></div>}>
                    <Header></Header>
                    <div class="container myyshopp-container p-lg-0 pt-lg-5">
                        <section class="pt-sm-0">
                            <div class="row myystory-div">
                                <div class="col-lg-4">
                                    <div className="myystoryContent">
                                        <h1 class="main-head"> Features <br></br> to <span>  grow online </span></h1>
                                    </div>
                                </div>
                                <div class="col-lg-8 storybanner-img">
                                    <img src={featuresbanner} class="img-fluid" alt=""></img>
                                </div>
                            </div>
                        </section>
                        <section class="features-listsection">
                            <div class="row">
                                <div class="col-md-5 features-listsection-list">
                                    <img src={website_weap} class="img-fluid" alt=""></img>
                                    <div class="col-lg-9 p-0 m-auto">
                                        <h2 class="txtclr-lightblue my-3">Website</h2>
                                        <p class="txtclr-lightblue">A personal mobile enabled website and address. Your Digital Identity the way you want it.</p>
                                    </div>
                                </div>
                                <div class="col-md-2"></div>
                                <div class="col-md-5 features-listsection-list">
                                    <img src={develop_app} class="img-fluid" alt=""></img>
                                    <div class="col-lg-9 p-0 m-auto">
                                        <h2 class="txtclr-lightblue my-3">Mobile App</h2>
                                        <p class="txtclr-lightblue">An Android & iOS mobile App that functions as an extension of your online store in your customers hands.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5 features-listsection-list">
                                    <img src={web_shopping} class="img-fluid" alt=""></img>
                                    <div class="col-lg-9 p-0 m-auto">
                                        <h2 class="txtclr-lightblue my-3">MyyFacebook Shopp</h2>
                                        <p class="txtclr-lightblue">A Facebook store that allows your customers and followers to shop directly from Facebook.</p>
                                    </div>
                                </div>
                                <div class="col-md-2"></div>
                                <div class="col-md-5 features-listsection-list">
                                    <img src={social_girl} class="img-fluid" alt=""></img>
                                    <div class="col-lg-9 p-0 m-auto">
                                        <h2 class="txtclr-lightblue my-3">Social Media </h2>
                                        <p class="txtclr-lightblue">Connect, share and promote your online store with easy Social Media Integration.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5 features-listsection-list">
                                    <img src={note_list} class="img-fluid" alt=""></img>
                                    <div class="col-lg-9 p-0 m-auto">
                                        <h2 class="txtclr-lightblue my-3"> Listing</h2>
                                        <p class="txtclr-lightblue">In addition to getting your own website, we also list you on <a href="/">www.myyshopp.com</a> allowing for greater reach and traction.</p>
                                    </div>
                                </div>
                                <div class="col-md-2"></div>
                                <div class="col-md-5 features-listsection-list">
                                    <img src={all_the_data} class="img-fluid" alt=""></img>
                                    <div class="col-lg-9 p-0 m-auto">
                                        <h2 class="txtclr-lightblue my-3"> Analytics </h2>
                                        <p class="txtclr-lightblue">Built in reports and analytics, helping you plan and scale your business better based on customer insights.</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Joincompany></Joincompany>
                    </div>
                    <Footer></Footer>
                </Suspense>
            </div>
        )
    }
}