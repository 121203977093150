import React, { Component, Suspense } from 'react';
import quote from "../images/quote.png"
import quoteleft from "../images/quote-left.png"
let Header = React.lazy(() => import("../header"));
let Footer = React.lazy(() => import("../footer"));

export default class Career extends Component {
    render() {
        return (
            <div class="myyshoppWrapperInner myyshopp-main-wrapper">
                <Suspense fallback={<div></div>}>
                    <Header></Header>
                    <div class="container myyshopp-container pb-0">
                        <section class="">
                            <div class="col-md-12">
                                <h1 class="main-head text-center mt-0">Join Us: </h1>
                            </div>
                            <div class="gray-text-dim-div">
                                <img src={quote} class="quote-img1" alt=""></img>
                                <div class="gray-text-dim"> At MyyShopp, we believe: to be the best, start by hiring the best.  </div>
                                <img src={quoteleft} class="quote-img2" alt=""></img>
                            </div>
                            <div class="col-md-12 text-center">
                                <h4 class="head-thin mb-4 txtclr-lightblue">We focus on nurturing talent within MyyShopp and offer career progression opportunities to our employees at all levels.</h4>
                                <p class="txtclr-lightblue">Our work culture is based on our fundamental core values and our work environment constantly challenges our people and offers them myriad opportunities for learning and development. The culture at MyyShopp is extremely friendly and open. We have a flat and transparent operating (and reporting) structure that is highly empowering and hugely encourages initiative. Self-driven and enterprising individuals thrive in our environment.</p><br></br>
                                <h4 class="head-thin mb-4 txtclr-lightblue">In fact, we are looking for some of those great people right now!</h4>
                                <h4 class="head-thin mb-4 txtclr-lightblue">If you want to be part of MyyShopp’s exciting future,</h4>
                            </div>
                        </section>
                        <section class="mshopp-openrole-main">
                            <h1 class="head-bold-normal text-center text-capitalize">Open roles</h1>
                            <div class="col-md-12">
                                <h3 class="openrole-head">Customer Support Executive</h3>
                                <div class="row">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <h3 class="openrole-head">Sales</h3>
                                <div class="row">
                                </div>
                            </div>
                        </section>
                        <section class="text-center pb-2 mt-5">
                            <p class="head-thin-lg">Get in touch with us @</p><br></br>
                            <a href="#" class="theme-round-btn text-lowercase">iam@myyshopp.com</a>
                        </section>
                    </div>
                    <Footer></Footer>
                </Suspense>
            </div>
        )
    }
}