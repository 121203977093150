import React, { Component,Suspense } from "react";
// import MraHeader from "./header";
// import MraFooter from "./footer";
import bannerImage from "./assets/images/bannerImage.jpg"
import BorderImg from "./assets/images/separator.svg";
import blog3 from "./assets/images/mraBlog3.jpg"
import blog1Sub from "./assets/images/bakery_image1.png"
import blog2Sub from "./assets/images/bakery_image2.png"
import blog3Sub from "./assets/images/bakery_image3.png"
let MraHeader = React.lazy(() => import("./header"));
let MraFooter = React.lazy(() => import("./footer"));
export default class BlogsDetails extends Component {
    render() {
        return (
            <>
                <div className="mraInnerSec">
                <Suspense fallback={<div></div>}>
                    <div className="coverWraper">
                        <MraHeader />
                        <div className="contentWrap">
                            <div className="bannerImginner">
                                <img src={bannerImage} alt="" />
                                <div className="mraTitle">
                                    <div className="contentSectn">
                                        <h1>Blog</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="mraBorders">
                               
                            </div>
                            <div className="storeCoverWrap mraAbt">

                                <div className="container">
                                    <div className="mraTitlesInner mraBlogs">
                                        <div className="mraTitle">
                                            <span>OUR LATEST</span>
                                            <img src={BorderImg} alt="" />
                                            <div className="contentSectn" style={{ margin: "0px" }}>
                                                <h1 >Blogs</h1>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4  ">
                                                <div className="mraBlogItem">
                                                    <img src={blog3} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="mraBlogTxt">
                                                    <h1>Bakery Goods</h1>
                                                    <h4>MARCH 19, 2022</h4>
                                                    <p>A wave of innovation sweeping the bakery market includes a revival of old classics, the rise of the individual portion, and new dining occasions to enjoy them. By working hard to stay bakery experts on ingredients, their functionalities, interactions, and their related processes. The complexity of applying knowledge in the development of new bakery products is our daily job. Whether it is transferring a successful product from abroad to your local bakery and ingredients, creating something completely new and unheard of or finetuning the last bit of your development. Our bakery experts will help facilitate your next innovation to stay on top of your market. Considering that there are several thousand varieties of bread, cakes, biscuits, and pastries it is near to impossible to master them all by heart. However, by approaching each product from the bakery process involved combining with a formulation coming from our bakery knowledge database we have a great starting point. Our product development consultant will transfer this to your local ingredients to develop specifically to your requirements: local taste, ingredients, desired shelf life and the installed production line.</p>
                                                    <p style={{ height: "auto" }}><b style={{textDecoration:"underline",fontWeight:"bold"}}>Cookies</b></p>
                                                    <p>The definition of cookies will vary per country and culture, as some will it cakes and other biscuits. We distinguish here the fact that use one of the following techniques: depositing, wire-cut or extrusion. Next, to that, they have certain spread during baking due to the high levels of fat and/ or sugar in the formulation. Depending on the choice of ingredients and the ratios in the formulations, we can create soft or hard cookies. By using extrusion techniques we can use up to three different coloured doughs with a centre-filling, creating a hard biscuit with a soft centre.</p>
                                                    <div className="col-md-4  ">
                                                        <div className="row">
                                                            <div className="mraBlogItem">
                                                                <img src={blog1Sub} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/*  */}
                                                    <p style={{ height: "auto" }}><b style={{textDecoration:"underline",fontWeight:"bold"}}>Pastries</b></p>
                                                    <p>Although pastry is also used as a collective for several bakery products we intend here the puff pastry, filo types together with the pies. Some products, considered to be sweet crusts, can be ranged under cookies or cakes (e.g. mooncakes) as well, but are often placed into the pastry category. The principle of the pastry product is that the filling(s) will make that the product becomes savoury or sweet, the dough itself is quite a neutral tasting.
                                                    </p>
                                                    <div className="col-md-4  ">
                                                        <div className="row">
                                                            <div className="mraBlogItem">
                                                                <img src={blog2Sub} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p style={{ height: "auto" }}><b style={{textDecoration:"underline",fontWeight:"bold"}}>Cupcakes</b></p>
                                                    <p>Cupcake & Cake Flavors - choose from over 100 flavours with fun themes, delicious flavour combos and seasonal favourites. Bel Air ... Full List of Flavors. Daily Classics Descriptions... Vanilla cake, vanilla pastry cream filling, chocolate frosting.
                                                    </p>
                                                    <div className="col-md-4  ">
                                                        <div className="row">
                                                            <div className="mraBlogItem">
                                                                <img src={blog3Sub} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <MraFooter />
                    </div>
                    </Suspense>
                </div>
            </>
        )
    }
}