import React, { Component,Suspense } from 'react';
import location from "../images/pin.png";
import phone from "../images/phone.png";
let Header = React.lazy(() => import("../header"));
let Footer = React.lazy(() => import("../footer"));
export default class Contact extends Component {
    render() {
        return (
            <div class="myyshoppWrapperInner myyshopp-main-wrapper">
                <Suspense fallback={<div></div>}>
                <Header></Header>
                <div class="container myyshopp-container">
                    <section class="row contact-section">
                        <div class="col-md-12 mb-2 mb-md-5">
                            <h1 class="head-bold-normal text-center">Contact Us</h1>
                        </div>
                        <div class="col-md-6">
                            <div className="d-flex mt-4">
                                <div>
                                    <img src={phone} width="30" class="mr-2" alt=""></img>
                                </div>
                                <div>
                                    <h3 class="subhead text-left mb-0">Phone / Email</h3>
                                    <p className="font-18 mb-0"><a href="tel:+91 9820041253" className="txtclr-lightblue">+91 98 20 04 12 53</a></p>
                                    <p className="font-18"><a className="txtclr-lightblue" href="mailto:support@myyshopp.com?Subject=Hello%20Sir" target="_top">support@myyshopp.com</a></p>
                                </div>
                            </div>
                            <div className="d-flex mt-4">
                                <div>
                                    <img src={location} width="30" class="mr-2" alt=""></img>
                                </div>
                                <div>
                                    <h3 class="subhead text-left">Address</h3>
                                    <p className="font-18"><strong>Business Headquarters</strong></p>
                                    <p className="font-18">MyyShopp eCommerce Technologies Pvt. Ltd. 901 B, Level 9, Kailas Business Park, Hiranandani Gardens Link Roads, Vikhroli West, Mumbai. MH 400079 IN</p>
                                    <p className="font-18">Business Enquiries: <a href="tel:+91 9820041253" className="txtclr-lightblue">+91 98 20 04 12 53</a></p>
                                    <br></br>                                   
                                    <p className="font-18"><strong>Registered Office</strong></p>
                                    <p className="font-18">MyyShopp eCommerce Technologies Pvt. Ltd. 4th Floor, 430, Narpatgiri Corner, Mangalwar Peth, Narpatgiri Chowk, Pune. MH 411011 IN</p>
                                    <br></br>                           
                                    <p className="font-18"><strong>Development Centre</strong></p>
                                    <p className="font-18">2nd Floor, Nila Building, Technopark Campus, Trivandrum, Kerela 695581 IN</p>                           
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <input type="text" placeholder="Name:" class="mshopp-input-design"></input>
                            <input type="text" placeholder="Email:" class="mshopp-input-design"></input>
                            <input type="text" placeholder="Subject:" class="mshopp-input-design"></input>
                            <textarea placeholder="Message:" class="mshopp-input-design"></textarea>
                            <p class="text-center"><button class="small-btn large-btn-round">Send</button></p>
                        </div>                       
                    </section>
                </div>
                <Footer></Footer>
                </Suspense>
            </div>
        )
    }        
}