import React, { Component } from 'react';
import CartBtn from "../restaurant/addcartBtn"
import Customize from "../restaurant/customize"
import RestaurantDetails from "../restaurant/restaurantDetails"
import { getData} from '../services';
import chilli from "../images/icons/chilli.png"
import close from "../images/icons/close-red.png"
import $ from "jquery";
export default class wishlist extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
    this.state = {
      activePage: 1,
      itemListingData: []
    };
  }
  componentDidMount() {
    $(".dashboardMenuLink").removeClass("dashboardActive");
    $("#wishlistLink").addClass("dashboardActive");
    let userId = localStorage.getItem('userId');
    const postData = {
      functionName: "userdashboarddetails/wishList",
      currentUserId: userId
    }
    getData(postData).then((data) => {
      console.log("dataa",data)
      if (data.data.success == "1") {
        this.setState({
          itemListingData: data.data.result.itemListingDetails,
        });
      }
    })
  }
  readMore(itemID) {
    $("#readmore" + itemID).show();
  }
  wishListClick(item, wishId) {
    let userId = localStorage.getItem('userId');
    let secUserId = userId
    let storeId = window.sessionStorage.getItem('storeId');
    let itemId = item
    if (userId != null && userId != '') {
      const postData1 = {
        functionName: "removewishlist",
        wishListId: wishId
      }
      getData(postData1).then((data) => {
        if (data.data.success == "1") {
          this.setState({
            itemListingData: data.data.result.deletewishList,
          });
          window.location.reload();
        }
      })
      const postData = {
        functionName: "userdashboarddetails/wishList",
        currentUserId: userId
      }
      getData(postData).then((data) => {
        if (data.data.success == "1") {
          this.setState({
            itemListingData: data.data.result.itemListingDetails,
          });
        }
      })
      alert("Item Successfully Removed from Wish List")
      $("#wishListItemId" + item).removeClass("wishListActive")
    }
    else {
      $(".wishList").hide();
      $(".loginPopUp").show();
    }
  }
  render() {
    return (
      <div >
        <div className="accountdetails">
          <div className="dashTitle">Wish List</div>
          <div className="dashSection">
            <div className="wishListItem">
              {(() => {
                if (this.state.itemListingData.length > 0) {
                  return (
                    <div>
                      {this.state.itemListingData.map((itemListingArrayObject, itemListingArrayIndex) => (
                        <div className="productList">
                          <div className="productItem restaurantItem">
                            <span id={"wishListItemId" + itemListingArrayObject.itemId} className="wishList wishListActive">
                              <img src={close} title="Remove" alt="" onClick={(e) => { this.wishListClick('Remove', itemListingArrayObject.itemId, itemListingArrayObject.wishListId) }} />
                            </span>
                            <span className="offerText">{itemListingArrayObject.offertext} </span>
                            <div className="productImage">
                              <a href="">
                                <img src={itemListingArrayObject.imageName} alt=""/>
                              </a>
                            </div>
                            <div className="productDetails">
                              <div className="restaurantFoodType">
                                {(itemListingArrayObject.spicetype == 'Low') ?
                                  <span className="spicySec" title="Low Spicy">
                                    <img src={chilli} alt="" ></img>
                                  </span>
                                  :
                                  (itemListingArrayObject.spicetype == 'Medium') ?
                                    <span className="spicySec" title="Medium Spicy">
                                      <img src={chilli} alt=""></img>
                                      <img src={chilli} alt="" ></img>
                                    </span>
                                    :
                                    (itemListingArrayObject.spicetype == 'High') ?
                                      <span className="spicySec" title="High Spicy">
                                        <img src={chilli} alt=""></img>
                                        <img src={chilli} alt=""></img>
                                        <img src={chilli} alt=""></img>
                                      </span>
                                      :
                                      ''
                                }
                                {(itemListingArrayObject.category == 'Veg') ?
                                  <span className="vegFood" title="vegetarian"></span> :
                                  <span className="nonVegFood" title="Non-vegetarian"></span>}
                              </div>
                              <div className="productName">
                                <a href="" className="elipsis">{itemListingArrayObject.itemName}</a>
                              </div>
                              <div className="productDescriptionDiv">
                                <div className="productDescriptionText">{itemListingArrayObject.itemDescription}</div>
                                <span className="productReadMore" onClick={(e) => this.readMore(itemListingArrayObject.itemId)}>read more</span>
                              </div>
                              <div>
                                {(() => {
                                  if (itemListingArrayObject.sizeGroup.length > 1) {
                                    return (
                                      <select id={"cmbSize" + itemListingArrayObject.itemId} onChange={(e) => this.setId(itemListingArrayObject.itemId)}>
                                        {itemListingArrayObject.sizeGroup.map((sizeName, sizeIndex) => (
                                          <option value={sizeName.itemsizname + '-' + sizeName.itemPrice + '-' + sizeName.storeItemSizeId}>{sizeName.itemsizname} - {itemListingArrayObject.currencySymbol} {parseFloat(sizeName.itemMrp).toFixed(window.sessionStorage.getItem('decimalCount'))}</option>
                                        ))}
                                      </select>
                                    )
                                  }
                                  else {
                                    return (
                                      <div className="productQty">{itemListingArrayObject.itemsizname} - {itemListingArrayObject.currencySymbol} {parseFloat(itemListingArrayObject.itemMrp).toFixed(window.sessionStorage.getItem('decimalCount'))}
                                      </div>
                                    )
                                  }
                                })()}
                                <input type="hidden" id={"hidStoreItemSizeId" + itemListingArrayObject.itemId} value={itemListingArrayObject.storeItemSizeId} ></input>
                              </div>
                              <div className="addCartButton">
                                {(itemListingArrayObject.isTopping == true) ?
                                  <div className="saveMore" onClick={(e) => this.saveMore(itemListingArrayObject.itemId)}>Customize</div>
                                  : ''}
                                <CartBtn isMultiSize={itemListingArrayObject.sizeGroup.length} storeId={itemListingArrayObject.storeId} itemId={itemListingArrayObject.itemId} customize={itemListingArrayObject.isTopping} index={itemListingArrayObject.itemId + '-' + itemListingArrayObject.storeItemSizeId} itemName={itemListingArrayObject.itemName} itemSize={itemListingArrayObject.itemsizname} itemMrp={itemListingArrayObject.itemMrp} itemImage={itemListingArrayObject.imageName} storeItemSizeId={itemListingArrayObject.storeItemSizeId} itemAddedColour="itemAddedColour"></CartBtn>
                              </div>
                            </div>
                          </div>
                          <div class="popupWrap restaurantDetailsPopUp" id={"readmore" + itemListingArrayObject.itemId}>
                            <RestaurantDetails itemId={itemListingArrayObject.itemId} itemName={itemListingArrayObject.itemName} itemDescription={itemListingArrayObject.itemDescription} ></RestaurantDetails>
                          </div>
                          {itemListingArrayObject.sizeGroup.map((toppingsObject, toppingsIndex) => (
                            <div>
                              {toppingsObject.toppingData.length > 0 ?
                                <div class="popupWrap customizePopUp" id={"customize" + toppingsObject.storeItemSizeId}>
                                  <Customize isMultiSize={itemListingArrayObject.sizeGroup.length} storeId={itemListingArrayObject.storeId} itemId={itemListingArrayObject.itemId} customize={itemListingArrayObject.isTopping} index={itemListingArrayObject.itemId + '-' + itemListingArrayObject.storeItemSizeId} toppings={toppingsObject.toppingData} storeItemSizeId={toppingsObject.storeItemSizeId} itemSize={itemListingArrayObject.itemsizname} itemName={itemListingArrayObject.itemName} itemPrice={itemListingArrayObject.itemMrp} itemImage={itemListingArrayObject.imageName} itemAddedColour="itemAddedColour"></Customize>
                                </div>
                                :
                                ''
                              }
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  )
                }
                else {
                  return (
                    <div className="nodataItem">No item added to wishlist</div>
                  )
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
