import React, { Component,Suspense } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
// import LokalInHeader from "../lokalIn/header"
// import LokalFooter from "../lokalIn/footer"
import Slider from "react-slick";
import bannerImg from '../images/lokal-seller/banner1.png'
import SignupSub from "./signupSub"
import { fetchSiteDetails } from "../config";
let LokalInHeader = React.lazy(() => import("../lokalIn/header"));
let LokalFooter = React.lazy(() => import("../lokalIn/footer"));
export default class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flag: false
        }
    }
    componentDidMount() {
        var siteId = localStorage.getItem('siteId')
        if (siteId !== null && siteId !== "null" && siteId !== "") {
            this.setState({
                flag: true
            });
        }
        else {
            this.intervalId = setInterval(this.timer.bind(this), 1000);
        }
    }
    timer() {
        var siteId = localStorage.getItem('siteId')
        if (siteId !== null && siteId !== "null" && siteId !== "") {
            window.location.reload()
        }
        else {
            fetchSiteDetails()
        }
    }
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 200,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
        };
        return (
            this.state.flag === true ?
                <div className="coverWraper lklSellerWrap lklSellerSignupWrap">
                     <Suspense fallback={<div></div>}>
                    <LokalInHeader />
                    <div className="lkcontentWrap">
                        <div className="lkbannerSection">
                            <Slider {...settings}>
                                <div>
                                    <div className="lkbannerItem">
                                        <div className="lkbannerImage"><img src={bannerImg} alt="" /></div>
                                        <div className="lkbannerContent lkbannerContentmargin">
                                            <h1>
                                                Sell Online with just <br></br> a few clicks!!
                                            </h1>
                                            <p>It’s Simple. It’s Quick. It’s Efficient!</p>
                                            <div className="bannerSignup">
                                                <SignupSub index={"1"}></SignupSub>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                            <div className="bannerSignupFixed">
                                <SignupSub index={"2"}></SignupSub>
                            </div>
                        </div>
                    </div>
                    <LokalFooter />
                    </Suspense>
                </div>
                : ""
        )
    }
}