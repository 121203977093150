import React, { Component } from 'react';
//import Slider from "react-slick";
import CartBtn from "../commonItem/addcartBtn"
import { Link } from 'react-router-dom';
import offer from "../images/icons/star.png"
import Pagination from "react-js-pagination";
export default class items extends Component {
    constructor(props) {
        super(props)
        this.state = {
        };
    }
    render() {
        return (
            <div>
                {this.props.topCategoryResult1.length > 0 ?
                    <div >
                        {/* <Slider {...slider}> */}
                        {this.props.topCategoryResult1.map((itemListingObject, index) => (
                            <div className="productList">
                                <div className="productItem restaurantItem">
                                    {itemListingObject.is_offer === "1" ?
                                        <span id={'offerSpan'} className="offerText"><img src={offer} alt="" /> {itemListingObject.offer_text}</span>
                                        : ""}
                                    <div className="productImage">
                                        <Link to={"/cmItemProfile" + '/' + itemListingObject.item_id}>
                                            <img src={itemListingObject.item_image} alt="" />
                                        </Link>
                                    </div>
                                    <div className="productDetails">
                                        <div className="productName">
                                            <a href="/#" className="elipsis" title={itemListingObject.item_name}>{itemListingObject.item_name}</a>
                                        </div>
                                        <div className="productDescriptionText">{itemListingObject.item_title}</div>
                                        <div className="productPrice">
                                            <span className="newPrice">Rs. {parseFloat(itemListingObject.net_amount).toFixed(window.sessionStorage.getItem('decimalCount'))}</span>
                                            <span className="oldPrice">Rs. {parseFloat(itemListingObject.mrp).toFixed(window.sessionStorage.getItem('decimalCount'))}</span>
                                        </div>
                                        <div className="itemSizeShow">
                                            Sizes:
                                            {itemListingObject.sizeResult.map((itemSizeObject, itemSizeIndex) => (
                                                <span>{itemSizeObject.sizeName}</span>
                                            ))}
                                        </div>
                                        <div className="addCartButton">
                                            <CartBtn itemId={itemListingObject.item_id} itemName={itemListingObject.item_name} storeId={itemListingObject.store_id} itemTitle={itemListingObject.item_title} brand={itemListingObject.brand_name} itemSize={itemListingObject.sizeResult} netAmount={itemListingObject.net_amount} mrp={itemListingObject.mrp} itemImage={itemListingObject.item_image} index={itemListingObject.item_id} itemMasterId={itemListingObject.item_master_id} itemAddedColour="itemAddedColour"></CartBtn>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* </Slider> */}
                        {this.props.rowCount > 24 ?
                            <div className="paginationSection" id="divPagination">
                                {this.props.divPagination == '1' ? <Pagination
                                    //hideNavigation
                                    activePage={this.props.activePage}
                                    itemsCountPerPage={25}
                                    totalItemsCount={this.props.rowCount}
                                    pageRangeDisplayed={10}
                                    onChange={this.props.handlePageChange}
                                /> : ''}
                            </div>
                            : ""}
                    </div>
                    :
                    ''
                }
            </div>
        )
    }
}
