import { getData } from './services';
export async function setData(){
    console.log('3333333333333333333');
    const postData = {
        functionName: "getsitedetail",
        siteUrl: window.location.host,
        //siteUrl: "www.lokal.in",
        storeId: "",
        sellerId: "",
        categoryId: ""
      }
      return new Promise((resolve, reject) => {
      getData(postData).then((data) => {
        console.log('site data...', data);
        console.log('4444444444444444444');
        if (data.data.success === "1") {       
          
            if (data.data.results.siteData[0].storeId !== "") {
              window.sessionStorage.setItem('subDomainFlag', data.data.results.siteData[0].subDomainFlag)
              window.sessionStorage.setItem('storeId', data.data.results.siteData[0].storeId)
              window.sessionStorage.setItem('mainGroupCategory', data.data.results.siteData[0].mainGroupCategoryId)
              window.sessionStorage.setItem('mainCategoryId', data.data.results.siteData[0].mainCategoryId);
              window.sessionStorage.setItem('storeMobile', data.data.results.siteData[0].storeMobileNumber)
              window.sessionStorage.setItem('storeEmail', data.data.results.siteData[0].storeEmail)
            }
            var siteNameReplace = data.data.results.siteData[0].siteUrl.replace("www.", "")
            var siteName = siteNameReplace.charAt(0).toUpperCase() + siteNameReplace.slice(1)
            window.sessionStorage.setItem('siteName', siteName);
            localStorage.setItem('siteId', data.data.results.siteData[0].siteId);
            localStorage.setItem('themeName', data.data.results.siteData[0].themeName);
            window.sessionStorage.setItem('hasMultipleCheckout', data.data.results.siteData[0].hasMultipleCheckout);
            window.sessionStorage.setItem('authenticationApi', data.data.results.siteData[0].authenticationApi);
            window.sessionStorage.setItem('hasAuthentication', data.data.results.siteData[0].hasAuthentication);
            window.sessionStorage.setItem('googleAnalyticsId', data.data.results.siteData[0].googleAnalyticsId);
            window.sessionStorage.setItem('fbPixelId', data.data.results.siteData[0].fbPixelId);
            window.sessionStorage.setItem('sellerId', data.data.results.siteData[0].sellerId);
            window.sessionStorage.setItem('siteCategoryId', data.data.results.siteData[0].siteCategoryGroupId);
            localStorage.setItem('themeId', data.data.results.siteData[0].themeId);
            window.sessionStorage.setItem('decimalCount', data.data.results.siteData[0].decimalCount);
            window.sessionStorage.setItem('hasParcel', data.data.results.siteData[0].hasParcel);   
            window.sessionStorage.setItem('IsEmbeded', true);
            window.sessionStorage.setItem('siteDetails', JSON.stringify(data.data.results));
            if (data.data.results.siteData[0].siteCategoryGroupId !== 0) {
              localStorage.setItem('hasLocation', '1');
              window.sessionStorage.setItem('displayCity', data.data.results.siteData[0].siteCategoryData[0].display_city);
              window.sessionStorage.setItem('seoTitle', data.data.results.siteData[0].siteCategoryData[0].seo_title);
              window.sessionStorage.setItem('seoKeyword', data.data.results.siteData[0].siteCategoryData[0].seo_keyword);
              window.sessionStorage.setItem('seoDescription', data.data.results.siteData[0].siteCategoryData[0].seo_description);
            }
            else {
              window.sessionStorage.setItem('countryCode', data.data.results.siteData[0].sellerData[0].countryCode);
              localStorage.setItem('hasLocation', data.data.results.siteData[0].sellerData[0].hasLocation);
              window.sessionStorage.setItem('seoTitle', data.data.results.siteData[0].sellerData[0].storeData[0].seo_title);
              window.sessionStorage.setItem('seoKeyword', data.data.results.siteData[0].sellerData[0].storeData[0].seo_keywords);
              window.sessionStorage.setItem('seoDescription', data.data.results.siteData[0].sellerData[0].storeData[0].seo_description);
            }
            window.sessionStorage.setItem('seoImage', data.data.results.siteData[0].logoPath);
            window.sessionStorage.setItem('seoUrl', data.data.results.siteData[0].siteUrl);
            if (data.data.results.siteData[0].sellerId !== 0 && (window.sessionStorage.getItem('storeId') === null || window.sessionStorage.getItem('storeId') === "null")) {
              window.sessionStorage.setItem('storeId', data.data.results.siteData[0].sellerData[0].storeData[0].storeId);
              window.sessionStorage.setItem('mainCategoryId', data.data.results.siteData[0].sellerData[0].storeData[0].mainCategoryId);
              window.sessionStorage.setItem('mainGroupCategory', data.data.results.siteData[0].sellerData[0].storeData[0].mainGroupCategoryId)
            }            
           // $("#defaultBannerLoad").hide();
           resolve(true);
        }
      });
    });
}