import React, { Component ,Suspense} from "react";
// import Header from "./header";
// import Footer from "./footer";
import Background from './images/about-bg-logo.png';
let Header = React.lazy(() => import("./header"));
let Footer = React.lazy(() => import("./footer"));
var sectionStyle = {
    width: "100%",
    height: "500px",
    float: "left",
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    margin: "0 auto",
    backgroundPosition: "50% 190px",
    backgroundSize: "460px"
};
export default class AboutUs extends Component {
  componentDidMount(){
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div className="perfumeTheme">
        <Suspense fallback={<div></div>}>
          <Header/>
        <div className="topcategorysec fragranceAbout aboutDetailsViewMore"    style={{position:"relative"} } >

                <div className="container" id="aboutus" style={ sectionStyle }>
                  <span className="perfumeMainHead" >
                <h1  className="abtH1" style={{textAlign:"left", textTransform:"none", justifyContent:"left", marginBottom:"0px"}}>About us </h1>
                <h2>Emissa Perfumes</h2>
                  </span>
                    <div className="topcategory ads" style={{marginTop:"25px"}}>
                        <div className="topcatList">
                          Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse
molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros
et accumsan et iusto odio. iriure dolor in hendrerit in vulputate velit esse
molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros
et accumsan et iusto odio vel illum dolore eu feugiat nulla facilisis at vero eros
et accumsan et iusto odio
                        </div>
                    </div>
                </div>

            </div>
        <Footer />
        </Suspense>
      </div>
    )
  }
}