import React, { Component } from 'react';
import $ from "jquery";
import deleteCart from "../images/icons/deleteCart.png"
import Parser from 'html-react-parser';
export default class addcartBtn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedProduct: {},
      isAdded: false,
      quantity: '',
    };
    this.addToCart = this.addToCart.bind(this);
    this.isNumber = this.isNumber.bind(this);
    this.setCartQuantity = this.setCartQuantity.bind(this);
  }

  componentDidMount() {
    this.setCartQuantity();
  }
  setCartQuantity() {
    var itemNo = this.props.index;
    this.setState({
      quantity: this.props.cartItemQuantity,
    })
  }
  addToCart(itemNo, type) {
    var packIndex = $("#hidPackIndex" + itemNo).val()
    var item_names = $("#itemName" + packIndex).val()
    var item_ids = $("#itemId" + packIndex).val()
    var item_prices = $("#price" + packIndex).val()
    var store_item_stock_ids = $("#sisId" + packIndex).val()
    var pack_ids = $("#packId" + packIndex).val()
    var stock_quantity = $("#itemStockQuantity" + packIndex).val()
    var itemImage = $("#image" + packIndex).val()

    let themeId = localStorage.getItem('themeId')
    let isDelete = false;
    if (type === "addToCart") {
      if (themeId === '36') {
        if ($("#addToCart" + itemNo).hasClass("cart_clk") === false) {
          $('#addToCart' + itemNo).addClass("cart_clk");
          $('#txtQty' + itemNo).val(1);
        }
        $('.addcartbtnTheme' + itemNo).addClass("addcartActive");
      }
      else {
        $('#addToCart' + itemNo).addClass("cart_clk");
        $('#txtQty' + itemNo).val(1);
      }
    }
    else if (type === "inc") {
      var prnum = $('#txtQty' + itemNo).val();
      if (parseInt(prnum) >= parseInt(stock_quantity)) {
        prnum = stock_quantity;
        $("#" + this.props.qtyError + itemNo).show();
        $("#" + this.props.qtyError + itemNo).text("Only " + stock_quantity + " qty available")
        $("#" + this.props.itemCount + itemNo).text($('#txtQty' + itemNo).val())
      }
      else {
        $("#" + this.props.qtyError + itemNo).hide();
        $("#" + this.props.qtyError + itemNo).text("")
        if (prnum > 0) {
          prnum++;
          $('#txtQty' + itemNo).val(prnum);
        }
        else if (prnum == 0) {
          $('#txtQty' + itemNo).val(1);
        }
      }
    }
    else if (type === "dec") {
      $("#" + this.props.qtyError + itemNo).hide();
      $("#" + this.props.qtyError + itemNo).text("")
      var prnum = $('#txtQty' + itemNo).val();
      prnum--;
      if (prnum == 0) {
        $('#addToCart' + itemNo).removeClass("cart_clk");
        if (themeId === '36') {
          $('.addcartbtnTheme' + itemNo).removeClass("addcartActive");
          $(".addToCartTheme" + itemNo).text('+')
        }
        else if (themeId === '34'&& themeId == '29' && themeId === '4') {
          //  alert("if ......"+itemNo)                 
          //$('#addToCart' + itemNo).text("ADD TO CART");
        }
      }
      $('#txtQty' + itemNo).val(prnum);
    }
    else if (type === "decZero") {
      $("#" + this.props.qtyError + itemNo).hide();
      $("#" + this.props.qtyError + itemNo).text("")
      var prnum = $('#txtQty' + itemNo).val();
      if (prnum == 0) {
        $('#addToCart' + itemNo).removeClass("cart_clk");
        if (themeId === '36') {
          $('.addcartbtnTheme' + itemNo).removeClass("addcartActive");
          $(".addToCartTheme" + itemNo).text('+')
        }
        else if (themeId === '34'&& themeId == '29'&& themeId === '4') {
          //  alert("if ......"+itemNo)                 
          // $('#addToCart' + itemNo).text("ADD TO CART");
        }
      }
      $('#txtQty' + itemNo).val(prnum);
    }
    if ($('#txtQty' + itemNo).val() == 0) {
      $("#" + this.props.itemCount + itemNo).text("");
      if (themeId != '36' && themeId != '34'&& themeId != '29' && themeId != '4') {
        $("#addToCart" + itemNo).text("ADD TO CART")
      }
      this.setState({
        isAdded: false
      });
      isDelete = true;
    }
    else {
      $("#" + this.props.itemCount + itemNo).text($('#txtQty' + itemNo).val())
      $("#" + this.props.itemAddedColour + itemNo).addClass('itemAdded')
      $("#" + this.props.itemAddedColour + itemNo).show()
      if (themeId !== '36' && themeId !== '34'&& themeId !== '29' && themeId !== '4') {
        $("#addToCart" + itemNo).text("ADDED")
      }
      this.setState({
        isAdded: true
      });
    }
    let itemQuantity = this.props.cartItemQuantity;
    itemQuantity = $('#txtQty' + itemNo).val();
    this.setState({
      quantity: $('#txtQty' + itemNo).val(),
    })
    this.updateCart(item_names, item_ids, $('#txtQty' + itemNo).val(), item_prices, store_item_stock_ids, pack_ids, isDelete, stock_quantity, itemImage)
    if (this.props.fromPage != "Profile") {
      this.props.itemLoad()
    }
    if (themeId === '36') {
      var itemListCount = parseInt($(".productItemListing").find('.productList').length) + (this.props.itemOffset)
      for (var i = this.props.itemOffset; i < itemListCount; i++) {
        var itemQty = $('#txtQty' + i).val()
        if (i != itemNo) {
          $(".addcartbtnTheme" + i).removeClass("addcartActive");
          if (itemQty < 1) {
            $("#addToCartTheme" + i).text('+');
          }
          else {
            $("#addToCartTheme" + i).text(itemQty);
          }
        }
        else {
          if (itemQty > 0) {
            $(".addcartbtnTheme" + i).addClass("addcartActive");
          }
        }
      }
    }
  }

  updateCart(item_names, item_ids, quantity, item_prices, store_item_stock_ids, pack_ids, isDelete, stock_quantity, itemImage) {
    let storeId = window.sessionStorage.getItem('storeId');
    var selectedCartProduct = JSON.parse(localStorage.getItem('selectedCartProduct')) || []
    if (localStorage.getItem('selectedCartProduct') === null) {
      if (isDelete === false || quantity > 0) {
        var selectedProduct = {
          storeId: storeId,
          item_names: item_names,
          item_ids: item_ids,
          quantity: quantity,
          item_prices: item_prices,
          store_item_stock_ids: store_item_stock_ids,
          pack_ids: pack_ids,
          stock_quantity: stock_quantity,
          item_image: itemImage,
          itemNo: this.props.index
        }
        selectedCartProduct.push(selectedProduct)
        localStorage.setItem('selectedCartProduct', JSON.stringify(selectedCartProduct))
      }
    }
    else {
      var selectedCartProduct = JSON.parse(localStorage.getItem('selectedCartProduct')) || []
      var cookieNewValue = [];
      var cookieArray = selectedCartProduct;
      var cookieUpdated = false
      var cookieNewValueCount = 0;
      for (var i = 0; i < cookieArray.length; i++) {
        if (cookieArray[i] !== null && cookieArray[i] !== "") {
          var cookieStoreId = cookieArray[i].storeId
          var cookieItemId = cookieArray[i].item_ids
          if (item_ids === cookieItemId && storeId === cookieStoreId) {
            if (isDelete === false && quantity > 0) {
              cookieNewValue[cookieNewValueCount] =
              {
                storeId: storeId,
                item_names: item_names,
                item_ids: item_ids,
                quantity: quantity,
                item_prices: item_prices,
                store_item_stock_ids: store_item_stock_ids,
                pack_ids: pack_ids,
                stock_quantity: stock_quantity,
                item_image: itemImage,
                itemNo: this.props.index
              }
              cookieNewValueCount++;
            }
            cookieUpdated = true
          }
          else {
            cookieNewValue[cookieNewValueCount] = cookieArray[i];
            cookieNewValueCount++;
          }
        }
      }
      if (cookieUpdated === false) {
        if (quantity > 0) {
          cookieNewValue[cookieNewValueCount] = {
            storeId: storeId,
            item_names: item_names,
            item_ids: item_ids,
            quantity: quantity,
            item_prices: item_prices,
            store_item_stock_ids: store_item_stock_ids,
            pack_ids: pack_ids,
            stock_quantity: stock_quantity,
            item_image: itemImage,
            itemNo: this.props.index
          }
          cookieUpdated = true
        }
      }
      localStorage.setItem('selectedCartProduct', JSON.stringify(cookieNewValue))
    }
    let cartLength = 0;
    if (localStorage.getItem('selectedCartProduct') !== null) {
      cartLength = parseInt(cartLength) + (JSON.parse(localStorage.getItem('selectedCartProduct')).length);
    }
    if (localStorage.getItem('selectRestaurantCart') !== null) {
      cartLength = parseInt(cartLength) + (JSON.parse(localStorage.getItem('selectRestaurantCart')).length);
    }
    if (localStorage.getItem('selectCommonItemCart') !== null) {
      cartLength = parseInt(cartLength) + (JSON.parse(localStorage.getItem('selectCommonItemCart')).length);
    }
    $("#cartCount").text(cartLength)
  }
  isNumber = (e) => {
    var itemNo = this.props.index;
    var packIndex = $("#hidPackIndex" + itemNo).val()
    var item_names = $("#itemName" + packIndex).val()
    var item_ids = $("#itemId" + packIndex).val()
    var item_prices = $("#price" + packIndex).val()
    var store_item_stock_ids = $("#sisId" + packIndex).val()
    var pack_ids = $("#packId" + packIndex).val()
    var stock_quantity = $("#itemStockQuantity" + packIndex).val()
    var itemImage = $("#image" + packIndex).val()
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      var prnum = $('#txtQty' + itemNo).val();
      if (parseInt(prnum) >= parseInt(this.props.stock_quantity)) {
        prnum = this.props.stock_quantity;
        $("#" + this.props.qtyError + itemNo).show();
        $("#" + this.props.qtyError + itemNo).text("Only " + this.props.stock_quantity + " qty available")
        $("#" + this.props.itemCount + itemNo).text(this.props.stock_quantity)
        $('#txtQty' + itemNo).val(this.props.stock_quantity)
        let itemQuantity = this.props.cartItemQuantity;
        itemQuantity = $('#txtQty' + itemNo).val();
        this.setState({
          quantity: $('#txtQty' + itemNo).val(),
        })
      }
      else if (prnum == 0) {
        this.addToCart(itemNo, "decZero")
      }
      else {
        $("#" + this.props.qtyError + itemNo).hide();
        $("#" + this.props.qtyError + itemNo).text("");
        $("#" + this.props.itemCount + itemNo).text(e.target.value)
        $('#txtQty' + itemNo).val(prnum)
        let itemQuantity = this.props.cartItemQuantity;
        itemQuantity = $('#txtQty' + itemNo).val();
        this.setState({
          quantity: $('#txtQty' + itemNo).val(),
        })
      }
    }
    else {
      var prnum = e.target.value.replace(/[^0-9\.]/g, '');
      $('#txtQty' + itemNo).val(prnum)
    }
    this.updateCart(item_names, item_ids, $('#txtQty' + itemNo).val(), item_prices, store_item_stock_ids, pack_ids, false, stock_quantity, itemImage)

  }
  render() {
    var itemNo = this.props.index;
    var cartItemFlag = this.props.cartItemFlag;
    let themeId = localStorage.getItem('themeId')
    return (
      <div>
        <div className={" addcartbtn addcartbtnTheme" + itemNo + " " + this.props.addcartActive}>
          <div class="qty">
            {(() => {
              if (themeId === '36' && this.state.isAdded && this.props.cartItemQuantity == 1) {
                return (<span class="dec" onClick={(e) => this.addToCart(itemNo, "dec")} id={"minus" + itemNo}><img src={deleteCart} alt=""/></span>)
              }
              else {
                return (<span class="dec" onClick={(e) => this.addToCart(itemNo, "dec")} id={"minus" + itemNo}>-</span>)
              }
            })()}
            {cartItemFlag === true ?
              <span class="cartNumber">
                <input type="text" className="cartInput" id={"txtQty" + itemNo} value={this.props.cartItemQuantity} onChange={this.isNumber} /></span>
              :
              <span class="cartNumber">
                <input type="text" className="cartInput" id={"txtQty" + itemNo} value={this.state.value} onChange={this.isNumber} /></span>
            }
            <span class="inc addBtn" onClick={(e) => this.addToCart(itemNo, "inc")} id={"add" + itemNo}>+</span>
          </div>
          {(() => {
            if (themeId === '36') {
              return (
                <div id={"addToCart" + itemNo} class={"cartButton cartButtonDefault " + this.props.isCartItemOpenClass + " addToCartTheme" + itemNo} onClick={(e) => this.addToCart(itemNo, "addToCart")}>{this.props.cartLabel}</div>
              )
            }
            else {
              return (
                <div id={"addToCart" + itemNo} class={"cartButton cartButtonDefault " + this.props.isCartItemOpenClass} onClick={(e) => this.addToCart(itemNo, "addToCart")}>{Parser(this.props.cartLabel)}</div>
              )
            }
          })()}
        </div>
      </div>
    )
  }
}
