 import React, { Component,Suspense } from "react";
// import MraHeader from "./header";
// import MraFooter from "./footer";
import bannerImage from "./assets/images/bannerImage.jpg"
import BorderImg from "./assets/images/separator.svg";
import pattern from "./assets/images/pattern-9.svg" 
let MraHeader = React.lazy(() => import("./header"));
let MraFooter = React.lazy(() => import("./footer"));
export default class Branches extends Component {
    render() {
        return (
            <>
                <div className="mraInnerSec">
                <Suspense fallback={<div></div>}>
                    <div className="coverWraper">
                        <MraHeader />
                        <div className="contentWrap">
                            <div className="bannerImginner">
                                <img src={bannerImage} alt="" />
                                <div className="mraTitle">
                                    {/* <span>OUR STORY</span>
                                    <img src={BorderImg} alt="" /> */}
                                    <div className="contentSectn">
                                        <h1>Branches</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="mraBorders">
                                <div className="borderLeftImg">
                                    <img src={pattern} alt="" />
                                </div>
                                <div className="borderRightImg">
                                    <img src={pattern} alt="" />
                                </div>
                            </div>
                            <div className="storeCoverWrap mraAbt">

                                <div className="container">
                                    <div className="mraTitlesInner mraBranches">
                                        <div className="mraTitle">
                                        <span>OUR BRANCHES</span> 
                                            <img src={BorderImg} alt="" />
                                            <div className="contentSectn" style={{ margin: "0px" }}>
                                                <h1 >Branches</h1>
                                            </div>
                                        </div>
                                      <p>Our concept is about the food and drink of course, but it’s also about our team, our facilities, our practices.</p>

                                    </div>
                                </div> 
                            </div>    
                        </div>
                        <MraFooter />
                    </div>
                    </Suspense>
                </div>
            </>
        )
    }
}