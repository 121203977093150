import React, { Component } from 'react'
import { getData, getBuyerAddress, getCartItemsMultiple, callStripe, pageHitAPICall, callEasyPay } from '../services'
import $ from 'jquery'
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { getPageHitAPICall } from "./success";
import WorldLine from '../worldLine/worldLine'
import { loadWorldLine } from '../worldLine/apiService'
import {SquarePaymentsForm,
    CreditCardInput,
  } from 'react-square-web-payments-sdk';
import { event } from 'react-ga';

// import { CreditCard } from "react-square-web-payments-sdk";
const applicationId = 'sandbox-sq0idb-S8mtTwm0OUaa2m48ZEfn5Q'
const locationId = 'LW4DZH5C2YGHJ'



const proxyurl = "https://cors-anywhere.herokuapp.com/";
export default class Payment extends Component {

    constructor(props) {
        super(props)
        this.state = {
            payments: [],
            razorList: [],
            isBambora: false,
            isSquare:false,
            squareAmount:"",
            squareToken:"",
            bamboraAmount: "",
            squareApplicationId:'',
            squareLocationId:''
        }
        this.handlePaymentChange = this.handlePaymentChange.bind(this);
        this.confirmShopping = this.confirmShopping.bind(this);
        this.cartItem = this.cartItem.bind(this);
    }
    componentDidMount() {
        //alert("payment componentDidMount")




        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
        // const postData = {
        //     functionName: "getPaymentOptions",
        //     storeId: window.sessionStorage.getItem('storeId')


        // }
        // getBuyerAddress(postData).then((data) => {

        //     if (data.data.result.paymentOptions.length > 0) {

        //         this.setState({
        //             payments: data.data.result.paymentOptions,
        //             //razorPay:data.data.result.paymentOptions.razorPayResult
        //         });


        //     }
        // });
        // alert("before getPaymentOptions")
        // getBuyerAddress(postData).then((data) => {
        //     //   alert("after getPaymentOptions.........."+data.data.result.paymentOptions.length)
        //     if (data.data.result.paymentOptions.length > 0) {

        //         this.setState({
        //             payments: data.data.result.paymentOptions
        //         });

        //     }
        // });
    }



    handlePaymentChange(paymentsList, paymentTypeId, razorpaykeyid, razorpaykeysecret) {
        if (paymentsList.paymentTypeId === 5) {
            if (paymentsList.paymentGatewayId === 1) {
                $("#hidKey1").val(paymentsList.payResult[0].razorpaykeysecret)
                $("#hidKey2").val(paymentsList.payResult[0].razorpaykeyid)
            }
            else if (paymentsList.paymentGatewayId === 3) {
                $("#hidKey1").val(paymentsList.payResult[0].worldLineEncryptionKey)
                $("#hidKey2").val(paymentsList.payResult[0].worldLineMerchantId)
            }
            else if (paymentsList.paymentGatewayId === 5) {
                $("#hidKey1").val(paymentsList.payResult[0].bamboraMerchantPassscode)
                $("#hidKey2").val(paymentsList.payResult[0].bamboraMerchantId)
                $("#hidKey3").val(paymentsList.payResult[0].bamboraEncodedPasscode)
            }
            else if (paymentsList.paymentGatewayId === 7) {
                $("#hidKey1").val(paymentsList.payResult[0].squareAccessToken)
                $("#hidKey2").val(paymentsList.payResult[0].squareApploicationId)
                $("#hidKey3").val(paymentsList.payResult[0].squareLocationId)
            }
            $("#hidPaymentGatewayId").val(paymentsList.paymentGatewayId)
        }
        else {
            $("#hidKey1").val('')
            $("#hidKey2").val('')
            $("#hidKey3").val('')
            $("#hidPaymentGatewayId").val('')
        }
        $("#txtPaymentMode").val(paymentsList.paymentTypeId)


    }

    getPaymentTypes(storeId) {
        // alert("getPaymentTypes...."+storeId)
        const postData = {
            functionName: "checkout/getPaymentOptions",
            storeId: storeId


        }
        // alert("before getPaymentOptions")
        getData(postData).then((data) => {
             console.log("after getPaymentOptions..........",data)
            if (data.data.result.paymentOptions.length > 0) {

                this.setState({
                    payments: data.data.result.paymentOptions,
                    
                });
                for(let i=0;i<this.state.payments.length;i++){
                    if(this.state.payments[i].payResult.length > 0 && this.state.payments[i].payResult[0].paymentGatewayId === 7){
                        this.setState({
                            squareApplicationId: this.state.payments[i].payResult[0].squareApploicationId,
                            squareLocationId: this.state.payments[i].payResult[0].squareLocationId
                            
                        }); 
                    }
                }

            }
        });
        var sellerID = "";
        var moduleID = 7;
        var siteCategoryGroupId = "";
        if (window.sessionStorage.getItem('siteCategoryId')) {
            siteCategoryGroupId = window.sessionStorage.getItem('siteCategoryId');
        }
        else {
            sellerID = window.sessionStorage.getItem('sellerId')
        }
        pageHitAPICall(sellerID, moduleID, siteCategoryGroupId);
    }
    cardTokenizeResponseReceived= (tokenReceived) =>{
        this.setState({
            isSquare: true,
            squareToken:tokenReceived.token
        }, () => {
            this.confirmShopping();
        });
        // this.squarePopUpSave()
       }
    commonItemCartValueArray() {
        let cityId = localStorage.getItem('cityIdGlobal') == null ? '' : localStorage.getItem('cityIdGlobal')
        let locationId = localStorage.getItem('locationId') == null ? '' : localStorage.getItem('locationId')
        var storeId = $("#hidStoreIdForCheckout").val()
        var commonItem = []
        var cookieNewCommonItemValue = []
        if (JSON.parse(localStorage.getItem('selectCommonItemCart')) != null) {
            if (JSON.parse(localStorage.getItem('selectCommonItemCart')).length > 0) {
                var j = 0
                var selectCommonItemCart = JSON.parse(localStorage.getItem('selectCommonItemCart')) || []
                for (var i = 0; i < selectCommonItemCart.length; i++) {
                    var slot = $("#txtSlot" + selectCommonItemCart[i].storeId).val()
                    var consumerNumber = $("#txtConsumerCard" + selectCommonItemCart[i].storeId).val()
                    var comments = $("#txtUserNeeds" + selectCommonItemCart[i].storeId).val()
                    var promoCodeDetails = $("#hidPromocodeDetails" + selectCommonItemCart[i].storeId).val()
                    var promoCodeArray = ''
                    var isPromoCode = '0'
                    if (promoCodeDetails != null && promoCodeDetails != 'null' && promoCodeDetails != '' && promoCodeDetails !== 'undefined') {
                        promoCodeArray = JSON.parse(promoCodeDetails)
                        isPromoCode = '1'
                    }
                    if (storeId != '') {
                        if (storeId == selectCommonItemCart[i].storeId.toString().trim()) {
                            cookieNewCommonItemValue[j] =
                            {
                                storeId: selectCommonItemCart[i].storeId.toString(),
                                itemId: selectCommonItemCart[i].itemId.toString(),
                                itemName: selectCommonItemCart[i].itemName,
                                quantity: selectCommonItemCart[i].quantity,
                                itemTitle: selectCommonItemCart[i].itemTitle,
                                brand: selectCommonItemCart[i].brand,
                                sizeName: selectCommonItemCart[i].sizeName.toString().trim(),
                                sizeId: selectCommonItemCart[i].sizeId.toString().trim(),
                                cmItemSizeId: selectCommonItemCart[i].cmItemSizeId.toString().trim(),
                                itemMasterId: selectCommonItemCart[i].itemMasterId.toString(),
                                netAmount: selectCommonItemCart[i].netAmount.toString(),
                                mrp: selectCommonItemCart[i].mrp.toString(),
                                itemImage: selectCommonItemCart[i].itemImage,
                                itemNo: selectCommonItemCart[i].itemNo,
                                slotName: slot,
                                consumerNumber: consumerNumber,
                                comments: comments,
                                isPromoCode: isPromoCode,
                                promoCodeDetails: promoCodeArray
                            }
                            j = parseInt(j) + 1
                        }
                    }
                    else {
                        cookieNewCommonItemValue[i] =
                        {
                            storeId: selectCommonItemCart[i].storeId.toString(),
                            itemId: selectCommonItemCart[i].itemId.toString(),
                            itemName: selectCommonItemCart[i].itemName,
                            quantity: selectCommonItemCart[i].quantity,
                            itemTitle: selectCommonItemCart[i].itemTitle,
                            brand: selectCommonItemCart[i].brand,
                            sizeName: selectCommonItemCart[i].sizeName.toString().trim(),
                            sizeId: selectCommonItemCart[i].sizeId.toString().trim(),
                            cmItemSizeId: selectCommonItemCart[i].cmItemSizeId.toString().trim(),
                            itemMasterId: selectCommonItemCart[i].itemMasterId.toString(),
                            netAmount: selectCommonItemCart[i].netAmount.toString(),
                            mrp: selectCommonItemCart[i].mrp.toString(),
                            itemImage: selectCommonItemCart[i].itemImage,
                            itemNo: selectCommonItemCart[i].itemNo,
                            slotName: slot,
                            consumerNumber: consumerNumber,
                            comments: comments,
                            isPromoCode: isPromoCode,
                            promoCodeDetails: promoCodeArray
                        }
                    }
                }
                if (cookieNewCommonItemValue.length > 0) {
                    commonItem[0] = {
                        locationId: locationId,
                        cityId: cityId,
                        selectCommonItemCart: cookieNewCommonItemValue
                    }
                }
            }
        }
        return commonItem;
    }

    restaurantCartValueArray() {
        let locationId = localStorage.getItem('locationId') == null ? '' : localStorage.getItem('locationId')
        var storeId = $("#hidStoreIdForCheckout").val()
        var restaurant = []
        var cookieNewRestaurantValue = []
        if (JSON.parse(localStorage.getItem('selectRestaurantCart')) != null) {
            if (JSON.parse(localStorage.getItem('selectRestaurantCart')).length > 0) {
                var j = 0
                var selectRestaurantCart = JSON.parse(localStorage.getItem('selectRestaurantCart')) || []
                for (var i = 0; i < selectRestaurantCart.length; i++) {
                    var slot = $("#txtSlot" + selectRestaurantCart[i].storeId).val()
                    //alert("slot..."+slot)
                    var consumerNumber = $("#txtConsumerCard" + selectRestaurantCart[i].storeId).val()
                    var comments = $("#txtUserNeeds" + selectRestaurantCart[i].storeId).val()
                    var promoCodeDetails = $("#hidPromocodeDetails" + selectRestaurantCart[i].storeId).val()
                    var promoCodeArray = ''
                    var isPromoCode = '0'
                    if (promoCodeDetails != null && promoCodeDetails != 'null' && promoCodeDetails != '' && promoCodeDetails !== 'undefined') {
                        promoCodeArray = JSON.parse(promoCodeDetails)
                        isPromoCode = '1'
                    }
                    if (storeId != '') {
                        if (storeId == selectRestaurantCart[i].storeId.toString().trim()) {
                            cookieNewRestaurantValue[j] =
                            {
                                storeId: selectRestaurantCart[i].storeId.toString().trim(),
                                itemId: selectRestaurantCart[i].itemId.toString().trim(),
                                itemName: selectRestaurantCart[i].itemName.toString().trim(),
                                quantity: selectRestaurantCart[i].quantity.toString().trim(),
                                itemSize: selectRestaurantCart[i].itemSize.toString().trim(),
                                itemMrp: selectRestaurantCart[i].itemMrp.toString().trim(),
                                itemImage: selectRestaurantCart[i].itemImage.toString().trim(),
                                storeItemSizeId: selectRestaurantCart[i].storeItemSizeId.toString().trim(),
                                itemNo: selectRestaurantCart[i].itemNo.toString().trim(),
                                toppingsData: selectRestaurantCart[i].toppingsData.toString().trim(),
                                slotName: slot,
                                consumerNumber: consumerNumber,
                                comments: comments,
                                isPromoCode: isPromoCode,
                                promoCodeDetails: promoCodeArray
                            }
                            j = parseInt(j) + 1
                        }
                    }
                    else {
                        cookieNewRestaurantValue[i] =
                        {
                            storeId: selectRestaurantCart[i].storeId.toString().trim(),
                            itemId: selectRestaurantCart[i].itemId.toString().trim(),
                            itemName: selectRestaurantCart[i].itemName.toString().trim(),
                            quantity: selectRestaurantCart[i].quantity.toString().trim(),
                            itemSize: selectRestaurantCart[i].itemSize.toString().trim(),
                            itemMrp: selectRestaurantCart[i].itemMrp.toString().trim(),
                            itemImage: selectRestaurantCart[i].itemImage.toString().trim(),
                            storeItemSizeId: selectRestaurantCart[i].storeItemSizeId.toString().trim(),
                            itemNo: selectRestaurantCart[i].itemNo.toString().trim(),
                            toppingsData: selectRestaurantCart[i].toppingsData.toString().trim(),
                            slotName: slot,
                            consumerNumber: consumerNumber,
                            comments: comments,
                            isPromoCode: isPromoCode,
                            promoCodeDetails: promoCodeArray
                        }
                    }
                }
                if (cookieNewRestaurantValue.length > 0) {
                    restaurant[0] = {
                        locationId: locationId,
                        selectRestaurantCart: cookieNewRestaurantValue
                    }
                }

            }
        }
        return restaurant;
    }

    groceryCartValueArray() {
        let cityId = localStorage.getItem('cityIdGlobal') == null ? '' : localStorage.getItem('cityIdGlobal')
        let locationId = localStorage.getItem('locationId') == null ? '' : localStorage.getItem('locationId')
        var storeId = $("#hidStoreIdForCheckout").val()
        var grocery = []
        var cookieNewGroceryItemValue = []
        if (JSON.parse(localStorage.getItem('selectedCartProduct')) != null) {
            if (JSON.parse(localStorage.getItem('selectedCartProduct')).length > 0) {
                var j = 0
                var selectGroceryItemCart = JSON.parse(localStorage.getItem('selectedCartProduct')) || []
                for (var i = 0; i < selectGroceryItemCart.length; i++) {
                    //  var prescriptionId = $("#hidPrescriptionId" + selectGroceryItemCart[i].storeId).val()
                    var slot = $("#txtSlot" + selectGroceryItemCart[i].storeId).val()
                    var consumerNumber = $("#txtConsumerCard" + selectGroceryItemCart[i].storeId).val()
                    var comments = $("#txtUserNeeds" + selectGroceryItemCart[i].storeId).val()
                    var promoCodeDetails = $("#hidPromocodeDetails" + selectGroceryItemCart[i].storeId).val()
                    var promoCodeArray = ''
                    var isPromoCode = '0'
                    if (promoCodeDetails != null && promoCodeDetails != 'null' && promoCodeDetails != '' && promoCodeDetails !== 'undefined') {
                        promoCodeArray = JSON.parse(promoCodeDetails)
                        isPromoCode = '1'
                    }
                    if (storeId != '') {
                        if (storeId == selectGroceryItemCart[i].storeId.toString().trim()) {
                            cookieNewGroceryItemValue[j] =
                            {
                                storeId: selectGroceryItemCart[i].storeId.toString().trim(),
                                item_names: selectGroceryItemCart[i].item_names.toString().trim(),
                                item_ids: selectGroceryItemCart[i].item_ids.toString().trim(),
                                quantity: selectGroceryItemCart[i].quantity.toString().trim(),
                                item_prices: selectGroceryItemCart[i].item_prices.toString().trim(),
                                store_item_stock_ids: selectGroceryItemCart[i].store_item_stock_ids.toString().trim(),
                                pack_ids: selectGroceryItemCart[i].pack_ids.toString().trim(),
                                stock_quantity: selectGroceryItemCart[i].stock_quantity.toString().trim(),
                                item_image: selectGroceryItemCart[i].item_image.toString().trim(),
                                itemNo: selectGroceryItemCart[i].itemNo.toString().trim(),
                                slotName: slot,
                                consumerNumber: consumerNumber,
                                comments: comments,
                                isPromoCode: isPromoCode,
                                promoCodeDetails: promoCodeArray,
                                phIds: ""
                            }
                            j = parseInt(j) + 1
                        }
                    }
                    else {
                        cookieNewGroceryItemValue[i] =
                        {
                            storeId: selectGroceryItemCart[i].storeId.toString().trim(),
                            item_names: selectGroceryItemCart[i].item_names.toString().trim(),
                            item_ids: selectGroceryItemCart[i].item_ids.toString().trim(),
                            quantity: selectGroceryItemCart[i].quantity.toString().trim(),
                            item_prices: selectGroceryItemCart[i].item_prices.toString().trim(),
                            store_item_stock_ids: selectGroceryItemCart[i].store_item_stock_ids.toString().trim(),
                            pack_ids: selectGroceryItemCart[i].pack_ids.toString().trim(),
                            stock_quantity: selectGroceryItemCart[i].stock_quantity.toString().trim(),
                            item_image: selectGroceryItemCart[i].item_image.toString().trim(),
                            itemNo: selectGroceryItemCart[i].itemNo.toString().trim(),
                            slotName: slot,
                            consumerNumber: consumerNumber,
                            comments: comments,
                            isPromoCode: isPromoCode,
                            promoCodeDetails: promoCodeArray,
                            phIds: ""
                        }
                    }
                }



                var k = cookieNewGroceryItemValue.length
                for (var i = 0; i < selectGroceryItemCart.length; i++) {
                    var slot = $("#txtSlot" + selectGroceryItemCart[i].storeId).val()
                    var consumerNumber = $("#txtConsumerCard" + selectGroceryItemCart[i].storeId).val()
                    var comments = $("#txtUserNeeds" + selectGroceryItemCart[i].storeId).val()
                    var promoCodeDetails = $("#hidPromocodeDetails" + selectGroceryItemCart[i].storeId).val()
                    var promoCodeArray = ''
                    var isPromoCode = '0'
                    if (promoCodeDetails != null && promoCodeDetails != 'null' && promoCodeDetails != '' && promoCodeDetails !== 'undefined') {
                        promoCodeArray = JSON.parse(promoCodeDetails)
                        isPromoCode = '1'
                    }
                    if (storeId != '') {
                        // alert("is store")
                        if (storeId == selectGroceryItemCart[i].storeId.toString().trim()) {
                            if (typeof $("#hidPrescriptionId" + selectGroceryItemCart[i].storeId).val() !== 'undefined') {
                                var prescriptionId = $("#hidPrescriptionId" + selectGroceryItemCart[i].storeId).val().slice(1)
                                var prescriptionIdSplit = prescriptionId.toString().trim().split(',');
                                $("#hidPrescriptionId" + selectGroceryItemCart[i].storeId).val('')
                                // alert("prescriptionIdSplit...if.."+prescriptionIdSplit)
                                //  alert("prescriptionIdSplit.length...if.."+prescriptionIdSplit.length)
                                for (var p = 0; p < prescriptionIdSplit.length; p++) {
                                    if (prescriptionIdSplit[p] != "") {
                                        cookieNewGroceryItemValue[k] =
                                        {
                                            storeId: selectGroceryItemCart[i].storeId.toString().trim(),
                                            item_names: "",
                                            item_ids: "",
                                            quantity: "",
                                            item_prices: "",
                                            store_item_stock_ids: "",
                                            pack_ids: "",
                                            stock_quantity: "",
                                            item_image: "",
                                            itemNo: "",
                                            slotName: slot,
                                            consumerNumber: consumerNumber,
                                            comments: comments,
                                            isPromoCode: isPromoCode,
                                            promoCodeDetails: promoCodeArray,
                                            phIds: prescriptionIdSplit[p]
                                        }
                                        k = parseInt(k) + 1
                                    }
                                }
                            }
                        }
                    }
                    else {

                        if (typeof $("#hidPrescriptionId" + selectGroceryItemCart[i].storeId).val() !== 'undefined') {
                            var prescriptionId = $("#hidPrescriptionId" + selectGroceryItemCart[i].storeId).val().slice(1)
                            var prescriptionIdSplit = prescriptionId.toString().split(',');
                            $("#hidPrescriptionId" + selectGroceryItemCart[i].storeId).val('')
                            // alert("prescriptionIdSplit...else.."+prescriptionIdSplit)
                            for (var p = 0; p < prescriptionIdSplit.length; p++) {
                                if (prescriptionIdSplit[p] != "") {
                                    cookieNewGroceryItemValue[k] =
                                    {
                                        storeId: selectGroceryItemCart[i].storeId.toString().trim(),
                                        item_names: "",
                                        item_ids: "",
                                        quantity: "",
                                        item_prices: "",
                                        store_item_stock_ids: "",
                                        pack_ids: "",
                                        stock_quantity: "",
                                        item_image: "",
                                        itemNo: "",
                                        slotName: slot,
                                        consumerNumber: consumerNumber,
                                        comments: comments,
                                        isPromoCode: isPromoCode,
                                        promoCodeDetails: promoCodeArray,
                                        phIds: prescriptionIdSplit[p]
                                    }
                                    k = parseInt(k) + 1
                                }
                            }
                        }


                    }
                }


                if (cookieNewGroceryItemValue.length > 0) {
                    grocery[0] = {
                        locationId: locationId,
                        cityId: cityId,
                        selectedProduct: cookieNewGroceryItemValue
                    }
                }
            }
        }
        return grocery;
    }
    squarePopUpCancel (){
        this.setState({
            isSquare: false
        }, () => {
            $(".locationPopUps1").hide();
            $("#orderConfirm").show();
            $("#orderProcessing").hide();
            $("commonButton checkBorderBtn").show();

        });
    }
    bamboraPopUpCancel() {
        this.setState({
            isBambora: false
        }, () => {
            $(".locationPopUps").hide();
            $("#orderConfirm").show();
            $("#orderProcessing").hide();
            $("commonButton checkBorderBtn").show();
            $("#cardHolderName").val('')
            $("#cardNumber").val('')
            $("#cvv").val('')
            $("#year").val('')
            $("#cardHolderName").removeClass('textred');
            $("#cardNumber").removeClass('textred');
            $("#cvv").removeClass('textred');
            $("#year").removeClass('textred');
        });

    }
    bamboraPopUpSave() {
        if ($("#cardHolderName").val() === "") {
            $("#cardHolderName").focus();
            $("#cardHolderName").addClass('textred');
            return false;
        }
        else if ($("#cardNumber").val() === "") {
            $("#cardNumber").addClass('textred');
            $("#cardNumber").focus()
            return false;
        }
        else if ($("#cvv").val() === "") {
            $("#cvv").addClass('textred');
            $("#cvv").focus()
            return false;
        }
        else if ($("#year").val() === "") {
            $("#year").addClass('textred');
            $("#year").focus()
            return false;
        }
        this.setState({
            isBambora: true
        }, () => {
            this.confirmShopping();
        });




    }
    monthYearValidation(event) {
        $("#year").removeClass('textred');
        var a = event.replace(
            /^([1-9]\/|[2-9])$/g, '0$1/' // To handle 3/ > 03/
        ).replace(
            /^(0[1-9]{1}|1[0-2]{1})$/g, '$1/' // 11 > 11/
        ).replace(
            /^([0-1]{1})([3-9]{1})$/g, '0$1/$2' // 13 > 01/3
        ).replace(
            /^(\d)\/(\d\d)$/g, '0$1/$2' // To handle 1/11 > 01/11
        ).replace(
            /^(0?[1-9]{1}|1[0-2]{1})([0-9]{2})$/g, '$1/$2' // 141 > 01/41
        ).replace(
            /^([0]{1,})\/|[0]{1,}$/g, '0' // To handle 0/ > 0 and 00 > 0
        ).replace(
            /[^\d\/]|^[\/]{0,}$/g, '' // To allow only numbers and /
        ).replace(
            /\/\//g, '/' // Prevent entering more than 1 /
        );
        $("#year").val(a)
    }
    // cardnumber(inputtxt)
    // {
    //   var cardno = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    //   if(inputtxt.value.match(cardno))
    //         {
    //       return true;
    //         }
    //       else
    //         {
    //         alert("Not a valid Visa credit card number!");
    //         return false;
    //         }
    // }


    cartItem() {

        $("#orderConfirm").hide();
        $("#orderProcessing").show();


        let locationId = localStorage.getItem('locationId') == null ? '' : localStorage.getItem('locationId')
        let cityId = localStorage.getItem('cityIdGlobal') == null ? '' : localStorage.getItem('cityIdGlobal')
        let hasLocation = localStorage.getItem('hasLocation') == '1' ? '1' : '0'



        var storeId = $("#hidStoreIdForCheckout").val()
        var grocery = []
        var commonItem = []
        var restaurant = []
        if (storeId != '') {
            // alert("store id und")
            var j = 0
            var cookieNewGroceryItemValue = []
            var a = JSON.parse(localStorage.getItem('selectedCartProduct'))
            // alert('a...'+a)
            if (JSON.parse(localStorage.getItem('selectedCartProduct')) != null && JSON.parse(localStorage.getItem('selectedCartProduct')) != "") {
                //  alert("ifffffffffffff")
                if (JSON.parse(localStorage.getItem('selectedCartProduct')).length > 0) {
                    var selectGroceryItemCart = JSON.parse(localStorage.getItem('selectedCartProduct')) || []
                    for (var i = 0; i < selectGroceryItemCart.length; i++) {
                        if (storeId == selectGroceryItemCart[i].storeId.toString().trim()) {
                            cookieNewGroceryItemValue[j] =
                            {
                                storeId: selectGroceryItemCart[i].storeId.toString().trim(),
                                item_names: selectGroceryItemCart[i].item_names.toString().trim(),
                                item_ids: selectGroceryItemCart[i].item_ids.toString().trim(),
                                quantity: selectGroceryItemCart[i].quantity.toString().trim(),
                                item_prices: selectGroceryItemCart[i].item_prices.toString().trim(),
                                store_item_stock_ids: selectGroceryItemCart[i].store_item_stock_ids.toString().trim(),
                                pack_ids: selectGroceryItemCart[i].pack_ids.toString().trim(),
                                stock_quantity: selectGroceryItemCart[i].stock_quantity.toString().trim(),
                                item_image: selectGroceryItemCart[i].item_image.toString().trim(),
                                itemNo: selectGroceryItemCart[i].itemNo.toString().trim()
                            }
                            j = parseInt(j) + 1
                        }
                    }


                    var k = cookieNewGroceryItemValue.length
                    if (typeof $("#hidPrescriptionId" + storeId).val() !== 'undefined') {
                        var prescriptionId = $("#hidPrescriptionId" + storeId).val().slice(1)
                        var prescriptionIdSplit = prescriptionId.toString().trim().split(',');
                        for (var p = 0; p < prescriptionIdSplit.length; p++) {
                            if (prescriptionIdSplit[p] != "") {
                                cookieNewGroceryItemValue[k] =
                                {
                                    storeId: storeId,
                                    item_names: "",
                                    item_ids: "",
                                    quantity: "",
                                    item_prices: "",
                                    store_item_stock_ids: "",
                                    pack_ids: "",
                                    stock_quantity: "",
                                    item_image: "",
                                    itemNo: "",
                                    isPromoCode: "",
                                    promoCodeDetails: "",
                                    phIds: prescriptionIdSplit[p]
                                }
                                k = parseInt(k) + 1
                            }
                        }
                    }
                }
            }
            else {
                // alert("elseeeeeeeee")     
                // var storeId = window.sessionStorage.getItem('storeId')   
                // alert("storeId.........."+storeId)    
                if (typeof $("#hidPrescriptionId" + storeId).val() !== 'undefined') {
                    var prescriptionId = $("#hidPrescriptionId" + storeId).val().slice(1)
                    var prescriptionIdSplit = prescriptionId.toString().split(',');
                    for (var p = 0; p < prescriptionIdSplit.length; p++) {
                        if (prescriptionIdSplit[p] != "") {
                            cookieNewGroceryItemValue[p] =
                            {
                                storeId: storeId,
                                item_names: "",
                                item_ids: "",
                                quantity: "",
                                item_prices: "",
                                store_item_stock_ids: "",
                                pack_ids: "",
                                stock_quantity: "",
                                item_image: "",
                                itemNo: "",
                                isPromoCode: "",
                                promoCodeDetails: "",
                                phIds: prescriptionIdSplit[p]
                            }
                        }
                    }
                }
            }
            if (cookieNewGroceryItemValue.length > 0) {
                grocery[0] = {
                    cartCategory: "grocery",
                    locationId: locationId,
                    selectedProduct: cookieNewGroceryItemValue
                }
            }





            j = 0
            var cookieNewCommonItemValue = []
            if (JSON.parse(localStorage.getItem('selectCommonItemCart')) != null) {
                if (JSON.parse(localStorage.getItem('selectCommonItemCart')).length > 0) {
                    var selectCommonItemCart = JSON.parse(localStorage.getItem('selectCommonItemCart')) || []
                    for (var i = 0; i < selectCommonItemCart.length; i++) {
                        if (storeId == selectCommonItemCart[i].storeId.toString().trim()) {
                            cookieNewCommonItemValue[j] =
                            {
                                storeId: selectCommonItemCart[i].storeId.toString().trim(),
                                itemId: selectCommonItemCart[i].itemId.toString().trim(),
                                itemName: selectCommonItemCart[i].itemName.toString().trim(),
                                quantity: selectCommonItemCart[i].quantity.toString().trim(),
                                itemTitle: selectCommonItemCart[i].itemTitle,
                                brand: selectCommonItemCart[i].brand,
                                sizeName: selectCommonItemCart[i].sizeName.toString().trim(),
                                sizeId: selectCommonItemCart[i].sizeId.toString().trim(),
                                cmItemSizeId: selectCommonItemCart[i].cmItemSizeId.toString().trim(),
                                itemMasterId: selectCommonItemCart[i].itemMasterId.toString().trim(),
                                netAmount: selectCommonItemCart[i].netAmount.toString().trim(),
                                mrp: selectCommonItemCart[i].mrp.toString().trim(),
                                itemImage: selectCommonItemCart[i].itemImage.toString().trim(),
                                itemNo: selectCommonItemCart[i].itemNo.toString().trim()
                            }
                            j = parseInt(j) + 1
                        }
                    }
                    if (cookieNewCommonItemValue.length > 0) {
                        commonItem[0] = {
                            cartCategory: "commonItem",
                            locationId: locationId,
                            cityId: cityId,
                            selectCommonItemCart: cookieNewCommonItemValue
                        }
                    }
                }
            }



            j = 0
            var cookieNewRestaurantValue = []
            if (JSON.parse(localStorage.getItem('selectRestaurantCart')) != null) {
                if (JSON.parse(localStorage.getItem('selectRestaurantCart')).length > 0) {
                    var selectRestaurantCart = JSON.parse(localStorage.getItem('selectRestaurantCart')) || []
                    for (var i = 0; i < selectRestaurantCart.length; i++) {
                        if (storeId == selectRestaurantCart[i].storeId.toString().trim()) {
                            cookieNewRestaurantValue[j] =
                            {
                                storeId: selectRestaurantCart[i].storeId.toString().trim(),
                                itemId: selectRestaurantCart[i].itemId.toString().trim(),
                                itemName: selectRestaurantCart[i].itemName.toString().trim(),
                                quantity: selectRestaurantCart[i].quantity.toString().trim(),
                                itemSize: selectRestaurantCart[i].itemSize.toString().trim(),
                                itemMrp: selectRestaurantCart[i].itemMrp.toString().trim(),
                                itemImage: selectRestaurantCart[i].itemImage.toString().trim(),
                                storeItemSizeId: selectRestaurantCart[i].storeItemSizeId.toString().trim(),
                                itemNo: selectRestaurantCart[i].itemNo.toString().trim(),
                                toppingsData: selectRestaurantCart[i].toppingsData.toString().trim()
                            }
                            j = parseInt(j) + 1
                        }
                    }
                    if (cookieNewRestaurantValue.length > 0) {
                        restaurant[0] = {
                            cartCategory: "restaurant",
                            locationId: locationId,
                            selectRestaurantCart: cookieNewRestaurantValue
                        }
                    }
                }
            }
        }
        else {
            var selectGroceryItemCart = JSON.parse(localStorage.getItem('selectedCartProduct')) || []
            var cookieNewGroceryItemValue = []
            if (JSON.parse(localStorage.getItem('selectedCartProduct')) != null) {
                if (JSON.parse(localStorage.getItem('selectedCartProduct')).length > 0) {
                    for (var i = 0; i < selectGroceryItemCart.length; i++) {
                        cookieNewGroceryItemValue[i] =
                        {
                            storeId: selectGroceryItemCart[i].storeId.toString().trim(),
                            item_names: selectGroceryItemCart[i].item_names.toString().trim(),
                            item_ids: selectGroceryItemCart[i].item_ids.toString().trim(),
                            quantity: selectGroceryItemCart[i].quantity.toString().trim(),
                            item_prices: selectGroceryItemCart[i].item_prices.toString().trim(),
                            store_item_stock_ids: selectGroceryItemCart[i].store_item_stock_ids.toString().trim(),
                            pack_ids: selectGroceryItemCart[i].pack_ids.toString().trim(),
                            stock_quantity: selectGroceryItemCart[i].stock_quantity.toString().trim(),
                            item_image: selectGroceryItemCart[i].item_image.toString().trim(),
                            itemNo: selectGroceryItemCart[i].itemNo.toString().trim()
                        }
                    }
                }
            }

            var storeArrayForPrescription = []
            var k = cookieNewGroceryItemValue.length
            for (var i = 0; i < selectGroceryItemCart.length; i++) {
                if (typeof $("#hidPrescriptionId" + selectGroceryItemCart[i].storeId).val() !== 'undefined') {
                    if (storeArrayForPrescription.includes(selectGroceryItemCart[i].storeId) === false) {
                        storeArrayForPrescription.push(selectGroceryItemCart[i].storeId)
                        var prescriptionId = $("#hidPrescriptionId" + selectGroceryItemCart[i].storeId).val().slice(1)
                        var prescriptionIdSplit = prescriptionId.toString().trim().split(',');
                        for (var p = 0; p < prescriptionIdSplit.length; p++) {
                            if (prescriptionIdSplit[p] != "") {
                                cookieNewGroceryItemValue[k] =
                                {
                                    storeId: selectGroceryItemCart[i].storeId,
                                    item_names: "",
                                    item_ids: "",
                                    quantity: "",
                                    item_prices: "",
                                    store_item_stock_ids: "",
                                    pack_ids: "",
                                    stock_quantity: "",
                                    item_image: "",
                                    itemNo: "",
                                    isPromoCode: "",
                                    promoCodeDetails: "",
                                    phIds: prescriptionIdSplit[p]
                                }
                                k = parseInt(k) + 1
                            }
                        }
                    }
                }
            }

            if (cookieNewGroceryItemValue.length > 0) {
                grocery[0] = {
                    cartCategory: "grocery",
                    locationId: locationId,
                    cityId: cityId,
                    selectedProduct: cookieNewGroceryItemValue
                }
            }



            if (JSON.parse(localStorage.getItem('selectCommonItemCart')) != null) {
                if (JSON.parse(localStorage.getItem('selectCommonItemCart')).length > 0) {
                    commonItem[0] = {
                        cartCategory: "commonItem",
                        locationId: locationId,
                        cityId: cityId,
                        selectCommonItemCart: JSON.parse(localStorage.getItem('selectCommonItemCart'))
                    }
                }
            }
            if (JSON.parse(localStorage.getItem('selectRestaurantCart')) != null) {
                if (JSON.parse(localStorage.getItem('selectRestaurantCart')).length > 0) {
                    restaurant[0] = {
                        cartCategory: "restaurant",
                        locationId: locationId,
                        selectRestaurantCart: JSON.parse(localStorage.getItem('selectRestaurantCart'))
                    }
                }
            }
        }


        var postData = {
            "functionName": "cartItem",
            "commonItem": commonItem,
            "restaurant": restaurant,
            "grocery": grocery,
            "paymentId": $("#txtPaymentMode").val(),
            "hasLocation": hasLocation,
            "timeZone": new Date().getTimezoneOffset().toString()
        }
        //  alert("inside cart items.......11111111...")
        getCartItemsMultiple(postData).then((dataCart) => {
            // alert("getCartItemsMultiple success........" + dataCart.data.success)
            // if (dataCart.data.success == 1) {
            //     this.confirmShopping();
            // }
            // this.setState({
            //            bamboraAmount: dataCart.data.result[0].grandTotalSale,
            //         });
            //         localStorage.setItem('totalAmount', dataCart.data.result[0].grandTotalSale)

            if (dataCart.data.result[0].isOutOfStock == '1') {
                alert(dataCart.data.result[0].outOfStockItems)
                this.removeCartItemGrocery(dataCart.data.result[0].cartPositionGrocery, dataCart.data.result[0].cartPositionInsufficientGrocery);
                this.removeCartItemCommonItem(dataCart.data.result[0].cartPositionCommon, dataCart.data.result[0].cartPositionInsufficientCommon);
                window.location.reload();
            }
            else if(dataCart.data.result[0].isOutOfOrderItem === '2'){
                alert(dataCart.data.result[0].isOutOfOrderItemMsg)
                window.location.reload();
            }
            else if (dataCart.data.result[0].cartDisable == true) {
                alert(dataCart.data.result[0].freeShoppingText)
                window.location.reload();
            }
            else if ($("#txtPaymentMode").val() === "5" && $("#hidPaymentGatewayId").val() === "4") {
                var postDataBeforePayment = {
                    functionName: "itemBeforePayment",
                    buyerId: localStorage.getItem('userId'),
                    buyerDeliveryAddressId: $("#deliveryAddressId").val(),
                    result: dataCart.data.result
                }
                getCartItemsMultiple(postDataBeforePayment).then((dataBeforePayment) => {
                    if (dataBeforePayment.data.success === "1") {

                        var restaurantArray = this.restaurantCartValueArray()
                        localStorage.setItem('restaurantArrayWL', JSON.stringify(restaurantArray))
                        var commonItemArray = this.commonItemCartValueArray()
                        localStorage.setItem('commonItemArrayWL', JSON.stringify(commonItemArray))
                        var groceryArray = this.groceryCartValueArray()
                        localStorage.setItem('groceryArrayWL', JSON.stringify(groceryArray))

                        let cityId = localStorage.getItem('cityIdGlobal') == null ? localStorage.getItem('cityId') : localStorage.getItem('cityIdGlobal')
                        var itemBeforePaymentGroupCode = dataBeforePayment.data.result[0].itemBeforePaymentGroupCode
                        var amount
                        var storeId = $("#hidStoreIdForCheckout").val()
                        var deliveryAddressId = $("#deliveryAddressId").val()
                        if ($("#txtTottal").val() == undefined || $("#txtTottal").val() == 'undefined')
                            if (storeId != '') {
                                $("#txtTottal" + storeId).val()
                                $("#txtTottalCon").val('')

                            }
                        if ($("#txtTottalCon").val() != null && $("#txtTottalCon").val() != '' && $("#txtTottalCon").val() != 'null') {
                            amount = parseFloat($("#txtTottalCon").val()).toFixed(window.sessionStorage.getItem('decimalCount')) * 100;
                        } else {
                            amount = parseFloat($("#txtTottal" + storeId).val()).toFixed(window.sessionStorage.getItem('decimalCount')) * 100;
                        }

                        var objectEP = {
                            cityId: cityId,
                            itemBeforePaymentGroupCode: itemBeforePaymentGroupCode,
                            amount: amount,
                            storeId: storeId,
                            deliveryAddressId: deliveryAddressId,
                            hasLocation: hasLocation
                        }
                        // let res = axios.post('https://01lrnzzpui.execute-api.ap-south-1.amazonaws.com/default/PaymentGateway',obj)

                        callEasyPay(objectEP).then((data) => {
                            if (data.data.dynamicLinkUrl === "") {
                                localStorage.setItem('dynamicLinkUrl', "")
                            }
                            else {
                                localStorage.setItem('dynamicLinkUrl', data.data.dynamicLinkUrl)
                            }

                            window.location.href = data.data.body;
                        });

                        // axios.post('https://01lrnzzpui.execute-api.ap-south-1.amazonaws.com/default/PaymentGateway')
                        // .then((res)=>{
                        //   window.location.href = res.data;
                        // }).catch((err)=>{})
                    }
                })
            }
            else if ($("#txtPaymentMode").val() === "5" && $("#hidPaymentGatewayId").val() === "3") {
                var postDataBeforePayment = {
                    functionName: "itemBeforePayment",
                    buyerId: localStorage.getItem('userId'),
                    buyerDeliveryAddressId: $("#deliveryAddressId").val(),
                    result: dataCart.data.result
                }
                getCartItemsMultiple(postDataBeforePayment).then((dataBeforePayment) => {
                    if (dataBeforePayment.data.success === "1") {

                        let cityId = localStorage.getItem('cityIdGlobal') == null ? localStorage.getItem('cityId') : localStorage.getItem('cityIdGlobal')
                        var itemBeforePaymentGroupCode = dataBeforePayment.data.result[0].itemBeforePaymentGroupCode
                        var amount
                        var mid = $("#hidKey2").val();
                        var encKey = $("#hidKey1").val();
                        var storeId = $("#hidStoreIdForCheckout").val()
                        var deliveryAddressId = $("#deliveryAddressId").val()
                        if ($("#txtTottal").val() == undefined || $("#txtTottal").val() == 'undefined')
                            if (storeId != '') {
                                $("#txtTottal" + storeId).val()
                                $("#txtTottalCon").val('')

                            }
                        if ($("#txtTottalCon").val() != null && $("#txtTottalCon").val() != '' && $("#txtTottalCon").val() != 'null') {
                            amount = parseFloat($("#txtTottalCon").val()).toFixed(window.sessionStorage.getItem('decimalCount')) * 100;
                        } else {
                            amount = parseFloat($("#txtTottal" + storeId).val()).toFixed(window.sessionStorage.getItem('decimalCount')) * 100;
                        }
                        document.getElementById("addField5").value = storeId;
                        var restaurantArray = this.restaurantCartValueArray()
                        localStorage.setItem('restaurantArrayWL', JSON.stringify(restaurantArray))
                        // document.getElementById("addField6").value = JSON.stringify(restaurantArray)
                        var commonItemArray = this.commonItemCartValueArray()
                        localStorage.setItem('commonItemArrayWL', JSON.stringify(commonItemArray))
                        //  document.getElementById("addField7").value = JSON.stringify(commonItemArray)
                        var groceryArray = this.groceryCartValueArray()
                        localStorage.setItem('groceryArrayWL', JSON.stringify(groceryArray))
                        //  document.getElementById("addField8").value = JSON.stringify(groceryArray)

                        loadWorldLine(mid, encKey, amount, itemBeforePaymentGroupCode, cityId, deliveryAddressId, hasLocation);
                    }
                    else {
                        alert(dataBeforePayment.data.errorMessage)
                    }
                })
            }
            else if ($("#txtPaymentMode").val() === "5" && $("#hidPaymentGatewayId").val() === "5") {
                var postDataBeforePayment = {
                    functionName: "itemBeforePayment",
                    buyerId: localStorage.getItem('userId'),
                    buyerDeliveryAddressId: $("#deliveryAddressId").val(),
                    result: dataCart.data.result
                }
                getCartItemsMultiple(postDataBeforePayment).then((dataBeforePayment) => {
                    if (dataBeforePayment.data.success === "1") {
                        this.setState({
                            bamboraAmount: dataCart.data.result[0].grandTotalSale,
                        }, () => {
                            $(".locationPopUps").show();
                            document.addEventListener('contextmenu', (e) => e.preventDefault());
                        });
                    }
                    else {
                        alert(dataBeforePayment.data.errorMessage)
                    }
                })
            }
            else if ($("#txtPaymentMode").val() === "5" && $("#hidPaymentGatewayId").val() === "6") {
                var postDataBeforePayment = {
                    functionName: "itemBeforePayment",
                    buyerId: localStorage.getItem('userId'),
                    buyerDeliveryAddressId: $("#deliveryAddressId").val(),
                    result: dataCart.data.result
                }
                getCartItemsMultiple(postDataBeforePayment).then((dataBeforePayment) => {
                    if (dataBeforePayment.data.success === "1") {

                        var restaurantArray = this.restaurantCartValueArray()
                        localStorage.setItem('restaurantArrayWL', JSON.stringify(restaurantArray))

                        var commonItemArray = this.commonItemCartValueArray()
                        localStorage.setItem('commonItemArrayWL', JSON.stringify(commonItemArray))

                        var groceryArray = this.groceryCartValueArray()
                        localStorage.setItem('groceryArrayWL', JSON.stringify(groceryArray))

                        const postData = {
                            "functionName": "stripePayment",
                            "amount": (dataCart.data.result[0].grandTotalSale) * 100,
                            "name": '',
                            "email": '',
                            "phone": '',
                            "siteId": localStorage.getItem('siteId'),
                            "successUrl": "stripeSuccess/",
                            "cancelUrl": "cmItemListing/0/0/0/",
                            "buyerDeliveryAddressId": $("#deliveryAddressId").val(),
                            "itemBeforePaymentGroupCode": dataBeforePayment.data.result[0].itemBeforePaymentGroupCode,
                            "storeId": $("#hidStoreIdForCheckout").val()
                        }
                        callStripe(postData).then(async (data) => {
                            console.log("zzzzzzzzzzzzz",data)
                            if (data.data.success === "1") {
                                
                                // window.sessionStorage.setItem('WorldlineUrl', data.data.result[0].config_value)
                                var stripe = await loadStripe(data.data.stripePublickey);
                                return stripe.redirectToCheckout({ sessionId: data.data.sessionId });
                                //  pk_test_51Lx6jJSIxwVCUhSv2f84yuA62WBjUr7YUXROxYqNS8OUmkDkCid5SzcoG4lsBsA2wqE7dCkHE2IAKtXnWeN35uQB00S6dOVd4p
                            }
                        });
                    }
                    else {
                        alert(dataBeforePayment.data.errorMessage)
                    }
                })
            }
            else if ($("#txtPaymentMode").val() === "5" && $("#hidPaymentGatewayId").val() === "7") {
                var postDataBeforePayment = {
                    functionName: "itemBeforePayment",
                    buyerId: localStorage.getItem('userId'),
                    buyerDeliveryAddressId: $("#deliveryAddressId").val(),
                    result: dataCart.data.result
                }
                getCartItemsMultiple(postDataBeforePayment).then((dataBeforePayment) => {
                    if (dataBeforePayment.data.success === "1") {
                        this.setState({
                            squareAmount: (dataCart.data.result[0].grandTotalSale) * 100,
                        }, () => {
                        
                           $(".locationPopUps1").show();
                           document.addEventListener('contextmenu', (e) => e.preventDefault()); 
                      
                        });
                    }
                    else {
                        alert(dataBeforePayment.data.errorMessage)
                    }
                })
            }
            else if  ($("#txtPaymentMode").val() === "5" && $("#hidPaymentGatewayId").val() === "8") {
                var postDataBeforePayment = {
                    functionName: "itemBeforePayment",
                    buyerId: localStorage.getItem('userId'),
                    buyerDeliveryAddressId: $("#deliveryAddressId").val(),
                    result: dataCart.data.result
                }
                getCartItemsMultiple(postDataBeforePayment).then((dataBeforePayment) => {
                    if (dataBeforePayment.data.success === "1") {
                        var amount
                        var itemBeforePaymentGroupCode = dataBeforePayment.data.result[0].itemBeforePaymentGroupCode
                        localStorage.setItem('itemBeforePaymentGroupCode',itemBeforePaymentGroupCode)
                        var restaurantArray = this.restaurantCartValueArray()
                        localStorage.setItem('restaurantArrayWL', JSON.stringify(restaurantArray))
                        var commonItemArray = this.commonItemCartValueArray()
                        localStorage.setItem('commonItemArrayWL', JSON.stringify(commonItemArray))
                        var groceryArray = this.groceryCartValueArray()
                        localStorage.setItem('groceryArrayWL', JSON.stringify(groceryArray))

                        var storeId = $("#hidStoreIdForCheckout").val()
                        localStorage.setItem('hidStoreIdForCheckout', storeId);
                        if ($("#txtTottal").val() == undefined || $("#txtTottal").val() == 'undefined')
                            if (storeId != '') {
                                $("#txtTottal" + storeId).val()
                                $("#txtTottalCon").val('')

                            }
                        if ($("#txtTottalCon").val() != null && $("#txtTottalCon").val() != '' && $("#txtTottalCon").val() != 'null') {
                            amount = parseFloat($("#txtTottalCon").val()).toFixed(window.sessionStorage.getItem('decimalCount'));
                        } else {
                            amount = parseFloat($("#txtTottal" + storeId).val()).toFixed(window.sessionStorage.getItem('decimalCount'));
                        }

                        var postData = 
                            {
                                "functionName": "phonePaySave",
                                "amount":amount,
                                "storeId": storeId,
                                "siteId": localStorage.getItem('siteId'),
                                "userId": localStorage.getItem('userId')
                              }
                              console.log("phnepeeee save",postData)
                              getCartItemsMultiple(postData).then((data) => {
console.log("#############################################################phonepeeeee",data)
                               window.location.href = data.data.result.responseUrl
                              })
                    }
                    else {
                        alert(dataBeforePayment.data.errorMessage)
                    }
                })
            }
            else if ($("#txtPaymentMode").val() === "5") {
                let cityId = localStorage.getItem('cityIdGlobal') == null ? localStorage.getItem('cityId') : localStorage.getItem('cityIdGlobal')
                // $('body').addClass('orderProcessingDisable')
                var storeId = $("#hidStoreIdForCheckout").val()
                var razorpaykeyid = $("#hidKey2").val();
                var razorpaykeysecret = $("#hidKey1").val();
                var string = razorpaykeyid + ':' + razorpaykeysecret;
                var encodedString = btoa(string);
                let encode = encodedString
                let amount

                if ($("#txtTottal").val() == undefined || $("#txtTottal").val() == 'undefined')
                    if (storeId != '') {
                        $("#txtTottal" + storeId).val()
                        $("#txtTottalCon").val('')

                    }
                if ($("#txtTottalCon").val() != null && $("#txtTottalCon").val() != '' && $("#txtTottalCon").val() != 'null') {
                    amount = parseFloat($("#txtTottalCon").val()).toFixed(window.sessionStorage.getItem('decimalCount')) * 100;
                } else {
                    amount = parseFloat($("#txtTottal" + storeId).val()).toFixed(window.sessionStorage.getItem('decimalCount')) * 100;
                }




                var postDataBeforePayment = {
                    functionName: "itemBeforePayment",
                    buyerId: localStorage.getItem('userId'),
                    buyerDeliveryAddressId: $("#deliveryAddressId").val(),
                    result: dataCart.data.result
                }
                //  alert("inside cart items.......11111111...")
                getCartItemsMultiple(postDataBeforePayment).then((dataBeforePayment) => {
                    if (dataBeforePayment.data.success === "1") {








                        let email = window.sessionStorage.getItem("userEmailId")
                        let mobileNumber = window.sessionStorage.getItem('userMobileNumber')
                        let userName = localStorage.getItem("displayName")
                        let options = {
                            key: razorpaykeyid,
                            amount: amount,
                            name: localStorage.getItem("displayName"),
                            description: "",
                            image: "",
                            handler: function (response) {
                                $('body').addClass('orderProcessingDisable')
                                const paymenTId = response.razorpay_payment_id;



                                var postDataRazorPay = {
                                    "functionName": "checkout/razorpay",
                                    "storeId": $("#hidStoreIdsInCart").val(),
                                    "paymentId": paymenTId,
                                    "itemBeforePaymentGroupCode": dataBeforePayment.data.result[0].itemBeforePaymentGroupCode
                                }


                                getData(postDataRazorPay).then((data) => {
                                    var razorTransferDetails = data.data.razorPayData;
                                    if (razorTransferDetails) {
                                        var element = JSON.parse(window.sessionStorage.getItem("appArray"));
                                        let newList = Object.assign(razorTransferDetails, element);

                                        //alert("newList..." + newList)
                                        // const apiJson = {
                                        //     method: "POST",
                                        //     body: JSON.stringify(newList),
                                        // };
                                        window.sessionStorage.setItem('razorList', JSON.stringify(newList));
                                        // saveRazorPay(apiJson).then((data) => {
                                        //     window.sessionStorage.setItem("appointmentSuccessArray", JSON.stringify(data));


                                        // if (data.data.success == "1") {
                                        $('body').addClass('orderProcessingDisable')
                                        $("#orderConfirm").hide();
                                        $("#orderProcessing").show();
                                        // alert("o999")
                                        //this.confirmShopping();

                                        var newRazor = [];

                                        newRazor[0] = JSON.parse(window.sessionStorage.getItem('razorList'))

                                        if ($("#txtPaymentMode").val() == '' || $("#txtPaymentMode").val() == 'selected') {
                                            alert("Select Payment Mode ");
                                            $("#orderProcessing").hide();
                                            $("#orderConfirm").show();
                                            $('body').removeClass('orderProcessingDisable')
                                            return false;
                                        }
                                        else {
                                            var commonItem = []
                                            var cookieNewCommonItemValue = []
                                            if (JSON.parse(localStorage.getItem('selectCommonItemCart')) != null) {
                                                if (JSON.parse(localStorage.getItem('selectCommonItemCart')).length > 0) {
                                                    var j = 0
                                                    var selectCommonItemCart = JSON.parse(localStorage.getItem('selectCommonItemCart')) || []
                                                    for (var i = 0; i < selectCommonItemCart.length; i++) {
                                                        var slot = $("#txtSlot" + selectCommonItemCart[i].storeId).val()
                                                        var consumerNumber = $("#txtConsumerCard" + selectCommonItemCart[i].storeId).val()
                                                        var comments = $("#txtUserNeeds" + selectCommonItemCart[i].storeId).val()
                                                        var promoCodeDetails = $("#hidPromocodeDetails" + selectCommonItemCart[i].storeId).val()
                                                        var promoCodeArray = ''
                                                        var isPromoCode = '0'
                                                        if (promoCodeDetails != null && promoCodeDetails != 'null' && promoCodeDetails != '' && promoCodeDetails !== 'undefined') {
                                                            promoCodeArray = JSON.parse(promoCodeDetails)
                                                            isPromoCode = '1'
                                                        }
                                                        if (storeId != '') {
                                                            if (storeId == selectCommonItemCart[i].storeId.toString().trim()) {
                                                                cookieNewCommonItemValue[j] =
                                                                {
                                                                    storeId: selectCommonItemCart[i].storeId.toString(),
                                                                    itemId: selectCommonItemCart[i].itemId.toString(),
                                                                    itemName: selectCommonItemCart[i].itemName,
                                                                    quantity: selectCommonItemCart[i].quantity,
                                                                    itemTitle: selectCommonItemCart[i].itemTitle,
                                                                    brand: selectCommonItemCart[i].brand,
                                                                    sizeName: selectCommonItemCart[i].sizeName.toString().trim(),
                                                                    sizeId: selectCommonItemCart[i].sizeId.toString().trim(),
                                                                    cmItemSizeId: selectCommonItemCart[i].cmItemSizeId.toString().trim(),
                                                                    itemMasterId: selectCommonItemCart[i].itemMasterId.toString(),
                                                                    netAmount: selectCommonItemCart[i].netAmount.toString(),
                                                                    mrp: selectCommonItemCart[i].mrp.toString(),
                                                                    itemImage: selectCommonItemCart[i].itemImage,
                                                                    itemNo: selectCommonItemCart[i].itemNo,
                                                                    slotName: slot,
                                                                    consumerNumber: consumerNumber,
                                                                    comments: comments,
                                                                    isPromoCode: isPromoCode,
                                                                    promoCodeDetails: promoCodeArray
                                                                }
                                                                j = parseInt(j) + 1
                                                            }
                                                        }
                                                        else {
                                                            cookieNewCommonItemValue[i] =
                                                            {
                                                                storeId: selectCommonItemCart[i].storeId.toString(),
                                                                itemId: selectCommonItemCart[i].itemId.toString(),
                                                                itemName: selectCommonItemCart[i].itemName,
                                                                quantity: selectCommonItemCart[i].quantity,
                                                                itemTitle: selectCommonItemCart[i].itemTitle,
                                                                brand: selectCommonItemCart[i].brand,
                                                                sizeName: selectCommonItemCart[i].sizeName.toString().trim(),
                                                                sizeId: selectCommonItemCart[i].sizeId.toString().trim(),
                                                                cmItemSizeId: selectCommonItemCart[i].cmItemSizeId.toString().trim(),
                                                                itemMasterId: selectCommonItemCart[i].itemMasterId.toString(),
                                                                netAmount: selectCommonItemCart[i].netAmount.toString(),
                                                                mrp: selectCommonItemCart[i].mrp.toString(),
                                                                itemImage: selectCommonItemCart[i].itemImage,
                                                                itemNo: selectCommonItemCart[i].itemNo,
                                                                slotName: slot,
                                                                consumerNumber: consumerNumber,
                                                                comments: comments,
                                                                isPromoCode: isPromoCode,
                                                                promoCodeDetails: promoCodeArray
                                                            }
                                                        }
                                                    }
                                                    if (cookieNewCommonItemValue.length > 0) {
                                                        commonItem[0] = {
                                                            locationId: locationId,
                                                            cityId: cityId,
                                                            selectCommonItemCart: cookieNewCommonItemValue
                                                        }
                                                    }
                                                }
                                            }


                                            var grocery = []
                                            var cookieNewGroceryItemValue = []
                                            if (JSON.parse(localStorage.getItem('selectedCartProduct')) != null) {
                                                if (JSON.parse(localStorage.getItem('selectedCartProduct')).length > 0) {
                                                    var j = 0
                                                    var selectGroceryItemCart = JSON.parse(localStorage.getItem('selectedCartProduct')) || []
                                                    for (var i = 0; i < selectGroceryItemCart.length; i++) {
                                                        //  var prescriptionId = $("#hidPrescriptionId" + selectGroceryItemCart[i].storeId).val()
                                                        var slot = $("#txtSlot" + selectGroceryItemCart[i].storeId).val()
                                                        var consumerNumber = $("#txtConsumerCard" + selectGroceryItemCart[i].storeId).val()
                                                        var comments = $("#txtUserNeeds" + selectGroceryItemCart[i].storeId).val()
                                                        var promoCodeDetails = $("#hidPromocodeDetails" + selectGroceryItemCart[i].storeId).val()
                                                        var promoCodeArray = ''
                                                        var isPromoCode = '0'
                                                        if (promoCodeDetails != null && promoCodeDetails != 'null' && promoCodeDetails != '' && promoCodeDetails !== 'undefined') {
                                                            promoCodeArray = JSON.parse(promoCodeDetails)
                                                            isPromoCode = '1'
                                                        }
                                                        if (storeId != '') {
                                                            if (storeId == selectGroceryItemCart[i].storeId.toString().trim()) {
                                                                cookieNewGroceryItemValue[j] =
                                                                {
                                                                    storeId: selectGroceryItemCart[i].storeId.toString().trim(),
                                                                    item_names: selectGroceryItemCart[i].item_names.toString().trim(),
                                                                    item_ids: selectGroceryItemCart[i].item_ids.toString().trim(),
                                                                    quantity: selectGroceryItemCart[i].quantity.toString().trim(),
                                                                    item_prices: selectGroceryItemCart[i].item_prices.toString().trim(),
                                                                    store_item_stock_ids: selectGroceryItemCart[i].store_item_stock_ids.toString().trim(),
                                                                    pack_ids: selectGroceryItemCart[i].pack_ids.toString().trim(),
                                                                    stock_quantity: selectGroceryItemCart[i].stock_quantity.toString().trim(),
                                                                    item_image: selectGroceryItemCart[i].item_image.toString().trim(),
                                                                    itemNo: selectGroceryItemCart[i].itemNo.toString().trim(),
                                                                    slotName: slot,
                                                                    consumerNumber: consumerNumber,
                                                                    comments: comments,
                                                                    isPromoCode: isPromoCode,
                                                                    promoCodeDetails: promoCodeArray,
                                                                    phIds: ""
                                                                }
                                                                j = parseInt(j) + 1
                                                            }
                                                        }
                                                        else {
                                                            cookieNewGroceryItemValue[i] =
                                                            {
                                                                storeId: selectGroceryItemCart[i].storeId.toString().trim(),
                                                                item_names: selectGroceryItemCart[i].item_names.toString().trim(),
                                                                item_ids: selectGroceryItemCart[i].item_ids.toString().trim(),
                                                                quantity: selectGroceryItemCart[i].quantity.toString().trim(),
                                                                item_prices: selectGroceryItemCart[i].item_prices.toString().trim(),
                                                                store_item_stock_ids: selectGroceryItemCart[i].store_item_stock_ids.toString().trim(),
                                                                pack_ids: selectGroceryItemCart[i].pack_ids.toString().trim(),
                                                                stock_quantity: selectGroceryItemCart[i].stock_quantity.toString().trim(),
                                                                item_image: selectGroceryItemCart[i].item_image.toString().trim(),
                                                                itemNo: selectGroceryItemCart[i].itemNo.toString().trim(),
                                                                slotName: slot,
                                                                consumerNumber: consumerNumber,
                                                                comments: comments,
                                                                isPromoCode: isPromoCode,
                                                                promoCodeDetails: promoCodeArray,
                                                                phIds: ""
                                                            }
                                                        }
                                                    }



                                                    var k = cookieNewGroceryItemValue.length
                                                    for (var i = 0; i < selectGroceryItemCart.length; i++) {
                                                        var slot = $("#txtSlot" + selectGroceryItemCart[i].storeId).val()
                                                        var consumerNumber = $("#txtConsumerCard" + selectGroceryItemCart[i].storeId).val()
                                                        var comments = $("#txtUserNeeds" + selectGroceryItemCart[i].storeId).val()
                                                        var promoCodeDetails = $("#hidPromocodeDetails" + selectGroceryItemCart[i].storeId).val()
                                                        var promoCodeArray = ''
                                                        var isPromoCode = '0'
                                                        if (promoCodeDetails != null && promoCodeDetails != 'null' && promoCodeDetails != '' && promoCodeDetails !== 'undefined') {
                                                            promoCodeArray = JSON.parse(promoCodeDetails)
                                                            isPromoCode = '1'
                                                        }
                                                        if (storeId != '') {
                                                            // alert("is store")
                                                            if (storeId == selectGroceryItemCart[i].storeId.toString().trim()) {
                                                                if (typeof $("#hidPrescriptionId" + selectGroceryItemCart[i].storeId).val() !== 'undefined') {
                                                                    var prescriptionId = $("#hidPrescriptionId" + selectGroceryItemCart[i].storeId).val().slice(1)
                                                                    var prescriptionIdSplit = prescriptionId.toString().trim().split(',');
                                                                    $("#hidPrescriptionId" + selectGroceryItemCart[i].storeId).val('')
                                                                    // alert("prescriptionIdSplit...if.."+prescriptionIdSplit)
                                                                    //  alert("prescriptionIdSplit.length...if.."+prescriptionIdSplit.length)
                                                                    for (var p = 0; p < prescriptionIdSplit.length; p++) {
                                                                        if (prescriptionIdSplit[p] != "") {
                                                                            cookieNewGroceryItemValue[k] =
                                                                            {
                                                                                storeId: selectGroceryItemCart[i].storeId.toString().trim(),
                                                                                item_names: "",
                                                                                item_ids: "",
                                                                                quantity: "",
                                                                                item_prices: "",
                                                                                store_item_stock_ids: "",
                                                                                pack_ids: "",
                                                                                stock_quantity: "",
                                                                                item_image: "",
                                                                                itemNo: "",
                                                                                slotName: slot,
                                                                                consumerNumber: consumerNumber,
                                                                                comments: comments,
                                                                                isPromoCode: isPromoCode,
                                                                                promoCodeDetails: promoCodeArray,
                                                                                phIds: prescriptionIdSplit[p]
                                                                            }
                                                                            k = parseInt(k) + 1
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        else {

                                                            if (typeof $("#hidPrescriptionId" + selectGroceryItemCart[i].storeId).val() !== 'undefined') {
                                                                var prescriptionId = $("#hidPrescriptionId" + selectGroceryItemCart[i].storeId).val().slice(1)
                                                                var prescriptionIdSplit = prescriptionId.toString().split(',');
                                                                $("#hidPrescriptionId" + selectGroceryItemCart[i].storeId).val('')
                                                                // alert("prescriptionIdSplit...else.."+prescriptionIdSplit)
                                                                for (var p = 0; p < prescriptionIdSplit.length; p++) {
                                                                    if (prescriptionIdSplit[p] != "") {
                                                                        cookieNewGroceryItemValue[k] =
                                                                        {
                                                                            storeId: selectGroceryItemCart[i].storeId.toString().trim(),
                                                                            item_names: "",
                                                                            item_ids: "",
                                                                            quantity: "",
                                                                            item_prices: "",
                                                                            store_item_stock_ids: "",
                                                                            pack_ids: "",
                                                                            stock_quantity: "",
                                                                            item_image: "",
                                                                            itemNo: "",
                                                                            slotName: slot,
                                                                            consumerNumber: consumerNumber,
                                                                            comments: comments,
                                                                            isPromoCode: isPromoCode,
                                                                            promoCodeDetails: promoCodeArray,
                                                                            phIds: prescriptionIdSplit[p]
                                                                        }
                                                                        k = parseInt(k) + 1
                                                                    }
                                                                }
                                                            }


                                                        }
                                                    }


                                                    if (cookieNewGroceryItemValue.length > 0) {
                                                        grocery[0] = {
                                                            locationId: locationId,
                                                            cityId: cityId,
                                                            selectedProduct: cookieNewGroceryItemValue
                                                        }
                                                    }
                                                }
                                            }


                                            var restaurant = []
                                            var cookieNewRestaurantValue = []
                                            if (JSON.parse(localStorage.getItem('selectRestaurantCart')) != null) {
                                                if (JSON.parse(localStorage.getItem('selectRestaurantCart')).length > 0) {
                                                    var j = 0
                                                    var selectRestaurantCart = JSON.parse(localStorage.getItem('selectRestaurantCart')) || []
                                                    for (var i = 0; i < selectRestaurantCart.length; i++) {
                                                        var slot = $("#txtSlot" + selectRestaurantCart[i].storeId).val()
                                                        //alert("slot..."+slot)
                                                        var consumerNumber = $("#txtConsumerCard" + selectRestaurantCart[i].storeId).val()
                                                        var comments = $("#txtUserNeeds" + selectRestaurantCart[i].storeId).val()
                                                        var promoCodeDetails = $("#hidPromocodeDetails" + selectRestaurantCart[i].storeId).val()
                                                        var promoCodeArray = ''
                                                        var isPromoCode = '0'
                                                        if (promoCodeDetails != null && promoCodeDetails != 'null' && promoCodeDetails != '' && promoCodeDetails !== 'undefined') {
                                                            promoCodeArray = JSON.parse(promoCodeDetails)
                                                            isPromoCode = '1'
                                                        }
                                                        if (storeId != '') {
                                                            if (storeId == selectRestaurantCart[i].storeId.toString().trim()) {
                                                                cookieNewRestaurantValue[j] =
                                                                {
                                                                    storeId: selectRestaurantCart[i].storeId.toString().trim(),
                                                                    itemId: selectRestaurantCart[i].itemId.toString().trim(),
                                                                    itemName: selectRestaurantCart[i].itemName.toString().trim(),
                                                                    quantity: selectRestaurantCart[i].quantity.toString().trim(),
                                                                    itemSize: selectRestaurantCart[i].itemSize.toString().trim(),
                                                                    itemMrp: selectRestaurantCart[i].itemMrp.toString().trim(),
                                                                    itemImage: selectRestaurantCart[i].itemImage.toString().trim(),
                                                                    storeItemSizeId: selectRestaurantCart[i].storeItemSizeId.toString().trim(),
                                                                    itemNo: selectRestaurantCart[i].itemNo.toString().trim(),
                                                                    toppingsData: selectRestaurantCart[i].toppingsData.toString().trim(),
                                                                    slotName: slot,
                                                                    consumerNumber: consumerNumber,
                                                                    comments: comments,
                                                                    isPromoCode: isPromoCode,
                                                                    promoCodeDetails: promoCodeArray
                                                                }
                                                                j = parseInt(j) + 1
                                                            }
                                                        }
                                                        else {
                                                            cookieNewRestaurantValue[i] =
                                                            {
                                                                storeId: selectRestaurantCart[i].storeId.toString().trim(),
                                                                itemId: selectRestaurantCart[i].itemId.toString().trim(),
                                                                itemName: selectRestaurantCart[i].itemName.toString().trim(),
                                                                quantity: selectRestaurantCart[i].quantity.toString().trim(),
                                                                itemSize: selectRestaurantCart[i].itemSize.toString().trim(),
                                                                itemMrp: selectRestaurantCart[i].itemMrp.toString().trim(),
                                                                itemImage: selectRestaurantCart[i].itemImage.toString().trim(),
                                                                storeItemSizeId: selectRestaurantCart[i].storeItemSizeId.toString().trim(),
                                                                itemNo: selectRestaurantCart[i].itemNo.toString().trim(),
                                                                toppingsData: selectRestaurantCart[i].toppingsData.toString().trim(),
                                                                slotName: slot,
                                                                consumerNumber: consumerNumber,
                                                                comments: comments,
                                                                isPromoCode: isPromoCode,
                                                                promoCodeDetails: promoCodeArray
                                                            }
                                                        }
                                                    }
                                                    if (cookieNewRestaurantValue.length > 0) {
                                                        restaurant[0] = {
                                                            locationId: locationId,
                                                            selectRestaurantCart: cookieNewRestaurantValue
                                                        }
                                                    }

                                                }
                                            }


                                            var postData = {
                                                functionName: "saleConfirm",
                                                device: "Web" + '-' + window.sessionStorage.getItem('browserName'),
                                                locationId: localStorage.getItem('locationId'),
                                                buyerId: localStorage.getItem('userId'),
                                                buyerDeliveryAddressId: $("#deliveryAddressId").val(),
                                                buyerBillingAddressId:window.sessionStorage.getItem('buyerBillingAddressId'),
                                                siteId: localStorage.getItem('siteId'),
                                                cityId: cityId,
                                                razorPayDetails: newRazor,
                                                paymentId: $("#txtPaymentMode").val(),
                                                commonItem: commonItem,
                                                restaurant: restaurant,
                                                grocery: grocery,
                                                hasLocation: hasLocation,
                                                timeZone: new Date().getTimezoneOffset().toString(),
                                                itemBeforePaymentGroupCode: dataBeforePayment.data.result[0].itemBeforePaymentGroupCode
                                            }

                                            getCartItemsMultiple(postData).then((data) => {
                                                console.log("sale data",data)
                                                // alert(data)    
                                                //  alert(" data.data.success......." + data.data.success)           
                                                if (data.data.success == 1) {
                                                    // var hasMultipleCheckout = window.sessionStorage.getItem('hasMultipleCheckout')
                                                    localStorage.setItem('orderSummery', JSON.stringify(data.data.result));
                                                    localStorage.setItem('hidStoreIdForCheckout', storeId);
                                                    // $("#paymentDiv").hide();
                                                    // $("#successDiv").show();
                                                    window.location.href = "/OrderSummary-ThankYou-Order-placed-successfully"
                                                    // getPageHitAPICall();
                                                    // $("#checkOutHeadingDiv").text("Finished!")
                                                    // $("#checkOutStep4").addClass("orderIndicationActive")
                                                    // if (hasMultipleCheckout === "0") {
                                                    //     var userOrderID = ""
                                                    //     var userDeliveryTime = ""
                                                    //     if (data.data.result[0].commonItems.length > 0) {
                                                    //         userOrderID = data.data.result[0].commonItems[0].saleOrderId
                                                    //         userDeliveryTime = data.data.result[0].commonItems[0].slotNames
                                                    //     }
                                                    //     else if (data.data.result[0].restaurant.length > 0) {
                                                    //         userOrderID = data.data.result[0].restaurant[0].saleOrderId
                                                    //         userDeliveryTime = data.data.result[0].restaurant[0].slotNames
                                                    //     }
                                                    //     else if (data.data.result[0].grocery.length > 0) {
                                                    //         userOrderID = data.data.result[0].grocery[0].saleOrderId
                                                    //         userDeliveryTime = data.data.result[0].grocery[0].slotNames
                                                    //     }
                                                    //     $("#divOrderId").text("Order ID : " + userOrderID)
                                                    //     $("#divDeliveryTime").text("Delivery Time : " + userDeliveryTime)
                                                    // }
                                                    // if (storeId != '') {
                                                    //     var selectGroceryItemCart = JSON.parse(localStorage.getItem('selectedCartProduct')) || []
                                                    //     var cookieNewGroceryItemValue = []
                                                    //     var j = 0
                                                    //     for (var i = 0; i < selectGroceryItemCart.length; i++) {
                                                    //         if (storeId != selectGroceryItemCart[i].storeId) {
                                                    //             cookieNewGroceryItemValue[j] =
                                                    //             {
                                                    //                 storeId: selectGroceryItemCart[i].storeId.toString().trim(),
                                                    //                 item_names: selectGroceryItemCart[i].item_names.toString().trim(),
                                                    //                 item_ids: selectGroceryItemCart[i].item_ids.toString().trim(),
                                                    //                 quantity: selectGroceryItemCart[i].quantity.toString().trim(),
                                                    //                 item_prices: selectGroceryItemCart[i].item_prices.toString().trim(),
                                                    //                 store_item_stock_ids: selectGroceryItemCart[i].store_item_stock_ids.toString().trim(),
                                                    //                 pack_ids: selectGroceryItemCart[i].pack_ids.toString().trim(),
                                                    //                 stock_quantity: selectGroceryItemCart[i].stock_quantity.toString().trim(),
                                                    //                 item_image: selectGroceryItemCart[i].item_image.toString().trim(),
                                                    //                 itemNo: selectGroceryItemCart[i].itemNo.toString().trim()
                                                    //             }
                                                    //             j = parseInt(j) + 1
                                                    //         }
                                                    //     }
                                                    //     localStorage.setItem('selectedCartProduct', JSON.stringify(cookieNewGroceryItemValue))

                                                    //     var selectCommonItemCart = JSON.parse(localStorage.getItem('selectCommonItemCart')) || []
                                                    //     var cookieNewCommonItemValue = []
                                                    //     j = 0
                                                    //     for (var i = 0; i < selectCommonItemCart.length; i++) {
                                                    //         if (storeId != selectCommonItemCart[i].storeId) {
                                                    //             cookieNewCommonItemValue[j] =
                                                    //             {
                                                    //                 storeId: selectCommonItemCart[i].storeId.toString().trim(),
                                                    //                 itemId: selectCommonItemCart[i].itemId.toString().trim(),
                                                    //                 itemName: selectCommonItemCart[i].itemName.toString().trim(),
                                                    //                 quantity: selectCommonItemCart[i].quantity.toString().trim(),
                                                    //                 itemTitle: selectCommonItemCart[i].itemTitle,
                                                    //                 brand: selectCommonItemCart[i].brand,
                                                    //                 sizeName: selectCommonItemCart[i].sizeName.toString().trim(),
                                                    //                 sizeId: selectCommonItemCart[i].sizeId.toString().trim(),
                                                    //                 cmItemSizeId: selectCommonItemCart[i].cmItemSizeId.toString().trim(),
                                                    //                 itemMasterId: selectCommonItemCart[i].itemMasterId.toString().trim(),
                                                    //                 netAmount: selectCommonItemCart[i].netAmount.toString().trim(),
                                                    //                 mrp: selectCommonItemCart[i].mrp.toString().trim(),
                                                    //                 itemImage: selectCommonItemCart[i].itemImage.toString().trim(),
                                                    //                 itemNo: selectCommonItemCart[i].itemNo.toString().trim()
                                                    //             }
                                                    //             j = parseInt(j) + 1
                                                    //         }
                                                    //     }
                                                    //     localStorage.setItem('selectCommonItemCart', JSON.stringify(cookieNewCommonItemValue))

                                                    //     var selectRestaurantCart = JSON.parse(localStorage.getItem('selectRestaurantCart')) || []
                                                    //     var cookieNewRestaurantValue = []
                                                    //     j = 0
                                                    //     for (var i = 0; i < selectRestaurantCart.length; i++) {
                                                    //         if (storeId != selectRestaurantCart[i].storeId) {
                                                    //             cookieNewRestaurantValue[j] =
                                                    //             {
                                                    //                 storeId: selectRestaurantCart[i].storeId.toString().trim(),
                                                    //                 itemId: selectRestaurantCart[i].itemId.toString().trim(),
                                                    //                 itemName: selectRestaurantCart[i].itemName.toString().trim(),
                                                    //                 quantity: selectRestaurantCart[i].quantity.toString().trim(),
                                                    //                 itemSize: selectRestaurantCart[i].itemSize.toString().trim(),
                                                    //                 itemMrp: selectRestaurantCart[i].itemMrp.toString().trim(),
                                                    //                 itemImage: selectRestaurantCart[i].itemImage.toString().trim(),
                                                    //                 storeItemSizeId: selectRestaurantCart[i].storeItemSizeId.toString().trim(),
                                                    //                 itemNo: selectRestaurantCart[i].itemNo.toString().trim(),
                                                    //                 toppingsData: selectRestaurantCart[i].toppingsData.toString().trim()
                                                    //             }
                                                    //             j = parseInt(j) + 1
                                                    //         }
                                                    //     }
                                                    //     localStorage.setItem('selectRestaurantCart', JSON.stringify(cookieNewRestaurantValue))
                                                    // }
                                                    // else {
                                                    //     localStorage.removeItem('selectedCartProduct')
                                                    //     localStorage.removeItem('selectCommonItemCart')
                                                    //     localStorage.removeItem('selectRestaurantCart')
                                                    // }
                                                    // window.scrollTo(0, 0)
                                                    // $('body').removeClass('orderProcessingDisable')
                                                    // //this.sendMail();
                                                    // var orderSummery = localStorage.getItem('orderSummery')
                                                    // var orderSummeryData = JSON.parse(orderSummery);
                                                    // var postData = {
                                                    //     functionName: "sendMail",
                                                    //     result: orderSummeryData
                                                    // }
                                                    // getCartItemsMultiple(postData).then((data) => {

                                                    // })
                                                }
                                                if (data.data.success == "0") {
                                                    alert(data.data.errorMessage)
                                                    $('body').removeClass('orderProcessingDisable')
                                                    $("#orderConfirm").show();
                                                    $("#orderProcessing").hide();
                                                    // $("#paymentDiv").show();
                                                    // window.location.reload();
                                                    // $("#shippingDiv").show();
                                                    // $("#checkOutStep3").removeClass("orderIndicationActive")
                                                    window.scrollTo(0, 0)
                                                }
                                            });
                                        }


                                        // }

                                        //  });


                                    }
                                });
                            },
                            prefill: {
                                name: userName,
                                email: email,
                                contact: mobileNumber,
                            },
                            // "notes": {
                            //     "address":
                            // },
                            theme: {
                                color: "#514886",
                            },

                        };

                        let rzp = new window.Razorpay(options);
                        rzp.open();



                    }
                })





                //alert("connnn")


            }

            else {
                $('body').addClass('orderProcessingDisable')
                $("#orderConfirm").hide();
                $("#orderProcessing").show();
                this.confirmShopping();
            }

            //alert("loooo")
        });



    }


    removeCartItemGrocery(cartPositionGrocery, cartPositionInsufficientGrocery) {
        var cookieArray = JSON.parse(localStorage.getItem('selectedCartProduct')) || []
        var cookieNewValue = [];
        var cookieNewValueCount = 0;
        var cartPositionSplit = cartPositionGrocery.split(',')
        var isDelete = '0';
        for (var i = 0; i < cookieArray.length; i++) {
            isDelete = '0';
            for (var c = 0; c < cartPositionSplit.length; c++) {
                if (parseInt(i) == parseInt(cartPositionSplit[c])) {
                    isDelete = '1';
                }
            }
            if (isDelete == '0') {
                cookieNewValue[cookieNewValueCount] = cookieArray[i];
                cookieNewValueCount++;
            }
        }
        if (cartPositionInsufficientGrocery != null && cartPositionInsufficientGrocery != '') {
            var cartPositionInsufficientGrocerySplit = cartPositionInsufficientGrocery.split(',')
            for (var i = 0; i < cookieNewValue.length; i++) {
                for (var c = 0; c < cartPositionInsufficientGrocerySplit.length; c++) {
                    var a = cartPositionInsufficientGrocerySplit[c].split('-')
                    var cartIndex = a[0]
                    cookieNewValue[cartIndex].quantity = a[1]
                }
            }
        }
        localStorage.setItem('selectedCartProduct', JSON.stringify(cookieNewValue))
    }



    removeCartItemCommonItem(cartPositionCommon, cartPositionInsufficientCommon) {
        var cookieArray = JSON.parse(localStorage.getItem('selectCommonItemCart')) || []
        var cookieNewValue = [];
        var cookieNewValueCount = 0;
        var cartPositionSplit = cartPositionCommon.split(',')
        var isDelete = '0';
        for (var i = 0; i < cookieArray.length; i++) {
            isDelete = '0';
            for (var c = 0; c < cartPositionSplit.length; c++) {
                if (parseInt(i) == parseInt(cartPositionSplit[c])) {
                    isDelete = '1';
                }
            }
            if (isDelete == '0') {
                cookieNewValue[cookieNewValueCount] = cookieArray[i];
                cookieNewValueCount++;
            }
        }
        if (cartPositionInsufficientCommon != null && cartPositionInsufficientCommon != '') {
            var cartPositionInsufficientGrocerySplit = cartPositionInsufficientCommon.split(',')
            for (var i = 0; i < cookieNewValue.length; i++) {
                for (var c = 0; c < cartPositionInsufficientGrocerySplit.length; c++) {
                    var a = cartPositionInsufficientGrocerySplit[c].split('-')
                    var cartIndex = a[0]
                    cookieNewValue[cartIndex].quantity = a[1]
                }
            }
        }
        localStorage.setItem('selectCommonItemCart', JSON.stringify(cookieNewValue))
    }


    async confirmShopping() {
        // alert("inside...confirmShopping.."+window.sessionStorage.getItem('razorList')) 

        let locationId = localStorage.getItem('locationId') == null ? '' : localStorage.getItem('locationId')
        let cityId = localStorage.getItem('cityIdGlobal') == null ? localStorage.getItem('cityId') : localStorage.getItem('cityIdGlobal')
        let hasLocation = localStorage.getItem('hasLocation') == '1' ? '1' : '0'
        var newRazor = [];
        var bamboraDetails = [];
        var squareDetails =[];
        if (this.state.isSquare === true) {
            squareDetails[0] =
            {
                "amount": this.state.squareAmount,
                "sourceToken": this.state.squareToken
            }
        }
        if (this.state.isBambora === true) {
            bamboraDetails[0] =
            {
                amount: this.state.bamboraAmount,//localStorage.getItem('totalAmount'),
                cardNumber: $("#cardNumber").val(),
                currency: "",
                email: "",
                cardHolderName: $("#cardHolderName").val(),
                expiryMonth: $("#year").val().split('/')[0],
                expiryYear: $("#year").val().split('/')[1],
                cvv: $("#cvv").val(),
                contactNumber: ""
            }
        }
        else {
            bamboraDetails = []
        }

        newRazor[0] = JSON.parse(window.sessionStorage.getItem('razorList'))
        var storeId = $("#hidStoreIdForCheckout").val()
        if ($("#txtPaymentMode").val() == '' || $("#txtPaymentMode").val() == 'selected') {
            alert("Select Payment Mode ");
            $("#orderProcessing").hide();
            $("#orderConfirm").show();
            $('body').removeClass('orderProcessingDisable')
            return false;
        }
        else {
            var commonItem = []
            var cookieNewCommonItemValue = []
            if (JSON.parse(localStorage.getItem('selectCommonItemCart')) != null) {
                if (JSON.parse(localStorage.getItem('selectCommonItemCart')).length > 0) {
                    var j = 0
                    var selectCommonItemCart = JSON.parse(localStorage.getItem('selectCommonItemCart')) || []
                    for (var i = 0; i < selectCommonItemCart.length; i++) {
                        var slot = $("#txtSlot" + selectCommonItemCart[i].storeId).val()
                        var consumerNumber = $("#txtConsumerCard" + selectCommonItemCart[i].storeId).val()
                        var comments = $("#txtUserNeeds" + selectCommonItemCart[i].storeId).val()
                        var promoCodeDetails = $("#hidPromocodeDetails" + selectCommonItemCart[i].storeId).val()
                        var promoCodeArray = ''
                        var isPromoCode = '0'
                        if (promoCodeDetails != null && promoCodeDetails != 'null' && promoCodeDetails != '' && promoCodeDetails !== 'undefined') {
                            promoCodeArray = JSON.parse(promoCodeDetails)
                            isPromoCode = '1'
                        }
                        if (storeId != '') {
                            if (storeId == selectCommonItemCart[i].storeId.toString().trim()) {
                                cookieNewCommonItemValue[j] =
                                {
                                    storeId: selectCommonItemCart[i].storeId.toString(),
                                    itemId: selectCommonItemCart[i].itemId.toString(),
                                    itemName: selectCommonItemCart[i].itemName,
                                    quantity: selectCommonItemCart[i].quantity,
                                    itemTitle: selectCommonItemCart[i].itemTitle,
                                    brand: selectCommonItemCart[i].brand,
                                    sizeName: selectCommonItemCart[i].sizeName.toString().trim(),
                                    sizeId: selectCommonItemCart[i].sizeId.toString().trim(),
                                    cmItemSizeId: selectCommonItemCart[i].cmItemSizeId.toString().trim(),
                                    itemMasterId: selectCommonItemCart[i].itemMasterId.toString(),
                                    netAmount: selectCommonItemCart[i].netAmount.toString(),
                                    mrp: selectCommonItemCart[i].mrp.toString(),
                                    itemImage: selectCommonItemCart[i].itemImage,
                                    itemNo: selectCommonItemCart[i].itemNo,
                                    slotName: slot,
                                    consumerNumber: consumerNumber,
                                    comments: comments,
                                    isPromoCode: isPromoCode,
                                    promoCodeDetails: promoCodeArray
                                }
                                j = parseInt(j) + 1
                            }
                        }
                        else {
                            cookieNewCommonItemValue[i] =
                            {
                                storeId: selectCommonItemCart[i].storeId.toString(),
                                itemId: selectCommonItemCart[i].itemId.toString(),
                                itemName: selectCommonItemCart[i].itemName,
                                quantity: selectCommonItemCart[i].quantity,
                                itemTitle: selectCommonItemCart[i].itemTitle,
                                brand: selectCommonItemCart[i].brand,
                                sizeName: selectCommonItemCart[i].sizeName.toString().trim(),
                                sizeId: selectCommonItemCart[i].sizeId.toString().trim(),
                                cmItemSizeId: selectCommonItemCart[i].cmItemSizeId.toString().trim(),
                                itemMasterId: selectCommonItemCart[i].itemMasterId.toString(),
                                netAmount: selectCommonItemCart[i].netAmount.toString(),
                                mrp: selectCommonItemCart[i].mrp.toString(),
                                itemImage: selectCommonItemCart[i].itemImage,
                                itemNo: selectCommonItemCart[i].itemNo,
                                slotName: slot,
                                consumerNumber: consumerNumber,
                                comments: comments,
                                isPromoCode: isPromoCode,
                                promoCodeDetails: promoCodeArray
                            }
                        }
                    }
                    if (cookieNewCommonItemValue.length > 0) {
                        commonItem[0] = {
                            locationId: locationId,
                            cityId: cityId,
                            selectCommonItemCart: cookieNewCommonItemValue
                        }
                    }
                }
            }


            var grocery = []
            var cookieNewGroceryItemValue = []
            if (JSON.parse(localStorage.getItem('selectedCartProduct')) != null && JSON.parse(localStorage.getItem('selectedCartProduct')) != "") {
                if (JSON.parse(localStorage.getItem('selectedCartProduct')).length > 0) {
                    var j = 0
                    var selectGroceryItemCart = JSON.parse(localStorage.getItem('selectedCartProduct')) || []
                    for (var i = 0; i < selectGroceryItemCart.length; i++) {
                        var slot = $("#txtSlot" + selectGroceryItemCart[i].storeId).val()
                        var consumerNumber = $("#txtConsumerCard" + selectGroceryItemCart[i].storeId).val()
                        var comments = $("#txtUserNeeds" + selectGroceryItemCart[i].storeId).val()
                        var promoCodeDetails = $("#hidPromocodeDetails" + selectGroceryItemCart[i].storeId).val()
                        var promoCodeArray = ''
                        var isPromoCode = '0'
                        if (promoCodeDetails != null && promoCodeDetails != 'null' && promoCodeDetails != '' && promoCodeDetails !== 'undefined') {
                            promoCodeArray = JSON.parse(promoCodeDetails)
                            isPromoCode = '1'
                        }
                        if (storeId != '') {
                            if (storeId == selectGroceryItemCart[i].storeId.toString().trim()) {
                                cookieNewGroceryItemValue[j] =
                                {
                                    storeId: selectGroceryItemCart[i].storeId.toString().trim(),
                                    item_names: selectGroceryItemCart[i].item_names.toString().trim(),
                                    item_ids: selectGroceryItemCart[i].item_ids.toString().trim(),
                                    quantity: selectGroceryItemCart[i].quantity.toString().trim(),
                                    item_prices: selectGroceryItemCart[i].item_prices.toString().trim(),
                                    store_item_stock_ids: selectGroceryItemCart[i].store_item_stock_ids.toString().trim(),
                                    pack_ids: selectGroceryItemCart[i].pack_ids.toString().trim(),
                                    stock_quantity: selectGroceryItemCart[i].stock_quantity.toString().trim(),
                                    item_image: selectGroceryItemCart[i].item_image.toString().trim(),
                                    itemNo: selectGroceryItemCart[i].itemNo.toString().trim(),
                                    slotName: slot,
                                    consumerNumber: consumerNumber,
                                    comments: comments,
                                    isPromoCode: isPromoCode,
                                    promoCodeDetails: promoCodeArray,
                                    phIds: ""
                                }
                                j = parseInt(j) + 1
                            }
                        }
                        else {
                            cookieNewGroceryItemValue[i] =
                            {
                                storeId: selectGroceryItemCart[i].storeId.toString().trim(),
                                item_names: selectGroceryItemCart[i].item_names.toString().trim(),
                                item_ids: selectGroceryItemCart[i].item_ids.toString().trim(),
                                quantity: selectGroceryItemCart[i].quantity.toString().trim(),
                                item_prices: selectGroceryItemCart[i].item_prices.toString().trim(),
                                store_item_stock_ids: selectGroceryItemCart[i].store_item_stock_ids.toString().trim(),
                                pack_ids: selectGroceryItemCart[i].pack_ids.toString().trim(),
                                stock_quantity: selectGroceryItemCart[i].stock_quantity.toString().trim(),
                                item_image: selectGroceryItemCart[i].item_image.toString().trim(),
                                itemNo: selectGroceryItemCart[i].itemNo.toString().trim(),
                                slotName: slot,
                                consumerNumber: consumerNumber,
                                comments: comments,
                                isPromoCode: isPromoCode,
                                promoCodeDetails: promoCodeArray,
                                phIds: ""
                            }
                        }
                    }

                    var k = cookieNewGroceryItemValue.length
                    for (var i = 0; i < selectGroceryItemCart.length; i++) {
                        var slot = $("#txtSlot" + selectGroceryItemCart[i].storeId).val()
                        var consumerNumber = $("#txtConsumerCard" + selectGroceryItemCart[i].storeId).val()
                        var comments = $("#txtUserNeeds" + selectGroceryItemCart[i].storeId).val()
                        var promoCodeDetails = $("#hidPromocodeDetails" + selectGroceryItemCart[i].storeId).val()
                        var promoCodeArray = ''
                        var isPromoCode = '0'
                        if (promoCodeDetails != null && promoCodeDetails != 'null' && promoCodeDetails != '' && promoCodeDetails !== 'undefined') {
                            promoCodeArray = JSON.parse(promoCodeDetails)
                            isPromoCode = '1'
                        }
                        if (storeId != '') {
                            // alert("is store")
                            if (storeId == selectGroceryItemCart[i].storeId.toString().trim()) {
                                if (typeof $("#hidPrescriptionId" + selectGroceryItemCart[i].storeId).val() !== 'undefined') {
                                    var prescriptionId = $("#hidPrescriptionId" + selectGroceryItemCart[i].storeId).val().slice(1)
                                    var prescriptionIdSplit = prescriptionId.toString().trim().split(',');
                                    $("#hidPrescriptionId" + selectGroceryItemCart[i].storeId).val('')
                                    // alert("prescriptionIdSplit...if.."+prescriptionIdSplit)
                                    //  alert("prescriptionIdSplit.length...if.."+prescriptionIdSplit.length)
                                    for (var p = 0; p < prescriptionIdSplit.length; p++) {
                                        if (prescriptionIdSplit[p] != "") {
                                            cookieNewGroceryItemValue[k] =
                                            {
                                                storeId: selectGroceryItemCart[i].storeId.toString().trim(),
                                                item_names: "",
                                                item_ids: "",
                                                quantity: "",
                                                item_prices: "",
                                                store_item_stock_ids: "",
                                                pack_ids: "",
                                                stock_quantity: "",
                                                item_image: "",
                                                itemNo: "",
                                                slotName: slot,
                                                consumerNumber: consumerNumber,
                                                comments: comments,
                                                isPromoCode: isPromoCode,
                                                promoCodeDetails: promoCodeArray,
                                                phIds: prescriptionIdSplit[p]
                                            }
                                            k = parseInt(k) + 1
                                        }
                                    }
                                }
                            }
                        }
                        else {

                            if (typeof $("#hidPrescriptionId" + selectGroceryItemCart[i].storeId).val() !== 'undefined') {
                                var prescriptionId = $("#hidPrescriptionId" + selectGroceryItemCart[i].storeId).val().slice(1)
                                var prescriptionIdSplit = prescriptionId.toString().split(',');
                                $("#hidPrescriptionId" + selectGroceryItemCart[i].storeId).val('')
                                // alert("prescriptionIdSplit...else.."+prescriptionIdSplit)
                                for (var p = 0; p < prescriptionIdSplit.length; p++) {
                                    if (prescriptionIdSplit[p] != "") {
                                        cookieNewGroceryItemValue[k] =
                                        {
                                            storeId: selectGroceryItemCart[i].storeId.toString().trim(),
                                            item_names: "",
                                            item_ids: "",
                                            quantity: "",
                                            item_prices: "",
                                            store_item_stock_ids: "",
                                            pack_ids: "",
                                            stock_quantity: "",
                                            item_image: "",
                                            itemNo: "",
                                            slotName: slot,
                                            consumerNumber: consumerNumber,
                                            comments: comments,
                                            isPromoCode: isPromoCode,
                                            promoCodeDetails: promoCodeArray,
                                            phIds: prescriptionIdSplit[p]
                                        }
                                        k = parseInt(k) + 1
                                    }
                                }
                            }


                        }
                    }
                }
            }
            // else{

            var k = cookieNewGroceryItemValue.length
            var storeIdPrescription = $("#hidStoreIdForPrescriptionCheckout").val()
            // alert("storeId.........."+storeId)    
            if (typeof $("#hidPrescriptionId" + storeIdPrescription).val() !== 'undefined') {
                var slot = $("#txtSlot" + storeIdPrescription).val()
                var consumerNumber = $("#txtConsumerCard" + storeIdPrescription).val()
                var comments = $("#txtUserNeeds" + storeIdPrescription).val()
                var promoCodeDetails = $("#hidPromocodeDetails" + storeIdPrescription).val()
                var promoCodeArray = ''
                var isPromoCode = '0'
                if (promoCodeDetails != null && promoCodeDetails != 'null' && promoCodeDetails != '' && promoCodeDetails !== 'undefined') {
                    promoCodeArray = JSON.parse(promoCodeDetails)
                    isPromoCode = '1'
                }
                var prescriptionId = $("#hidPrescriptionId" + storeIdPrescription).val().slice(1)
                var prescriptionIdSplit = prescriptionId.toString().split(',');
                for (var p = 0; p < prescriptionIdSplit.length; p++) {
                    if (prescriptionIdSplit[p] != "") {
                        cookieNewGroceryItemValue[k] =
                        {
                            storeId: storeIdPrescription,
                            item_names: "",
                            item_ids: "",
                            quantity: "",
                            item_prices: "",
                            store_item_stock_ids: "",
                            pack_ids: "",
                            stock_quantity: "",
                            item_image: "",
                            itemNo: "",
                            slotName: slot,
                            consumerNumber: consumerNumber,
                            comments: comments,
                            isPromoCode: isPromoCode,
                            promoCodeDetails: promoCodeArray,
                            phIds: prescriptionIdSplit[p]
                        }
                        k = parseInt(k) + 1
                    }
                }
            }
            // }



            if (cookieNewGroceryItemValue.length > 0) {
                grocery[0] = {
                    locationId: locationId,
                    cityId: cityId,
                    selectedProduct: cookieNewGroceryItemValue
                }
            }


            var restaurant = []
            var cookieNewRestaurantValue = []
            if (JSON.parse(localStorage.getItem('selectRestaurantCart')) != null) {
                if (JSON.parse(localStorage.getItem('selectRestaurantCart')).length > 0) {
                    var j = 0
                    var selectRestaurantCart = JSON.parse(localStorage.getItem('selectRestaurantCart')) || []
                    for (var i = 0; i < selectRestaurantCart.length; i++) {
                        var slot = $("#txtSlot" + selectRestaurantCart[i].storeId).val()
                        //alert("slot..."+slot)
                        var consumerNumber = $("#txtConsumerCard" + selectRestaurantCart[i].storeId).val()
                        var comments = $("#txtUserNeeds" + selectRestaurantCart[i].storeId).val()
                        var promoCodeDetails = $("#hidPromocodeDetails" + selectRestaurantCart[i].storeId).val()
                        var promoCodeArray = ''
                        var isPromoCode = '0'
                        if (promoCodeDetails != null && promoCodeDetails != 'null' && promoCodeDetails != '' && promoCodeDetails !== 'undefined') {
                            promoCodeArray = JSON.parse(promoCodeDetails)
                            isPromoCode = '1'
                        }
                        if (storeId != '') {
                            if (storeId == selectRestaurantCart[i].storeId.toString().trim()) {
                                cookieNewRestaurantValue[j] =
                                {
                                    storeId: selectRestaurantCart[i].storeId.toString().trim(),
                                    itemId: selectRestaurantCart[i].itemId.toString().trim(),
                                    itemName: selectRestaurantCart[i].itemName.toString().trim(),
                                    quantity: selectRestaurantCart[i].quantity.toString().trim(),
                                    itemSize: selectRestaurantCart[i].itemSize.toString().trim(),
                                    itemMrp: selectRestaurantCart[i].itemMrp.toString().trim(),
                                    itemImage: selectRestaurantCart[i].itemImage.toString().trim(),
                                    storeItemSizeId: selectRestaurantCart[i].storeItemSizeId.toString().trim(),
                                    itemNo: selectRestaurantCart[i].itemNo.toString().trim(),
                                    toppingsData: selectRestaurantCart[i].toppingsData.toString().trim(),
                                    slotName: slot,
                                    consumerNumber: consumerNumber,
                                    comments: comments,
                                    isPromoCode: isPromoCode,
                                    promoCodeDetails: promoCodeArray
                                }
                                j = parseInt(j) + 1
                            }
                        }
                        else {
                            cookieNewRestaurantValue[i] =
                            {
                                storeId: selectRestaurantCart[i].storeId.toString().trim(),
                                itemId: selectRestaurantCart[i].itemId.toString().trim(),
                                itemName: selectRestaurantCart[i].itemName.toString().trim(),
                                quantity: selectRestaurantCart[i].quantity.toString().trim(),
                                itemSize: selectRestaurantCart[i].itemSize.toString().trim(),
                                itemMrp: selectRestaurantCart[i].itemMrp.toString().trim(),
                                itemImage: selectRestaurantCart[i].itemImage.toString().trim(),
                                storeItemSizeId: selectRestaurantCart[i].storeItemSizeId.toString().trim(),
                                itemNo: selectRestaurantCart[i].itemNo.toString().trim(),
                                toppingsData: selectRestaurantCart[i].toppingsData.toString().trim(),
                                slotName: slot,
                                consumerNumber: consumerNumber,
                                comments: comments,
                                isPromoCode: isPromoCode,
                                promoCodeDetails: promoCodeArray
                            }
                        }
                    }
                    if (cookieNewRestaurantValue.length > 0) {
                        restaurant[0] = {
                            locationId: locationId,
                            selectRestaurantCart: cookieNewRestaurantValue
                        }
                    }

                }
            }
            var parcelDeliveryCharge = ""
            var parcelTrackingId = ""
            if (window.sessionStorage.getItem('hasParcel') === "1") {
                let parcelResult = await this.parcelApiCalling(storeId);
                if (parcelResult.length > 0) {
                    parcelDeliveryCharge = parcelResult[0].deliveryCharge
                    parcelTrackingId = parcelResult[0].trackingId
                }
            }
            var postData = {
                functionName: "saleConfirm",
                device: "Web" + '-' + window.sessionStorage.getItem('browserName'),
                locationId: localStorage.getItem('locationId'),
                buyerId: localStorage.getItem('userId'),
                buyerDeliveryAddressId: $("#deliveryAddressId").val(),
                buyerBillingAddressId:window.sessionStorage.getItem('buyerBillingAddressId'),
                siteId: localStorage.getItem('siteId'),
                cityId: cityId,
                razorPayDetails: newRazor[0]!=null?newRazor:[],
                bamboraPayDetails: bamboraDetails,
                squarePayDetails:squareDetails,
                paymentId: $("#txtPaymentMode").val(),
                commonItem: commonItem,
                restaurant: restaurant,
                grocery: grocery,
                hasLocation: hasLocation,
                timeZone: new Date().getTimezoneOffset().toString(),
                orderOptionsId: $("input:radio[name='rdOrderOptions']:checked").val(),
                receiverOptionsId: $("input:radio[name='rdReceiverOptions']:checked").val(),
                receiverName: $("#txtAlcoholReceiverName").val(),
                parcelDeliveryCharge: parcelDeliveryCharge,
                parcelTrackingId: parcelTrackingId
            }
            getCartItemsMultiple(postData).then((data) => {
                console.log("sale data",data)
                // alert(data)    
                //  alert(" data.data.success......." + data.data.success)           
                if (data.data.success == 1) {
                    //  var hasMultipleCheckout = window.sessionStorage.getItem('hasMultipleCheckout')
                     localStorage.setItem('orderSummery', JSON.stringify(data.data.result));
                     localStorage.setItem('hidStoreIdForCheckout', storeId);
                    // $("#paymentDiv").hide();
                    // $("#successDiv").show();

                    window.location.href = "/OrderSummary-ThankYou-Order-placed-successfully"
                    // getPageHitAPICall();
                    // $("#checkOutHeadingDiv").text("Finished!")
                    // $("#checkOutStep4").addClass("orderIndicationActive")
                    // if (hasMultipleCheckout === "0") {
                    //     var userOrderID = ""
                    //     var userDeliveryTime = ""
                    //     if (data.data.result[0].commonItems.length > 0) {
                    //         userOrderID = data.data.result[0].commonItems[0].saleOrderId
                    //         userDeliveryTime = data.data.result[0].commonItems[0].slotNames
                    //     }
                    //     else if (data.data.result[0].restaurant.length > 0) {
                    //         userOrderID = data.data.result[0].restaurant[0].saleOrderId
                    //         userDeliveryTime = data.data.result[0].restaurant[0].slotNames
                    //     }
                    //     else if (data.data.result[0].grocery.length > 0) {
                    //         userOrderID = data.data.result[0].grocery[0].saleOrderId
                    //         userDeliveryTime = data.data.result[0].grocery[0].slotNames
                    //     }
                    //     $("#divOrderId").text("Order ID : " + userOrderID)
                    //     $("#divDeliveryTime").text("Delivery Time : " + userDeliveryTime)
                    // }
                    // if (storeId != '') {
                    //     var selectGroceryItemCart = JSON.parse(localStorage.getItem('selectedCartProduct')) || []
                    //     var cookieNewGroceryItemValue = []
                    //     var j = 0
                    //     for (var i = 0; i < selectGroceryItemCart.length; i++) {
                    //         if (storeId != selectGroceryItemCart[i].storeId) {
                    //             cookieNewGroceryItemValue[j] =
                    //             {
                    //                 storeId: selectGroceryItemCart[i].storeId.toString().trim(),
                    //                 item_names: selectGroceryItemCart[i].item_names.toString().trim(),
                    //                 item_ids: selectGroceryItemCart[i].item_ids.toString().trim(),
                    //                 quantity: selectGroceryItemCart[i].quantity.toString().trim(),
                    //                 item_prices: selectGroceryItemCart[i].item_prices.toString().trim(),
                    //                 store_item_stock_ids: selectGroceryItemCart[i].store_item_stock_ids.toString().trim(),
                    //                 pack_ids: selectGroceryItemCart[i].pack_ids.toString().trim(),
                    //                 stock_quantity: selectGroceryItemCart[i].stock_quantity.toString().trim(),
                    //                 item_image: selectGroceryItemCart[i].item_image.toString().trim(),
                    //                 itemNo: selectGroceryItemCart[i].itemNo.toString().trim()
                    //             }
                    //             j = parseInt(j) + 1
                    //         }
                    //     }
                    //     localStorage.setItem('selectedCartProduct', JSON.stringify(cookieNewGroceryItemValue))

                    //     var selectCommonItemCart = JSON.parse(localStorage.getItem('selectCommonItemCart')) || []
                    //     var cookieNewCommonItemValue = []
                    //     j = 0
                    //     for (var i = 0; i < selectCommonItemCart.length; i++) {
                    //         if (storeId != selectCommonItemCart[i].storeId) {
                    //             cookieNewCommonItemValue[j] =
                    //             {
                    //                 storeId: selectCommonItemCart[i].storeId.toString().trim(),
                    //                 itemId: selectCommonItemCart[i].itemId.toString().trim(),
                    //                 itemName: selectCommonItemCart[i].itemName.toString().trim(),
                    //                 quantity: selectCommonItemCart[i].quantity.toString().trim(),
                    //                 itemTitle: selectCommonItemCart[i].itemTitle,
                    //                 brand: selectCommonItemCart[i].brand,
                    //                 sizeName: selectCommonItemCart[i].sizeName.toString().trim(),
                    //                 sizeId: selectCommonItemCart[i].sizeId.toString().trim(),
                    //                 cmItemSizeId: selectCommonItemCart[i].cmItemSizeId.toString().trim(),
                    //                 itemMasterId: selectCommonItemCart[i].itemMasterId.toString().trim(),
                    //                 netAmount: selectCommonItemCart[i].netAmount.toString().trim(),
                    //                 mrp: selectCommonItemCart[i].mrp.toString().trim(),
                    //                 itemImage: selectCommonItemCart[i].itemImage.toString().trim(),
                    //                 itemNo: selectCommonItemCart[i].itemNo.toString().trim()
                    //             }
                    //             j = parseInt(j) + 1
                    //         }
                    //     }
                    //     localStorage.setItem('selectCommonItemCart', JSON.stringify(cookieNewCommonItemValue))

                    //     var selectRestaurantCart = JSON.parse(localStorage.getItem('selectRestaurantCart')) || []
                    //     var cookieNewRestaurantValue = []
                    //     j = 0
                    //     for (var i = 0; i < selectRestaurantCart.length; i++) {
                    //         if (storeId != selectRestaurantCart[i].storeId) {
                    //             cookieNewRestaurantValue[j] =
                    //             {
                    //                 storeId: selectRestaurantCart[i].storeId.toString().trim(),
                    //                 itemId: selectRestaurantCart[i].itemId.toString().trim(),
                    //                 itemName: selectRestaurantCart[i].itemName.toString().trim(),
                    //                 quantity: selectRestaurantCart[i].quantity.toString().trim(),
                    //                 itemSize: selectRestaurantCart[i].itemSize.toString().trim(),
                    //                 itemMrp: selectRestaurantCart[i].itemMrp.toString().trim(),
                    //                 itemImage: selectRestaurantCart[i].itemImage.toString().trim(),
                    //                 storeItemSizeId: selectRestaurantCart[i].storeItemSizeId.toString().trim(),
                    //                 itemNo: selectRestaurantCart[i].itemNo.toString().trim(),
                    //                 toppingsData: selectRestaurantCart[i].toppingsData.toString().trim()
                    //             }
                    //             j = parseInt(j) + 1
                    //         }
                    //     }
                    //     localStorage.setItem('selectRestaurantCart', JSON.stringify(cookieNewRestaurantValue))
                    // }
                    // else {
                    //     localStorage.removeItem('selectedCartProduct')
                    //     localStorage.removeItem('selectCommonItemCart')
                    //     localStorage.removeItem('selectRestaurantCart')
                    // }
                    // window.scrollTo(0, 0)
                    // $('body').removeClass('orderProcessingDisable')
                    // this.sendMail();
                }
                else {
                    alert(data.data.errorMessage)
                    $("#paymentDiv").show();
                }
            });
        }


    }

    async parcelApiCalling(storeId) {
        let amount
        if ($("#txtTottalCon").val() != null && $("#txtTottalCon").val() != '' && $("#txtTottalCon").val() != 'null') {
            amount = parseFloat($("#txtTottalCon").val()).toFixed(window.sessionStorage.getItem('decimalCount')) * 100;
        } else {
            amount = parseFloat($("#txtTottal" + storeId).val()).toFixed(window.sessionStorage.getItem('decimalCount')) * 100;
        }
        var postData = {
            "functionName": "getDeliveryCharge",
            "addressId": $("#deliveryAddressId").val(),
            "storeId": storeId,
            "total": amount,
            "siteId": localStorage.getItem('siteId')
        }
        return new Promise((resolve, reject) => {
            getCartItemsMultiple(postData).then((data) => {

                resolve(data.data.result);
            })
        })
    }

    sendMail() {
        var orderSummery = localStorage.getItem('orderSummery')
        var orderSummeryData = JSON.parse(orderSummery);
        var postData = {
            functionName: "sendMail",
            result: orderSummeryData
        }
        getCartItemsMultiple(postData).then((data) => {

        })

    }

    backToPage() {
        $("#checkOutHeadingDiv").text("Select a delivery address")
        $("#paymentDiv").hide();
        $("#shippingDiv").show();
        $("#checkOutStep3").removeClass("orderIndicationActive")
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div className="coverWraper" id="paymentDiv" style={{ display: 'none' }}>
                <div className="checkoutSection">
                    {/* <div className="checkoutTitle">Payment</div> */}
                    <div className="checkoutPayment" id="checkoutPaymentDiv">
                        <div class="paymentMethod">
                            <input type="hidden" id="txtPaymentMode"></input>
                            <input type="hidden" id="hidKey2" ></input>
                            <input type="hidden" id="hidKey1"></input>
                            <input type="hidden" id="hidKey3"></input>
                            <input type="hidden" id="hidPaymentGatewayId"></input>
                            {this.state.payments.map((paymentsList, index) => (
                                <div class="paymentMethodList">
                                    <span class="radioSection">
                                        <input type="radio" onClick={(e) => { this.handlePaymentChange(paymentsList, paymentsList.paymentTypeId, paymentsList.razorPayResult != null && paymentsList.razorPayResult != 'null' && paymentsList.razorPayResult != '' ? paymentsList.razorPayResult[0].razorpaykeyid : '', paymentsList.razorPayResult != null && paymentsList.razorPayResult != 'null' && paymentsList.razorPayResult != '' ? paymentsList.razorPayResult[0].razorpaykeysecret : '') }} id={'radiopayment' + index} name="paymentVal" value={paymentsList.paymentTypeId} />
                                        <label for={'radiopayment' + index}></label>{paymentsList.paymentTitle + ((paymentsList.description != null && paymentsList.description != '') ? ' : ' + paymentsList.description : '')}

                                        {/* <input type="text" id="txtkeyid" value={paymentsList.razorPayResult!=null && paymentsList.razorPayResult!='null' && paymentsList.razorPayResult!=''?paymentsList.razorPayResult[0].razorpaykeyid :''}></input>
                                        <input type="text" id="txtkeysecret" value={paymentsList.razorPayResult!=null && paymentsList.razorPayResult!='null' && paymentsList.razorPayResult!= ''?paymentsList.razorPayResult[0].razorpaykeysecret:'' }></input> */}
                                        {/* <input type="radio" onChange={this.handlePaymentChange} id={'radiopayment' + index} name="paymentVal" value={paymentsList.paymentTypeId} />
                                        <label for={'radiopayment' + index}></label>{paymentsList.paymentTitle} */}
                                    </span>
                                </div>
                            ))}
                        </div>
                        <div className="shippingButton">
                            <span className="commonButton checkBorderBtn" onClick={this.backToPage}>Back to Shipping</span>
                            <span id="orderConfirm" className="commonButton" onClick={(e) => { this.cartItem() }}>Confirm</span>
                            <span id="orderProcessing" style={{ display: 'none' }} className="commonButton">Your order is being processed. Please Wait.</span>
                        </div>
                    </div>
                    <div className="checkoutPayment">
                        <WorldLine></WorldLine>
                    </div>

                </div>
                {/* <div className="checkoutShippingStore checkoutShippingStoreTop">
                    <span className="checkoutShippingStoreText">Contact Store</span>
                    <span className="checkoutShippingStoreSpan">Mobile : 9876543216</span>
                    <span className="checkoutShippingStoreSpan">Email : hnjjvbn@gmail.com</span>
                </div>           */}

                <div className="popupWrap locationPopUps" style={{ display: 'none' }}>
                    <div className="confirmWrappers">
                        <div className="paymentSectionWrap">
                            <div className="paymentHead">
                                Enter Card Details
                            </div>
                            <div className="form-wrap">
                                <div className="form-label-payment">Name On Card  <sup className='star'>*</sup></div>
                                <input type="text" name="cardHolderName" id="cardHolderName" className='form-field-confirm' onKeyPress={(event) => {
                                    $("#cardHolderName").removeClass('textred');
                                }} placeholder='Card HolderName' />
                            </div>
                            <div className="form-wrap">
                                <div className="form-label-payment">Card Number  <sup className='star'>*</sup></div>
                                <input type="text" name="cardNumber" id="cardNumber" className='form-field-confirm' placeholder='Card Number' onKeyPress={(event) => {
                                    $("#cardNumber").removeClass('textred');
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        // alert("Only Numbers Accepted");
                                    }
                                }} />
                            </div>
                            <div className="form-wrap">
                                <div className="form-label-payment">CVV  <sup className='star'>*</sup></div>
                                <input type="text" name="cvv" id="cvv" className='form-field-confirm' placeholder='CVV' maxlength='3' onKeyPress={(event) => {
                                    $("#cvv").removeClass('textred');
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        // alert("Only Numbers Accepted");
                                    }
                                }} />
                            </div>
                            <div className="form-wrap">
                                <div className="form-label-payment">Expiry Date  <sup className='star'>*</sup></div>
                                <input type="tel" name="year" id="year" className='form-field-confirm' placeholder='MM/YY' maxlength='5' onChange={(e) => { this.monthYearValidation(e.target.value) }} />
                            </div>

                            <div className="buttongroups">
                                <button className='button_confirmtext save_confirmBtn' onClick={(e) => { this.bamboraPopUpSave() }}>Submit</button>
                                <button className='button_confirmtext cancel_confirmBtn' onClick={(e) => { this.bamboraPopUpCancel() }}>Cancel</button>
                            </div>
                        </div>

                    </div>
                </div>

                {/*  */}

                <div className="popupWrap locationPopUps1" style={{ display: 'none' }}>
                    <span>&times;</span>
                    <div className="confirmWrappers">
                        <div className="paymentSectionWrap">
                            <div className="paymentHead">
                               <span > Enter Card Details</span>
 
                                <SquarePaymentsForm
                                    applicationId= {this.state.squareApplicationId}
                                    // onClick={this.cardTokenizeResponseReceived}
                                    cardTokenizeResponseReceived={this.cardTokenizeResponseReceived}
                                    locationId={this.state.squareLocationId}
                                >
                                    <CreditCardInput />
                                    <button className='paymentCancel'onClick={(e) => { this.squarePopUpCancel() }}>Cancel</button>
                                </SquarePaymentsForm>
                            </div>
                            
                          
                           
                          
                        </div>

                    </div>
                </div>

            </div>
        )
    }
}