import React, { Component } from 'react';
import Myshop from "../images/myyshopp/myyShoppLogo2.png"
import { rtOrderDetails, getData } from '../services';
import numberToWords from 'number-to-words';

export default class Invoice extends Component {
  constructor(props) {
    super(props)
    this.state = {
      storeData: [],
      orderData: [],
      restaurantData: [],
      resrdataNew: [],
      subTotal: 0,
      commonItemData: [],
      groceryItemData: [],
      taxData: []

    };

  }
  printDiv() {
    var printContents = document.getElementById("container").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }
  componentDidMount() {
    const { match: { params } } = this.props;
    if (typeof params.slug !== 'undefined' && params.slug != 'undefined') {
      params.orderId = params.slug.toString().split('I')[1]
    }

    const postData = {
      "functionName": "pothys/decryptUrl",
      "encryptedOrderId": params.orderId
    }
    getData(postData).then((data) => {
console.log(postData,"dataaaaaaaaaaaaaaaaaaa",data)
this.listing(data.data.result.decryptedOrderId)
    })
    //console.log("resrdataNew,....000,..."+this.state.resrdataNew)

  }
  listing = (orderId) =>{
    const postData = {
      functionName: "rtOrderDetails",
      orderId:orderId
    }
    getData(postData).then((data) => {
      console.log('data....order details...', data)
      if (data.data.success == "1") {
        this.setState({
          storeData: data.data.result.storeAddressQueryResult,
          storeName: data.data.result.storeAddressQueryResult[0].storeName,
          storeLogo: data.data.result.storeAddressQueryResult[0].storeImagePath,
          transactionId: data.data.result.storeAddressQueryResult[0].paymentTransactionId,
          supplyState: data.data.result.storeAddressQueryResult[0].stateName,
          saleId: data.data.result.storeAddressQueryResult[0].saleId,
          orderData: data.data.result.orderQueryResults[0],
          cancelledtotal: data.data.result.orderQueryResults[0].cancelledTotal,
          cancelledConvenienceFee: data.data.result.orderQueryResults[0].cancelledConvenienceFee,
          cancelledTax: data.data.result.orderQueryResults[0].cancelledTax,
          ondcConvenientFee: data.data.result.orderQueryResults[0].ondcConvenientFee,
          ondcConvenienceFeeTax: data.data.result.orderQueryResults[0].ondc_conveniencefee_tax,
          ondcDeliveryChargeTax: data.data.result.orderQueryResults[0].ondc_deliveryCharge_tax,
          restaurantData: data.data.result.restauarantListing,
          taxResultNew: (data.data.result.resrdataNew.length > 0 ? data.data.result.resrdataNew[0].taxResultNew : []),
          resrdataNew: data.data.result.resrdataNew,
          commonItemData: data.data.result.commonItemListing,
          groceryItemData: data.data.result.groceryItemListing,
          taxData: data.data.result.taxData
        });
      }
    })
  }
  convertToWords = (amount) => {
    debugger
    const match = amount.match(/\d+\.\d+/);
    const numericAmount = match ? parseFloat(match[0]) : NaN;

    if (isNaN(numericAmount)) {
      return ''; // Return empty string if numericAmount is NaN
    }

    // Converting the numeric value to words
    const words = numberToWords.toWords(numericAmount);
    return words.charAt(0).toUpperCase() + words.slice(1);
  };
  render() {
    const { storeData } = this.state;
    var container = { width: "711px", margin: "0px auto", padding: "5px" };
    var page = { width: "711px", padding: 0, margin: "0 auto", background: "white" };
    var logo = { float: "left", padding: "20px 25px 0 0", width: "200px" };
    var logoImg = { width: "auto" }
    var address = { fontSize: "12px", lineHeight: "18px", paddingTop: "15px", fontStyle: "normal", letterSpacing: "0", float: "right", textAlign: "right", width: "511px" };
    var head = { width: "711px", fontSize: "1.4em", fontWeight: "bold", margin: "15px 0 30px", textAlign: "center", width: "711px", float: "left" };
    var line = { borderBottom: "2px #000000 solid", margin: "16px auto", width: "711px" };
    var invoiceContainer = { float: "left", width: "711px" };
    var invoiceCol1 = { float: "left", width: "50%", fontSize: "12px", lineHeight: "20px", fontStyle: "normal" };
    var head2 = { fontSize: "12px", marginBottom: "5px", letterSpacing: "0.3px", fontStyle: "normal",  clear: "left" };
    var address2 = { fontSize: "12px", lineHeight: "15px", fontStyle: "normal", marginBottom: "5px", float: "right", width: "100%",fontFamily:"Arial" };
    var state = {
      fontSize: "13px", marginBottom: "5px", letterSpacing: "0.3px", fontStyle: "normal", 
      clear: "left", fontWeight:"600"
    }
    var usaved = { marginTop: "5px !important", height: "auto", clear: "both", fontSize: "12px !important", textAlign: "right", padding: "0px", marginTop: "30px !important", marginLeft: "auto", marginRight: "auto", clear: "both" };
    var print = { backgroundColor: "grey", backgroundImage: "none!important", color: "#FFF", borderRadius: "3px", fontSize: "13px", fontWeight: "400", padding: "2px", verticalAlign: "middle", whiteSpace: "nowrap", border: "1px solid transparent", cursor: "pointer", textAlign: "center", width: "50px", height: "auto", margin: "-5px 1% 0px 5px" };
    var image = { width: "60px", float: "right", marginLeft: "10px", marginTop: "-13px" };

    var invoiceCol3 = { float: "left", width: "50%", textAlign: "right" };
    var table = { borderRadius: "0px", fontSize: "13px", marginBottom: "5px" , width:"100%", border:"1px solid black",fontFamily: "Arial"};
    var colContainer = { fontFamily: "Arial", fontSize: "13px", width: "100%", 
    display: "flex", 
    justifyContent: "left ", borderBottom: "1px solid black", 
    alignItems:"center" 
  
  };
    var invoicehldr = { marginLeft: "auto", marginRight: "auto", width: "711px", clear: "both" };
    var col1 = { width: '80px', padding: '6px 4px 8px 9px', float: 'left', margin: '-1px', fontSize: '10px', lineHeight: '15px', fontStyle: 'normal' };
    var col2 = { width: '10%', padding: '6px 4px 8px 9px', float: 'left', margin: '-1px', fontSize: '12px', lineHeight: '15px', fontStyle: 'normal', textAlign: "left",borderLeft: "1px solid black", borderRight: "1px solid black" };
    var col22 = { width: '10%', padding: '6px 4px 8px 9px', float: 'left', margin: '-1px', fontSize: '12px', lineHeight: '15px', fontStyle: 'normal', textAlign: "left" };
    var col222 = { width: '10%', padding: '6px 4px 8px 9px', float: 'left', margin: '-1px', fontSize: '12px', lineHeight: '15px', fontStyle: 'normal', textAlign: "left", borderLeft: "1px solid black" };
var col13 = {width: '30%', padding: '1px 8px', float: 'left', margin: '-1px', fontSize: '12px', lineHeight: '15px', fontStyle: 'normal'}
    var col3 = { width: '30%', padding: '1px 8px', float: 'left', margin: '-1px', fontSize: '12px', lineHeight: '15px', fontStyle: 'normal', borderLeft: "1px solid black" };
    var col4 = { width: '100px', padding: '6px 4px 8px 9px', float: 'left', margin: '-1px', fontSize: '10px', lineHeight: '15px', fontStyle: 'normal' };
    var col5 = { width: '5%', padding: '6px 4px 8px 9px', float: 'left', margin: '-1px', fontSize: '12px', lineHeight: '15px', fontStyle: 'normal' };
var col15 ={width: '5%', padding: '6px 4px 8px 9px', float: 'left', margin: '-1px', fontSize: '12px', lineHeight: '15px', fontStyle: 'normal', borderRight: "1px solid black"}
    var col5555 = { width: '10%', padding: '6px 4px 8px 6px', float: 'left', margin: '-1px', fontSize: '12px', lineHeight: '15px', fontStyle: 'normal', textAlign: "center" };

    var col555 = { width: '10%', padding: '6px 4px 8px 9px', float: 'left', margin: '-1px', fontSize: '12px', lineHeight: '15px', fontStyle: 'normal' };


    var col6 = { width: 'auto', padding: '6px 4px 8px 9px', float: 'left', margin: '-1px', fontSize: '10px', lineHeight: '15px', fontStyle: 'normal', textAlign: 'right' }
    
    var col1166 = { width: '10%', padding: '6px 4px 8px 9px', float: 'left', margin: '-1px', fontSize: '12px', lineHeight: '15px', fontStyle: 'normal', textAlign: 'left', borderLeft: "1px solid black" }

    var col66 = { width: '10%', padding: '6px 4px 8px 9px', float: 'left', margin: '-1px', fontSize: '12px', lineHeight: '15px', fontStyle: 'normal', textAlign: 'left', borderLeft: "1px solid black", borderRight: "1px solid black" }
    var col166 = { width: '10%', padding: '6px 4px 8px 9px', float: 'left', margin: '-1px', fontSize: '12px', lineHeight: '15px', fontStyle: 'normal', textAlign: 'left' }

    var col666 = { width: '10%', padding: '6px 4px 8px 9px', float: 'left', margin: '-1px', fontSize: '12px', lineHeight: '15px', fontStyle: 'normal', textAlign: 'left' }

    var col66666 = { width: '10%', padding: '6px 4px 8px 9px', float: 'left', margin: '-1px', fontSize: '12px', lineHeight: '15px', fontStyle: 'normal', textAlign: 'left', borderLeft: "1px solid black" }

    var col55 = { width: '355px', padding: '6px 4px 8px 9px', float: 'left', margin: '-1px', fontSize: '12px', lineHeight: '15px', fontStyle: 'normal', textAlign: 'left' }
var col55Btm = {width: '50%',textShadow:"0 0 black", padding: '4px 5px', float: 'left', margin: '-1px', fontSize: '12px',   fontStyle: 'normal', textAlign: 'left'}
var col33Btm = {width: '50%',textShadow:"0 0 black", padding: '4px 5px', float: 'left', margin: '-1px', fontSize: '12px', fontStyle: 'normal', textAlign: 'right' }
var colContainerBtm ={ fontFamily: "Arial", fontSize: "13px", width: "100%", display: "flex", justifyContent: "space-between " } 
var payment = { width: '50%', padding: '2px 5px', float: 'left', margin: '-1px', fontSize: '12px', lineHeight: '15px', fontStyle: 'normal', textAlign: 'left', paddingTop: "5px", borderRight:"1px solid black", height:"34px", overflow:"hidden" }
    var paymentInvoice = { width: '50%', padding: '2px 5px', float: 'left', margin: '-1px', fontSize: '12px', lineHeight: '15px', fontStyle: 'normal', textAlign: 'left',   paddingTop: "5px",borderRight:"1px solid black", height:"34px", overflow:"hidden", display:"flex", alignItems:"center" }

    var paymentDate = { width: '50%', padding: '2px 5px', float: 'left', margin: '-1px', fontSize: '12px', lineHeight: '15px', fontStyle: 'normal', textAlign: 'right', paddingTop: "5px", height:"34px", overflow:"hidden", display:"flex", alignItems:"center" }
    var paymentmthd = { width: '50%', padding: '2px 5px', float: 'left', margin: '-1px', fontSize: '12px', lineHeight: '15px', fontStyle: 'normal', textAlign: 'right', paddingTop: "5px", height:"34px", overflow:"hidden", display:"flex", alignItems:"center" }

    var col33 = { width: '355px', padding: '6px 4px 8px 9px', float: 'left', margin: '-1px', fontSize: '12px', lineHeight: '15px', fontStyle: 'normal', textAlign: 'right' }

    var col155 = { width: '100%', padding: '6px 4px 8px 9px', float: 'left', margin: '-1px', fontSize: '12px', lineHeight: '15px', fontStyle: 'normal', textAlign: 'right' }

    var taxInfo = { width: '100%', padding: '0px', float: 'left', margin: '-1px', fontSize: '12px', lineHeight: '15px', fontStyle: 'normal', textAlign: 'left', fontFamily:"Arial" }
    var stateValue = { fontSize: "12px",    fontFamily: "Arial" }
    return (


      <div id="container" class="container" style={container}>
        <div class="page" style={page}>
          <div class="subpage" style={{ clear: "both" }}>
            <div class="order-detail-header" style={{ width: "100%", float: "left", marginBottom: "30px" }}>
              <div  style={logo}>
                <img src={this.state.storeLogo} alt="" style={logoImg} />
                {/* <img src="https://s3.amazonaws.com/storedigfiles/production/storeimages/thumb/STORE_LOGO_1111.jpg"  style={logoImg} alt="" /> */}
              </div>
              
              <div class="address" style={address}>
                <span>
                  <b style={{fontSize:"13px", fontWeight:"600"}}>Tax Invoice/Bill of Supply/Cash Memo</b><br></br> 
                  </span>  
                  
                  <span style={{fontSize:"13px" }}>
                    (Original for Recipient) <br></br>
                  </span> 
              </div>
            </div>

            {/* <div class="line" style={line}></div> */}
            {/* <div class="head" style={head}>Invoice</div> */}
            <div class="invoiceContainer" style={invoiceContainer}>

              <div class="invoiceCol1" style={invoiceCol1}>
                <div><b style={{fontSize:"13px", fontWeight:"600"}}>Sold By : </b></div>
                {this.state.storeData.map((storeDataObject, storeDataIndex) => (
                  <div style={{ float: "left", fontFamily: 'Arial' }}> {storeDataObject.storeName} {storeDataObject.locality},<br />
                    {storeDataObject.storeAddress1},{storeDataObject.storeAddress2}
                    <br /> {storeDataObject.cityName},{storeDataObject.stateName}<br></br>
                    {/* {storeDataObject.email}<br></br>
                    {storeDataObject.telephone}<br></br> */}
                  </div>
                ))}
              </div>
              <div class="invoiceCol3" style={invoiceCol3}>

                <address>
                  <div class="head2" style={head2}>

                   <b style={{fontSize:"13px", fontWeight:"600"}}>Billing Address :</b> 

                  </div>
                  {this.state.orderData.map((orderDataObject, orderDataIndex) => (
                    <>
                      <span class="address2" style={address2}>{orderDataObject.billingName} <br />{orderDataObject.billingAddress1}
                        <br />{orderDataObject.billingAddress2}<br />{orderDataObject.billingZip}<br/>
                        IN </span>
                      <div><b style={state}>State/UT Code : </b><span style={stateValue}>{orderDataObject.billingStateCode}</span></div>
                    </>))}
                </address>

              </div>
            </div>
            <div class="invoiceContainer" style={invoiceContainer}>
              {this.state.storeData.map((storeDataObject, storeDataIndex) => (
                <div class="invoiceCol1" style={invoiceCol1}>
                  <div><b style={{fontSize:"13px", fontWeight:"600"}}>PAN No : </b>{storeDataObject.panNumber}</div>
                  <div><b style={{fontSize:"13px", fontWeight:"600"}}>GST Registration No : </b>{storeDataObject.gstNumber}</div>

                </div>))}
              <div class="invoiceCol3" style={invoiceCol3}>

                <address>
                  <div class="head2" style={head2}>

                    <b style={{fontSize:"13px", fontWeight:"600"}}>Shipping Address :</b>

                  </div>
                  {this.state.orderData.map((orderDataObject, orderDataIndex) => (
                    <>
                      <span class="address2" style={address2}>{orderDataObject.buyerName} <br />{orderDataObject.buyerAddress1}<br />{orderDataObject.buyerAddress2}
                        <br />{orderDataObject.zipcode}<br/>
                        IN<br /></span>
                      <div><b style={state}>State/UT Code : </b><span style={stateValue}>{orderDataObject.shippingStateCode}</span></div>
                      <div><b style={state}>Place of supply : </b><span style={stateValue}>{this.state.supplyState}</span></div>
                      <div><b style={state}>Place of delivery : </b><span style={stateValue}>{orderDataObject.stateName}</span></div>
                    </>))}




                </address>

              </div>
            </div>
            <div class="invoiceContainer" style={invoiceContainer}>
              {this.state.orderData.map((orderDataObject, orderDataIndex) => (
                <>
                  <div class="invoiceCol1" style={invoiceCol1}>
                    <div><b style={{fontSize:"13px", fontWeight:"600"}}>Order Number : </b><span style={stateValue}>{orderDataObject.orderId}</span></div>
                    <div><b style={{fontSize:"13px", fontWeight:"600"}}>Order Date : </b> <span style={stateValue}>{orderDataObject.orderDate}</span></div>

                  </div>
                  <div class="invoiceCol3" style={invoiceCol3}>

                    <address>




                      <div><b style={state}>Invoice Number : </b><span style={stateValue}>IN-{orderDataObject.orderId}</span></div>
                      <div><b style={state}>Invoice Details : </b><span style={stateValue}>IN-{orderDataObject.orderId}-{this.state.saleId}</span></div>
                      <div><b style={state}>Invoice Date : </b><span style={stateValue}>{orderDataObject.orderDate}</span></div>

                    </address>

                  </div>
                </>))}
            </div>
            <div class="ms_tbleWraps" style={{ width: "100%", float: "left" }}>
              <div class="table table-bordered table-striped" style={table}>
                <div style={{width:"100%",float:"left"}}>
                  <div style={{borderBottom:"1px solid black", float:"left",width:"100%",background:"#cacaca"}}>
                    <div class="col1w borderBottom " style={col15}> Sl. No.</div>
                    <div class="col2w" style={col13}>&nbsp;<br/>Description</div>
                    <div class="col1w" style={col2}>Unit <br/>Price</div>
                    <div class="col1w" style={col5}><br/>Qty</div>
                    <div class="col1w" style={col1166}>Net <br/> Amount </div>
                    <div class="col1w" style={col555}> Tax <br/>Rate</div>
                    <div class="col1w" style={col666}> Tax <br/>Type</div>
                    <div class="col1w" style={col166}>Tax <br/>Amount</div>
                    <div class="col1w" style={col22}>Total <br/>Amount</div>
                  </div>

                </div>
                <div>
                  {this.state.groceryItemData.map((groceryItemDataObject, groceryDataIndex) => (
                    <div class="colContainer" style={colContainer}>
                      <div class="col1w" style={col5}>{groceryDataIndex + 1}</div>
                      <div class="col2w" style={col3}> {groceryItemDataObject.itemCode}<br></br>
                        {groceryItemDataObject.itemName}
                        {groceryItemDataObject.sizeItem != "" ?
                          <span>
                            {groceryItemDataObject.sizeItem === "1000gm" ?
                              <span> 1 Kg </span>
                              :
                              <span> {groceryItemDataObject.sizeItem} </span>}
                          </span>
                          : ''

                        }
                        {groceryItemDataObject.brandName != 'null' && groceryItemDataObject.brandName != '' && groceryItemDataObject.brandName != null ?
                          <span> - {groceryItemDataObject.brandName}</span>
                          : ''
                        }
                        <br />
                      </div>
                      <div class="col1w "
                        style={col2}

                      >{groceryItemDataObject.itemName != "" ? groceryItemDataObject.itemPrice : "-"}

                      </div>
                      <div class="col1w" style={col5}>{groceryItemDataObject.itemName != "" ? (groceryItemDataObject.quantity) - (groceryItemDataObject.cancelled_quantity != null && groceryItemDataObject.cancelled_quantity) : "NA"}</div>
                      <div class="col1w" style={col66}>{groceryItemDataObject.netAmount != "" ? groceryItemDataObject.netAmount : "-"}</div>
                      <div class="col1w" style={col5555}><br/>{groceryItemDataObject.taxGroupPercentage ? (groceryItemDataObject.taxGroupPercentage != "" ? groceryItemDataObject.taxGroupPercentage+"%" : "-"):"-"}</div>
                      <div class="col1w" style={col66666}><br/>{groceryItemDataObject.group_name ? (groceryItemDataObject.group_name != "" ? groceryItemDataObject.group_name : "-"):"-"}</div>
                      <div class="col1w" style={col66666}><br />{groceryItemDataObject.taxAmount ? (groceryItemDataObject.taxAmount != "" ? storeData[0].currencySymbol + ' ' + parseFloat(groceryItemDataObject.taxAmount).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "-") : "-"}</div>
                      <div class="col1w " style={col222}>{groceryItemDataObject.totalNetAmt ? (storeData[0].currencySymbol + ' ' + parseFloat(groceryItemDataObject.totalNetAmt).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : groceryItemDataObject.netAmount}</div>


                    </div>))}
                </div>
                {/* <hr></hr> */}
                <div>
                  {/* {this.state.resrdataNew.map((resrdataNewObject, resrdataNewIndex) => (
                  <div class="colContainer" style={colContainer}>
                    <div class="col1" style={col55}><b>Total</b></div>
                    <div class="col2" style={col33}> <b>{resrdataNewObject.subtotal}</b>
                    
                    </div>
                  </div>))} */}
                  {storeData[0] && (
                    <>
                      {this.state.resrdataNew.map((resrdataNewObject, resrdataNewIndex) => (
                        <>
                          {resrdataNewObject.promoCodeAmount !== 0 && resrdataNewObject.promoCodeAmount !== '0.00' ?
                            <div class="colContainer" style={{ fontSize: "12px", fontStyle: "normal", fontFamily: "Arial" }}>
                              <div class="colWide" style={{ width: "50%", float: "left", textAlign: "right", marginBottom: "5px" }}>Promocode Discount :</div>
                              <div class="col6" style={{ width: "40%", float: "right", textAlign: "right", paddingRight: "5px", marginBottom: "5px" }}>{storeData[0].currencySymbol + ' ' + parseFloat(resrdataNewObject.promoCodeAmount).toFixed(2)}</div>
                            </div> : ""}
                          <div class="colContainer" style={colContainerBtm}>
                            <div class="col1" style={col55Btm}><b>Sub Total : </b></div>
                            <div class="col2" style={col33Btm}> <b>{resrdataNewObject.subtotal}</b>

                            </div>
                          </div></>))}
                          {this.state.resrdataNew.map((resrdataNewObject, resrdataNewIndex) => (
                  <div>
                   
                    <div class="colContainer" style={colContainerBtm}>
                            <div class="col1" style={col55Btm}><b>Delivery Charges :</b></div>
                            <div class="col2" style={col33Btm}> <b>{storeData[0].currencySymbol + ' ' + parseFloat(this.state.orderData[0].deliverCharge).toFixed(2)}</b>

                            </div>
                           
                          </div>


                          <div class="colContainer" style={colContainerBtm}>
                            <div class="col1" style={col55Btm}><b>Tax : </b></div>
                            <div class="col2" style={col33Btm}> <b>{storeData[0].currencySymbol + ' ' + parseFloat(resrdataNewObject.totalTax).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b>

                            </div>

                          </div>
                          <div class="colContainer" style={colContainerBtm}>
                            <div class="col1" style={col55Btm}><b>Total Amount : </b></div>
                            <div class="col2" style={col33Btm}> <b>{storeData[0].currencySymbol} {' '}{(this.state.orderData[0].orderNetAmount.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}</b>

                            </div>

                          </div>
                          {resrdataNewObject.comments !== '' ?
                          <div class="colContainer" style={{padding:'4px 5px',color:'red'}}><b>  Message : {resrdataNewObject.comments}</b></div>
                          : ''
                        }
                          <div class="colContainer" style={colContainer}></div>

                         
                          {/* <div class="colContainer" style={colContainer}>
                            <div class="col1" style={col55Btm}><b> Total Amount :</b></div>
                            <div class="col2" style={col33Btm}> <b>{storeData[0].currencySymbol + ' ' + parseFloat((this.state.orderData[0].orderNetAmount).toFixed(2)).toLocaleString('en-IN')}</b>

                            </div>
                          </div> */}
                          <div class="colContainer" style={colContainer}>
                      <div class="col1" style={col55Btm}><b>Amount in Words :  </b>
                      <br/>
                      {this.convertToWords(parseFloat(this.state.orderData[0].orderNetAmount).toFixed(2))}
                      </div>
                      {/* <div class="col2" style={col33Btm}><b> </b></div> */}<br/>
                      
                    </div>
                  </div>))}

                          
                          
                      {/* <div class="colContainer" style={{ fontSize: "12px", fontStyle: "normal", fontFamily: "Arial" }}>
                        <div class="colWide" style={{ width: "50%", float: "left", textAlign: "right", marginBottom: "5px" }}>Delivery Charges :</div>
                        <div class="col6" style={{ width: "40%", float: "right", textAlign: "right", paddingRight: "5px", marginBottom: "5px" }}>{storeData[0].currencySymbol + ' ' + parseFloat(this.state.orderData[0].deliverCharge).toFixed(2)}</div>
                      </div> */}
                      {/* <div class="colContainer" style={{ fontSize: "12px", fontStyle: "normal", fontFamily: "Arial" }}>
                        <div class="colWideTotal" style={{ width: "50%", float: "left", textAlign: "right", marginBottom: "5px" }} >
                          Total Amount :
                        </div>
                        <div class="col6" style={{ width: "40%", float: "right", textAlign: "right", paddingRight: "5px", marginBottom: "5px" }}><b>{storeData[0].currencySymbol + ' ' + parseFloat(this.state.orderData[0].orderNetAmount).toFixed(2)}</b></div>
                      </div> */}
                    </>)}
                </div>
                {/* {this.state.resrdataNew.map((resrdataNewObject, resrdataNewIndex) => (
                  <div>
                    <div class="colContainer" style={colContainer}>
                      <div class="col1" style={col55Btm}><b>Amount in Words :  </b></div>
                      <div class="col2" style={col33Btm}><b> {this.convertToWords(parseFloat(this.state.orderData[0].orderNetAmount).toFixed(2))}</b></div>
                    </div>
                  </div>))} */}
                <div>
                  <div class="colContainer" style={colContainerBtm}>
                    <div class="col1" style={col155}><b>For {this.state.storeName} : <br />  <br /> 
                     <b>  </b> 
                     <br />
                    <br />Authorized Signatory</b></div>

                  </div>
                </div>

              </div>
              <div>
                <div class="colContainer1"  >
                  <div class="col1" style={taxInfo}>
                    Whether tax is payable under reverse charge - No
                  </div>

                </div>
              </div>
              {this.state.orderData.map((orderDataObject, orderDataIndex) => (
                <div style={{width:"100%", float:"left", border:"1px solid black",marginTop:"10px", fontFamily: "Arial"}}>
                  {/* <div class="colContainer" style={colContainer}> */}
                  <div class="col12" style={payment}><b>Payment Transaction ID :  </b>&nbsp;{this.state.transactionId}</div>
                  <div class="col12" style={paymentDate}><b>Date & Time :  </b>&nbsp;{orderDataObject.orderDate}</div>
                  <div class="col12" style={paymentInvoice}><b>Invoice Value :  </b>&nbsp;{orderDataObject.currencySymbol}{parseFloat(orderDataObject.orderNetAmount).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                  <div class="col12" style={paymentmthd}><b>Mode of Payment :  </b>&nbsp;{orderDataObject.paymentType}</div>

                  {/* </div>  */}
                </div>))}


            </div>
            <br></br>
            <br></br>

            <div class="invoice_hldr" style={invoicehldr}>
              {this.state.storeData.map((storeDataObject, storeDataIndex) => (


                <div className='ms_inv_footer' style={{ fontSize: "12px", fontFamily: "Arial", color: "rgb(5757,54)",padding: "2%" }}>





                  {storeDataObject.email_footer_text != null && storeDataObject.email_footer_text != '' ?
                    <div>
                      Please Note : {storeDataObject.email_footer_text}


                      <br></br></div> : ''
                  }
                  {/* {this.state.orderData.map((orderDataObject, orderDataIndex) => (
                    <div>
                      {orderDataObject.mainCategoryName != 'Restaurant' ?
                        <div>

                          Terms and conditions apply. Images are indicative. Offers valid till stocks last.
                        </div>
                        : <div>Terms and conditions apply. Images indicative indicative.</div>
                      }

                    </div>
                  ))} */}
                </div>
              ))}
            </div>
            <div class="uSaved" style={usaved}>

              <div style={{ float: "right", fontSize:"12px" }}> Powered by <img src="https://d37ky63zmmmzfj.cloudfront.net/production/logo/logoMyyShopp2.png" alt="MyyShopp" title="MyyShopp" style={image} />

              </div>
            </div>
            {/* <div class="uSaved" style={usaved}>

              <div style={{ float: "right" }}> Powered by <img src="https://d37ky63zmmmzfj.cloudfront.net/production/logo/logoMyyShopp2.png" alt="MyyShopp" title="MyyShopp" style={image} />

              </div>
            </div> */}
            <div onClick={() => this.printDiv()} title="Print Invoice" class="printBtnInvoice noprint " style={print}>
              Print
            </div>

          </div>
        </div>
      </div>
    )
  }
}