import React, { Component,Suspense } from "react";
// import Header from "./header";
// import Footer from "./footer";
import Background from './images/about-bg-logo.png';
let Header = React.lazy(() => import("./header"));
let Footer = React.lazy(() => import("./footer"));
var sectionStyle = {
    width: "100%",
    height: "500px",
    float: "left",
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    margin: "0 auto",
    backgroundPosition: "50% 190px",
    backgroundSize: "460px"
};
export default class AboutUs extends Component {
    componentDidMount(){
        window.scrollTo(0, 0)
    }
    render() {
        return (

            <div className="perfumeTheme">
               <Suspense fallback={<div></div>}>
          <Header/>
        <div className="topcategorysec fragranceAbout aboutDetailsViewMore"    style={{position:"relative"} } >

                <div className="container" id="aboutus" style={ sectionStyle }>
                  <span className="perfumeMainHead" >
                <h1  className="abtH1" style={{textAlign:"left", textTransform:"none", justifyContent:"left", marginBottom:"0px"}}>Our Vision </h1>
                <h2>Emissa Perfumes</h2>
                  </span>
                    <div className="topcategory ads" style={{marginTop:"25px"}}>
                        <div className="topcatList">
                         As Emissa, our vision is to remain committed to production with a focus on continuous customer satisfaction.
                            From introducing new perfumes to the industry to improved perfume bottle designs, Emissa works with high-quality designers, perfume experts and perfume makers worldwide.
                             Emissa has already begun to lay the foundation for new investments in some parts of the Middle East and Western countries.
                             It also works fast to achieve universal leadership by integrating with Perfume Sector and related products.
                        </div>
                    </div>
                </div>

            </div>
        <Footer />
        </Suspense>
      </div>
        )
    }
}