import React, { Component, Suspense } from 'react';
import TrackLeft from "../images/lokal/track-order.png"
import TrackLine from "../images/lokal/track-order-line.png"
import Sort from "../images/lokal/sort.png"
import Arrow from "../images/lokal/ph_arrow-up.png";
import Home from "../images/lokal/home.png";
import Offer from "../images/lokal/offer.png"
import Deliverd from "../images/lokal/deliverd.png"
const Header = React.lazy(() => import("../luna/header"));
const Footer = React.lazy(() => import("../luna/footer"));
export default class Storelisting extends Component {
 


  render() {

    return (
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Header />
          <div className='thank-you-wrapper'>
           <div className='col-12 thanks-wraps'>Thank You For Your Purchase</div>
           <div className='col-12 mb-5 confirmed-order'>
              Order #123RGR231567Y Confirmed
           </div>           
           <div className=''>
              <button className='mr-3 btn-thankyou'>Track Your Order</button>
              <button className='btn-thankyou'>Back To Home</button>
           </div>
           <div className='text-center generate-report'>
            <span>Generate Receipt</span>
           </div>
          </div>
          {/* <Footer /> */}
        </Suspense>
      </div>
    );
  }
}
