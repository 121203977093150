import React, { Component } from 'react';
import { getData } from '../services';
export default class personal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      isMale: false,
      isFemale: false,
      married: false,
      unmarried: false,
      disabledName: true,
    };
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleMaleChange = this.handleMaleChange.bind(this);
    this.handleFemaleChange = this.handleFemaleChange.bind(this);
    this.handleMarriedChange = this.handleMarriedChange.bind(this);
    this.handleUnmarriedChange = this.handleUnmarriedChange.bind(this);
    this.onEdit = this.onEdit.bind(this);
  }
  onEdit() {
    document.getElementById('btnEditPersonal').style.display = 'none';
    document.getElementById('btnSavePersonal').style.display  = '';
    this.setState({
      disabledName: !this.state.disabledName
    })
  }
  handleButtonClick = (event) => {
    event.preventDefault();
    var personalMsgError = "";
    let formIsValid;
    document.getElementsByClassName("personalMsgError")[0].innerHTML="";
    let firstName = this.state.firstName;
    if (!firstName) {
      personalMsgError = "Please enter First Name";
      formIsValid = false;
    }
    else {
      formIsValid = true;
    }
    if (!formIsValid) {
      document.getElementsByClassName("personalMsgError")[0].innerHTML=personalMsgError;
      return false;
    }
    else {
      let currentUserId = localStorage.getItem('userId');
      const postData = {
        functionName: "saveuserdashboarddetails/personalInformation",
        userId: currentUserId,
        firstName: this.state.firstName,
        lastName: (this.state.lastName === null || this.state.lastName === "") ? null : this.state.lastName,
        gender: this.state.isMale === true ? 1 : this.state.isFemale === true ? 2 : 0,
        married: this.state.unmarried === true ? 0 : 1,
      }
      getData(postData).then((data) => {
        if (data.data.success === "1") {         
          document.getElementsByClassName("personalMsgSuccess")[0].innerHTML="Personal information updated successfully";
          document.getElementById('btnEditPersonal').style.display = '';
          document.getElementById('btnSavePersonal').style.display  = 'none';
          this.setState({
            disabledName: !this.state.disabledName
          })
        }
      });
    }
  }
  componentDidMount() {    
    const postData = {
      functionName: "userdashboarddetails/personalInformation",
      currentUserId: localStorage.getItem('userId')
    }
    getData(postData).then((data) => {
      if (data.data.success === "1") {
        this.setState({
          firstName: data.data.result.personalInformation[0].firstName,
          lastName: data.data.result.personalInformation[0].lastName === 'null' ? '' : data.data.result.personalInformation[0].lastName,
          isMale: data.data.result.personalInformation[0].gender === 1 ? true : false,
          isFemale: data.data.result.personalInformation[0].gender === 2 ? true : false,
          married: data.data.result.personalInformation[0].married === 1 ? true : false,
          unmarried: data.data.result.personalInformation[0].married === 0 ? true : false,
        });
      }
    })
  }
  handleFirstNameChange = (event) => {
    this.setState({
      firstName: event.target.value
    });
  }
  handleLastNameChange = (event) => {
    this.setState({
      lastName: event.target.value
    });
  }
  handleMaleChange = (event) => {
    this.setState({
      isMale: event.target.value
    });
  }
  handleFemaleChange = (event) => {
    this.setState({
      isFemale: event.target.value
    });
  }
  handleMarriedChange = (event) => {
    this.setState({
      married: event.target.value
    });
  }
  handleUnmarriedChange = (event) => {
    this.setState({
      unmarried: event.target.value
    });
  }
  render() {
    const { firstName, lastName, isMale, isFemale, married, unmarried } = this.state;
    return (
      <div>
        <div className="accountdetails">
          <div className="dashTitle">Personal Information</div>
          <div className="dashSection">
            <div>
              <div className="addressForm">
                <form>
                  <div className="text-success personalMsgSuccess text-center"></div>
                  <div className="errorMsg personalMsgError"></div>
                  <div className="adressFormList">
                    <label className="labelField">First Name<sup>*</sup></label>
                    <div className="formInputSection">
                      <input type="text" tabindex='1' className="formInputField" disabled={(this.state.disabledName) ? "disabledName" : ""} value={firstName} onChange={this.handleFirstNameChange} placeholder="" />
                    </div>
                  </div>
                  <div className="adressFormList">
                    <label className="labelField">Last Name</label>
                    <div className="formInputSection">
                      <input type="text" tabindex='2' className="formInputField" disabled={(this.state.disabledName) ? "disabledName" : ""} value={lastName} onChange={this.handleLastNameChange} placeholder="" />
                    </div>
                  </div>
                  <div className="adressFormList">
                    <label className="labelField">Gender</label>
                    <div className="formInputSection radioSectionRight">
                      <span className="radioSection">
                        <input type="radio" id="radio1" tabindex='3' checked={isMale} disabled={(this.state.disabledName) ? "disabledName" : ""} value="Male" onChange={() => { this.setState({ isMale: true, isFemale: false }) }} name="radio1" />
                        <label for="radio1"></label>
                        Male
                      </span>
                      <span className="radioSection">
                        <input type="radio" id="radio2" tabindex='3' checked={isFemale} disabled={(this.state.disabledName) ? "disabledName" : ""} value="Female" onChange={() => { this.setState({ isMale: false, isFemale: true }) }} name="radio1" />
                        <label for="radio2"></label>
                        Female
                      </span>
                    </div>
                  </div>
                  <div className="adressFormList">
                    <label className="labelField">Married</label>
                    <div className="formInputSection radioSectionRight">
                      <span className="radioSection">
                        <input type="radio" id="radio3" tabindex='4' checked={married} disabled={(this.state.disabledName) ? "disabledName" : ""} onChange={() => { this.setState({ married: true, unmarried: false }) }} name="radio2" />
                        <label for="radio3"></label>
                        Yes
                      </span>
                      <span className="radioSection">
                        <input type="radio" id="radio4" tabindex='4' checked={unmarried} disabled={(this.state.disabledName) ? "disabledName" : ""} onChange={() => { this.setState({ married: false, unmarried: true }) }} name="radio2" />
                        <label for="radio4"></label>
                        No
                      </span>
                    </div>
                  </div>
                  <div className="adressFormButton adressButtonTop">
                    <span class="commonButton" tabindex='5' id="btnEditPersonal" onClick={this.onEdit} >Edit</span>
                    <span class="commonButton" tabindex='6' style={{ display: 'none' }} id="btnSavePersonal" onClick={this.handleButtonClick}>Save</span>
                  </div>
                  <div className="adressFormButton adressButtonTop">
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
