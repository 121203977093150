import React, { Component } from 'react';
import { getData } from '../services';
export default class orderDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      storeData: [],
      orderData: [],
      restaurantData: [],
      resrdataNew: [],
      subTotal: 0,
      commonItemData: [],
      groceryItemData: [],
      taxData: []
    };
  }
  printDiv() {
    var printContents = document.getElementById("container").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }
  componentDidMount() {
    const { match: { params } } = this.props;
    if (typeof params.slug !== 'undefined' && params.slug !== 'undefined') {
      params.orderId = params.slug.toString().split('I')[1]
    }
    const postData = {
      "functionName": "pothys/decryptUrl",
      "encryptedOrderId": params.orderId
    }
    getData(postData).then((data) => {
console.log(postData,"dataaaaaaaaaaaaaaaaaaa",data)
this.listing(data.data.result.decryptedOrderId)
    })
  }
  listing = (orderId) =>{
    const postData = {
      functionName: "rtOrderDetails",
      orderId: orderId
    }
    getData(postData).then((data) => {
      console.log('data....order details...', data)
      if (data.data.success === "1") {
        this.setState({
          storeData: data.data.result.storeAddressQueryResult,
          orderData: data.data.result.orderQueryResults[0],
          restaurantData: data.data.result.restauarantListing,
          taxResultNew: (data.data.result.resrdataNew.length > 0 ? data.data.result.resrdataNew[0].taxResultNew : []),
          resrdataNew: data.data.result.resrdataNew,
          commonItemData: data.data.result.commonItemListing,
          groceryItemData: data.data.result.groceryItemListing,
          taxData: data.data.result.taxData
        });
      }
    })
  }
  render() {
    const { storeData } = this.state;
    var container = { width: "700px", margin: "0px auto", padding: "5px" };
    var page = { width: "18cm", padding: 0, margin: "0 auto", background: "white" };
    var logo = { float: "left", padding: "0 25px 0 0" };
    var address = { fontSize: "12px", lineHeight: "18px", paddingTop: "45px", fontStyle: "normal", letterSpacing: "0.6px" };
    var head = { width: "700px", fontSize: "1.4em", fontWeight: "bold", margin: "15px 0 30px", textAlign: "center", float: "left" };
    var line = { borderBottom: "2px #000000 solid", margin: "16px auto", width: "700px" };
    var invoiceContainer = { float: "left", width: "700px" };
    var invoiceCol1 = { float: "left", width: "225px", fontSize: "12px", lineHeight: "20px", fontStyle: "normal" };
    var invoiceCol2 = { float: "left", width: "325px" };
    var head2 = { fontSize: "12px", marginBottom: "8px", letterSpacing: "0.6px", fontStyle: "normal", fontWeight: "bold", clear: "left" };
    var address2 = { fontSize: "11px", lineHeight: "15px", fontStyle: "normal", marginBottom: "15px", float: "left" };
    var invoiceCol3 = { float: "left", width: "150px" };
    var table = { borderRadius: "0px", fontSize: "13px" };
    var colContainer = { fontFamily: "Arial", fontSize: "13px", width: "100%", display: "flex", justifyContent: "left " };
    var unSaved = { fontSize: "11px", fontFamily: "Arial", textAlign: "right", padding: "10px 5px 10px 5px", marginTop: "30px !important", marginLeft: "auto", marginRight: "auto", clear: "both", borderTop: "1px dashed rgba(0, 0, 0, 0.1)" };
    var invoicehldr = { marginLeft: "auto", marginRight: "auto", width: "700px", clear: "both" };
    var usaved = { height: "auto", clear: "both", fontSize: "12px !important", textAlign: "right", padding: "0px", marginTop: "30px !important", marginLeft: "auto", marginRight: "auto" };
    var print = { backgroundColor: "grey", backgroundImage: "none!important", color: "#FFF", borderRadius: "3px", fontSize: "14px", fontWeight: "400", padding: "2px", verticalAlign: "middle", whiteSpace: "nowrap", border: "1px solid transparent", cursor: "pointer", textAlign: "center", width: "50px", height: "auto", margin: "-5px 1% 0px 5px" };
    var image = { width: "60px", float: "right", marginLeft: "10px", marginTop: "-13px" };
    var col1 = { width: '80px', padding: '6px 4px 8px 9px', float: 'left', margin: '-1px', fontSize: '10px', lineHeight: '15px', fontStyle: 'normal' };
    var col2 = { width: '80px', padding: '6px 4px 8px 9px', float: 'left', margin: '-1px', fontSize: '10px', lineHeight: '15px', fontStyle: 'normal' };
    var col3 = { width: '215px', padding: '6px 4px 8px 9px', float: 'left', margin: '-1px', fontSize: '10px', lineHeight: '15px', fontStyle: 'normal' };
    var col4 = { width: '100px', padding: '6px 4px 8px 9px', float: 'left', margin: '-1px', fontSize: '10px', lineHeight: '15px', fontStyle: 'normal' };
    var col5 = { width: '52px', padding: '6px 4px 8px 9px', float: 'left', margin: '-1px', fontSize: '10px', lineHeight: '15px', fontStyle: 'normal' };
    var col6 = { width: 'auto', padding: '6px 4px 8px 9px', float: 'left', margin: '-1px', fontSize: '10px', lineHeight: '15px', fontStyle: 'normal', textAlign: 'right' }
    return (
      <div id="container" class="container" style={container}>
        <div class="page" style={page}>
          <div class="subpage" style={{ clear: "both" }}>
            <div class="order-detail-header">
              <div class="logo" style={logo}></div>
              {this.state.storeData.map((storeDataObject, storeDataIndex) => (
                <div class="address" style={address}>
                  {storeDataObject.locality === '~locality' ?
                    <span>
                      {storeDataObject.storeName} <br></br> </span> : <span>{storeDataObject.storeName}{storeDataObject.locality}<br></br></span>}
                  {storeDataObject.storeAddress1 === '~address1' ? ''
                    : <span>{storeDataObject.storeAddress1},{storeDataObject.storeAddress2}<br></br></span>
                  }
                  {storeDataObject.cityName},{storeDataObject.stateName}<br></br>
                  {storeDataObject.email}<br></br>
                  {storeDataObject.telephone}<br></br>
                </div>
              ))}
            </div>
            <div class="line" style={line}></div>
            <div class="head" style={head}>Order Summary</div>
            <div class="invoiceContainer" style={invoiceContainer}>
              {this.state.orderData.map((orderDataObject, orderDataIndex) => (
                <div class="invoiceCol1" style={invoiceCol1}>
                  <div>Order Number: {orderDataObject.orderId}</div>
                  <div style={{ float: "left" }}> Order Date: {orderDataObject.orderDate}</div>
                  {orderDataObject.consumerNumber !== null && orderDataObject.consumerNumber !== '' && orderDataObject.consumerNumber !== 'null' ?
                    this.state.storeData.consumerCardName !== null && this.state.storeData.consumerCardName !== 'null' && this.state.storeData.consumerCardName !== '' ?
                      <div style={{ float: "left" }}>Card Number:{this.state.storeData.consumerCardName}</div>
                      :
                      ''
                    :
                    ''
                  }
                </div>
              ))}
              <div class="invoiceCol2" style={invoiceCol2}>
                {this.state.orderData.map((orderDataObject, orderDataIndex) => (
                  <div>
                    <address>
                      <div class="head2" style={head2}>Delivery Address</div>
                      <span class="address2" style={address2}>{orderDataObject.buyerName}<br></br>
                        {orderDataObject.buyerAddress1} {orderDataObject.buyerAddress2 === '' ? '' : ',' + orderDataObject.buyerAddress2},<br></br>
                        {orderDataObject.locationName},<br></br>
                        {orderDataObject.cityName}, {orderDataObject.stateName},{orderDataObject.zipcode}<br></br>
                        {orderDataObject.buyerTelephone}<br></br> {orderDataObject.buyerEmail}
                      </span>
                    </address>
                    {orderDataObject.receiverName !== "" ?
                      <div> <div class="head2" style={head2}>Receiver Name</div> <span class="address2" style={address2}>{orderDataObject.receiverName}</span></div> : ''
                    }
                  </div>
                ))}
              </div>
              <div class="invoiceCol3" style={invoiceCol3}>
                {this.state.orderData.map((orderDataObject, orderDataIndex) => (
                  <address>
                    {orderDataObject.mainCategoryName == 'Restaurant' ?
                      <div class="head2" style={head2}>
                        Preferred Delivery Date and Time
                      </div> : <div class="head2" style={head2}>
                        Preferred Delivery Time
                      </div>
                    }
                    <span class="address2" style={address2}>{orderDataObject.deliverySlot}</span>
                    <div class="head2" style={head2}>Mode Of Payment</div>
                    <span class="address2" style={address2}>{orderDataObject.paymentType}</span>
                    {orderDataObject.consumerNumber ?
                      <div>
                        <div class="head2" style={head2}>Consumer Card #</div>  <span class="address2" style={address2}>{orderDataObject.consumerNumber}</span></div> : ' '
                    }
                    {orderDataObject.isStorePickUp === "1" ?
                      <div>
                        <div class="head2" style={head2}>Delivery Option</div>  <span class="address2" style={address2}>Will Pickup from</span></div> : ' '
                    }
                    {orderDataObject.isDineIn === "1" ?
                      <div> <div class="head2" style={head2}>Delivery Option</div> <span class="address2" style={address2}>(Dine In {orderDataObject.dineIn} People)</span></div> : ''
                    }
                    {orderDataObject.orderOptionsName !== "" ?
                      <div> <div class="head2" style={head2}>Order / Receiver Option</div> <span class="address2" style={address2}>{orderDataObject.orderOptionsName + " / " + orderDataObject.receiverOptionsName}</span></div> : ''
                    }
                  </address>
                ))}
              </div>
            </div>
            <div class="ms_tbleWraps" style={{ width: "700px", float: "left" }}>
              <div class="table table-bordered table-striped" style={table}>
                <div>
                  <div>
                    <div class="col1" style={col1}>Sl. No.</div>
                    <div class="col1" style={col2}>Item Code</div>
                    <div class="col2" style={col3}>Item Name</div>
                    <div class="col1" style={col4}>MRP</div>
                    <div class="col1" style={col4}>Unit Price</div>
                    <div class="col1" style={col5}>Qty</div>
                    <div class="col1" style={col6}>Total</div>
                  </div>
                </div>
                <div>
                  {this.state.groceryItemData.map((groceryItemDataObject, groceryDataIndex) => (
                    <div class="colContainer" style={colContainer}>
                      <div class="col1" style={col1}>{groceryDataIndex + 1}</div>
                      <div class="col1" style={col2}>{groceryItemDataObject.itemName !== "" ? groceryItemDataObject.itemCode : 'Prescription'}</div>
                      <div class="col2" style={col3}
                      >{groceryItemDataObject.itemName !== "" ? groceryItemDataObject.itemName : groceryItemDataObject.phAttachmentName}-
                        {groceryItemDataObject.sizeItem != "" ?
                          <span>
                            {groceryItemDataObject.sizeItem === "1000gm" ?
                              <span> 1 Kg </span>
                              :
                              <span> {groceryItemDataObject.sizeItem} </span>}
                          </span>
                          : ''
                        }
                        {groceryItemDataObject.brandName !== 'null' && groceryItemDataObject.brandName !== '' && groceryItemDataObject.brandName !== null ?
                          <span> - {groceryItemDataObject.brandName}</span>
                          : ''
                        }
                      </div>
                      <div class="col1" style={col4}>{groceryItemDataObject.itemName !== "" ? groceryItemDataObject.itemMrp : "NA"}</div>
                      <div class="col1" style={col4}>{groceryItemDataObject.itemName !== "" ? groceryItemDataObject.itemPrice : "NA"}</div>
                      <div class="col1" style={col5}> {groceryItemDataObject.itemName !== "" ? groceryItemDataObject.quantity : "NA"}</div>
                      <div class="col1" style={col6}>{groceryItemDataObject.itemName !== "" ? groceryItemDataObject.totalPrice : "NA"}</div>
                    </div>
                  ))}
                  {this.state.restaurantData.map((restaurantDataObject, restaurantDataIndex) => (
                    <div class="colContainer" style={colContainer}>
                      <div class="col1" style={col1}>{restaurantDataIndex + 1}</div>
                      <div class="col1" style={col1}>-</div>
                      <div class="col2"
                        style={col3}
                      >{restaurantDataObject.itemName}-
                        {restaurantDataObject.itemsize !== 'None' ?
                          <span> {restaurantDataObject.itemsize}</span>
                          : ''
                        }
                        {restaurantDataObject.toppingsData.map((toppingsObject, toppingsIndex) => (
                          <div style={{ padding: '0 0 0 15px' }}>
                            <span>{toppingsObject.toppings1}</span>
                            <br />
                            <span>{toppingsObject.toppings2} - {toppingsObject.toppings3}</span>
                            <br />
                          </div>
                        ))}
                      </div>
                      <div class="col1" style={col4}>{restaurantDataObject.netAmount}</div>
                      <div class="col1" style={col4}>{restaurantDataObject.netAmount}</div>
                      <div class="col1" style={col5}>{restaurantDataObject.quantity}</div>
                      <div class="col1" style={col6}>{restaurantDataObject.totalPrice}</div>
                    </div>
                  ))}
                  {this.state.commonItemData.map((commonItemDataObject, commonItemDataIndex) => (
                    <div class="colContainer" style={colContainer}>
                      <div class="col1" style={col1}>{commonItemDataIndex + 1}</div>
                      <div class="col1" style={col2}>-</div>
                      <div class="col1 ms_col1Print "
                        style={col3}
                      >{commonItemDataObject.itemName}-{commonItemDataObject.packName}-
                        {commonItemDataObject.size_name !== 'None' ?
                          <span> {commonItemDataObject.size_name}</span>
                          : ''
                        }
                      </div>
                      <div class="col1" style={col4}>{commonItemDataObject.itemMrp}</div>
                      <div class="col1" style={col4}>{commonItemDataObject.itemPrice}</div>
                      <div class="col1" style={col5}> {commonItemDataObject.quantity}</div>
                      <div class="col1" style={col6}>{commonItemDataObject.totalPrice}</div>
                    </div>
                  ))}
                </div>
                <hr></hr>
                {this.state.resrdataNew.map((resrdataNewObject, resrdataNewIndex) => (
                  <div className='row' >
                    <div className='col-md-7 ms_taxTableData'>
                      <table className='ms_odrDetails' style={{ fontSize: "10px" }}>
                        {this.state.taxResultNew[0].taxDetails.length > 0 ?
                          <>
                            <tr style={{ border: "1px solid #dee2e6" }}>
                              {this.state.taxResultNew[0].taxHeadings.map((obj, i) => (
                                (i === 0 || i === 1) ?
                                  <th style={{ borderWidth: "0px", fontWeight: "400", borderRight: "1px solid #dee2e6" }}>{obj.name}</th>
                                  :
                                  <th style={{ fontWeight: "400" }} colspan="2">{obj.name}</th>
                              ))}
                            </tr>
                            <tr style={{ borderLeft: "1px solid #dee2e6" }}>
                              {this.state.taxResultNew[0].taxHeadings.map((obj, i) => (
                                (i === 0 || i === 1) ?
                                  <th style={{ borderWidth: "0px", borderRight: "1px solid #dee2e6", borderTop: "1px solid transparent" }}></th>
                                  :
                                  <>
                                    <th style={{ fontWeight: "400" }} >%</th>
                                    <th style={{ fontWeight: "400" }} >Amt</th>
                                  </>
                              ))}
                            </tr>
                          </>
                          : ""}
                        {this.state.taxResultNew[0].taxDetails.map((obj, i) => (
                          <tr>
                            <td>{obj.taxPercentage}%</td>
                            <td>{obj.taxAmount}</td>
                            {obj.taxSubDetails.map((objSub, i) => (
                              <td>{objSub}</td>
                            ))}
                          </tr>
                        ))}
                      </table>
                    </div>
                    <div className='col-md-5'>
                      <div class="colContainer" style={{ fontSize: "11px", fontStyle: "normal", fontFamily: "Arial" }}>
                        <div class="colWide" style={{ width: "50%", float: "left", textAlign: "right", marginBottom: "5px" }} >Sub Total :</div>
                        <div class="col6" style={{ width: "40%", float: "right", textAlign: "right", paddingRight: "5px", marginBottom: "5px" }} >{resrdataNewObject.subtotal}</div>
                      </div>
                      {resrdataNewObject.taxData.length > 0 ?
                        <>
                          {resrdataNewObject.taxData.map((tax, taxIndex) => (
                            <>
                              <div key={taxIndex} className="colContainer" style={{ fontSize: "11px", fontStyle: "normal", fontFamily: "Arial" }}>
                                <div class="colWide" style={{ width: "50%", float: "left", textAlign: "right", marginBottom: "5px" }}>{tax.taxName}({parseFloat(tax.taxPercentage).toFixed(2)} %) :</div>
                                <div class="col6" style={{ width: "40%", float: "right", textAlign: "right", paddingRight: "5px", marginBottom: "5px" }} id={"taxValuediv" + taxIndex}>{tax.taxAmount}</div>
                              </div>
                            </>
                          ))}
                        </>
                        :
                        <>
                          {this.state.taxResultNew[0].taxDetails.map((tax1, taxIndex1) => (
                            <div key={taxIndex1} className="colContainer" style={{ fontSize: "11px", fontStyle: "normal", fontFamily: "Arial" }}>
                              <div class="colWide" style={{ width: "50%", float: "left", textAlign: "right", marginBottom: "5px" }}>{tax1.taxName}({parseFloat(tax1.taxPercentage).toFixed(2)} %) :</div>
                              <div class="col6" style={{ width: "40%", float: "right", textAlign: "right", paddingRight: "5px", marginBottom: "5px" }} id={"taxValuediv" + taxIndex1}>{tax1.taxAmount}</div>
                            </div>
                          ))}
                        </>
                      }
                      {resrdataNewObject.promoCodeAmount !== 0 && resrdataNewObject.promoCodeAmount !== '0.00' ?
                        <div class="colContainer" style={{ fontSize: "11px", fontStyle: "normal", fontFamily: "Arial" }}>
                          <div class="colWide" style={{ width: "50%", float: "left", textAlign: "right", marginBottom: "5px" }}>Promocode Discount :</div>
                          <div class="col6" style={{ width: "40%", float: "right", textAlign: "right", paddingRight: "5px", marginBottom: "5px" }}>{storeData[0].currencySymbol + ' ' + parseFloat(resrdataNewObject.promoCodeAmount).toFixed(2)}</div>
                        </div> : ""}
                      <div class="colContainer" style={{ fontSize: "11px", fontStyle: "normal", fontFamily: "Arial" }}>
                        <div class="colWide" style={{ width: "50%", float: "left", textAlign: "right", marginBottom: "5px" }}>Delivery Charges :</div>
                        <div class="col6" style={{ width: "40%", float: "right", textAlign: "right", paddingRight: "5px", marginBottom: "5px" }}>{storeData[0].currencySymbol + ' ' + parseFloat(this.state.orderData[0].deliverCharge).toFixed(2)}</div>
                      </div>
                      <div class="colContainer" style={{ fontSize: "11px", fontStyle: "normal", fontFamily: "Arial" }}>
                        <div class="colWideTotal" style={{ width: "50%", float: "left", textAlign: "right", marginBottom: "5px" }} >
                          Total Amount :
                        </div>
                        <div class="col6" style={{ width: "40%", float: "right", textAlign: "right", paddingRight: "5px", marginBottom: "5px" }}><b>{storeData[0].currencySymbol + ' ' + parseFloat(this.state.orderData[0].orderNetAmount).toFixed(2)}</b></div>
                      </div>
                      <div class="uSaved" style={unSaved}>
                        {resrdataNewObject.savedAmount !== 'Rs.0.00' ?
                          <div>  You Saved {resrdataNewObject.savedAmount}</div>
                          : <div>You Saved Rs. 0.00</div>

                        }
                         {this.state.orderData[0].promoCodeItemName !== '' ?
                          <div style={{color:'green'}}><b>  Congratulations! You got {this.state.orderData[0].promoCodeItemName} free</b></div>
                          : ''
                        }
                        {resrdataNewObject.comments !== '' ?
                          <div style={{color:'red'}}><b>  Message : {resrdataNewObject.comments}</b></div>
                          : ''
                        }
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <br></br>
            <br></br>
            <div class="invoice_hldr" style={invoicehldr}>
              {this.state.storeData.map((storeDataObject, storeDataIndex) => (
                <div className='ms_inv_footer' style={{ fontSize: "11px", fontFamily: "Arial", color: "rgb(5757,54)", borderTop: "1px solid black", padding: "2%" }}>
                  {storeDataObject.email_footer_text !== null && storeDataObject.email_footer_text !== '' ?
                    <div>
                      Please Note : {storeDataObject.email_footer_text}
                      <br></br></div> : ''
                  }
                  {this.state.orderData.map((orderDataObject, orderDataIndex) => (
                    <div>
                      {orderDataObject.mainCategoryName !== 'Restaurant' ?
                        <div>
                          Terms and conditions apply. Images are indicative. Offers valid till stocks last.
                        </div>
                        : <div>Terms and conditions apply. Images indicative indicative.</div>
                      }
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div class="uSaved" style={usaved}>
              <div style={{ float: "right" }}> Powered by <img src="https://d37ky63zmmmzfj.cloudfront.net/production/logo/logoMyyShopp2.png" alt="MyyShopp" title="MyyShopp" style={image} />
              </div>
            </div>
            <div onClick={() => this.printDiv()} title="Print Invoice" class="printBtnInvoice noprint " style={print}>
              Print
            </div>
          </div>
        </div>
      </div>
    )
  }
}