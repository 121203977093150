import React, { Component ,Suspense} from "react";
// import Header from "./header";
// import Footer from "./footer";
import Background from './images/about-bg-logo.png';
let Header = React.lazy(() => import("./header"));
let Footer = React.lazy(() => import("./footer"));
var sectionStyle = {
    width: "100%",
    height: "500px",
    float: "left",
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    margin: "0 auto",
    backgroundPosition: "50% 190px",
    backgroundSize: "460px"
};
export default class AboutUs extends Component {
    componentDidMount(){
        window.scrollTo(0, 0)
    }
    render() {
        return (
            // <div className="perfumeTheme">
            //     <div className="perfumeWrapper">
            //         <Header />
            //         <div className="container">
            //             <div className="perfumeInnerPages ">
            //                 <h1 style={{ paddingTop: "30px" }}>Our<span style={{ color: "rgb(224 192 119)" }}>&nbsp;Mission</span> </h1>
            //                 <p>We are offering our perfumes without compromising on quality and completeness.<br/>
            //                 We are obliged to listen to and supply the needs of our colleagues, business partners and customers.<br/>
            //                We continue to support environmental protection, which is the most important part of our responsibilities.<br/>
            //                 We are committed to our entrepreneurial spirit that triggers the growth of our company.</p>
            //             </div>
            //         </div>
            //         <Footer />
            //     </div>
            // </div>
             <div className="perfumeTheme">
               <Suspense fallback={<div></div>}>
          <Header/>
        <div className="topcategorysec fragranceAbout aboutDetailsViewMore"    style={{position:"relative"} } >

                <div className="container" id="aboutus" style={ sectionStyle }>
                  <span className="perfumeMainHead" >
                <h1  className="abtH1" style={{textAlign:"left", textTransform:"none", justifyContent:"left", marginBottom:"0px"}}>Our Mission </h1>
                <h2>Emissa Perfumes</h2>
                  </span>
                    <div className="topcategory ads" style={{marginTop:"25px"}}>
                        <div className="topcatList">
                        We are offering our perfumes without compromising on quality and completeness.
                            We are obliged to listen to and supply the needs of our colleagues, business partners and customers.
                          We continue to support environmental protection, which is the most important part of our responsibilities.
                           We are committed to our entrepreneurial spirit that triggers the growth of our company.
                        </div>
                    </div>
                </div>

            </div>
        <Footer />
        </Suspense>
      </div>
        )
    }
}