import React, { Component, Suspense } from 'react';
import Joinpeople from "../myyshopp/joinpeople"
import checkmark_14 from "../images/checkmark-14.png";
import $ from "jquery";
let Header = React.lazy(() => import("../header"));
let Footer = React.lazy(() => import("../footer"));
export default class Pricing extends Component {
    componentDidMount() {
        var li = $('.priceBottomPart1 .priceBottomPart-para');
        var i, listp = $('.priceBottomPart1 .priceBottomPart-para');
        var listk = $('.priceBottomPart2 .priceBottomPart-para');
        var listm = $('.priceBottomPart3 .priceBottomPart-para');
        for (i = 0; i <= li.length; i++) {
            listp.eq(i).addClass('priceBottomPart-para' + i);
            listk.eq(i).addClass('priceBottomPart-para' + i);
            listm.eq(i).addClass('priceBottomPart-para' + i);
            var hh = $('.priceBottomPart1 .priceBottomPart-para' + i).height() + 40;
            $('.priceBottomPart1 .priceBottomPart-para' + i).height(hh);
            $('.priceBottomPart2 .priceBottomPart-para' + i).height(hh);
            $('.priceBottomPart3 .priceBottomPart-para' + i).height(hh);
        }
        $(".priceBottomPart-innr").hide();

        $(".priceBottomPart1 .priceBottomPart-head").click(function () {
            $(this).toggleClass("priceBottomPart-head-active");
        });

        $(".priceBottomPart1 .priceBottomPart-head1").click(function () {
            $(".priceBottomPart-innr1").slideToggle();
        });

        $(".priceBottomPart1 .priceBottomPart-head2").click(function () {
            $(".priceBottomPart-innr2").slideToggle();
        });

        $(".priceBottomPart1 .priceBottomPart-head3").click(function () {
            $(".priceBottomPart-innr3").slideToggle();
        });

        $(".priceBottomPart1 .priceBottomPart-head4").click(function () {
            $(".priceBottomPart-innr4").slideToggle();
        });

        $(".priceBottomPart1 .priceBottomPart-head5").click(function () {
            $(".priceBottomPart-innr5").slideToggle();
        });

        $(".priceBottomPart1 .priceBottomPart-head6").click(function () {
            $(".priceBottomPart-innr6").slideToggle();
        });

        $(".priceBottomPart1 .priceBottomPart-head7").click(function () {
            $(".priceBottomPart-innr7").slideToggle();
        });

        $(".priceBottomPart1 .priceBottomPart-head8").click(function () {
            $(".priceBottomPart-innr8").slideToggle();
        });

        $(".priceBottomPart1 .priceBottomPart-head9").click(function () {
            $(".priceBottomPart-innr9").slideToggle();
        });

        $(".priceBottomPart1 .priceBottomPart-head10").click(function () {
            $(".priceBottomPart-innr10").slideToggle();
        });

        $(".priceBottomPart1 .priceBottomPart-head11").click(function () {
            $(".priceBottomPart-innr11").slideToggle();
        });

        $(".priceBottomPart1 .priceBottomPart-head12").click(function () {
            $(".priceBottomPart-innr12").slideToggle();
        });

        $(".priceBottomPart1 .priceBottomPart-head13").click(function () {
            $(".priceBottomPart-innr13").slideToggle();
        });
    }
    render() {
        return (
            <div class="myyshoppWrapperInner myyshopp-main-wrapper">
                <Suspense fallback={<div></div>}>
                    <Header></Header>
                    <div class="container myyshopp-container">
                        <section class="">
                            <div class="col-md-12">
                                <h1 class="main-head text-center mt-0">Price and Plans </h1><br></br>
                            </div>
                            <div className="priceMain">

                                <div className="priceSub priceSub-feature">
                                    <div className="priceMiddlePart">
                                        <div className="priceMiddleHead">
                                            Features
                                        </div>
                                        <hr></hr>
                                        <div className="priceMiddleRate">
                                            Price
                                        </div>
                                    </div>
                                    <div className="priceBottomPart priceBottomPart1">
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head1">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Personal Digital Store
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr1">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Mobile & Tab Optimised & Compatible
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No. of Products
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Images Per Product
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Templates
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Logo Management
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Banner Management
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Landing Page
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> HTML Pages (About Us, Privacy Policy, T&C, Contact Us etc.)
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Free SSL Certificate
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Super Fast Website (via CDN)
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main priceBottomPart-head-inactive">
                                            <div className="priceBottomPart-head priceBottomPart-head2">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Store Configuration & Profile Management
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head3">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Delivery Management
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr3">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Delivery Charges & Rules
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Delivery Location Management
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Delivery Timings & Slots Management
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Enabling No Delivery / Contact Us Options
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head4">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Product Management
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr4">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Add & Edit for single items
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Offers & Discounts Management
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Product Variations
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head5">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Advanced Product Management
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr5">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Customised Main Menu
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Bulk Product Upload
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Category Management (Add, Edit, Sort Order, Define upto 3 levels)
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Multi level product categories
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Brand (Add & Edit)
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Filters customisation
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Advanced Search
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Wishlist
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main priceBottomPart-head-inactive">
                                            <div className="priceBottomPart-head priceBottomPart-head6">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Professional Email Creation
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head7">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> SEO
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr7">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Auto Generated SEO
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> SEO friendly URL
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Key Word Management
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head8">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Payment Options
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr8">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Payment Gateway Integration
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> COD, Sodexo, CC & DC on Delivery, Store Pick Up
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head9">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Campaign Management
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr9">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Promotional Email
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Promotional SMS
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> SMS campaign Management (CSV upload)
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> SMS Campaign History (Log with Status and reach)
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Customise Messaging (SMS & Email)
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head10">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Promotions Management
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr10">
                                                <p className="priceBottomPart-para height80">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Promo Builder Tool (By Date, By hour, bill breakers, urgency based, free item, first order, on delivery)
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Coupon Engine
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Item Level Discount Management
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head11">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Order Management
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr11">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Order History
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Summary Invoice Generation (Print Enabled)
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Invoice Customisation
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Order Status Management
                                                </p>
                                                <p className="priceBottomPart-para height80">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Filters by (Status, Date, Order No, Stores, Buyer Name, Buyer Email, Buyer Phone No)
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head12">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Notifications
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr12">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Transactional Email
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Transactional SMS
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head13">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Reports
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr13">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Quick Store Insight (Orders, Amount, Site Views)
                                                </p>
                                                <p className="priceBottomPart-para height120">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Order Related Reports (with export functionality) 1. Cancelled / Delivered Order Report 2. Verify Cancelled Order 3. Returned Orders 4. Date Wise Delivered Order Report 5. Review Ordered Items
                                                </p>
                                                <p className="priceBottomPart-para height80">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Customer Details (with export functionality). Buyer Details 2. Customer Wise Date Wise Order Report
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Store Created Order Report
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Request through Contact Us form
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Requested Products Report
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Stock Report (with export functionality)
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Best Customer Report
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Repeat Customer Report
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Registration Report (Desktop / Android / iOS)
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Device Purchase Report
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Bowser Report
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Ticket Size
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Fall Out Report
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Search History
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="priceButtonPart">
                                        <span class="theme-round-btn" style={{ opacity: 0, pointerEvents: 'none' }}>SELECT</span>
                                    </div>
                                </div>
                                <div className="priceSub">
                                    <div className="priceMiddlePart">
                                        <div className="priceMiddleHead">
                                            MyyShopp Basic
                                        </div>
                                        <hr></hr>
                                        <div className="priceMiddleRate">
                                            Free
                                        </div>
                                    </div>
                                    <div className="priceBottomPart priceBottomPart2">
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head1">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr1">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> 200
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> 1
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> 1
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Unlimited
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head2">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Single Outlet
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head3">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Limited
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr3">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head4">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr4">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head5">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr5">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head6">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head7">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Auto Generated
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr7">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head8">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Limited
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr8">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head9">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Email Only
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr9">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head10">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Limited
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr10">
                                                <p className="priceBottomPart-para height80">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para  ">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head11">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Limited
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr11">
                                                <p className="priceBottomPart-para ">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para ">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para height80">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head12">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Email Only
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr12">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Unlimited
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head13">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Limited
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr13">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para height120">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para height80">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> No
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="priceButtonPart">
                                        <a href="/myyshopp/contact" class="theme-round-btn">SELECT</a>
                                    </div>
                                </div>

                                <div className="priceSub">
                                    <div className="priceMiddlePart">
                                        <div className="priceMiddleHead">
                                            MyyShopp PRO
                                        </div>
                                        <hr></hr>
                                        <div className="priceMiddleRate">
                                            INR 2000 PM +Tax
                                        </div>
                                    </div>
                                    <div className="priceBottomPart priceBottomPart3">
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head1">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr1">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Unlimited
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Unlimited
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> 5
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Unlimited
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head2">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Single Outlet
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head3">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr3">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt="" ></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head4">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr4">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head5">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Limited
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr5">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head6">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Upto 5 Emails - On request only
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head7">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Key Word Management
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr7">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head8">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr8">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head9">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Email & SMS
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr9">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Unlimited
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Buy
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head10">
                                                <p className="priceBottomPart-para ">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Enhanced
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr10">
                                                <p className="priceBottomPart-para height80">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head11">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Enhanced
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr11">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para ">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para  ">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para height80">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head12">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> SMS & Email
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr12">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Unlimited
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> 1000
                                                </p>
                                            </div>
                                        </div>
                                        <div className="priceBottomPart-main">
                                            <div className="priceBottomPart-head priceBottomPart-head13">
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Enhanced
                                                </p>
                                            </div>
                                            <div className="priceBottomPart-innr priceBottomPart-innr13">
                                                <p className="priceBottomPart-para ">
                                                    <img src={checkmark_14} width="20 " className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para height120">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para height80">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                                <p className="priceBottomPart-para">
                                                    <img src={checkmark_14} width="20" className="mr-2" alt=""></img> Yes
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="priceButtonPart">
                                        <a href="/myyshopp/contact" class="theme-round-btn">SELECT</a>
                                    </div>
                                </div>

                            </div>
                        </section>
                    </div>
                    <Joinpeople></Joinpeople>
                    <Footer></Footer>
                </Suspense>
            </div>
        )
    }
}