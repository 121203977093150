import React, { Component } from 'react';
import close from "../images/icons/close.png"
import $ from "jquery";
import CartBtn from "../restaurant/addcartBtn"
export default class customize extends Component {
  componentDidMount() {
    $(".popOverlay").click(function () {
      $(".popupWrap").hide();
    });
    $(".saveClose").click(function () {
      $(".popupWrap").hide();
    });
  }
  addtoCartCheck() {
    var amount = this.props.itemPrice;
    var toppingsIDs = []  
    this.props.toppings.map((obj, index) => {
      obj.toppingArray.map((objSub, indexSub) => {
        if ($("#check" + objSub.id + this.props.storeItemSizeId).is(':checked')) {
          amount = parseFloat(amount) + parseFloat($("#check" + objSub.id + this.props.storeItemSizeId).val())
          toppingsIDs.push(objSub.id)
        }
      })
      if ($("input:radio[name='rdToppings" + obj.level1id + this.props.storeItemSizeId + "']").is(":checked")) {
        var radioValue = $("input[name='rdToppings" + obj.level1id + this.props.storeItemSizeId + "']:checked").val();
        var toppingAmt = obj.toppingArray.filter((objSub) => {
          return objSub.id == radioValue
        })
        amount = parseFloat(amount) + parseFloat(toppingAmt[0].price)
        $("#rdToppings" + obj.level1id + this.props.storeItemSizeId).attr('checked', true);
        toppingsIDs.push(toppingAmt[0].id)
      }
    })    
    let amt = parseFloat(amount).toFixed(2)
    $("#customId" + this.props.storeItemSizeId).text(amt);
    $("#totalToppingPrice" + this.props.storeItemSizeId).val(amount);
    $("#hidToppingsData" + this.props.storeItemSizeId).val(toppingsIDs)
  }  
  render() {
    return (
      <div className="customize">
        <div className="popOverlay"></div>
        <div className="loginPop saveMoreDiv">
          <div className="loginHead">
            <span className="saveClose"><img src={close} /></span>
            <div className="loginText">Customize</div>
          </div>
          <div className="saveMoreContent customizeContent">
            <div className="customizeItemName">{this.props.itemName} ({this.props.itemSize})</div>
            {this.props.toppings.map((toppingsObject, toppingsIndex) => (
              <div className={"customizeItemExtra customizeItemExtra" + this.props.storeItemSizeId}>
                <h4>{toppingsObject.name}:</h4>
                <ul>
                  {toppingsObject.toppingArray.map((toppingArrayObject, toppingArrayIndex) => (
                    <li>
                      {toppingsObject.multiSelect == true ?
                        <span class="checkBoxSquare largeCheck">
                          <input type="checkbox" className="chkTopping" value={toppingArrayObject.price} id={"check" + toppingArrayObject.id + this.props.storeItemSizeId} name="check" onClick={(e) => this.addtoCartCheck()} />
                          <label for={"check" + toppingArrayObject.id + this.props.storeItemSizeId}></label>
                        </span>
                        :
                        <span class="radioSection largeRadio">
                          <input type="radio" className="chkTopping" value={toppingArrayObject.id} id={"rdToppings" + toppingArrayObject.id + this.props.storeItemSizeId} name={"rdToppings" + toppingsObject.level1id + this.props.storeItemSizeId} onClick={(e) => this.addtoCartCheck()} />
                          <label for={"rdToppings" + toppingArrayObject.id + this.props.storeItemSizeId}></label>
                        </span>
                      }
                      <div className="customizeExtraName">{toppingArrayObject.name} {toppingArrayObject.price != 0 ? '(' + this.props.currencySymbol + " " + parseFloat(toppingArrayObject.price).toFixed(window.sessionStorage.getItem('decimalCount')) + ')' : '(Free)'}</div>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            <div className="customizePrice">
              <input type="hidden" id={"totalToppingPrice" + this.props.storeItemSizeId} name={"totalToppingPrice" + this.props.storeItemSizeId} value={this.props.itemPrice}></input>
              <input type="hidden" id={"hidToppingsData" + this.props.storeItemSizeId} name={"hidToppingsData" + this.props.storeItemSizeId} ></input>
              <span className="customizePriceText">Total : {this.props.currencySymbol}<span id={"customId" + this.props.storeItemSizeId}>{this.props.itemPrice}</span></span>
            </div>            
            <div class="addCartButton largeCartButton">
              <CartBtn cartSize={this.props.cartSize} cartLabel={this.props.cartLabel} isCartItemColorShow={this.props.isCartItemColorShow} isCartItemOpenClass={this.props.isCartItemOpenClass} itemLoad={this.props.itemLoad} isMultiSize={this.props.isMultiSize} storeId={this.props.storeId} itemId={this.props.itemId} customize={this.props.customize} index={this.props.index} itemName={this.props.itemName} itemSize={this.props.itemSize} itemMrp={this.props.itemMrp} itemImage={this.props.itemImage} storeItemSizeId={this.props.storeItemSizeId} itemAddedColour="itemAddedColour"></CartBtn>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
