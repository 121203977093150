import React, { Component ,Suspense} from "react";
import bannerImage from "./assets/images/bannerImage.jpg"
import gallery1 from "./assets/images/galleryImg1.jpg"
import gallery2 from "./assets/images/galleryImg2.jpg"
import gallery3 from "./assets/images/galleryImg3.jpg"
import gallery4 from "./assets/images/galleryImg4.jpg"
import gallery5 from "./assets/images/galleryImg5.jpg"
import gallery6 from "./assets/images/galleryImg6.jpg"
import gallery7 from "./assets/images/galleryImg7.jpg"
import gallery8 from "./assets/images/galleryImg8.jpg"

import gallery10 from "./assets/images/galleryImg10.jpg"
import gallery11 from "./assets/images/galleryImg11.jpg"
import gallery12 from "./assets/images/galleryImg12.jpg"
import gallery13 from "./assets/images/galleryImg13.jpg"
import gallery14 from "./assets/images/galleryImg14.jpg"
import gallery15 from "./assets/images/galleryImg15.jpg"
import gallery16 from "./assets/images/galleryImg16.jpg"
import gallery17 from "./assets/images/galleryImg17.jpg"
import gallery18 from "./assets/images/galleryImg18.jpg"
import gallery19 from "./assets/images/galleryImg19.jpg"
import gallery20 from "./assets/images/galleryImg20.jpg"
import gallery21 from "./assets/images/galleryImg21.jpg"
import gallery22 from "./assets/images/galleryImg22.jpg" 
import gallery23 from "./assets/images/galleryImg23.jpg"
import gallery24 from "./assets/images/galleryImg24.jpg"
import gallery25 from "./assets/images/galleryImg25.jpg"
import gallery26 from "./assets/images/galleryImg26.jpg"
import gallery27 from "./assets/images/galleryImg27.jpg"
import gallery28 from "./assets/images/galleryImg28.jpg"
import gallery29 from "./assets/images/galleryImg29.jpg"
import gallery30 from "./assets/images/galleryImg30.jpg"
import $ from "jquery"
let MraHeader = React.lazy(() => import("./header"));
let MraFooter = React.lazy(() => import("./footer"));
export default class Gallery extends Component {
    componentDidMount(){
        // Gallery image hover
$( ".img-wrapper" ).hover(
    function() {
      $(this).find(".img-overlay").animate({opacity: 1}, 600);
    }, function() {
      $(this).find(".img-overlay").animate({opacity: 0}, 600);
    }
  );
  
  // Lightbox
  var $overlay = $('<div id="overlay"></div>');
  var $image = $("<img>");
  var $prevButton = $('<div id="prevButton"><</div>');
  var $nextButton = $('<div id="nextButton">></i></div>');
  var $exitButton = $('<div id="exitButton">X</div>');
  
  // Add overlay
  $overlay.append($image).prepend($prevButton).append($nextButton).append($exitButton);
  $("#gallery").append($overlay);
  
  // Hide overlay on default
  $overlay.hide();
  
  // When an image is clicked
  $(".img-overlay").click(function(event) {
    // Prevents default behavior
    event.preventDefault();
    // Adds href attribute to variable
    var imageLocation = $(this).prev().attr("href");
    // Add the image src to $image
    $image.attr("src", imageLocation);
    // Fade in the overlay
    $overlay.fadeIn("slow");
  });
  
  // When the overlay is clicked
  $overlay.click(function() {
    // Fade out the overlay
    $(this).fadeOut("slow");
  });
  
  // When next button is clicked
  $nextButton.click(function(event) {
    // Hide the current image
    $("#overlay img").hide();
    // Overlay image location
    var $currentImgSrc = $("#overlay img").attr("src");
    // Image with matching location of the overlay image
    var $currentImg = $('#image-gallery img[src="' + $currentImgSrc + '"]');
    // Finds the next image
    var $nextImg = $($currentImg.closest(".image").next().find("img"));
    // All of the images in the gallery
    var $images = $("#image-gallery img");
    // If there is a next image
    if ($nextImg.length > 0) { 
      // Fade in the next image
      $("#overlay img").attr("src", $nextImg.attr("src")).fadeIn(800);
    } else {
      // Otherwise fade in the first image
      $("#overlay img").attr("src", $($images[0]).attr("src")).fadeIn(800);
    }
    // Prevents overlay from being hidden
    event.stopPropagation();
  });
  
  // When previous button is clicked
  $prevButton.click(function(event) {
    // Hide the current image
    $("#overlay img").hide();
    // Overlay image location
    var $currentImgSrc = $("#overlay img").attr("src");
    // Image with matching location of the overlay image
    var $currentImg = $('#image-gallery img[src="' + $currentImgSrc + '"]');
    // Finds the next image
    var $nextImg = $($currentImg.closest(".image").prev().find("img"));
    // Fade in the next image
    $("#overlay img").attr("src", $nextImg.attr("src")).fadeIn(800);
    // Prevents overlay from being hidden
    event.stopPropagation();
  });
  
  // When the exit button is clicked
  $exitButton.click(function() {
    // Fade out the overlay
    $("#overlay").fadeOut("slow");
  });
    }
    render() {
        return (
            <>
                <div className="mraInnerSec">
                <Suspense fallback={<div></div>}>
                    <div className="coverWraper">
                        <MraHeader />
                        <div className="contentWrap">
                            <div className="bannerImginner">
                                <img src={bannerImage} alt="" />
                                <div className="mraTitle">
                                    <div className="contentSectn">
                                        <h1>Gallery</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="storeCoverWrap mraAbt">
                                <div className="container">
                                    <div className="mraTitlesInner mraBlogs">
                                        {/* <div className="mraTitle">
                                        <span>OUR GALLERY</span> 
                                            <img src={BorderImg} alt="" />
                                            <div className="contentSectn" style={{ margin: "0px" }}>
                                                <h1 >Gallery</h1>
                                            </div>
                                        </div> */}
                                        <div className="row">
                                            <section id="gallery">
                                                <div class="container">
                                                    <div id="image-gallery">
                                                        <div class="row">
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery1}>
                                                                        <img src={gallery1} class="img-responsive" alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery2}>
                                                                        <img src={gallery2} class="img-responsive"  alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery3}>
                                                                        <img src={gallery3} class="img-responsive"  alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery4}>
                                                                        <img src={gallery4} class="img-responsive" alt="" /></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery5}>
                                                                        <img src={gallery5} class="img-responsive" alt="" /></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery6}>
                                                                        <img src={gallery6} class="img-responsive"  alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery7}>
                                                                        <img src={gallery7} class="img-responsive"  alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery8}>
                                                                        <img src={gallery8} class="img-responsive"  alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>


                                                           
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery10}>
                                                                        <img src={gallery10} class="img-responsive"  alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery11}>
                                                                        <img src={gallery11} class="img-responsive"  alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery12}>
                                                                        <img src={gallery12} class="img-responsive"  alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery13}>
                                                                        <img src={gallery13} class="img-responsive"  alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery14}>
                                                                        <img src={gallery14} class="img-responsive"  alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery15}>
                                                                        <img src={gallery15} class="img-responsive"  alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery16}>
                                                                        <img src={gallery16} class="img-responsive"  alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery17}>
                                                                        <img src={gallery17} class="img-responsive"  alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery18}>
                                                                        <img src={gallery18} class="img-responsive"  alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery19}>
                                                                        <img src={gallery19} class="img-responsive"  alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery20}>
                                                                        <img src={gallery20} class="img-responsive"  alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery21}>
                                                                        <img src={gallery21} class="img-responsive"  alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery22}>
                                                                        <img src={gallery22} class="img-responsive"  alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery23}>
                                                                        <img src={gallery23} class="img-responsive"  alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery24}>
                                                                        <img src={gallery24} class="img-responsive"  alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery25}>
                                                                        <img src={gallery25} class="img-responsive"  alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery26}>
                                                                        <img src={gallery26} class="img-responsive"  alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery27}>
                                                                        <img src={gallery27} class="img-responsive"  alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery28}>
                                                                        <img src={gallery28} class="img-responsive"  alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery29}>
                                                                        <img src={gallery29} class="img-responsive"  alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                                                <div class="img-wrapper">
                                                                    <a href={gallery30}>
                                                                        <img src={gallery30} class="img-responsive"  alt=""/></a>
                                                                    <div class="img-overlay">
                                                                       +
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <MraFooter />
                    </div>
                    </Suspense>
                </div>
            </>
        )
    }
}