import React, { Component } from 'react';
import uploadIco from '../images/lokal/upload-icon.png'
import $ from 'jquery'
import AWS from "aws-sdk";
import { environmentType } from "../config";
import { getData} from '../services'
var s3;
export default class UploadPrescription extends Component {
    constructor(props) {
        super(props)
        this.state = {
          prescription:[]
        };
        this.filePrescription = this.filePrescription.bind(this);        
    }
    componentDidMount(){
        const postData = {
            "functionName": "getstorehomedata/s3configuration"
        }
        getData(postData).then((data) => {
            if (data.data.success === "1") {
                AWS.config.update({
                    accessKeyId: data.data.result[0].accessKey,
                    secretAccessKey: data.data.result[0].secretKey,
                    region: "us-east-1"
                });
                var bucketParams = {
                    Bucket: data.data.result[0].bucketName
                };
                s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
            }
        })   
        this.listPrescriptions();
        $("#prescriptionContinueBtn").hide()
        $(".uploadOpenClick").click(function(){
            $(".uploadPopDiv").show()
        });
        $(".uploadCloseClick").click(function(){
            $(".uploadPopDiv").hide()
        });
    } 
    prescriptionFile(storeId){
        $("#prescriptionFile"+storeId).click()
    }
    randumNumber = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    filePrescription(e) {  
        $("#prescriptionFileUploadBtn"+this.props.storeId).hide();
        $("#prescriptionFileUploading"+this.props.storeId).show();
        var file = e.target.files[0]      
        let S3BucketMainFolder = environmentType()+"/pharmacy/attachments"
        let fileName = this.randumNumber()+'___'+ file.name;        
        let fullFilePath = S3BucketMainFolder + "/" + fileName;        
        s3.upload(
            {
                Key: fullFilePath,
                Body: file,
                ACL: "public-read"
            },
            (err, data) => {
                if (err) {
                    // // alert(
                    // //     "There was an error uploading your photo: ",
                    // //     err.message
                    // );
                    return null
                } else {
                    this.savePrescription(fileName)
                    //   alert("Image uploaded successfuly")
                    //alert("fullFilePath"+fullFilePath)
                    // return fullFilePath;
                }
            }
        )
    }
    listPrescriptions(){
        const postData = {
            "functionName": "pharmacy/listRecord",
            "buyerId":localStorage.getItem('userId'),
            "siteId":localStorage.getItem('siteId'),
            "storeId":this.props.storeId
        }
        getData(postData).then((data) => {        
            if (data.data.success == "1") {              
                this.setState({ prescription: data.data.result.phListing })
            }            
        });
    }
    savePrescription(fileName){
        $("#prescriptionFileUploadBtn"+this.props.storeId).show();
        $("#prescriptionFileUploading"+this.props.storeId).hide();
        const postData = {
            "functionName": "pharmacy/saveRecord",
            "buyerId":localStorage.getItem('userId'),
            "siteId":localStorage.getItem('siteId'),
            "storeId":this.props.storeId,
            "attachment":fileName
        }
        getData(postData).then((data) => {          
            if (data.data.success == "1") {              
                this.listPrescriptions();
            }            
        });
    }
    removePrescription(prescriptionId){
        const postData = {
            "functionName": "pharmacy/saveRecord",
            "id":prescriptionId
        }
        getData(postData).then((data) => {                     
            if (data.data.success == "1") {                              
                this.listPrescriptions();
               // $("#hidPrescriptionId"+this.props.storeId).val("")
              //  $(".uploadIconThumpSec").removeClass('uploadIconThumpActive')
                this.selectPrescription(prescriptionId)
                //this.props.savedAmount()
            }            
        });
    }
    selectPrescription(prescriptionId){     

        
        
        // if($("#prescriptionFile"+prescriptionId).hasClass('uploadIconThumpActive') === true){
        //     $("#prescriptionFile"+prescriptionId).removeClass('uploadIconThumpActive')
        //     $("#prescriptionText"+prescriptionId).text('Select')
        // }
        // else{
        //     $("#prescriptionFile"+prescriptionId).addClass('uploadIconThumpActive')
        //     $("#prescriptionText"+prescriptionId).text('Unselect')
        // }        

        var prescriptionIdArray = $("#hidPrescriptionId"+this.props.storeId).val().toString().split(',');
        var indexOfItem = $.inArray(prescriptionId.toString(), prescriptionIdArray);
        if (indexOfItem == '-1') {
            $("#hidStoreIdForPrescriptionCheckout").val(this.props.storeId)
            $("#prescriptionFileSpan"+prescriptionId).addClass('uploadIconThumpActive')
            $("#prescriptionText"+prescriptionId).text('Unselect')
            prescriptionIdArray.push(prescriptionId)
        }
        else {
            $("#prescriptionFileSpan"+prescriptionId).removeClass('uploadIconThumpActive')
            $("#prescriptionText"+prescriptionId).text('Select')
            prescriptionIdArray = $.grep(prescriptionIdArray, function (a) {
                return a !== prescriptionId.toString();
            });
        }
        if(prescriptionIdArray.length === 0){
            $("#hidStoreIdForPrescriptionCheckout").val('')
        }
        $("#hidPrescriptionId"+this.props.storeId).val(prescriptionIdArray.toString().trim())
        this.props.savedAmount()
    }
    render() {        
        return (
          <div className="uploadCover">
                <input type="hidden" id={"hidPrescriptionId"+this.props.storeId}/>
              <div className="uploadIconSec" id={"prescriptionFileUploadBtn"+this.props.storeId} onClick={()=>this.prescriptionFile(this.props.storeId)}><img src={uploadIco} alt=""/><span>Upload Rx</span></div>
              <div className="uploadIconSec" id={"prescriptionFileUploading"+this.props.storeId} style={{cursor:'default',display:'none'}}><span>Uploading...</span></div>
              <input type="file" id={"prescriptionFile"+this.props.storeId} onChange={this.filePrescription} style={{display:'none'}}></input>
              <div className="uploadPrescription">
                    <div className="uploadPrescriptionHead">
                        Attach Prescription <span  className="uploadValid uploadOpenClick">Valid Prescription?</span>
                    </div>
                    <p>(Only upload .jpeg,.jpg,or.png etc. file types of not more than 10 MB)</p>
                    <div className="uploadIconThumpSec">
                        {this.state.prescription.map((prescriptionObj, i) => (
                            <span className="uploadIconThumpView">
                                <span id={"prescriptionFileSpan"+prescriptionObj.id} className="uploadIconThump"><a href={prescriptionObj.path} target="_blank"><img src={prescriptionObj.path} alt="Prescription"/></a>
                                    {/* <span title="Delete Prescription" className="uploadClose" onClick={()=>this.removePrescription(prescriptionObj.id)}>x</span> */}
                                </span>
                                <a href="javascript:void(0);" className="thumpViewPris" onClick={()=>this.selectPrescription(prescriptionObj.id)} id={"prescriptionText"+prescriptionObj.id}>Select</a>
                            </span>
                            // <span className="uploadIconThump uploadIconThumpActive"><img src={uploadIco}/><span className="uploadClose">x</span></span>
                            // <span className="uploadIconThump"><img src={uploadIco}/><span className="uploadClose">x</span></span>
                            // <span className="uploadIconThump"><img src={uploadIco}/><span className="uploadClose">x</span></span>
                        ))}
                    </div>
              </div>
          </div>
        )
    }
}