import React, { Component } from 'react'
import close from "../images/icons/close.png"
import $ from "jquery"
export default class TermsAndCondition extends Component {
    closeTermsConditionPopup(){
        $("#termsConditionPopup").hide()
    }
    render() {
        window.scrollTo(0, 0)
        return (
            <div className="customize">
                <div className="popOverlay"></div>
                <div className="loginPop popMediumDiv">
                    <div className="saveMoreContent customizeContent">
                        <div className="container">
                            <span className="saveClose" onClick={(e) => this.closeTermsConditionPopup()}><img title="Close" style={{display:"block"}} src={close} alt="Close" /></span>
                            <div className="termsHeadDiv">
                                <h3 className="terms termsHead termsHeadsize" >Merchant Agreement <br /> (Terms and Conditions)</h3>
                            </div>
                            <div className="terms termsHead">
                                <strong>Dear Merchant,</strong>
                            </div>
                            <div className="terms termsPara">
                                Thank you for choosing MyyShopp to be your eCommerce technology partner.
                            </div>
                            <div className="terms termsPara">
                                The following is the product construct & commercials:
                            </div>
                            <div className="terms termsHead">
                                <strong>What do you get?</strong>
                            </div>
                            <div className="termsParaoltext">
                                <ol className="terms termsPara listOltype pl-3">
                                    <li> <strong>eCommerce Sub-domain website</strong> for your store (e.g., yourstorename.lokal.in)</li>
                                    <li>Listing of your store on <a href="#">www.lokal.in</a> </li>
                                    <li> <strong>Google My Business listing</strong> for your store</li>
                                    <li><strong>Terms & Conditions:</strong>
                                        <ol className="listtype-terms">
                                            <li className="pt-3">4.1. The work on your product will begin post receipt of all relevant data and content.</li>
                                            <li>4.2. As part of Sub-domain offering ( <a href="#">yourstorename.lokal.in</a> ), MyyShopp will provide standard branding and marketing material (one-time).</li>
                                            <li>4.3. Please note, MyyShopp or Lokal is not responsible for the promotion or marketing of your online / physical store.</li>
                                            <li>4.4. The payment plan is applicable per merchant per store. Multiple stores will be charged as per the same commercials per outlet.</li>
                                            <li>4.5. 10% YOY escalation in monthly subscription fee will be applicable at the start of Year 3. (25th month onwards)</li>
                                            <li>4.6. The subscripton is ongoing and can be cancelled by either party by giving 30 days notice</li>
                                            <li>4.7. All fee paid is non refundable.</li>
                                            <li>4.8. Commision on Direct Online Sales through Lokal.in:
                                                <ul>
                                                    <li className="pt-3">Grocery | Fruits & Vegetables: 3%</li>
                                                    <li>Stationary | Bakery | Dairy | Meats & Cold storage: 5%</li>
                                                    <li>Pet supplies | Nutrition products | Restaurants: 10% </li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}