import React, { Component, Suspense} from "react";
import MenuStatic from "./menuStatic";
import bannerImage from "./assets/images/bannerImageMenu.jpg"
import BorderImg from "./assets/images/separator.svg";
let MraHeader = React.lazy(() => import("./header"));
let MraFooter = React.lazy(() => import("./footer"));
export default class MenuDetails extends Component {
    render() {
        return (
            <>
                <div className="mraInnerSec MenuMRA">
                <Suspense fallback={<div></div>}>
                    <div className="coverWraper">
                        <MraHeader />
                        <div className="contentWrap">
                            <div className="bannerImginner">
                                <img src={bannerImage} alt="" />
                                <div className="mraTitle">
                                    {/* <span>DELICIOUS & AMAZING</span>
                                    <img src={BorderImg} alt="" /> */}
                                    <div className="contentSectn">
                                        <h1>Menu</h1>
                                        <p className="OurmenuTxtdesktop">Innovative and tasty food cooked by professionals and served with style. Showcasing the very best of traditional and new menus in a style that suits you.</p>

                                    </div>
                                </div>
                            </div> 
                            <div className="storeCoverWrap mraMenuDetail"> 
                                <div className="container">
                                    <div className="mraTitlesInner">
                                        <div className="mraTitle">
                                            <span>Our MENU</span>
                                            <img src={BorderImg} alt="" />
                                            <div className="contentSectn" style={{ margin: "0px" }}>
                                            <p className="OurmenuTxtMob">Innovative and tasty food cooked by professionals and served with style. Showcasing the very best of traditional and new menus in a style that suits you.</p>

                                                {/* <h1 >Appetizers</h1> */}
                                                {/* <p>Innovative and tasty food cooked by professionals and served with style. Showcasing the very best of traditional and new menus in a style that suits you.</p> */}
                                            </div>
                                        </div>
                                        <div className="row mraMarginBottom mraInnerMenupg menuOuter ">
                                            <span className="menuStaticTabInner">
                                            <MenuStatic />
                                            </span>
                                           
                                            {/* <div class="menu-col col-lg-6 col-md-12 col-sm-12">
                                                <div class="inner">
                                                    <div class="dish-block">
                                                        <div class="inner-box">
                                                            <div class="dish-image"><a href="#">
                                                                <img src={dish1} alt="" />
                                                            </a></div>
                                                            <div class="title clearfix"><div class="ttl clearfix"><h5>
                                                                <a href="#">Greek Salad</a>
                                                            </h5></div><div class="price"><span>$25.50</span></div></div>
                                                            <div class="text desc"><a href="#">Tomatoes, green bell pepper, sliced cucumber onion, olives, and feta cheese.</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="menu-col col-lg-6 col-md-12 col-sm-12">
                                                <div class="inner">
                                                    <div class="dish-block">
                                                        <div class="inner-box">
                                                            <div class="dish-image"><a href="#">
                                                                <img src={dish1} alt="" />
                                                            </a></div>
                                                            <div class="title clearfix"><div class="ttl clearfix"><h5>
                                                                <a href="#">Greek Salad</a>
                                                            </h5></div><div class="price"><span>$25.50</span></div></div>
                                                            <div class="text desc"><a href="#">Tomatoes, green bell pepper, sliced cucumber onion, olives, and feta cheese.</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="menu-col col-lg-6 col-md-12 col-sm-12">
                                                <div class="inner">
                                                    <div class="dish-block">
                                                        <div class="inner-box">
                                                            <div class="dish-image"><a href="#">
                                                                <img src={dish1} alt="" />
                                                            </a></div>
                                                            <div class="title clearfix"><div class="ttl clearfix"><h5>
                                                                <a href="#">Greek Salad</a>
                                                            </h5></div><div class="price"><span>$25.50</span></div></div>
                                                            <div class="text desc"><a href="#">Tomatoes, green bell pepper, sliced cucumber onion, olives, and feta cheese.</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="menu-col col-lg-6 col-md-12 col-sm-12">
                                                <div class="inner">
                                                    <div class="dish-block">
                                                        <div class="inner-box">
                                                            <div class="dish-image"><a href="#">
                                                                <img src={dish1} alt="" />
                                                            </a></div>
                                                            <div class="title clearfix"><div class="ttl clearfix"><h5>
                                                                <a href="#">Greek Salad</a>
                                                            </h5></div><div class="price"><span>$25.50</span></div></div>
                                                            <div class="text desc"><a href="#">Tomatoes, green bell pepper, sliced cucumber onion, olives, and feta cheese.</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    {/* <div className="mraTitlesInner">
                                        <div className="mraTitle">
                                            <span>FRESH & HYGINE</span>
                                            <img src={BorderImg} alt="" />
                                            <div className="contentSectn" style={{ margin: "0px" }}>
                                                <h1 >Main Dishes</h1>
                                            </div>
                                        </div>
                                        <div className="row mraMarginBottom">
                                            <div class="menu-col col-lg-6 col-md-12 col-sm-12">
                                                <div class="inner">
                                                    <div class="dish-block">
                                                        <div class="inner-box">
                                                            <div class="dish-image"><a href="#">
                                                                <img src={dish1} alt="" />
                                                            </a></div>
                                                            <div class="title clearfix"><div class="ttl clearfix"><h5>
                                                                <a href="#">Greek Salad</a>
                                                            </h5></div><div class="price"><span>$25.50</span></div></div>
                                                            <div class="text desc"><a href="#">Tomatoes, green bell pepper, sliced cucumber onion, olives, and feta cheese.</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="menu-col col-lg-6 col-md-12 col-sm-12">
                                                <div class="inner">
                                                    <div class="dish-block">
                                                        <div class="inner-box">
                                                            <div class="dish-image"><a href="#">
                                                                <img src={dish1} alt="" />
                                                            </a></div>
                                                            <div class="title clearfix"><div class="ttl clearfix"><h5>
                                                                <a href="#">Greek Salad</a>
                                                            </h5></div><div class="price"><span>$25.50</span></div></div>
                                                            <div class="text desc"><a href="#">Tomatoes, green bell pepper, sliced cucumber onion, olives, and feta cheese.</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="menu-col col-lg-6 col-md-12 col-sm-12">
                                                <div class="inner">
                                                    <div class="dish-block">
                                                        <div class="inner-box">
                                                            <div class="dish-image"><a href="#">
                                                                <img src={dish1} alt="" />
                                                            </a></div>
                                                            <div class="title clearfix"><div class="ttl clearfix"><h5>
                                                                <a href="#">Greek Salad</a>
                                                            </h5></div><div class="price"><span>$25.50</span></div></div>
                                                            <div class="text desc"><a href="#">Tomatoes, green bell pepper, sliced cucumber onion, olives, and feta cheese.</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="menu-col col-lg-6 col-md-12 col-sm-12">
                                                <div class="inner">
                                                    <div class="dish-block">
                                                        <div class="inner-box">
                                                            <div class="dish-image"><a href="#">
                                                                <img src={dish1} alt="" />
                                                            </a></div>
                                                            <div class="title clearfix"><div class="ttl clearfix"><h5>
                                                                <a href="#">Greek Salad</a>
                                                            </h5></div><div class="price"><span>$25.50</span></div></div>
                                                            <div class="text desc"><a href="#">Tomatoes, green bell pepper, sliced cucumber onion, olives, and feta cheese.</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mraTitlesInner">
                                        <div className="mraTitle">
                                            <span>SWEET & SWEET</span>
                                            <img src={BorderImg} alt="" />
                                            <div className="contentSectn" style={{ margin: "0px" }}>
                                                <h1 >Desserts</h1>
                                            </div>
                                        </div>
                                        <div className="row mraMarginBottom">
                                            <div class="menu-col col-lg-6 col-md-12 col-sm-12">
                                                <div class="inner">
                                                    <div class="dish-block">
                                                        <div class="inner-box">
                                                            <div class="dish-image"><a href="#">
                                                                <img src={dish1} alt="" />
                                                            </a></div>
                                                            <div class="title clearfix"><div class="ttl clearfix"><h5>
                                                                <a href="#">Greek Salad</a>
                                                            </h5></div><div class="price"><span>$25.50</span></div></div>
                                                            <div class="text desc"><a href="#">Tomatoes, green bell pepper, sliced cucumber onion, olives, and feta cheese.</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="menu-col col-lg-6 col-md-12 col-sm-12">
                                                <div class="inner">
                                                    <div class="dish-block">
                                                        <div class="inner-box">
                                                            <div class="dish-image"><a href="#">
                                                                <img src={dish1} alt="" />
                                                            </a></div>
                                                            <div class="title clearfix"><div class="ttl clearfix"><h5>
                                                                <a href="#">Greek Salad</a>
                                                            </h5></div><div class="price"><span>$25.50</span></div></div>
                                                            <div class="text desc"><a href="#">Tomatoes, green bell pepper, sliced cucumber onion, olives, and feta cheese.</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="menu-col col-lg-6 col-md-12 col-sm-12">
                                                <div class="inner">
                                                    <div class="dish-block">
                                                        <div class="inner-box">
                                                            <div class="dish-image"><a href="#">
                                                                <img src={dish1} alt="" />
                                                            </a></div>
                                                            <div class="title clearfix"><div class="ttl clearfix"><h5>
                                                                <a href="#">Greek Salad</a>
                                                            </h5></div><div class="price"><span>$25.50</span></div></div>
                                                            <div class="text desc"><a href="#">Tomatoes, green bell pepper, sliced cucumber onion, olives, and feta cheese.</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="menu-col col-lg-6 col-md-12 col-sm-12">
                                                <div class="inner">
                                                    <div class="dish-block">
                                                        <div class="inner-box">
                                                            <div class="dish-image"><a href="#">
                                                                <img src={dish1} alt="" />
                                                            </a></div>
                                                            <div class="title clearfix"><div class="ttl clearfix"><h5>
                                                                <a href="#">Greek Salad</a>
                                                            </h5></div><div class="price"><span>$25.50</span></div></div>
                                                            <div class="text desc"><a href="#">Tomatoes, green bell pepper, sliced cucumber onion, olives, and feta cheese.</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mraTitlesInner">
                                        <div className="mraTitle">
                                            <span>HOT & COLD</span>
                                            <img src={BorderImg} alt="" />
                                            <div className="contentSectn" style={{ margin: "0px" }}>
                                                <h1 >Drinks Menu</h1>
                                            </div>
                                        </div>
                                        <div className="row mraMarginBottom">
                                            <div class="menu-col col-lg-6 col-md-12 col-sm-12">
                                                <div class="inner">
                                                    <div class="dish-block">
                                                        <div class="inner-box">
                                                            <div class="dish-image"><a href="#">
                                                                <img src={dish1} alt="" />
                                                            </a></div>
                                                            <div class="title clearfix"><div class="ttl clearfix"><h5>
                                                                <a href="#">Greek Salad</a>
                                                            </h5></div><div class="price"><span>$25.50</span></div></div>
                                                            <div class="text desc"><a href="#">Tomatoes, green bell pepper, sliced cucumber onion, olives, and feta cheese.</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="menu-col col-lg-6 col-md-12 col-sm-12">
                                                <div class="inner">
                                                    <div class="dish-block">
                                                        <div class="inner-box">
                                                            <div class="dish-image"><a href="#">
                                                                <img src={dish1} alt="" />
                                                            </a></div>
                                                            <div class="title clearfix"><div class="ttl clearfix"><h5>
                                                                <a href="#">Greek Salad</a>
                                                            </h5></div><div class="price"><span>$25.50</span></div></div>
                                                            <div class="text desc"><a href="#">Tomatoes, green bell pepper, sliced cucumber onion, olives, and feta cheese.</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="menu-col col-lg-6 col-md-12 col-sm-12">
                                                <div class="inner">
                                                    <div class="dish-block">
                                                        <div class="inner-box">
                                                            <div class="dish-image"><a href="#">
                                                                <img src={dish1} alt="" />
                                                            </a></div>
                                                            <div class="title clearfix"><div class="ttl clearfix"><h5>
                                                                <a href="#">Greek Salad</a>
                                                            </h5></div><div class="price"><span>$25.50</span></div></div>
                                                            <div class="text desc"><a href="#">Tomatoes, green bell pepper, sliced cucumber onion, olives, and feta cheese.</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="menu-col col-lg-6 col-md-12 col-sm-12">
                                                <div class="inner">
                                                    <div class="dish-block">
                                                        <div class="inner-box">
                                                            <div class="dish-image"><a href="#">
                                                                <img src={dish1} alt="" />
                                                            </a></div>
                                                            <div class="title clearfix"><div class="ttl clearfix"><h5>
                                                                <a href="#">Greek Salad</a>
                                                            </h5></div><div class="price"><span>$25.50</span></div></div>
                                                            <div class="text desc"><a href="#">Tomatoes, green bell pepper, sliced cucumber onion, olives, and feta cheese.</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>  
                                    <div className="offerImgTxt">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className="offerImgLeft">
                                                    <img src={menuDetailImg} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="offerImgLeft">
                                                    <img src={offerImgRight} alt="" />
                                                    <div className="mraOfferInfo">
                                                        <span>HOT DEAL</span>
                                                        <p>Lunch & Dinner Specials</p>
                                                    </div>
                                                    <div className="offerPrice">
                                                        <div class="discount-info">
                                                            <div class="s-ttl">up to</div>
                                                            <div class="num">45%</div>
                                                            <div class="s-ttl">discount</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <MraFooter />
                    </div>
                    </Suspense>
                </div>
            </>
        )
    }
}