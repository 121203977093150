import React, { Component ,Suspense} from 'react';
import bannerimage from "../images/lokal/banner.png"
import Bannerbg from "../images/lokal/bg.png"
// import Header from "../lokalIn/header"
// import Footer from "../lokalIn/footer"
let Header = React.lazy(() => import("../lokalIn/header"));
let Footer = React.lazy(() => import("../lokalIn/footer"));
export default class aboutNew extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (<div>
             <Suspense fallback={<div></div>}>
            <Header></Header>
            <div class="gsa-about-banner gsa-about-banners">
                <img src={bannerimage} alt="" class="img-responsive" width="100%" />
                <h2 class="gsa-about-text">About Us</h2>
            </div>
            <div class="gsa-about-banner  mb-5">
                <img src={Bannerbg} alt="" />
                <div class="gsa-small-retailers">
                    <h1 class="gsa-15m">15M<span class="gsa-15m-sub">SMALL RETAILERS IN INDIA</span></h1>
                </div>
            </div>
            <div class="gsa-container-fluid mb-5">
                <p class="gsa-p-container">Small retailers and Mom & Pop Stores form the very foundation of the Indian Retail Industry,
                    with most of them being family owned and managed. In this digital world, these Indian supermarkets face
                    aggressive competition from online players and large organized retail chains. </p>
            </div>
            <div class="gsa-about-banner mt-2 mb-5">
                <img src={Bannerbg} alt="" />
                <div class="gsa-small-retailers">
                    <h1 class="gsa-15m">92%<span class="gsa-15m-sub1">OF THE SECTOR IS UNORGANIZED</span></h1>
                </div>
            </div>
            <div class="gsa-container-fluid mb-3">
                <p class="gsa-p-container">The urgency to get local grocery stores online is pressing and business owners
                    are now starting to realize this. To meet the competition, Indian supermarkets need to harness the power
                    of the Internet and grow their stores online with grocery delivery options</p>
                <p class="gsa-p-container">To manage an online supermarket though, needs a lot of time, skill and knowledge
                    – which may not be readily available to everyone. As a result, many small, local grocery stores are threatened
                    with the danger of going out of business. </p>
            </div>
            <div>
                <div class="gsa-container-fluid">
                    <h3 class="text-center gsa-silver-line mt-2">THE SILVER LINING – YOU</h3>
                    <p class="gsa-p-container">For years these local grocery stores have catered to your grocery delivery needs –
                        they know each brand, each craving, no matter how mundane it may be. They ensure your Nutella
                        and Protein Shakes are kept in stock, especially that one brand you’re partial to. They know
                        the vegetables you like and the meats you convert into steaming meals for your family, and of
                        course – your guilty pleasures, like that chilled coke at the end of a hot day.</p>
                    <p class="gsa-p-container">They’ve been around and supported you – now they need to know you’ve
                        got their back. Lokal.in is the brainchild of MyyShopp, India’s leading e-Commerce Platform
                        for local shops, Retailers and Business Owners. Nearly 3 years ago, we began our journey as
                        a technology partner with the sole purpose of working with owners of shops across categories
                        like, grocery, pet food, bakeries, restaurants, nutrition & supplements, stationeries, fruits
                        & vegetables and more, to help create and manage their digital identities. </p>
                    <p class="gsa-p-container mb-5">So now we reach out to you – their trusted customers,
                        with a handy platform, where you can order groceries online from your local grocery stores without having to step out of home!</p>
                </div>
            </div>
            <Footer></Footer>
            </Suspense>
        </div>);
    }
}