import React, { Component, Suspense } from 'react';
import Joinpeople from "../myyshopp/joinpeople"
import storybanner from "../images/story-banner.png"
import video from "../images/video.png"
import arrowimg from "../images/downarrow.png"
import Ashwin from "../images/Ashwin.jpg"
import Aakarsh from "../images/Aakarsh.jpg"
import Anugunj from "../images/Anugunj.jpg"
import sudeer from "../images/testimonial/sudeer.jpg"
import yashwardhan from "../images/testimonial/yashwardhan.jpg"
import yash_shetty from "../images/testimonial/yash_shetty.jpg"
let Header = React.lazy(() => import("../header"));
let Footer = React.lazy(() => import("../footer"));
export default class Mystory extends Component {
    render() {
        return (
            <div class="myyshoppWrapperInner myyshopp-main-wrapper mshopp-section-top section-logobg">
                <Suspense fallback={<div></div>}>
                    <Header></Header>
                    <div class="container myyshopp-container p-lg-0 pt-lg-5">
                        <section class="pt-sm-0">
                            <div class="row myystory-div">
                                <div class="col-lg-4">
                                    <div className="myystoryContent">
                                        <h1 class="main-head">real people <br></br><span> real stories</span></h1>
                                        <a href="#myystory-sectionId" class="large-btn large-btn-round">See Stories<img src={arrowimg} alt="" width="17" class="img-fluid ml-2" /></a>
                                    </div>
                                </div>
                                <div class="col-lg-8 storybanner-img">
                                    <img src={storybanner} class="img-fluid" alt=""></img>
                                    <div class="storybanner-video">
                                        <img src={video} class="img-fluid" alt=""></img>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section id="myystory-sectionId">
                            <div class="row myystory-subdiv">
                                <div class="col-lg-5 px-3 mt-3">
                                    <img src={sudeer} class="img-fluid" alt=""></img>
                                    <p class="myystory-details">Sudheer Kanakkod</p>
                                </div>
                                <div class="col-lg-7">
                                    <div className="myystorySubContent">
                                        <p>We started using the MyyShopp platform a year and a half back. I was hesitant to get on board when they approached me, but they promised results. The results promised were seen earlier than expected.
                                            I got more customers who could order online at their convenience. I could showcase more products and run bundled offers. I could also get insights to customer’s ticket sizes to run loyalty programs. This not only helped me get more customers but I got more from my customers!
                                            It was the best decision I took to scale my business.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row myystory-subdiv">
                                <div class="col-lg-7">
                                    <div className="myystorySubContent">
                                        <p>MyyShopp has helped me drive my business smoothly so I can manage all my orders from one platform. I can also track the visits monthly and the success of the campaigns I run. My Restaurant has grown immensely across the 4 outlets around the city.
                                            The Team is extremely helpful and I have a wonderful time working with them. I thank the MyyShopp Team for their constant support at all times.</p>
                                    </div>
                                </div>
                                <div class="col-lg-5 px-3 mt-3">
                                    <img src={yashwardhan} class="img-fluid" alt=""></img>
                                    <p class="myystory-details">Yashwardhan Sinha</p>
                                </div>
                            </div>
                            <div class="row myystory-subdiv">
                                <div class="col-lg-5 px-3 mt-3">
                                    <img src={yash_shetty} class="img-fluid" alt=""></img>
                                    <p class="myystory-details">Yash Shetty</p>
                                </div>
                                <div class="col-lg-7">
                                    <div className="myystorySubContent">
                                        <p>The MyyShopp eCommerce website and platform has helped me in expand my business and create new opportunities for me. It has made my business platform visible to clients residing in different cities and now I can easily get in touch with them and sell my products wherever they are, as well as manage the entire process simply and quickly.
                                            Getting my business online with MyyShopp was one of the best decisions that I have ever made.
                                            The MyyShopp Team is always ready to help me. Even when I was offline and was not able to check my orders, they used to follow up and inform me of what was happening as well as provided helpful advice anytime that I needed it.
                                            The MyyShopp Team Rocks!!</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row myystory-subdiv">
                                <div class="col-lg-7">
                                    <div className="myystorySubContent">
                                        <p>We Started our association with MyyShopp since 2018 and we must say their product is really robust. The Team MyyShopp are very corporative and are continuously improving the overall experience whether in terms of Service or backend management has been top notch.</p>
                                        <p>Their team is very supportive & understanding in terms of the customers business requirements and even in tough situations of lockdown they are prompt to attend for any quires we had.</p>
                                        <p>We are happy to be associated with such a dedicated team with experties in the online world and highly recommend their services.</p>
                                    </div>
                                </div>
                                <div class="col-lg-5 px-3 mt-3">
                                    <img src={Ashwin} class="img-fluid" alt=""></img>
                                    <p class="myystory-details">Ashwin poojari</p>
                                    <p class="myystory-details myystory-detailsSub">Rira Marketing (Director)</p>
                                </div>
                            </div>
                            <div class="row myystory-subdiv">
                                <div class="col-lg-5 px-3 mt-3">
                                    <img src={Aakarsh} class="img-fluid" alt=""></img>
                                    <p class="myystory-details">Aakarsh Singh</p>
                                    <p class="myystory-details myystory-detailsSub">Take-Away-Express (President, Western Region)</p>
                                </div>
                                <div class="col-lg-7">
                                    <div className="myystorySubContent">
                                        <p>We have been associated with MyyShopp for the past two years, it has been a nice experience using their portal for our website and mobile application. The MyyShopp team has been helpful in providing prompt responses and resolutions. All the best to them in their future endeavours.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row myystory-subdiv">
                                <div class="col-lg-7">
                                    <div className="myystorySubContent">
                                        <p>We have been associated with MyyShopp since 2017 and i must say the product has come really well. They built our website & e-Commerce platform from scratch in just few days and are open to any kind of improvements and changes we keep suggesting, continuously improving the overall experience whether in terms of customer feedback or backend management.</p>
                                        <p>Their team is diligent and pro active and even in tough situations of lockdown they were dedicated towards building a robust portal as per our needs.</p>
                                        <p>I am happy to see continued improvements and a new interface experience and highly recommend their service</p>
                                    </div>
                                </div>
                                <div class="col-lg-5 px-3 mt-3">
                                    <img src={Anugunj} class="img-fluid" alt=""></img>
                                    <p class="myystory-details">Anugunj Agarwal</p>
                                    <p class="myystory-details myystory-detailsSub">Partner</p>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Joinpeople></Joinpeople>
                    <Footer></Footer>
                </Suspense>
            </div>
        )
    }
}