import React, { Component } from 'react'
import arrowImg from '../images/lokal-seller/arrow-white.png'
import $ from "jquery"
import { getData } from '../services';
import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';
import TermsAndCondition from './termsAndCondition';
export default class SignupSub extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processFalg: "0",
            fullName: "",
            phoneNo: "",
            emailId: "",
            storeName: "",
            location: "",
            salesPerson: "",
            otp: "",
            planDetails: [],
            razorpaykeysecret: "",
            razorpaykeyid: "",
            planAmount: "",
            planName: "",
            planId: "",
            sellerId: "",
            subscriptionId: "",
            planDescription: "",
            subscriptionPlanDetailsId: "",
            initialAmount: "",
        }
    }
    componentDidMount() {
        $("#Congratulation" + this.props.index).hide();
        $("#Congratulations" + this.props.index).hide();
        $("#FirstBlock" + this.props.index).show();
        $("#paymentsOuter" + this.props.index).hide();
        this.getPlanDetails();
        this.getRazorPayKey();
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
    }
    mail(id){
        // alert(id)
        const postData = {
            "functionName": "sellerRegistrationSendMail",
            "subscriptionSellerDetailsId": id
          }
        getData(postData).then((data) => {
            if (data.data.success === "1") {
            //    alert("yes")
    
            }
            else {
                // alert("no")
            }
        });
    }
    createSubscription() {
        var errorFlag = true;
        this.state.planDetails.map((val, idx) => {
            if ($("#featured-" + idx + "-" + this.props.index).is(":checked")) {
                errorFlag = false;
            }
            else {
                errorFlag = true;
            }
        })
        if ($("#invalidCheck" + this.props.index).is(":checked") === true) {
            $("#sellerRegisterThankYouBtn_payment" + this.props.index).show();
            $("#sellerRegisterThankYouBtn_PROCEED" + this.props.index).hide();
            if(this.state.planId == 'Free'){
                $("#OtpBlock" + this.props.index).hide();
                $("#FirstBlock" + this.props.index).hide();
                $("#paymentsOuter" + this.props.index).hide();
                $("#Congratulations" + this.props.index).show(); 
                this.mail(this.state.sellerId)
                
            }
            else if (this.state.planId !== "" && this.state.planId !== "Free") {
                const postData = {
                    "functionName": "saveSubscriptionDetails",
                    "planId": this.state.planId,
                    "initialAmount": this.state.initialAmount,
                }
                getData(postData).then((data) => {
                    if (data.data.success === "1") {
                        this.setState({ subscriptionId: data.data.responseSubData.id })
                        this.paymentHandler();
                    }
                    else {
                        $("#sellerRegisterThankYouBtn_payment" + this.props.index).hide();
                        $("#sellerRegisterThankYouBtn_PROCEED" + this.props.index).show();
                    }
                });
            }
            else {
                this.paymentHandler();
            }
        }
        else if (errorFlag === true) {
            alert("Select any one Option")
        }
        else {
            alert("Please agree with terms and conditions");
        }
    }
    paymentHandler() {
        let subscriptionPlanDetailsId = this.state.subscriptionPlanDetailsId;
        let sellerId = this.state.sellerId;
        let ids = this.props.index;
        let email = this.state.emailId;
        let mobileNumber = this.state.phoneNo;
        let userName = this.state.fullName;
        var string = this.state.razorpaykeyid + ":" + this.state.razorpaykeysecret;
        var encodedString = btoa(string);
        let options = {
            key: this.state.razorpaykeyid,
            subscription_id: this.state.subscriptionId,
            amount: parseFloat(this.state.planAmount).toFixed(window.sessionStorage.getItem('decimalCount')) * 100,
            name: this.state.planName,
            description: this.state.planDescription,
            image: "",
            modal: {
                ondismiss: function () {
                    $("#sellerRegisterThankYouBtn_payment" + ids).hide();
                    $("#sellerRegisterThankYouBtn_PROCEED" + ids).show();
                }
            },
            handler: function (response) {
                const paymenTId = response.razorpay_payment_id;
                if (paymenTId === "" || paymenTId === null) {
                    $("#sellerRegisterThankYouBtn_payment" + ids).hide();
                    $("#sellerRegisterThankYouBtn_PROCEED" + ids).show();
                }
                else {
                    $("#sellerRegisterThankYouBtn_payment" + ids).show();
                    $("#sellerRegisterThankYouBtn_PROCEED" + ids).hide();
                }
                const postData = {
                    "functionName": "saveRazorPaymentDetails",
                    "paymentId": paymenTId,
                    "subscriptionPlanDetailsId": subscriptionPlanDetailsId,
                    "subscriptionSellerDetailsId": sellerId
                }
                getData(postData).then((data) => {
                    if (data.data.success === "1") {
                        $("#paymentsOuter" + ids).hide();
                        $("#Congratulation" + ids).show();
                        window.scrollTo(0, 0)
                        if (data.data.razorPayData.status === "authorized") {
                            const postDataPaymentStatus = {
                                "functionName": "razorPayStatusChange",
                                "paymentId": data.data.razorPayData.id,
                                "id": data.data.razorPayData.paymentId,
                                "amount": data.data.razorPayData.amount,
                                "currency": data.data.razorPayData.currency
                            }
                          
                            getData(postDataPaymentStatus).then((data) => {
                            });
                        }

                        const postDataMail = {
                            "functionName": "sendMailRazorPaymentDetails",
                            "mailOptions": data.data.mailOptions,
                            "mailOptions1": data.data.mailOptions1
                        }
                        getData(postDataMail).then((data) => {
                        })
                    }
                });
                

            },
            prefill: {
                name: userName,
                email: email,
                contact: mobileNumber,
            },
            theme: {
                color: "#514886",
            },
        };
        let rzp = new window.Razorpay(options);
        rzp.open();
    }
    getRazorPayKey() {
        const postData = {
            "functionName": "getUserConfiguration",
        }
        getData(postData).then((data) => {
            if (data.data.success === "1") {
                this.setState({
                    razorpaykeyid: data.data.result.razorpaykeyid,
                    razorpaykeysecret: data.data.result.razorpaykeysecret
                })
            }
        });
    }
    getPlanDetails() {
        const postData = {
            "functionName": "getPlanDetails",
        }
        getData(postData).then((data) => {
            if (data.data.success === "1") {
                this.setState({ planDetails: data.data.result })
            }
        });
    }
    saveSellerDetails() {
        if ($("#txtName" + this.props.index).val() === "") {
            $("#errorMsgSellerEnquiry" + this.props.index).text("Full Name Required");
            $("#txtName" + this.props.index).focus();
        }
        else if ($("#txtMobileNumber" + this.props.index).val() === "") {
            $("#errorMsgSellerEnquiry" + this.props.index).text("Mobile Number Required");
            $("#txtMobileNumber" + this.props.index).focus();
        }
        else if ($("#txtEmailId" + this.props.index).val() === "") {
            $("#errorMsgSellerEnquiry" + this.props.index).text("Email Id Required");
            $("#txtEmailId" + this.props.index).focus();
        }
        else {
            $("#sellerRegisterBtn" + this.props.index).hide();
            $("#sellerRegisterThankYouBtn" + this.props.index).show();
            $("#errorMsgSellerEnquiry" + this.props.index).text("");
            this.setState({
                fullName: $("#txtName" + this.props.index).val(),
                emailId: $("#txtEmailId" + this.props.index).val(),
                phoneNo: $("#txtMobileNumber" + this.props.index).val(),
                storeName: $("#txtStoreName" + this.props.index).val(),
                location: $("#txtLocation" + this.props.index).val(),
                salesPerson: $("#txtSalesPerson" + this.props.index).val(),
            })
            const postData = {
                "functionName": "saveSellerDetails",
                "name": $("#txtName" + this.props.index).val(),
                "mobileNumber": $("#txtMobileNumber" + this.props.index).val(),
                "emailId": $("#txtEmailId" + this.props.index).val(),
                "siteId": localStorage.getItem('siteId'),
                "storeName": $("#txtStoreName" + this.props.index).val(),
                "location": $("#txtLocation" + this.props.index).val(),
                "salesPerson": $("#txtSalesPerson" + this.props.index).val(),
            }
            getData(postData).then((data) => {
                if (data.data.success === "1") {
                    $("#sellerRegisterBtn" + this.props.index).hide();
                    this.setState({ processFalg: "1" });
                    $("#sellerRegisterThankYouBtn" + this.props.index).hide();
                    $("#OtpBlock" + this.props.index).show();
                    $("#otp" + this.props.index).focus();
                }
            })
        }
    }
    otpSellerDetails() {
        if ($("#otp" + this.props.index).val() === "") {
            $("#errorMsgSellerEnquiry" + this.props.index).text("OTP Required");
            $("#otp" + this.props.index).focus();
        }
        else {
            $("#sellerRegisterThankYouBtn_otp" + this.props.index).show();
            $("#sellerRegisterBtn_otp" + this.props.index).hide();
            const postData = {
                "functionName": "saveSellerDetails",
                "name": this.state.fullName,
                "mobileNumber": this.state.phoneNo,
                "emailId": this.state.emailId,
                "siteId": localStorage.getItem('siteId'),
                "otp": $("#otp" + this.props.index).val(),
                "storeName": this.state.storeName,
                "location": this.state.location,
                "salesPerson": this.state.salesPerson
            }
            getData(postData).then((data) => {
                if (data.data.success === "1") {
                    $("#OtpBlock" + this.props.index).hide();
                    $("#FirstBlock" + this.props.index).hide();
                    $("#paymentsOuter" + this.props.index).show();
                    this.setState({ sellerId: data.data.sellerDetails[0].id })
                }
                else {
                    $("#sellerRegisterThankYouBtn_otp" + this.props.index).hide();
                    $("#sellerRegisterBtn_otp" + this.props.index).show();
                    alert(data.data.errorMessage)
                }
            });
        }
    }
    getSelectedValue(planId, planName, planAmount, subscriptionId, planDescription, subscriptionPlanDetailsId, initialAmount) {
        this.setState({
            planId: planId,
            planName: planName,
            planAmount: planAmount,
            subscriptionId: subscriptionId,
            planDescription: planDescription,
            subscriptionPlanDetailsId: subscriptionPlanDetailsId,
            initialAmount: initialAmount
        })
    }
    handleNumberChange(event) {
        var mobiNo = $("#txtMobileNumber" + this.props.index).val();
        if (/\D/g.test(mobiNo)) {
            $("#txtMobileNumber" + this.props.index).val(mobiNo.replace(/\D/g, ""));
        }
    }

    openTermsConditionPopup() {
        $("#termsConditionPopup").show()
    }
    render() {
        window.scrollTo(0, 0)
        return (
            <div className="signuploginWrapper">
                <div id={"FirstBlock" + this.props.index}>
                    <div class="lkbanner-errorMsg" id={"errorMsgSellerEnquiry" + this.props.index}></div>
                    <div className="lkbannerPincode lkbannerPincodeMargin">
                        <input type="text" placeholder="Full Name" id={"txtName" + this.props.index} />
                    </div>
                    <div className="lkbannerPincode lkbannerPincodeMargin">
                        <input type="text" placeholder="Mobile Number" id={"txtMobileNumber" + this.props.index}
                            onChange={(e) => {
                                this.handleNumberChange(this.value);
                            }} />
                    </div>
                    <div className="lkbannerPincode lkbannerPincodeMargin">
                        <input type="text" placeholder="Email Id" id={"txtEmailId" + this.props.index} />
                    </div>
                    <div className="lkbannerPincode lkbannerPincodeMargin">
                        <input type="text" placeholder="Store Name (Optional)" id={"txtStoreName" + this.props.index} />
                    </div>
                    <div className="lkbannerPincode lkbannerPincodeMargin">
                        <input type="text" placeholder="Location (Optional)" id={"txtLocation" + this.props.index} />
                    </div>
                    <div className="lkbannerPincode lkbannerPincodeMargin">
                        <input type="text" placeholder="Sales Person (Optional)" id={"txtSalesPerson" + this.props.index} />
                    </div>
                    <div>
                        <span className="bannerButton bannerButtonPadng bannerButtonOTPMargin" onClick={() => this.saveSellerDetails()} id={"sellerRegisterBtn" + this.props.index}>Register <img src={arrowImg} alt="" /></span>
                        <span className="bannerButton bannerButtonPadng" id={"sellerRegisterThankYouBtn" + this.props.index} style={{ display: 'none' }}>Processing...</span>
                        <Link to="/" ><button id={"gotoHome" + this.props.index} style={{ display: 'none' }}></button></Link>
                    </div>
                </div>
                <div id={"OtpBlock" + this.props.index} style={{ display: "none" }}>
                    <span class="lkbanner-errorMsg" id={"errorMsgSellerEnquiry_otp" + this.props.index}></span>
                    <div className="lkbannerPincode lkbannerPincodeMargin">
                        <input type="text" placeholder="Enter Your OTP" id={"otp" + this.props.index} />
                    </div>
                    <div>
                        <span className="bannerButton bannerButtonPadng bannerButtonOTPMargin" onClick={() => this.saveSellerDetails()} id={"sellerRegisterBtn_otp" + this.props.index}>Re-sent OTP </span>
                        <span className="bannerButton bannerButtonPadng bannerButtonOTPMargin" onClick={() => this.otpSellerDetails()} id={"sellerRegisterBtn_otp" + this.props.index}>Signup <img src={arrowImg} alt="" /></span>
                        <span className="bannerButton bannerButtonPadng" id={"sellerRegisterThankYouBtn_otp" + this.props.index} style={{ display: 'none' }}>Processing...</span>
                    </div>
                </div>
                <div className="paymentsOuter" id={"paymentsOuter" + this.props.index} style={{ display: "none" }}>
                    <div className="lkbannerPincode mb-2">
                        <div className="signupLabelText mb-2 paymentTextHeadTop">Payment Options</div>
                        <div class="custom_radio">
                            {this.state.planDetails.map((plan, i) => {
                                return (
                                    <span>
                                        <input type="radio" id={"featured-" + i + "-" + this.props.index} name="featured"
                                            defaultValue={plan.subscriptionId}
                                            onClick={() => this.getSelectedValue(plan.planId, plan.planName, plan.planAmount, plan.subscriptionId, plan.planDescription, plan.subscriptionPlanDetailsId, plan.initialAmount)} />
                                        <label for={"featured-" + i + "-" + this.props.index} className="signupLabelText paymentlabel">
                                            {Parser(plan.planName)}<br />
                                        </label>
                                        <br />
                                    </span>
                                )
                            })}
                        </div>
                    </div>
                    <div className="signupLabelText mb-3">
                        <p>All fees will be applicable in advance and will be charged at the time of sign up.</p>
                    </div>
                    <div className="custom-checkbox">
                        <input class="styled-checkbox" id={"invalidCheck" + this.props.index} type="checkbox" value="value1" />
                        <label className="signupLabelText termscondtnWidth invalidCheck">
                            <span>I Agree to</span><a href="#" onClick={() => this.openTermsConditionPopup()}> Terms & Conditions</a>
                            <span> of Merchant Agreement</span> </label>
                    </div>
                    <div class="popupWrap" id="termsConditionPopup">
                        <TermsAndCondition></TermsAndCondition>
                    </div>
                    <div>
                        <span className="bannerButton bannerButtonPadng" id={"sellerRegisterThankYouBtn_payment" + this.props.index} style={{ display: 'none' }}>Processing...</span>
                        <button className="bannerButton bannerButtonWidth mb-3" id={"sellerRegisterThankYouBtn_PROCEED" + this.props.index}
                            onClick={() => this.createSubscription()}>PROCEED</button>
                    </div>
                </div>
                <div className="paymentConfirmedDiv mt-4" id={"Congratulation" + this.props.index}>
                    <div className="paymentconfrmedText paymentconfrmedTextcolor">Payment confirmed.</div>
                    <div className="paymentconfrmedText">Congratulation !</div>
                    <div className="paymentconfrmedText">Thank You For signing up.</div>
                    <div className="paymentconfrmedText">The Team will reachout to you !</div>
                </div>
                <div className="paymentConfirmedDiv mt-4" id={"Congratulations" + this.props.index}>
                    <div className="paymentconfrmedText paymentconfrmedTextcolor">Registered Successfully.</div>
                    <div className="paymentconfrmedText">Congratulation !</div>
                    <div className="paymentconfrmedText">Thank You For signing up.</div>
                    <div className="paymentconfrmedText">The Team will reachout to you !</div>
                </div>
            </div>
        )
    }
}