import React, { Component } from 'react';
export default class UploadedPrescription extends Component {
    render() {
        return (
            this.props.isPharmacy == "1" ?
                <div className="uploadedPrescription">
                    <div className="uploadedPrescriptionText">Uploaded Prescriptions</div>
                    {this.props.cartItems.map((groceryCartItems) =>
                        groceryCartItems.item_names === "" ?
                            <div className="uploadedPrescriptionLink">
                                <a href={groceryCartItems.phAttachment} target="_blank">{groceryCartItems.phAttachmentName}</a>
                            </div>
                            :
                            ''
                    )}
                </div>
                : ''
        )
    }
}