import React, { Component } from 'react';
import { getData } from '../services';
import jwt from 'jsonwebtoken'
import config from '../config'
export default class changePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            oldPassword: "",
            confirmPassword: "",
            errors: {},
            fields: {},
        }
        this.handleoldPasswordChange = this.handleoldPasswordChange.bind(this);
        this.handlepasswordChange = this.handlepasswordChange.bind(this);
        this.handleconfirmPasswordChange = this.handleconfirmPasswordChange.bind(this);
        this.handleChangeClick = this.handleChangeClick.bind(this);
    }
    componentDidMount() {
    }
    handleoldPasswordChange = event => {
        this.setState({
            oldPassword: event.target.value
        });
    };
    handlepasswordChange = event => {
        this.setState({
            password: event.target.value
        });
    };
    handleconfirmPasswordChange = event => {
        this.setState({
            confirmPassword: event.target.value
        });
    };
    handleChangeClick = (event) => {
        event.preventDefault();
        document.getElementsByClassName("changePwrdErrorMsg").text = "";
        let userId = localStorage.getItem('userId');
        let oldPassword = this.state.oldPassword;
        let password = this.state.password;
        let confirmPassword = this.state.confirmPassword;
        var changePwrdErrorMsg = "";
        let formIsValid;
        document.getElementsByClassName("changePwrdErrorMsg")[0].innerHTML = "";
        if (!oldPassword) {
            changePwrdErrorMsg = "Current Password Required";
            document.getElementById("oldPassword").focus();
            formIsValid = false;
        }
        else if (!password) {
            changePwrdErrorMsg = "New Password Required";
            alert(document.getElementById("changedPassword").value)
            document.getElementById("changedPassword").focus();
            formIsValid = false;
        }
        else if (password.length < 6) {
            changePwrdErrorMsg = "Minimum 6 Characters Required in New Password";
            document.getElementById("changedPassword").focus();
            formIsValid = false;
        }
        else if (!confirmPassword) {
            changePwrdErrorMsg = "Confirm Password Required";
            document.getElementById("confirmChangedPassword").focus();
            formIsValid = false;
        }
        else if (confirmPassword !== password) {
            changePwrdErrorMsg = "New Password and Confirm Password does not match";
            document.getElementById("changedPassword").focus();
            formIsValid = false;
        }
        else {
            formIsValid = true;
        }
        if (!formIsValid) {
            document.getElementsByClassName("changePwrdErrorMsg")[0].innerHTML = changePwrdErrorMsg;
            return false;
        }
        else {
            const token = jwt.sign({
                userIdInChangePwrd: userId,
                oldPasswordInChangePwrd: oldPassword,
                newPasswordInChangePwrd: confirmPassword

            }, config.jwtSecret);
            const postData = {
                functionName: "userverification/changePassword",
                token: token
            }
            getData(postData).then((data) => {
                if (data.data.success === "1") {
                    document.getElementsByClassName("changePwrdErrorMsg")[0].innerHTML = "Password Changed Successfully";
                    document.getElementById("oldPassword").value = "";
                    document.getElementById("changedPassword").value = "";
                    document.getElementById("confirmChangedPassword").value = "";
                    this.setState({
                        oldPassword: '',
                        password: '',
                        confirmPassword: ''
                    });
                }
                else {
                    document.getElementsByClassName("changePwrdErrorMsg")[0].innerHTML = data.data.errorMessage;
                    document.getElementById("oldPassword").value = "";
                    document.getElementById("changedPassword").value = "";
                    document.getElementById("confirmChangedPassword").value = ";"
                    this.setState({
                        oldPassword: '',
                        password: '',
                        confirmPassword: ''
                    });
                }
            })
        }
    }
    render() {
        return (
            <div>
                <div className="accountdetails">
                    <div className="dashTitle">Change Password</div>
                    <div className="dashSection">
                        <div className="addressForm">
                            <form>
                                <div className="text-success personalMsgSuccess text-center"></div>
                                <div className="errorMsg changePwrdErrorMsg" id=""></div>
                                <div className="adressFormList">
                                    <label className="labelField">Current password<sup>*</sup></label>
                                    <div className="formInputSection">
                                        <input type="password" tabindex='1' className="formInputField" id="oldPassword" value={this.state.oldPassword} onChange={this.handleoldPasswordChange} placeholder="" />
                                    </div>
                                </div>
                                <div className="adressFormList">
                                    <label className="labelField">New password<sup>*</sup></label>
                                    <div className="formInputSection">
                                        <input type="password" tabindex='2' className="formInputField" id="changedPassword" value={this.state.password} onChange={this.handlepasswordChange} placeholder="" />
                                    </div>
                                </div>
                                <div className="adressFormList">
                                    <label className="labelField">Confirm New password<sup>*</sup></label>
                                    <div className="formInputSection">
                                        <input type="password" tabindex='3' className="formInputField" id="confirmChangedPassword" value={this.state.confirmPassword} onChange={this.handleconfirmPasswordChange} placeholder="" />
                                    </div>
                                </div>
                                <div className="adressFormButton adressButtonTop">
                                    <span class="commonButton" tabindex='4' type="submit" onClick={this.handleChangeClick}>Save</span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
